import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { VIDEO_PUBLISH_STATE, VIDEO_STATUS } from "@shared/models/video/video";
import {
  formatViews,
  getMetaInfo,
  getVideoCreateDate,
} from "src/app/utility/video-utils";
import {
  PollResults,
  VideoService,
} from "src/app/services/video/video.service";
import { Subscription } from "rxjs";
import { MediaProgressComponent } from "../../shared/media-progress/media-progress.component";
import {
  ContextMenuComponent,
  ContextMenuOption,
} from "../../shared/context-menu/context-menu.component";
import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { VIDEO } from "src/app/models/video/video";
import { T } from "src/app/services/localization/localization.service";
import { UserService } from "src/app/services/user/user.service";
import { USER_DTO } from "@shared/models/user/user";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { uuidToShortId } from "@shared/utils/utils";
import {
  UploadInfo,
  UploadService,
} from "src/app/services/upload/upload.service";
import { SessionService } from "src/app/services/session/session.service";
import { FastAverageColor } from "fast-average-color";
import { AlertModalOptions } from "../../modals/alert-modal/alert-modal.component";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { UiService } from "src/app/services/ui/ui.service";

interface OptionContent {
  icon: string;
  action: (event: Event) => void;
  disabled?: boolean;
  tooltipText?: string;
}

@Component({
  selector: "video-card",
  templateUrl: "./video-card.component.html",
  styleUrls: ["./video-card.component.scss"],
})
export class VideoCardComponent implements OnInit {
  //Component Inputs
  @Input()
  set VideoData(value: VIDEO | null) {
    if (value == null) return;
    if (this.m_VideoData == null) {
      this.m_VideoData = value;
      if (!this.shouldShowThumbnail()) {
        this.checkProgress();
      }
      this.initializeCard();
    } else {
      this.m_VideoData = value;
    }
  }
  @Input() m_Edit: boolean = false;
  @Input() m_Library: boolean = false;
  @Input() m_ShowJustThumbnail: boolean = false;
  @Input() m_AutoWidth: boolean = false;
  @Input() m_SendToWatchPage: boolean = false;
  @Input() m_AllowClickToOpen: boolean = true;
  @Input() m_DisableActions: boolean = false;

  //Component References
  @ViewChild(MediaProgressComponent) m_VideoProgressComponent:
    | MediaProgressComponent
    | undefined;

  @ViewChild("contextMenu", { read: ContextMenuComponent }) m_CtxMenu:
    | ContextMenuComponent
    | undefined;

  @ViewChild("publishContextMenu", { read: ContextMenuComponent })
  m_PublishCtxMenu: ContextMenuComponent | undefined;

  public $t = T.translate;
  public m_Duration: string = "";
  public m_TimedOut: boolean = false;
  public m_VideoCreationDate: string = "";
  public m_IsPublishing: boolean = false;
  public m_ViewsDisplay: string = "";
  public m_Hovered: boolean = false;
  public m_BackgroundColor: string = "#363434";
  public m_ThumbLoading: boolean = true;

  //--------------------------------------------------------------------
  //Polling events
  private m_OnVideoReadySub: Subscription | null = null;
  private m_OnIndexReadySub: Subscription | null = null;
  private m_OnVideoProgressSub: Subscription | null = null;
  private m_OnVideoTimeoutSub: Subscription | null = null;
  private m_OnVideoUploadCompleteSub: Subscription | null = null;
  private m_PollID: string | null = null;
  private m_VideoData: VIDEO | null = null;
  private m_UserData: USER_DTO | null = null;
  private m_Errored: boolean = false;
  private m_ContextOptions: ContextMenuOption[] = [];
  private m_CardFooterOptions: OptionContent[] = [];
  private m_PublishContextOptions: ContextMenuOption[] = [];
  private m_UploadInfo?: UploadInfo;

  get VideoId() {
    return this.m_VideoData?.video_id;
  }
  get Errored() {
    return this.m_Errored;
  }
  get VideoData() {
    return this.m_VideoData;
  }

  //--------------------------------------------------------------------
  constructor(
    private m_VideoService: VideoService,
    private m_AuthService: SessionService,
    private m_Router: Router,
    private m_AlertController: AlertController,
    private m_Events: Events,
    private m_UserService: UserService,
    private m_Analytics: AnalyticsService,
    private m_UploadService: UploadService,
    private m_NotificationService: HIANotificationService,
    private m_UiService: UiService
  ) {}
  //--------------------------------------------------------------------
  ngOnInit() {
    try {
      this.getBackgroundColor();
      if (this.m_VideoData?.video_user_id != this.m_AuthService.UserId)
        this.getUserData();
      else this.m_UserData = this.m_UserService.ActiveUserInfo;
    } catch (error) {
      console.error(error);
    }
  }
  ngOnDestroy() {
    this.unsubscribeFromPolling();
  }
  //--------------------------------------------------------------------
  //#region Event Handlers
  onVideoClicked() {
    if (this.m_DisableActions) return;
    if (
      this.m_VideoData?.video_user_id == this.m_AuthService.UserId &&
      !this.m_SendToWatchPage
    ) {
      this.onEditClicked();
    } else {
      this.m_Router.navigate(["/watch"], {
        queryParams: { v: uuidToShortId(this.m_VideoData?.video_id || "") },
      });
    }
  } //--------------------------------------------------------------------
  onQnAClicked() {
    if (this.m_VideoData?.video_user_id == this.m_AuthService.UserId) {
      let page = this.m_Router.url.split("?")[0];
      this.m_Analytics.trackClickEvent(
        this.getButtonName("video-card-edit-"),
        page
      );

      this.m_UiService.setExpandSidebar(false);

      this.m_Router.navigate(["/edit"], {
        queryParams: {
          v: uuidToShortId(this.m_VideoData?.video_id || ""),
          qna: true,
        },
      });
    }
  }
  //--------------------------------------------------------------------
  onOptionsClicked(event: Event) {
    if (this.m_DisableActions) return;
    event.preventDefault();

    let page = this.m_Router.url.split("?")[0];
    this.m_Analytics.trackClickEvent(
      this.getButtonName("video-card-options-"),
      page
    );
    event.stopPropagation();
    this.m_CtxMenu?.open(event);
  }
  //--------------------------------------------------------------------
  onPublishOptionsClicked(event: Event) {
    let page = this.m_Router.url.split("?")[0];
    this.m_Analytics.trackClickEvent(
      this.getButtonName("video-card-publish-options-"),
      page
    );
    event.stopPropagation();
    this.m_PublishCtxMenu?.open(event);
  }
  //--------------------------------------------------------------------
  async onRefreshClicked() {
    this.checkProgress();
  }
  //--------------------------------------------------------------------
  async onDeleteClicked() {
    let page = this.m_Router.url.split("?")[0];
    this.m_Analytics.trackClickEvent(
      "video-card-delete-" + this.m_VideoData?.video_id,
      page
    );

    // Show alert modal
    let alertConfig: AlertModalOptions = {
      mainText: this.$t("components.alert.deleteAlert.title"),
      description: this.$t("components.alert.deleteAlert.message"),
      allowCancel: true,
      showIcon: true,
      customClass: "delete-modal",
      allowNeverShow: false,
      customButtons: [
        {
          label: this.$t("components.alert.deleteAlert.button"),
          color: "#dd2222cc",
          close: true,
          callback: async () => {
            this.deleteVideo();
          },
        },
      ],
    };

    this.m_Events.publish(EVENTS.ALERT, alertConfig);
  }
  //--------------------------------------------------------------------
  async onShareClicked() {
    if (this.m_VideoData == null) return;
    let sharelink = this.getSharableLink();
    if (!sharelink) return;
    await navigator.clipboard.writeText(sharelink).then(
      () => {
        this.m_NotificationService.showSuccess(
          this.$t("shared.messages.linkCopied"),
          2000
        );
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  }

  onPlaylistsClicked() {
    if (!this.m_VideoData) return;
    this.m_Events.publish(EVENTS.OPEN_PLAYLIST_MODAL, {
      videoIds: [this.m_VideoData.video_id],
    });
  }

  async onFoldersClicked() {
    if (this.m_VideoData == null) return;
    this.m_Events.publish(EVENTS.SHOW_FOLDERS_MODAL, {
      video_ids: [this.m_VideoData.video_id],
      videos: [this.m_VideoData],
    });
  }
  //--------------------------------------------------------------------
  async onReuploadClicked() {
    this.m_Events.publish(EVENTS.REUPLOAD_VIDEO, this.m_VideoData);
  }
  //--------------------------------------------------------------------
  async onUserProfileClicked(event: Event) {
    if (this.m_VideoData == null || this.m_UserData == null) return;

    this.m_Router.navigate([`/u/${this.m_UserData.username}`]);
    event.stopPropagation();
  }
  //--------------------------------------------------------------------
  onEditClicked() {
    let page = this.m_Router.url.split("?")[0];
    this.m_Analytics.trackClickEvent(
      this.getButtonName("video-card-edit-"),
      page
    );

    this.m_Router.navigate(["/edit"], {
      queryParams: { v: uuidToShortId(this.m_VideoData?.video_id || "") },
    });
  }
  //--------------------------------------------------------------------
  onVideoCardMouseEnter() {
    this.m_Hovered = true;
  }
  //--------------------------------------------------------------------
  onVideoCardMouseLeave() {
    this.m_Hovered = false;
  }
  //#endregion
  //#region Render Methods
  //--------------------------------------------------------------------
  getBackgroundColor() {
    if (!this.VideoData) return;

    if (this.VideoData.video_thumb_url) {
      this.m_ThumbLoading = true;

      const fac = new FastAverageColor();
      const img = new Image();

      img.src = this.VideoData.video_thumb_url;
      img.crossOrigin = "anonymous";

      img.onload = () => {
        this.m_BackgroundColor = fac.getColor(img).hex;
        this.m_ThumbLoading = false;
      };
    }
  }
  //--------------------------------------------------------------------
  getButtonName(name: string) {
    return name + "_" + this.m_VideoData?.video_id ?? "";
  }
  getTitle() {
    return this.m_VideoData?.video_name;
  }
  //--------------------------------------------------------------------
  getMetaInfo() {
    return `${getMetaInfo(this.m_VideoData)}`;
  }

  getVideoDate() {
    return `${getVideoCreateDate(this.m_VideoData)}`;
  }
  //--------------------------------------------------------------------
  getUserProfilePic() {
    if (this.m_VideoData?.video_user_id == this.m_AuthService.UserId)
      return this.m_UserService.UserProfilePicture;
    if (this.m_UserData == null) return "assets/icon/user/default-avatar.svg";
    return this.m_UserData.profile_picture_url;
  }
  //--------------------------------------------------------------------
  getUsername() {
    if (this.m_VideoData?.video_user_id == this.m_AuthService.UserId)
      return this.m_UserService.ActiveUserInfo?.username ?? "";
    if (this.m_UserData == null) return "";
    return this.m_UserData.username;
  }
  //--------------------------------------------------------------------
  getFullname() {
    if (!this.m_UserData?.first_name || !this.m_UserData.last_name) {
      return this.m_UserData?.username ?? "";
    }
    return `${this.m_UserData?.first_name} ${this.m_UserData?.last_name}`;
  }
  //--------------------------------------------------------------------
  shouldShowThumbnail() {
    //return if video is not errored in encode status or index status and if it isn't processing in encode status or index status in one line
    return (
      this.m_VideoData?.video_status != VIDEO_STATUS.PROCESSING &&
      this.m_VideoData?.video_index_status != VIDEO_STATUS.PROCESSING &&
      !this.Errored
    );
  }
  //--------------------------------------------------------------------
  shouldShowOpts() {
    return (
      this.m_VideoData?.video_user_id == this.m_AuthService.UserId &&
      !this.m_ShowJustThumbnail
    );
  }
  //--------------------------------------------------------------------
  shouldShowInfo() {
    return !this.m_ShowJustThumbnail;
  }
  //--------------------------------------------------------------------
  getThumbnail() {
    let url = this.m_VideoData?.video_thumb_url;

    if (url == null || url === "") {
      //Check if video is errored in encode status or index status
      if (
        this.m_VideoData?.video_status == VIDEO_STATUS.ERROR ||
        this.m_VideoData?.video_index_status == VIDEO_STATUS.ERROR
      ) {
        return "assets/icon/video/video-error.svg";
      }
      return "assets/icon/video/video-error.svg";
    }

    return url;
  }
  //--------------------------------------------------------------------
  getVisibilityText() {
    switch (this.m_VideoData?.video_publish_state) {
      case VIDEO_PUBLISH_STATE.PRIVATE:
        return this.$t("components.videoPublish.private");
      case VIDEO_PUBLISH_STATE.PUBLIC:
        return this.$t("components.videoPublish.public");
      case VIDEO_PUBLISH_STATE.UNLISTED:
        return this.$t("components.videoPublish.unlisted");
      default:
        return this.$t("components.videoPublish.private");
    }
  }
  //--------------------------------------------------------------------
  getVisibilityTooltip() {
    switch (this.m_VideoData?.video_publish_state) {
      case VIDEO_PUBLISH_STATE.PRIVATE:
        return this.$t("components.videoPublish.private");
      case VIDEO_PUBLISH_STATE.PUBLIC:
        return this.$t("components.videoPublish.public");
      case VIDEO_PUBLISH_STATE.UNLISTED:
        return this.$t("components.videoPublish.unlisted");
      default:
        return this.$t("components.videoPublish.private");
    }
  }
  //--------------------------------------------------------------------
  getPlaceholderText() {
    //return "Processing [Video] or [AI]" if video is processing in encode status or index status
    if (this.m_VideoData?.video_status == VIDEO_STATUS.PROCESSING) {
      return this.$t("shared.messages.processingVideo");
    } else if (
      this.m_VideoData?.video_index_status == VIDEO_STATUS.PROCESSING
    ) {
      return this.$t("shared.messages.processingAI");
    } else {
      return this.$t("shared.messages.unknownError");
    }
  }
  //--------------------------------------------------------------------
  getContextMenuOpts() {
    return this.m_ContextOptions;
  }
  //--------------------------------------------------------------------
  getBottomMenuOpts() {
    return this.m_CardFooterOptions;
  }
  //--------------------------------------------------------------------
  getPublishContextMenuOpts() {
    return this.m_PublishContextOptions;
  }
  //--------------------------------------------------------------------
  isUploading(): boolean {
    return this.m_UploadInfo?.uploading || false;
  }
  //--------------------------------------------------------------------
  uploadProgress(): number {
    return this.m_UploadInfo?.uploadProgress || 0;
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region private methods
  private async checkProgress() {
    if (
      this.m_VideoData?.video_id == null ||
      this.m_VideoData.video_status == VIDEO_STATUS.PENDING
    )
      return;
    this.m_TimedOut = false;

    if (this.m_VideoData.video_status == VIDEO_STATUS.PROCESSING) {
      this.m_OnVideoReadySub = this.m_VideoService.OnVideoReady.subscribe(
        (results: PollResults) => {
          this.onVideoReady(results);
        }
      );
    }

    if (this.m_VideoData.video_index_status <= VIDEO_STATUS.PROCESSING) {
      this.m_OnIndexReadySub = this.m_VideoService.OnIndexReady.subscribe(
        (results: PollResults) => {
          this.onIndexReady(results);
        }
      );
    }

    this.m_OnVideoProgressSub = this.m_VideoService.OnVideoProgress.subscribe(
      (results: PollResults) => {
        this.onVideoProgress(results);
      }
    );

    this.m_OnVideoTimeoutSub = this.m_VideoService.OnVideoTimeout.subscribe(
      (results: PollResults) => {
        this.handleTimeout(results);
      }
    );

    this.m_PollID = await this.m_VideoService.startVideoStatusPolling(
      this.m_VideoData?.video_id
    );

    setTimeout(() => {
      if (this.m_VideoData?.video_status == VIDEO_STATUS.PROCESSING) {
        this.setProgress(0, this.$t("shared.messages.processingVideo"));
      } else if (
        this.m_VideoData?.video_index_status == VIDEO_STATUS.PROCESSING
      ) {
        this.setProgress(0, this.$t("shared.messages.processingAI"));
      }
    }, 1);
  }
  //--------------------------------------------------------------------
  private async onVideoReady(results: PollResults) {
    if (results.video_id == this.m_VideoData?.video_id) {
      this.m_OnVideoReadySub?.unsubscribe();
      try {
        let newData = await this.m_VideoService.fetchVideo(
          this.m_VideoData?.video_id
        );
        this.m_VideoData = newData;
      } catch (error) {
        console.error(error);
        this.handleVideoError(this.$t("shared.messages.errorLoadingVideo"));
        return;
      }
    }
  }
  //--------------------------------------------------------------------
  private async onIndexReady(results: PollResults) {
    if (results.video_id == this.m_VideoData?.video_id) {
      this.unsubscribeFromPolling();
      try {
        let newData = await this.m_VideoService.fetchVideo(
          this.m_VideoData?.video_id
        );
        this.m_VideoData = newData;
      } catch (error) {
        console.error(error);
        this.handleVideoError(this.$t("shared.messages.errorLoadingVideo"));
        return;
      }
    }
  }
  //--------------------------------------------------------------------
  private async onVideoProgress(results: PollResults) {
    if (results.video_id == this.m_VideoData?.video_id) {
      let progress = results.progress || 1;
      this.setProgress(progress, this.getPlaceholderText());
    }
  }
  //--------------------------------------------------------------------
  private unsubscribeFromPolling() {
    this.m_OnVideoProgressSub?.unsubscribe();
    this.m_OnVideoReadySub?.unsubscribe();
    this.m_OnIndexReadySub?.unsubscribe();
    this.m_OnVideoTimeoutSub?.unsubscribe();
    this.m_OnVideoUploadCompleteSub?.unsubscribe();

    if (this.m_PollID != null)
      this.m_VideoService.stopVideoStatusPolling(this.m_PollID);
  }
  //--------------------------------------------------------------------
  private handleVideoError(error: any) {
    console.log(`Error loading video ${this.m_VideoData?.video_id}: ${error}`);
    this.m_Errored = true;
    this.setProgress(100, error);
    this.unsubscribeFromPolling();
    return false;
  }
  //--------------------------------------------------------------------
  private handleTimeout(results: PollResults) {
    if (results.video_id != this.m_VideoData?.video_id) return;

    this.unsubscribeFromPolling();
    this.m_TimedOut = true;
    this.setProgress(100, this.$t("shared.messages.timeoutRefresh"));
  }
  //--------------------------------------------------------------------
  private initContextMenus() {
    if (!this.m_VideoData) return;

    //Initialize context menu options
    this.m_ContextOptions = [
      {
        text: this.$t("shared.button.share"),
        icon: "assets/icon/card/share.svg",
        action: this.onShareClicked.bind(this),
        disabledTooltip: this.$t("pages.library.copyLink_tooltip_private"),
        disabled:
          this.m_VideoData?.video_publish_state === VIDEO_PUBLISH_STATE.PRIVATE,
      },
      {
        text: this.$t("shared.button.rename"),
        icon: "assets/icon/card/edit.svg",
        action: this.onEditClicked.bind(this),
      },
      {
        text: this.$t("shared.button.move") + "...",
        icon: "assets/icon/card/folder.svg",
        action: this.onFoldersClicked.bind(this),
      },
      {
        text: this.$t("shared.button.delete"),
        icon: "assets/icon/card/delete.svg",
        action: this.onDeleteClicked.bind(this),
        disabled: this.m_VideoData?.read_only,
        disabledTooltip: this.$t("shared.messages.readOnly"),
      },
      {
        text: this.$t("shared.button.addPlaylist"),
        icon: "assets/icon/card/playlist.svg",
        action: this.onPlaylistsClicked.bind(this),
      },
    ];

    if (this.m_UserService.IsAdmin) {
      this.m_ContextOptions.push({
        text: this.$t("shared.button.reupload"),
        icon: "assets/icon/card/reupload.svg",
        action: this.onReuploadClicked.bind(this),
        disabled: this.m_VideoData?.read_only,
        disabledTooltip: this.$t("shared.messages.readOnly"),
      });
    }

    if (this.m_VideoData) {
      let userPublishStates =
        this.m_UserService.ActiveUserLimits?.publishStates;

      const userPublishStatesSet = new Set(
        userPublishStates?.map((state) => state.enum_value)
      );
      //Initialize publish context menu options
      this.m_PublishContextOptions = [
        {
          // Private
          text: this.$t("components.videoPublish.private"),
          disabled:
            this.m_VideoData?.video_publish_state ===
              VIDEO_PUBLISH_STATE.PRIVATE ||
            !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PRIVATE),
          disabledTooltip: !userPublishStatesSet.has(
            VIDEO_PUBLISH_STATE.PRIVATE
          )
            ? this.$t("shared.tooltip.requirePermission")
            : this.$t("shared.tooltip.alreadyInVisibility").replace(
                "{0}",
                this.$t("components.videoPublish.private").toLowerCase()
              ),
          action: () => {
            let page = this.m_Router.url.split("?")[0];
            this.m_Analytics.trackClickEvent(
              this.getButtonName("video-card-publish-private-"),
              page
            );

            this.setVisibility(VIDEO_PUBLISH_STATE.PRIVATE);
          },
        },
        {
          // Unlisted
          text: this.$t("components.videoPublish.unlisted"),
          disabled:
            this.m_VideoData?.video_publish_state ===
              VIDEO_PUBLISH_STATE.UNLISTED ||
            !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.UNLISTED),
          disabledTooltip: !userPublishStatesSet.has(
            VIDEO_PUBLISH_STATE.UNLISTED
          )
            ? this.$t("shared.tooltip.requirePermission")
            : this.$t("shared.tooltip.alreadyInVisibility").replace(
                "{0}",
                this.$t("components.videoPublish.unlisted").toLowerCase()
              ),
          action: () => {
            let page = this.m_Router.url.split("?")[0];
            this.m_Analytics.trackClickEvent(
              this.getButtonName("video-card-publish-unlisted-"),
              page
            );

            this.setVisibility(VIDEO_PUBLISH_STATE.UNLISTED);
          },
        },
        {
          // Public
          text: this.$t("components.videoPublish.public"),
          disabled:
            this.m_VideoData?.video_publish_state ===
              VIDEO_PUBLISH_STATE.PUBLIC ||
            !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PUBLIC),
          disabledTooltip: !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PUBLIC)
            ? this.$t("shared.tooltip.requirePermission")
            : this.$t("shared.tooltip.alreadyInVisibility").replace(
                "{0}",
                this.$t("components.videoPublish.public").toLowerCase()
              ),
          action: () => {
            let page = this.m_Router.url.split("?")[0];
            this.m_Analytics.trackClickEvent(
              this.getButtonName("video-card-publish-public-"),
              page
            );

            this.setVisibility(VIDEO_PUBLISH_STATE.PUBLIC);
          },
        },
      ];
    }
  }
  //--------------------------------------------------------------------
  private initMenuOptions() {
    this.m_CardFooterOptions = [
      {
        icon: "assets/icon/card/qa.svg",
        tooltipText: this.$t("shared.tooltip.viewQnA"),
        action: (event: Event) => {
          event.preventDefault();
          event.stopPropagation();
          this.onQnAClicked();
        },
      },
      {
        icon: "assets/icon/card/folder.svg",
        tooltipText: this.$t("pages.library.move_tooltip"),
        action: (event: Event) => {
          event.preventDefault();
          event.stopPropagation();
          this.onFoldersClicked();
        },
      },
      {
        icon: "assets/icon/card/link.svg",
        disabled:
          this.m_VideoData?.video_publish_state == VIDEO_PUBLISH_STATE.PRIVATE,
        tooltipText:
          this.m_VideoData?.video_publish_state == VIDEO_PUBLISH_STATE.PRIVATE
            ? this.$t("pages.library.copyLink_tooltip_private")
            : this.$t("pages.library.copyLink_tooltip"),
        action: (event: Event) => {
          event.preventDefault();
          event.stopPropagation();
          if (
            this.m_VideoData?.video_publish_state == VIDEO_PUBLISH_STATE.PRIVATE
          )
            return;
          this.onShareClicked();
        },
      },
      {
        icon: "assets/icon/card/options.svg",
        action: (event: Event) => {
          this.onOptionsClicked(event);
        },
      },
    ];
  }
  //--------------------------------------------------------------------
  public async deleteVideo() {
    if (this.m_VideoData?.video_id == null) return;

    try {
      this.m_Errored = true;
      this.setProgress(100, this.$t("shared.messages.deletingVideo"));
      await this.m_VideoService.deleteVideo(this.m_VideoData?.video_id);
      this.m_Events.publish(EVENTS.VIDEO_DELETED, this.m_VideoData.video_id);
    } catch (error) {
      console.error(error);
    }
  }

  private async setProgress(progress: number, text: string) {
    setTimeout(() => {
      this.m_VideoProgressComponent?.setProgress(progress);
      this.m_VideoProgressComponent?.setProgressText(text);
    }, 1);
  }
  //--------------------------------------------------------------------
  private async getUserData() {
    if (this.m_VideoData == null) return;
    this.m_UserData = await this.m_UserService.getUserInfo(
      this.m_VideoData?.video_user_id
    );
  }
  //--------------------------------------------------------------------
  private async setVisibility(visibility: VIDEO_PUBLISH_STATE) {
    if (this.m_VideoData == null) return;

    this.m_IsPublishing = true;
    try {
      await this.m_VideoService.setVideoPublishState(
        this.m_VideoData?.video_id,
        visibility
      );
      this.m_VideoData.video_publish_state = visibility;
      this.initContextMenus();
    } catch (error) {
      console.error(error);
    } finally {
      this.m_IsPublishing = false;
    }
  }
  //--------------------------------------------------------------------
  private initializeCard() {
    if (this.m_VideoData == null) return;
    this.initContextMenus();
    this.initMenuOptions();
    //Set duration text in hh:mm:ss duration format
    if (this.m_VideoData?.video_duration != null) {
      let duration = this.m_VideoData.video_duration;
      let hours = Math.floor(duration / 3600);
      let minutes = Math.floor((duration % 3600) / 60);
      let seconds = Math.floor((duration % 3600) % 60);
      // Pad each time component to ensure two digits
      let hoursFormatted = String(hours).padStart(2, "0");
      let minutesFormatted = String(minutes).padStart(2, "0");
      let secondsFormatted = String(seconds).padStart(2, "0");

      if (hours > 0) {
        let hoursFormatted = String(hours).padStart(2, "0");
        this.m_Duration = `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
      } else {
        this.m_Duration = `${minutesFormatted}:${secondsFormatted}`;
      }
    }

    this.m_ViewsDisplay = formatViews(this.m_VideoData?.video_views || 0);
    //Check if video is currently uploading
    this.m_UploadInfo = this.m_UploadService.getVideoUploadInfo(
      this.m_VideoData.video_id
    );
    if (this.m_UploadInfo && this.m_UploadInfo.uploading) {
      this.m_OnVideoUploadCompleteSub =
        this.m_VideoService.OnUploadComplete.subscribe(
          (results: PollResults) => {
            if (results.video_id == this.VideoId) {
              this.m_VideoData!.video_status = VIDEO_STATUS.PROCESSING;
              this.checkProgress();
              this.m_OnVideoUploadCompleteSub?.unsubscribe();
            }
          }
        );
    }
  }

  private getSharableLink() {
    if (this.m_VideoData?.video_id == null) return;

    let shortId = uuidToShortId(this.m_VideoData.video_id);
    let shareableLink = window.location.origin + "/watch?v=" + shortId;

    return shareableLink;
  }
  //#endregion

  allowClickToOpen(status: boolean) {
    this.m_AllowClickToOpen = status;
  }
}
