// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  pointer-events: auto;

  .element-overlay {
    width: 100%;
    height: 100%;
    display: block;

    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-family: var(--hia-font-base);
      font-size: 16px;
    }
  }

  .iframe-overlay {
    width: 100%;
    height: 100%;
  }
}

.hidden-animated-center {
  left: 0px !important;
  transform: translate(-100%, -50%) !important;
  pointer-events: none !important;
}

.hidden-animated-left {
  left: 0px !important;
  transform: translateX(-100%) !important;
  pointer-events: none !important;
}

.hidden-animated-right {
  right: 0px !important;
  transform: translateX(100%) !important;
  pointer-events: none !important;
}

.hidden-animated-fade {
  opacity: 0 !important;
  pointer-events: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/video/video-overlay/video-overlay.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;;EAEpB;IACE,WAAW;IACX,YAAY;IACZ,cAAc;;IAEd;MACE,aAAa;MACb,uBAAuB;MACvB,mBAAmB;MACnB,YAAY;MACZ,iCAAiC;MACjC,eAAe;IACjB;EACF;;EAEA;IACE,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE,oBAAoB;EACpB,4CAA4C;EAC5C,+BAA+B;AACjC;;AAEA;EACE,oBAAoB;EACpB,uCAAuC;EACvC,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,sCAAsC;EACtC,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;AACjC","sourcesContent":[".container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.overlay {\n  position: absolute;\n  pointer-events: auto;\n\n  .element-overlay {\n    width: 100%;\n    height: 100%;\n    display: block;\n\n    .text {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      height: 100%;\n      font-family: var(--hia-font-base);\n      font-size: 16px;\n    }\n  }\n\n  .iframe-overlay {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.hidden-animated-center {\n  left: 0px !important;\n  transform: translate(-100%, -50%) !important;\n  pointer-events: none !important;\n}\n\n.hidden-animated-left {\n  left: 0px !important;\n  transform: translateX(-100%) !important;\n  pointer-events: none !important;\n}\n\n.hidden-animated-right {\n  right: 0px !important;\n  transform: translateX(100%) !important;\n  pointer-events: none !important;\n}\n\n.hidden-animated-fade {\n  opacity: 0 !important;\n  pointer-events: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
