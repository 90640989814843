import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UploadComponent } from "./upload.component";
import { AlertModalModule } from "../modals/alert-modal/alert-modal.module";

@NgModule({
  declarations: [UploadComponent],
  imports: [CommonModule, AlertModalModule],
  exports: [UploadComponent],
})
export class UploadModule {}
