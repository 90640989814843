// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-container .show-hover {
  cursor: pointer;
}
.avatar-container .show-hover:hover {
  opacity: 0.8;
}
.avatar-container .avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
}
.avatar-container .avatar-image p {
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8em;
}
.avatar-container .avatar-image .avatar-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  font-size: 0.7em;
  font-weight: 700;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-container .avatar-image .avatar-image-overlay div {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/avatar/avatar.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;AACJ;AAAI;EACE,YAAA;AAEN;AACE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iCAAA;EACA,uCAAA;EACA,sCAAA;EACA,kBAAA;AACJ;AAEI;EACE,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,gBAAA;AAAN;AAGI;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,oCAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADN;AAGM;EACE,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AADR","sourcesContent":[".avatar-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .show-hover {\n    cursor: pointer;\n    &:hover {\n      opacity: 0.8;\n    }\n  }\n  .avatar-image {\n    width: 100%;\n    height: 100%;\n    border-radius: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-size: cover !important;\n    background-repeat: no-repeat !important;\n    background-position: center !important;\n    position: relative;\n\n    // Initials\n    p {\n      font-family: Roboto;\n      text-transform: uppercase;\n      font-weight: 500;\n      font-size: 0.8em;\n    }\n\n    .avatar-image-overlay {\n      position: absolute;\n      width: 100%;\n      height: 100%;\n      border-radius: 100%;\n      font-size: 0.7em;\n      font-weight: 700;\n      color: white;\n      background-color: rgba($color: #000000, $alpha: 0.7);\n      font-size: 0.9em;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n\n      div {\n        height: 100%;\n        text-align: center;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex-direction: column;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
