import { Injectable, NgZone } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { StorageService } from "../storage/storage.service";
import { UserService } from "../user/user.service";
import { ConfigService } from "../config/config.service";
import { HIANotificationService } from "../notification/notification.service";
import { T } from "../localization/localization.service";
import { LAST_SEEN_RES_DTO } from "@shared/models/user/user";
import { StytchService } from "../stytch/stytch.service";
import { ResponseCommon, StytchEventType } from "@stytch/vanilla-js";
import { Events } from "../events/events.service";
import { EVENTS } from "src/app/constants/events";
import { FoldersService } from "../folders/folders.service";

export enum MagicLinkResult {
  StytchResult,
  AlreadyVerified,
}

interface StytchURLTokenInfo {
  token: string;
  tokenType: string;
}

export interface LoginEvent {
  fromToken: boolean;
  eulaAccepted: string;
}

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private m_LoggedIn: boolean = false;
  private m_UserId: string = "";
  private m_SessionToken: string = "";
  private $t = T.translate;
  private m_SESSION_DURATION_MINUTES: number = 60;

  private focusHandler: () => void;
  private sessionExpiredSub: Subscription | null = null;
  public OnLogin: Subject<LoginEvent> = new Subject<LoginEvent>();
  public OnLogout: Subject<void> = new Subject<void>();

  get LoggedIn(): boolean {
    return this.m_LoggedIn;
  }

  get UserId(): string {
    return this.m_UserId;
  }

  get SessionToken(): string {
    return this.m_SessionToken;
  }

  constructor(
    private m_UserService: UserService,
    private m_Storage: StorageService,
    private m_Router: Router,
    private m_Route: ActivatedRoute,
    private m_NotificationService: HIANotificationService,
    private m_NgZone: NgZone,
    private m_StytchService: StytchService,
    private m_EventsService: Events,
    private m_FolderService: FoldersService
  ) {
    this.m_StytchService.sessionChange.subscribe(async (session) => {
      if (!session && this.m_LoggedIn) {
        this.handleLogOut(true);
      }
    });

    this.m_StytchService.stytchEvent.subscribe((event) => {
      this.onStytchEvent(event);
    });

    this.focusHandler = this.handleVisibilityChange.bind(this);
  }

  async checkForToken() {
    if (this.LoggedIn) return;

    await this.m_StytchService.initialize();
    let token = await this.m_Storage.get("stytch_token");
    let urlTokenInfo = this.getStytchURLTokenInfo();
    if (token && urlTokenInfo == null) {
      this.m_SessionToken = token;
      if (await this.checkUserSessionAndNavigate()) {
        if (!this.m_LoggedIn) {
          let res = await this.notifyLogin();
          let eulaAccepted = res.eula_accepted_at ?? "0";
          this.OnLogin.next({ fromToken: true, eulaAccepted: eulaAccepted });
        }
      }
    } else if (urlTokenInfo != null) {
      await this.handleStytchURLTokenInfo(urlTokenInfo);
    }
  }

  async initFrontend() {
    let stytchTokenType =
      this.m_Route.snapshot.queryParams["stytch_token_type"];
    let token = this.m_Route.snapshot.queryParams["token"];
    if (stytchTokenType == "reset_password") {
      this.initFrontendPasswordReset(token);
    } else {
      this.initFrontendLogin();
    }
  }

  async logoutUser() {
    this.handleLogOut();
  }

  async authenticate(token: any, tokenType?: string) {
    try {
      const response = await this.m_StytchService.authenticate(
        token,
        tokenType
      );
      this.m_UserId = response.user_id;
      this.m_SessionToken = response.session_token;

      try {
        let res = await this.notifyLogin();
        this.m_Storage.set("stytch_token", this.m_SessionToken);
        this.OnLogin.next({
          fromToken: false,
          eulaAccepted: res.eula_accepted_at ?? "0",
        });
      } catch (err) {
        console.error(err);
        this.logoutUser();
      }
    } catch (error: any) {
      let errorMessage = this.$t("shared.messages.loginError");
      if (error && error.status_code != null) {
        if (error.status_code == 401) {
          errorMessage = this.$t("shared.messages.loginErrorInvalid");
        }
      }
      this.m_NotificationService.showError(errorMessage);
      this.m_Router.navigateByUrl("/login", { replaceUrl: true });
    }
  }

  isEmailVerified() {
    if (!this.m_LoggedIn) return false;

    return this.m_StytchService.isEmailVerified();
  }

  private async notifyLogin(): Promise<LAST_SEEN_RES_DTO> {
    this.setupEventHandlers();
    let res = await this.m_UserService.lastSeen(
      this.m_SessionToken,
      this.m_UserId
    );
    this.m_LoggedIn = true;
    return res;
  }

  private async initFrontendLogin() {
    if (!this.m_LoggedIn) {
      try {
        await this.m_StytchService.initFrontendLogin();
      } catch (error: any) {
        if (!this.checkUserSessionAndNavigate()) {
          console.error("Unknown error when initializing Stytch");
          this.logoutUser();
        }
      }
    }
  }

  private async initFrontendPasswordReset(token: string) {
    if (!this.m_LoggedIn) {
      await this.m_StytchService.initFrontendPasswordReset(token);
    }
  }

  private async handleStytchURLTokenInfo(tokenInfo: StytchURLTokenInfo) {
    switch (tokenInfo.tokenType) {
      case "login":
      case "magic_links":
      case "oauth":
        await this.authenticate(tokenInfo.token, tokenInfo.tokenType);
        break;
    }
  }

  private async handleLogOut(timedout: boolean = false) {
    if (!this.m_LoggedIn) return;
    this.m_UserService.clearUserInfo();
    this.m_FolderService.clearFoldersData();
    await this.m_Storage.remove("stytch_token");
    this.m_LoggedIn = false;
    this.OnLogout.next();
    this.cleanupEventHandlers();

    if (!timedout) {
      await this.m_StytchService.revokeSession();
      this.m_NgZone.run(() => {
        this.m_Router.navigateByUrl("/login", { replaceUrl: true });
      });
    } else {
      window.location.href =
        "/login?timeout=true&redirect=" + this.m_Router.url;
    }
  }

  private checkUserSessionAndNavigate(): boolean {
    const session = this.m_StytchService.getSession();
    if (session) {
      const user = this.m_StytchService.getUser();
      this.m_UserId = session.user_id;
      if (user && !user.emails.some((email) => email.verified)) {
        this.m_Router.navigateByUrl("/verify", { replaceUrl: true });
      }
      return true;
    }
    return false;
  }

  private getStytchURLTokenInfo(): StytchURLTokenInfo | null {
    let token = this.m_Route.snapshot.queryParams["token"];
    let tokenType = this.m_Route.snapshot.queryParams["stytch_token_type"];
    if (token && tokenType) {
      return { token, tokenType };
    }
    return null;
  }

  private onStytchEvent(event: any) {
    switch (event.type) {
      case StytchEventType.PasswordAuthenticate:
        this.handleUserEvent(event, false);
        break;
      case StytchEventType.PasswordCreate:
        this.handleUserEvent(event, true);
        break;
      case StytchEventType.B2BMagicLinkAuthenticate:
        this.handleUserEvent(event, false);
        break;
      case StytchEventType.PasswordResetByEmail:
        this.handleUserEvent(event, false);
        break;
    }
  }

  private async handleUserEvent(event: any, isNewUser: boolean) {
    const user = this.m_StytchService.getUser();
    if (!user) {
      console.error("Failed to get user from Stytch");
      return;
    }

    this.m_UserId = event.data.user_id;
    this.m_SessionToken = event.data.session_token;
    this.m_LoggedIn = true;
    await this.m_Storage.set("stytch_token", this.m_SessionToken);

    try {
      let res = await this.notifyLogin();
      this.OnLogin.next({
        fromToken: false,
        eulaAccepted: res.eula_accepted_at ?? "0",
      });
    } catch (err) {
      console.error(err);
      this.logoutUser();
      return;
    }

    if (isNewUser) {
      let priceId = this.m_Route.snapshot.queryParams["plan"];
      let urlParams = priceId ? ["plan=" + priceId] : undefined;
      await this.sendMagicLink(urlParams);
      this.m_Router.navigateByUrl("/verify", { replaceUrl: true });
    } else if (!user.emails.some((email) => email.verified)) {
      this.m_Router.navigateByUrl("/verify", { replaceUrl: true });
    }
  }

  private setupEventHandlers() {
    window.addEventListener("focus", this.focusHandler);
    this.sessionExpiredSub = this.m_EventsService.subscribe(
      EVENTS.SESSION_EXPIRED,
      () => {
        this.logoutUser();
      }
    );
  }

  private cleanupEventHandlers() {
    window.removeEventListener("focus", this.focusHandler);
    if (this.sessionExpiredSub) {
      this.sessionExpiredSub.unsubscribe();
    }
  }

  private handleVisibilityChange() {
    if (!document.hidden && this.m_LoggedIn) {
      const session = this.m_StytchService.getSession();
      const expiresIn =
        new Date(session?.expires_at ?? 0).getTime() - Date.now();
      if (!session || expiresIn < 0) {
        this.handleLogOut(true);
      }
    }
  }

  async sendMagicLink(urlParams?: string[]): Promise<{
    result: MagicLinkResult;
    stytchResult?: ResponseCommon | null;
  }> {
    const user = this.m_StytchService.getUser();
    if (!user) {
      let errorMessage = "Failed to get user";
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    const email = user.emails.find((email) => !email.verified)?.email;
    if (!email) {
      console.warn("User has no unverified emails");
      return { result: MagicLinkResult.AlreadyVerified, stytchResult: null };
    }

    let stytchResult = await this.m_StytchService.sendMagicLink(
      email,
      urlParams
    );
    return { result: MagicLinkResult.StytchResult, stytchResult: stytchResult };
  }
}
