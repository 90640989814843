import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ConfigService } from "src/app/services/config/config.service";
import { T } from "src/app/services/localization/localization.service";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "app-membership-payments",
  templateUrl: "./membership-payments.component.html",
  styleUrls: ["./membership-payments.component.scss"],
})
export class MembershipPaymentsComponent implements OnInit {
  public m_MaxLength: number = 30;
  public m_CurrentLength: number = 0;
  public m_TierName: string = "";
  public m_TierValue: string = "";
  public m_IsTrial: boolean = false;
  public m_CurrentProgress: number = 0;
  public m_SubscriptionId: number = 0;
  public m_Redirecting: boolean = false;
  public m_UserLimits: any = null;
  public $t = T.translate;

  private m_RouteSubscription: Subscription | null = null;

  constructor(
    private m_UserService: UserService,
    private m_Router: Router,
    private m_Route: ActivatedRoute,
    private m_ConfigService: ConfigService
  ) {}

  ngOnInit() {
    this.getUserPlan();
    //Check if manage=true is in query params
    this.m_RouteSubscription = this.m_Route.queryParams.subscribe((params) => {
      if (params["manage"]) {
        //Remove manage query param but keep others
        this.m_Router.navigate([], {
          queryParams: { ...params, manage: null },
        });
        this.manageSubscription();
      }
    });
  }

  ngOnDestroy() {
    this.m_RouteSubscription?.unsubscribe();
  }

  getUserPlan() {
    const userLimits = this.m_UserService.ActiveUserLimits;

    if (userLimits) {
      // Set User plan informations
      this.m_UserLimits = userLimits;
      this.m_MaxLength = userLimits.maxLength;
      this.m_CurrentLength = userLimits.currentLength;
      this.m_TierName = userLimits.tierDisplayName || "";
      this.m_TierValue = userLimits.tierName;
      this.m_IsTrial = this.m_UserService.isFreeTrial;
      this.getProgress();
    }
  }

  isTrialPlan() {
    return this.m_IsTrial;
  }

  isAdmin() {
    return this.m_TierValue === "admin";
  }

  getProgress() {
    this.m_CurrentProgress = (this.m_CurrentLength / this.m_MaxLength) * 100;
  }

  goToUpgrade() {
    this.m_Router.navigate(["/pricing"], {
      queryParams: {},
    });
  }

  async manageSubscription() {
    this.m_Redirecting = true;
    let url = await this.m_UserService.createPortalLink();
    this.m_Redirecting = false;
    window.open(url, "_blank");
  }

  getUpgradeIcon() {
    return "assets/icon/upgrade.svg";
  }
}