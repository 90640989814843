// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contextMenu {
  --backdrop-opacity: 0;
}
.contextMenu::part(content) {
  min-width: var(--min-content-width);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 15px 0 rgba(100, 100, 100, 0.1);
  border-radius: var(--border-radius-content);
  --offset-y: var(--offset-y-content);
  --offset-x: var(--offset-x-content);
  width: 9rem;
}
.contextMenu ion-item {
  --border-radius: 8px;
  --color: #3d6573;
  font-size: 14px;
  width: 95%;
  margin: 0 auto;
}
.contextMenu ion-label {
  font-family: var(--hia-font-base);
  font-weight: 500;
  line-height: 18.23px;
  letter-spacing: -0.01em;
}
.contextMenu img {
  width: 16px;
  margin-right: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/context-menu/context-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAAE;EACE,mCAAA;EACA,iFAAA;EAEA,2CAAA;EACA,mCAAA;EACA,mCAAA;EACA,WAAA;AACJ;AACE;EACE,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,UAAA;EACA,cAAA;AACJ;AACE;EACE,iCAAA;EACA,gBAAA;EACA,oBAAA;EACA,uBAAA;AACJ;AACE;EACE,WAAA;EACA,oBAAA;AACJ","sourcesContent":[".contextMenu {\n  --backdrop-opacity: 0;\n  &::part(content) {\n    min-width: var(--min-content-width);\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1),\n      0 6px 15px 0 rgba(100, 100, 100, 0.1);\n    border-radius: var(--border-radius-content);\n    --offset-y: var(--offset-y-content);\n    --offset-x: var(--offset-x-content);\n    width: 9rem;\n  }\n  ion-item {\n    --border-radius: 8px;\n    --color: #3d6573;\n    font-size: 14px;\n    width: 95%;\n    margin: 0 auto;\n  }\n  ion-label {\n    font-family: var(--hia-font-base);\n    font-weight: 500;\n    line-height: 18.23px;\n    letter-spacing: -0.01em;\n  }\n  img {\n    width: 16px;\n    margin-right: 0.8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
