import { Injectable } from "@angular/core";
import {
  StytchUIClient,
  OAuthAuthenticateResponse,
  MagicLinksAuthenticateResponse,
  Products,
  StytchLoginConfig,
  User,
  OAuthProviders,
  ProvidersOptions,
} from "@stytch/vanilla-js";
import { ConfigService } from "../config/config.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StytchService {
  private stytch: StytchUIClient | null = null;
  private initialized: boolean = false;
  private sessionDurationMinutes: number = 60;

  public sessionChange: Subject<boolean> = new Subject<boolean>();
  public stytchEvent: Subject<any> = new Subject<any>();

  constructor(private configService: ConfigService) {}

  async initialize() {
    if (this.initialized) return;
    const config = (await this.configService.getHiaConfig())?.stytch;
    if (config == null) {
      console.error("Stytch config not found");
      return;
    }
    const token = config.token;
    this.sessionDurationMinutes = config.session_duration;

    this.stytch = new StytchUIClient(token);
    if (this.stytch === null) {
      console.error("Stytch failed to load");
      return;
    }

    this.stytch.session.onChange(async (session) => {
      this.sessionChange.next(!!session);
    });

    this.initialized = true;
  }

  getSession() {
    return this.stytch?.session.getSync();
  }

  getUser(): User | null {
    return this.stytch?.user.getSync() || null;
  }

  isEmailVerified() {
    const user = this.getUser();
    if (user == null) {
      return false;
    } else {
      return !user.emails.find((email) => !email.verified)?.email;
    }
  }

  async authenticate(token: any, tokenType?: string) {
    if (this.stytch == null) throw new Error("Stytch not initialized");

    let response:
      | OAuthAuthenticateResponse
      | MagicLinksAuthenticateResponse
      | null = null;
    if (tokenType == "oauth") {
      response = await this.stytch.oauth.authenticate(token, {
        session_duration_minutes: this.sessionDurationMinutes,
      });
    } else {
      response = await this.stytch.magicLinks.authenticate(token, {
        session_duration_minutes: this.sessionDurationMinutes,
      });
    }

    if (!response) throw new Error("Failed to authenticate with Stytch");
    return response;
  }

  async initFrontendLogin() {
    if (this.stytch !== null) {
      let oauthConfigStr: string = await this.configService.get("OAUTH_CONFIG");
      if (oauthConfigStr == null) {
        console.error("Failed to load oauth config");
        oauthConfigStr = '{"enabled": false, "providers": []}';
      }
      let oauthConfig = JSON.parse(oauthConfigStr);
      let config: StytchLoginConfig = {
        products: [Products.passwords],
        passwordOptions: {
          loginRedirectURL: `${window.location.origin}/login`,
          resetPasswordRedirectURL: `${window.location.origin}/login`,
          resetPasswordTemplateId: "ml_reset",
        },
        sessionOptions: {
          sessionDurationMinutes: this.sessionDurationMinutes,
        },
      };

      if (oauthConfig.enabled) {
        config.products.push(Products.oauth);
        let providers: ProvidersOptions = oauthConfig.providers.map(
          (provider: keyof typeof OAuthProviders) => ({
            type: OAuthProviders[provider],
          })
        );
        config.oauthOptions = {
          providers: providers,
          loginRedirectURL: `${window.location.origin}/login`,
          signupRedirectURL: `${window.location.origin}/login?signup=true`,
        };
      }
      this.stytch.mountLogin({
        elementId: "#login",
        config: config,
        styles: {
          hideHeaderText: true,
          container: {
            borderColor: "transparent",
          },
        },
        callbacks: {
          onEvent: this.onStytchEvent.bind(this),
        },
      });
    } else {
      throw new Error("Stytch not initialized");
    }
  }

  async initFrontendPasswordReset(token: string) {
    if (this.stytch !== null) {
      this.stytch.mountResetPassword({
        elementId: "#login",
        config: {
          products: [Products.passwords],
          passwordOptions: {
            loginRedirectURL: `${window.location.origin}/login`,
            resetPasswordRedirectURL: `${window.location.origin}/login`,
            resetPasswordTemplateId: "ml_reset",
          },
        },
        callbacks: {
          onEvent: this.onStytchEvent.bind(this),
        },
        passwordResetToken: token,
      });
    }
  }

  async revokeSession() {
    if (this.stytch !== null) {
      await this.stytch.session.revoke();
    }
  }

  async sendMagicLink(email: string, urlParams?: string[]) {
    let url = `${window.location.origin}/verify`;
    if (urlParams && urlParams.length > 0) {
      url += "?" + urlParams.join("&");
    }

    const config = {
      login_magic_link_url: url,
      login_expiration_minutes: 60,
      signup_magic_link_url: url,
      signup_expiration_minutes: 60,
      login_template_id: "ml_login",
      signup_template_id: "ml_signup",
    };

    return await this.stytch?.magicLinks.email.send(email, config);
  }

  private onStytchEvent(event: any) {
    this.stytchEvent.next(event);
  }
}
