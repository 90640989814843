import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ContextModule } from "./context-menu/context-menu.module";
import { RootContainerComponent } from "./root-container/root-container.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TabsNavbarComponent } from "./tabs-navbar/tabs-navbar.component";
import { FeedbackModalModule } from "../modals/feedback-modal/feedback-modal.module";
import { PricingComponent } from "../pricing/pricing.component";
import { TermsModalModule } from "../modals/terms-modal/terms-modal.module";
import { ChangeLogModule } from "../modals/change-log-modal/change-log-modal.module";
import { PricingModalComponent } from "../modals/pricing-modal/pricing-modal.component";
import { AlertModalModule } from "../modals/alert-modal/alert-modal.module";
import { AvatarComponent } from "./avatar/avatar.component";
import { AutoCompleteModule } from "@progress/kendo-angular-dropdowns";
import { UploadModule } from "../upload/upload.module";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { UtilsModule } from "@progress/kendo-angular-utils";
import { FolderModalModule } from "src/app/components/modals/folders-modal/folders-modal.module";
import { AddPlaylistModalModule } from "../modals/add-playlist-modal/add-playlist-modal.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ContextModule,
    MatTooltipModule,
    FeedbackModalModule,
    TermsModalModule,
    AlertModalModule,
    ChangeLogModule,
    AutoCompleteModule,
    UploadModule,
    TreeViewModule,
    UtilsModule,
    FolderModalModule,
    AddPlaylistModalModule,
  ],
  declarations: [
    RootContainerComponent,
    HeaderComponent,
    FooterComponent,
    TabsNavbarComponent,
    PricingComponent,
    PricingModalComponent,
    AvatarComponent,
  ],
  exports: [
    RootContainerComponent,
    HeaderComponent,
    FooterComponent,
    PricingComponent,
    AvatarComponent,
  ],
})
export class SharedModule {}
