// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.embed-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.get-embed-link-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
}

.embed-text-container {
  height: 80%;
  margin: 1rem 0;
}

.embed-text {
  width: 100%;
  height: 100%;
  resize: none;
  background-color: transparent;
  text-decoration: none !important;
}

ion-modal::part(content) {
  width: 500px;
  height: 400px;
}

@media (max-height: 400px) {
  ion-modal::part(content) {
    height: 100%;
  }
}
@media (max-width: 500px) {
  ion-modal::part(content) {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/modals/embed-modal/embed-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,6BAAA;EACA,gCAAA;AACF;;AAGE;EACE,YAAA;EACA,aAAA;AAAJ;;AAIA;EAEI;IACE,YAAA;EAFJ;AACF;AAMA;EAEI;IACE,WAAA;EALJ;AACF","sourcesContent":[".embed-title {\n  font-size: 1.2rem;\n  font-weight: 600;\n}\n\n.get-embed-link-container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 2rem;\n}\n\n.embed-text-container {\n  height: 80%;\n  margin: 1rem 0;\n}\n\n.embed-text {\n  width: 100%;\n  height: 100%;\n  resize: none;\n  background-color: transparent;\n  text-decoration: none !important;\n}\n\nion-modal {\n  &::part(content) {\n    width: 500px;\n    height: 400px;\n  }\n}\n\n@media (max-height: 400px) {\n  ion-modal {\n    &::part(content) {\n      height: 100%;\n    }\n  }\n}\n\n@media (max-width: 500px) {\n  ion-modal {\n    &::part(content) {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
