import { BaseApiService } from "../base-api-service";
import { UserService } from "src/app/services/user/user.service";
import {
  FOLDER_DTO_CREATE_RES,
  FOLDER_DTO_GET_SUBFOLDERS_RES,
  FOLDER_DTO_GET_USER_RES,
  FOLDER_DTO_UPDATE_RES,
} from "@shared/models/folders/folder";
import { Events } from "src/app/services/events/events.service";

export class FoldersApi extends BaseApiService {
  //User Routes

  private static readonly getAllFoldersEndpoint = {
    method: "GET",
    path: "/api/folders/user/{{userId}}",
  };

  private static readonly getSubFoldersEndpoint = {
    method: "GET",
    path: "/api/folders/user/{{userId}}/subfolders?parentId={{folderId}}",
  };

  private static readonly createFolderEndpoint = {
    method: "POST",
    path: "/api/folders",
  };

  private static readonly moveToFolderEndpoint = {
    method: "PATCH",
    path: "/api/video/update-folders?folderId={{folderId}}",
  };

  private static readonly moveFoldersEndpoint = {
    method: "PATCH",
    path: "/api/folders/update-parents",
  };

  private static readonly renameFoldersEndpoint = {
    method: "PATCH",
    path: "/api/folders/{{folderId}}/name",
  };
  private static readonly deleteFoldersEndpoint = {
    method: "DELETE",
    path: "/api/folders/{{folderId}}",
  };

  get SessionId() {
    return this.m_UserService.SessionId;
  }

  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }

  //#region fOLDERS Routes

  //--------------------------------------------------------------------
  /**
   * Get user Folders
   * @param userId
   * @returns
   */
  async getUserFolders(userId: string): Promise<FOLDER_DTO_GET_USER_RES> {
    let apiRequest = {
      method: FoldersApi.getAllFoldersEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.SessionId,
      },
    };

    const response: any = await this.makeRequestWithRetry(
      FoldersApi.getAllFoldersEndpoint.path.replace("{{userId}}", userId),
      apiRequest,
      "Get Folders failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get subfolders or root folders
   * @param userId
   * @param folderId
   * @returns
   */
  async getSubFolders(
    userId: string,
    folderId?: string
  ): Promise<FOLDER_DTO_GET_SUBFOLDERS_RES> {
    let apiRequest = {
      method: FoldersApi.getSubFoldersEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.SessionId,
      },
    };

    if (folderId != "" && folderId != undefined) {
      const response: any = await this.makeRequestWithRetry(
        FoldersApi.getSubFoldersEndpoint.path
          .replace("{{userId}}", userId)
          .replace("{{folderId}}", folderId),
        apiRequest,
        "Get sub Folders failed",
        3,
        200
      );

      return response;
    } else {
      const response: any = await this.makeRequestWithRetry(
        FoldersApi.getSubFoldersEndpoint.path
          .replace("{{userId}}", userId)
          .replace("?parentId={{folderId}}", ""),
        apiRequest,
        "Get sub Folders failed",
        3,
        200
      );

      return response;
    }
  }
  //--------------------------------------------------------------------

  /**
   * Create a folder for a user library
   * @param userId
   * @returns
   */
  async createFolders(
    userId: string,
    name: string
  ): Promise<FOLDER_DTO_CREATE_RES> {
    let apiRequest = {
      method: FoldersApi.createFolderEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.SessionId,
      },
      body: JSON.stringify({ user_id: userId, name: name }),
    };

    const response: FOLDER_DTO_CREATE_RES = await this.makeRequestWithRetry(
      FoldersApi.createFolderEndpoint.path,
      apiRequest,
      "Failed to create a folder",
      3,
      200
    );

    return response;
  }

  //--------------------------------------------------------------------

  /**
   * Create a subFolder for a user library
   * @param userId
   * @returns
   */
  async createSubFolders(
    userId: string,
    parentId: string,
    name: string
  ): Promise<FOLDER_DTO_CREATE_RES> {
    let apiRequest = {
      method: FoldersApi.createFolderEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.SessionId,
      },
      body: JSON.stringify({
        user_id: userId,
        name: name,
        parent_id: parentId,
      }),
    };

    const response: FOLDER_DTO_CREATE_RES = await this.makeRequestWithRetry(
      FoldersApi.createFolderEndpoint.path,
      apiRequest,
      "Failed to create a folder",
      3,
      200
    );

    return response;
  }

  //--------------------------------------------------------------------

  /**
   * Create a subFolder for a user library
   * @param userId
   * @returns
   */
  async moveVideosToFolder(
    videoIds: string[],
    folderId: string
  ): Promise<FOLDER_DTO_CREATE_RES> {
    let apiRequest = {
      method: FoldersApi.moveToFolderEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.SessionId,
      },
      body: JSON.stringify({
        videoIds: videoIds,
      }),
    };

    if (folderId == "0") {
      const response: FOLDER_DTO_CREATE_RES = await this.makeRequestWithRetry(
        FoldersApi.moveToFolderEndpoint.path.replace(
          "?folderId={{folderId}}",
          ""
        ),
        apiRequest,
        "Failed to move to folder",
        3,
        200
      );
      return response;
    } else {
      const response: FOLDER_DTO_CREATE_RES = await this.makeRequestWithRetry(
        FoldersApi.moveToFolderEndpoint.path.replace("{{folderId}}", folderId),
        apiRequest,
        "Failed to move to folder",
        3,
        200
      );

      return response;
    }
  }
  //--------------------------------------------------------------------

  /**
   * Create a subFolder for a user library
   * @param userId
   * @returns
   */
  async moveFolders(
    parentId: string,
    folderId: string
  ): Promise<FOLDER_DTO_UPDATE_RES> {
    let requestBody: any = {
      folderIds: [folderId],
    };

    if (parentId !== "0") {
      requestBody.newParentId = parentId;
    }

    let apiRequest = {
      method: FoldersApi.moveFoldersEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.SessionId,
      },
      body: JSON.stringify(requestBody),
    };

    const response: FOLDER_DTO_UPDATE_RES = await this.makeRequestWithRetry(
      FoldersApi.moveFoldersEndpoint.path,
      apiRequest,
      "Failed to move to folder",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------

  /**
   * Create a subFolder for a user library
   * @param userId
   * @returns
   */
  async renameFolders(
    newName: string,
    folderId: string
  ): Promise<FOLDER_DTO_UPDATE_RES> {
    let apiRequest = {
      method: FoldersApi.renameFoldersEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.SessionId,
      },
      body: JSON.stringify({
        name: newName,
      }),
    };

    const response: FOLDER_DTO_UPDATE_RES = await this.makeRequestWithRetry(
      FoldersApi.renameFoldersEndpoint.path.replace("{{folderId}}", folderId),
      apiRequest,
      "Failed to rename to folder",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------

  /**
   * Deletes a folder
   * @param FolderId
   * @param Force
   * @returns
   */
  async deleteFolders(
    folderId: string,
    force?: boolean
  ): Promise<FOLDER_DTO_UPDATE_RES> {
    let apiRequest = {
      method: FoldersApi.deleteFoldersEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.SessionId,
      },
    };

    let path = FoldersApi.deleteFoldersEndpoint.path.replace(
      "{{folderId}}",
      folderId
    );
    if (force) path = path + "?force=true";
    const response: FOLDER_DTO_UPDATE_RES = await this.makeRequestWithRetry(
      path,
      apiRequest,
      "Failed to delete to folder",
      3,
      200
    );

    return response;
  }

  //#endregion User Routes
}
