import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IonInput } from "@ionic/angular";
import { USER_DTO } from "@shared/models/user/user";
import { VIDEO } from "src/app/models/video/video";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { UserService } from "src/app/services/user/user.service";
import {
  LanguageData,
  VideoService,
} from "src/app/services/video/video.service";
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  compareChapterMaps,
  getMetaInfo,
  getVideoCreateDate,
  getLanguageName,
  parseTimestampsFromString,
} from "src/app/utility/video-utils";
import { EmbedModalComponent } from "../../modals/embed-modal/embed-modal.component";
import { ShareModalComponent } from "../../modals/share-modal/share-modal.component";
import { Router } from "@angular/router";
import { LANGUAGES } from "src/app/constants/languages";
import { StorageService } from "src/app/services/storage/storage.service";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { Subscription } from "rxjs";
import { VIDEO_STATUS } from "@shared/models/video/video";
import { SessionService } from "src/app/services/session/session.service";

@Component({
  selector: "app-video-details",
  templateUrl: "./video-details.component.html",
  styleUrls: ["./video-details.component.scss"],
})
export class VideoDetailsComponent implements OnInit {
  @Input() set VideoData(video: VIDEO | null) {
    this.m_VideoData = video;
    this.m_Description = video?.video_description || "No Description";
    this.initAsync(this.m_VideoData);
  }
  @Input() set CurrentTime(currentTime: number) {
    this.m_CurrentTime = currentTime;
  }
  @Input() set Width(width: number) {
    this.m_Width = width;
    let rootContainer = this.m_Root?.nativeElement;
    if (rootContainer == null) return;
    rootContainer.style.width = width + "px";
  }
  @Input() m_Edit: boolean = false;
  @ViewChild("m_TitleInput") m_TitleInput: IonInput | undefined;
  @ViewChild("m_DescriptionInput") m_DescriptionInput: IonInput | undefined;
  @ViewChild("m_Root") m_Root: ElementRef | undefined;
  @ViewChild(EmbedModalComponent) m_EmbedModal: EmbedModalComponent | undefined;
  @ViewChild(ShareModalComponent) m_ShareModal: ShareModalComponent | undefined;
  @Output() OnEditWatchClicked: EventEmitter<string> = new EventEmitter();
  @Output() OnLanguageChanged: EventEmitter<string> = new EventEmitter();
  @Output() OnNewChapterList: EventEmitter<string> = new EventEmitter();

  //Template helpers
  public m_EditingTitle: boolean = false;
  public m_EditingDescription: boolean = false;
  public m_EditTitle: string = "";
  public m_Edit360: boolean = false;
  public m_EditDescription: string = "";
  public $t = T.translate;
  public m_Width: number = 0;
  public m_Description: string = "";
  public m_DescriptionExpanded: boolean = false;
  public m_MaxDescriptionLength: number = MAX_DESCRIPTION_LENGTH;
  public m_MaxTitleLength: number = MAX_TITLE_LENGTH;
  public m_DescriptionTextWidth = 0;
  public m_UserData: USER_DTO | null = null;
  public m_UserId: string | null = null;
  public m_Loading: boolean = true;
  public m_IsUpdating: boolean = false;
  public m_VideothumbnailURL: string | null = null;
  public m_Videothumbnail: File | null = null;
  public m_Uploading: boolean = false;
  public m_UpdateThumbnail: boolean = false;

  private m_VideoData: VIDEO | null = null;
  private m_CurrentTime: number = 0;
  private m_VideothumbnailChanged: boolean = false;

  public m_LanguageList: { id: string; label: string }[] | undefined;
  public m_SelectedLanguage: { id: string; label: string } = {
    id: "",
    label: "",
  };
  public m_SelectLoading = true;

  private m_LanguageUpdatedSubscription: Subscription | undefined;
  private m_OpenShareModalSubscription: Subscription | undefined;

  get EditWatchText() {
    return this.m_Edit
      ? this.$t("shared.button.view")
      : this.$t("shared.button.edit");
  }

  get Title() {
    return this.m_VideoData?.video_name || "";
  }

  get Loading() {
    return this.m_VideoData != null && this.m_UserData == null;
  }

  get NativeElement() {
    return this.m_Root?.nativeElement;
  }

  get VideoData() {
    return this.m_VideoData;
  }

  get UserId() {
    return this.VideoData?.video_user_id;
  }

  constructor(
    private m_VideoService: VideoService,
    private m_AuthService: SessionService,
    private m_UserService: UserService,
    private m_NotificationService: HIANotificationService,
    private m_Router: Router,
    private m_StorageService: StorageService,
    private m_Events: Events
  ) {}

  ngOnInit() {
    //this.fetchLanguages();
  }

  async initAsync(video: VIDEO | null) {
    if (video != null) this.initialize();
  }

  async initialize() {
    if (this.m_VideoData == null) return;
    this.m_EditTitle = this.m_VideoData.video_name;
    this.m_EditDescription = this.m_VideoData.video_description || "";
    this.m_Edit360 = this.m_VideoData.is_360_video || false;
    this.m_VideothumbnailURL = this.m_VideoData.video_thumb_url || null;
    await this.getUserData();
    await this.setDescriptionTextWidth();
    await this.fetchLanguages();
    this.setCurrentLanguage();

    this.m_LanguageUpdatedSubscription = this.m_Events.subscribe(
      EVENTS.INSIGHT_LANGUAGE_CHANGED,
      (data: { video_id: string; language: LanguageData }) => {
        if (data.video_id !== this.m_VideoData?.video_id) return;
        this.setCurrentLanguage(data.language.value || "");
      }
    );

    this.m_OpenShareModalSubscription = this.m_Events.subscribe(
      EVENTS.OPEN_SHARE_MODAL,
      () => {
        this.onShareClicked();
      }
    );
  }

  reset() {
    this.m_VideoData = null;
    this.m_UserData = null;
    this.m_SelectLoading = true;
    this.m_LanguageUpdatedSubscription?.unsubscribe();
    this.m_OpenShareModalSubscription?.unsubscribe();
  }

  //#region HTML Handlers
  onEditWatchClicked() {
    if (this.m_VideoData == null) return;
    this.OnEditWatchClicked.emit(this.m_VideoData?.video_id);
  }
  //--------------------------------------------------------------------
  onShareClicked() {
    if (this.m_VideoData == null) return;
    //this.m_EmbedModal?.show();
    this.m_ShareModal?.show(this.m_CurrentTime);
  }
  //--------------------------------------------------------------------
  handleTitleEdit(edit: boolean, event: any) {
    if (this.m_VideoData?.read_only) {
      this.m_NotificationService.showError(
        this.$t("shared.messages.readOnly"),
        5000
      );
      return;
    }

    if (this.m_VideoData == null || !this.m_Edit) return;
    this.m_EditingTitle = edit;

    if (edit) {
      this.m_EditTitle = this.m_VideoData.video_name;
      this.m_TitleInput?.setFocus();
    } else if (this.m_EditTitle != this.m_VideoData.video_name) {
      if (!this.validateTitle()) {
        event.preventDefault();
        return;
      }

      this.m_VideoData.video_name = this.m_EditTitle;
      this.m_VideoService.updateVideoDetails(
        this.m_VideoData.video_name,
        this.m_VideoData.video_description || "",
        this.m_VideoData.video_id,
        this.m_VideoData.is_360_video
      );
      this.m_Events.publish(EVENTS.VIDEO_UPDATED, this.m_VideoData);
    }
  }
  //--------------------------------------------------------------------
  handleDescriptionEdit(edit: boolean, event: any) {
    if (this.m_VideoData?.read_only) {
      this.m_NotificationService.showError(
        this.$t("shared.messages.readOnly"),
        5000
      );
      return;
    }

    let isTargetAnchor = event.target.tagName === "A";
    if (this.m_VideoData == null || !this.m_Edit || isTargetAnchor) return;
    this.m_EditingDescription = edit;

    if (edit) {
      this.m_EditDescription = this.m_VideoData.video_description || "";
      this.m_DescriptionInput?.setFocus();
    } else if (this.m_EditDescription != this.m_VideoData.video_description) {
      let oldDescription = this.m_VideoData.video_description;
      let oldChapterList = parseTimestampsFromString(oldDescription || "");
      this.m_VideoData.video_description = this.m_EditDescription;
      this.m_Description = this.m_EditDescription;
      try {
        this.m_VideoService.updateVideoDetails(
          this.m_VideoData.video_name,
          this.m_VideoData.video_description || "",
          this.m_VideoData.video_id
        );
        this.m_Events.publish(EVENTS.VIDEO_UPDATED, this.m_VideoData);

        //Check if the chapter list has changed, if so trigger a re-render of the video player
        let newChapterList = parseTimestampsFromString(
          this.m_VideoData.video_description || ""
        );
        if (!compareChapterMaps(oldChapterList, newChapterList)) {
          this.OnNewChapterList.emit(this.m_VideoData.video_id);
        }
      } catch (e) {
        console.log(e);
        this.m_VideoData.video_description = oldDescription || "";
        this.m_Description = oldDescription || "";
        this.m_NotificationService.showError(
          "Failed to update description",
          5000
        );
      }
    }

    this.setDescriptionTextWidth();
  }
  //--------------------------------------------------------------------
  handleDescriptionEnter(event: KeyboardEvent) {
    //Allow new lines in the description, but if shift is held, blur the input
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      //Remove the last new line character if it exists
      this.m_EditDescription = this.m_EditDescription.replace(/\n$/, "");
      this.handleDescriptionEdit(false, event);
    }
  }
  //--------------------------------------------------------------------
  setDescriptionTextWidth() {
    let tempStringElement = document.createElement("span");
    tempStringElement.style.visibility = "hidden";
    this.m_Description = this.m_VideoData?.video_description || "";
    tempStringElement.textContent = this.m_Description;

    document.body.appendChild(tempStringElement);
    this.m_DescriptionTextWidth = tempStringElement.offsetWidth;
    document.body.removeChild(tempStringElement);

    this.m_Description = this.wrapLinks(this.m_Description);
    this.m_Description = this.wrapTimestamps(this.m_Description);
  }
  //--------------------------------------------------------------------
  getVideoThumbnail() {
    return this.m_VideoData ? this.VideoData?.video_thumb_url : null;
  }
  //--------------------------------------------------------------------
  onFileInputChanged(event: any) {
    //Verify a file was selected
    if (event.target == null || event.target.files.length == 0) return;

    let file = event.target.files[0];
    this.onFileSelected(file);
  }
  //--------------------------------------------------------------------
  onFileSelected(file: File) {
    if (!this.validateThumbnail(file)) return;
    var reader = new FileReader();

    reader.onload = (event: any) => {
      // Set the m_VideothumbnailURL to the base64 representation of the selected image
      this.m_VideothumbnailURL = event.target.result;
    };
    this.m_Videothumbnail = file;
    this.m_VideothumbnailChanged = true;
    reader.readAsDataURL(file);
    this.saveThumbnail();
  }
  //--------------------------------------------------------------------
  private validateThumbnail(file: File) {
    // Return if file is not video
    if (!file.type.includes("image")) {
      this.m_NotificationService.showError("Invalid File type", 10000);
      return false;
    }

    //Check if file is more than 2MB
    if (file.size > 2097152) {
      this.m_NotificationService.showError(
        "File size is too large. Max size is 2MB",
        10000
      );

      return false;
    }

    return true;
  }
  //--------------------------------------------------------------------
  async saveThumbnail() {
    this.m_Uploading = true;
    if (!this.m_VideoData?.video_id) return;
    if (!this.m_Videothumbnail) return;
    try {
      let data = await this.m_VideoService.updateThumbnail(
        this.m_VideoData.video_id,
        this.m_Videothumbnail
      );

      if (data) {
        this.m_VideoData.video_thumb_url = this.m_VideothumbnailURL ?? "";
        this.m_Events.publish(EVENTS.UPDATE_THUMBNAIL, {
          video_id: this.m_VideoData.video_id,
        });
        this.m_Events.publish(EVENTS.VIDEO_UPDATED, this.m_VideoData);
      }

      this.m_Videothumbnail = null;
      this.m_VideothumbnailURL = null;
    } catch (err: any) {
      console.log(err);
      this.m_Uploading = false;

      this.m_NotificationService.showError(err.message, 10000);
    } finally {
      this.m_Uploading = false;
      this.m_VideothumbnailChanged = false;
    }
  }

  //Helper function to wrap links in the description with anchor tags, if they are not already wrapped
  wrapLinks(text: string): string {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(
      linkRegex,
      (match) => `<a href="${match}" target="_blank">${match}</a>`
    );
  }

  //Helper function to wrap timestamps in the description with anchor tags
  wrapTimestamps(text: string): string {
    //Regex for timestamps in the format of 00:00:00 or 00:00
    const timestampRegex = /\b\d{1,2}:\d{2}(:\d{2})?\b/g;
    // Find all timestamps in the description, extract them, convert them to seconds, and wrap them in anchor tags
    return text.replace(timestampRegex, (match) => {
      let time = match.split(":");
      let seconds = 0;
      for (let i = 0; i < time.length; i++) {
        seconds += parseInt(time[i]) * Math.pow(60, time.length - i - 1);
      }
      return `<a class="qvio-description-timestamp" href="${this.m_Router.url}#${seconds}">${match}</a>`;
    });
  }
  //--------------------------------------------------------------------
  shouldShowExpandButton() {
    let descriptionContainer = document.getElementById(
      "video-details-description-container"
    ) as HTMLInputElement;
    if (!descriptionContainer) return;
    return (
      descriptionContainer.offsetWidth < this.m_DescriptionTextWidth ||
      this.m_Description.split("\n").length - 1 > 1
    );
  }
  //--------------------------------------------------------------------
  async handleToggle() {
    if (!this.m_VideoData) return;
    this.m_IsUpdating = true;

    this.m_VideoData.is_360_video = this.m_Edit360;

    this.m_Events.publish(EVENTS.PLAYER_360, this.m_VideoData);

    await this.m_VideoService.updateVideoDetails(
      this.m_VideoData.video_name,
      this.m_VideoData.video_description || "",
      this.m_VideoData.video_id,
      this.m_VideoData.is_360_video
    );
    this.m_IsUpdating = false;
  }

  //--------------------------------------------------------------------
  onExpandDescription() {
    this.m_DescriptionExpanded = !this.m_DescriptionExpanded;
  }
  //--------------------------------------------------------------------
  async onUserProfileClicked(event: Event) {
    if (this.m_VideoData == null || this.m_UserData == null) return;

    this.m_Router.navigate([`/u/${this.m_UserData.username}`]);
    event.stopPropagation();
  }
  //--------------------------------------------------------------------
  async onVideoLanguageSelected(value: any) {
    if (!this.m_VideoData?.video_id) return;
    let selectedValue: string;
    selectedValue = value.target.value;
    this.m_SelectedLanguage = {
      id: selectedValue,
      label: getLanguageName(value.target.value),
    };
    this.m_SelectLoading = true;

    let currentCaptionLang = undefined;
    let track = [
      {
        default: false,
        language: selectedValue,
        label: getLanguageName(value.target.value),
        mode: "showing",
      },
    ];

    currentCaptionLang = await this.m_VideoService.getInsightSupportLanguage(
      this.m_SelectedLanguage.id
    );
    if (!currentCaptionLang) {
      this.m_NotificationService.showError(
        this.$t("shared.messages.languageFailed")
      );
      this.m_SelectLoading = false;
      return;
    }

    await this.m_VideoService.setCaptionValue(
      this.m_VideoData?.video_id,
      track
    );
    this.m_StorageService.set("show_cc", true);
    this.m_SelectLoading = false;

    this.OnLanguageChanged.emit();
  }

  isVideoReady() {
    return this.VideoData?.video_status == VIDEO_STATUS.READY;
  }
  //#endregion
  //#region Render Methods
  shouldShowEdit() {
    if (this.m_VideoData == null) return false;
    if (this.m_AuthService.LoggedIn) {
      if (this.m_VideoData.video_user_id == this.m_AuthService.UserId) {
        return true;
      }
    }

    return false;
  }
  //--------------------------------------------------------------------
  getButtonName(name: string) {
    return name + "_" + this.m_VideoData?.video_id ?? "";
  }
  //--------------------------------------------------------------------
  getUserProfilePic() {
    if (this.m_VideoData?.video_user_id == this.m_AuthService.UserId)
      return this.m_UserService.UserProfilePicture;
    if (this.m_UserData == null || this.m_UserData?.profile_picture_url == null)
      return "assets/icon/user/default-avatar.svg";
    return this.m_UserData.profile_picture_url;
  }
  //--------------------------------------------------------------------
  getUsername() {
    if (this.m_VideoData?.video_user_id == this.m_AuthService.UserId)
      return this.m_UserService.ActiveUserInfo?.username ?? "";
    if (this.m_UserData == null) return "";
    return this.m_UserData.username;
  }
  //--------------------------------------------------------------------
  validateTitle() {
    if (this.m_EditTitle == "") return false;
    return true;
  }
  //--------------------------------------------------------------------
  getMetaInfo() {
    return `${this.getUsername()} | ${getMetaInfo(this.m_VideoData)}`;
  }
  //--------------------------------------------------------------------
  getVideoDate() {
    return `${getVideoCreateDate(this.m_VideoData)}`;
  }
  //--------------------------------------------------------------------
  isLangSalected(value: string) {
    if (
      value.toLocaleLowerCase() ==
      this.m_SelectedLanguage.id.toLocaleLowerCase()
    )
      return true;
    else return false;
  }
  //--------------------------------------------------------------------
  shouldShowLanguageDropdown() {
    return (
      this.m_VideoData?.video_index_status === VIDEO_STATUS.READY &&
      this.m_SelectLoading === false
    );
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  //Helper method to set the current language dropdown
  private async setCurrentLanguage(overrideLang?: string) {
    if (!this.m_VideoData?.video_id) return;

    const isMultiEnabled = await this.isMultiEnabled();

    this.m_SelectLoading = true;

    if (overrideLang && isMultiEnabled) {
      overrideLang = overrideLang.toLowerCase();
      this.m_SelectedLanguage = {
        id: overrideLang,
        label: getLanguageName(overrideLang),
      };
      this.m_SelectLoading = false;
      return;
    }

    let selectedLang = await this.m_VideoService.getVideoCaptions(
      this.m_VideoData.video_id
    );

    //We had not language persisted prior to this, use the source lang on video
    if (!selectedLang[0] || !isMultiEnabled) {
      let sourceLang = this.m_VideoData.source_language?.toLowerCase();
      if (sourceLang) {
        this.m_SelectedLanguage = {
          id: sourceLang,
          label: getLanguageName(sourceLang),
        };
      }
      this.m_SelectLoading = false;
      return;
    }

    // Set the selected language if found
    if (selectedLang && isMultiEnabled) {
      this.m_SelectedLanguage = {
        id: selectedLang[0].language.toLowerCase(),
        label: selectedLang[0].label,
      };
    }
    setTimeout(() => {
      this.m_SelectLoading = false;
    }, 1000);
  }
  //--------------------------------------------------------------------
  private async getUserData() {
    if (this.m_VideoData == null) return;
    try {
      this.m_Loading = true;
      this.m_UserData = await this.m_UserService.getUserInfo(
        this.m_VideoData?.video_user_id
      );
      this.m_UserId = this.m_UserData.id;
    } finally {
      this.m_Loading = false;
    }
  }
  //--------------------------------------------------------------------
  private async isMultiEnabled() {
    return this.m_VideoData?.can_multilang ?? false;
  }

  //Helper method to fetch the list of languages for the dropdown
  private async fetchLanguages() {
    const isMultiEnabled = await this.isMultiEnabled();
    const nativeLang = this.m_VideoData?.source_language;

    if (isMultiEnabled) {
      this.m_LanguageList = LANGUAGES.filter((languageCode, index, self) => {
        let languageCodeStr = languageCode.toLowerCase();

        if (
          languageCodeStr === "" ||
          languageCodeStr === "multi" ||
          languageCodeStr === "auto"
        )
          return false; // Remove empty strings
        const languageLabel = getLanguageName(languageCodeStr);

        if (!isMultiEnabled) {
          return;
        }

        return (
          self.findIndex((lang) => getLanguageName(lang) === languageLabel) ===
          index
        ); // Remove duplicates based on label
      })
        .map((languageCode) => ({
          id: languageCode.toLowerCase(),
          label: getLanguageName(languageCode.toLowerCase()),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    } else {
      //Select native language when multi is disabled
      this.m_LanguageList = LANGUAGES.filter((lang) => {
        return lang === nativeLang;
      }).map((languageCode) => ({
        id: languageCode.toLowerCase(),
        label: getLanguageName(languageCode.toLowerCase()),
      }));
    }
  }
  //--------------------------------------------------------------------
  //#endregion
}
