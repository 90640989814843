/**
 * Formats a timestamp string to include milliseconds if they are not already present
 * @param timestamp - The timestamp to format
 * @returns The formatted timestamp
 */

import { T } from "../services/localization/localization.service";
export function addMSToTimestamp(timestamp: string): string {
  const parts = timestamp.split(":");

  if (parts.length === 2) {
    return `00:${timestamp}.000`;
  } else if (parts.length === 3 && !timestamp.includes(".")) {
    return `${timestamp}.000`;
  } else if (parts.length === 3 && timestamp.includes(".")) {
    return timestamp;
  } else {
    return "00:00:00.000";
  }

  return timestamp;
}

/**
 * Checks if a timestamp is a zero time
 * @param timestamp - The timestamp to check
 * @returns True if the timestamp is a zero time
 */
export function isZeroTime(timestamp: string): boolean {
  return /^(00:00:00(?:\.000)?|00:00(?:\.000)?)$/.test(timestamp);
}

/**
 * Parses a timestamp string to seconds
 * @param timeString
 * @returns
 */
export function timeStringToSeconds(timeString: string) {
  const parts = timeString.split(":");
  let seconds = 0;

  if (parts.length === 3) {
    seconds += parseInt(parts[0], 10) * 3600;
    seconds += parseInt(parts[1], 10) * 60;
    seconds += parseFloat(parts[2]);
  } else if (parts.length === 2) {
    seconds += parseInt(parts[0], 10) * 60;
    seconds += parseFloat(parts[1]);
  }

  return seconds;
}

/**
 * Checks if the video duration is more than an hour
 * @param videoDuration
 * @returns
 */
export function hasMoreThanAHour(videoDuration: number) {
  return videoDuration >= 3600;
}

/**
 * Converts seconds to a time display string
 * @param totalTimeInSeconds - The total time in seconds
 * @param hasHours - If the time display should include hours
 * @returns The time display string
 */
export function secondsToTimeDisplay(
  totalTimeInSeconds: number,
  hasHours: boolean
) {
  var hours = Math.floor(totalTimeInSeconds / 3600);
  var remainingSeconds = totalTimeInSeconds % 3600; // Seconds remaining after hours are accounted for
  var minutes = Math.floor(remainingSeconds / 60); // Calculate minutes from the remaining seconds
  var seconds = Math.floor(remainingSeconds % 60); // Use Math.floor to ensure seconds are within the 0-59 range

  // Formatting hours, minutes, and seconds with leading zeros if necessary
  var intHours = hours < 10 ? "0" + hours : hours.toString();
  var intMinutes = minutes < 10 ? "0" + minutes : minutes.toString();
  var intSeconds = seconds < 10 ? "0" + seconds : seconds.toString();

  if (hasHours) {
    return intHours + ":" + intMinutes + ":" + intSeconds;
  } else {
    // If the total time exceeds 1 hour and hasHours is false, adjust minutes accordingly
    var totalMinutes = hours * 60 + minutes;
    intMinutes =
      totalMinutes < 10 ? "0" + totalMinutes : totalMinutes.toString();
    return intMinutes + ":" + intSeconds;
  }
}

export function timeAgo(dateString: string | Date) {
  const now = new Date();
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format");
  }

  const diffInMs = now.getTime() - date.getTime();

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(diffInMs / (1000 * 60));
  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years > 1
      ? `${T.translate("shared.timeAgo.years").replace("{0}", years)}`
      : `${T.translate("shared.timeAgo.year").replace("{0}", years)}`;
  } else if (months > 0) {
    return months > 1
      ? `${T.translate("shared.timeAgo.months").replace("{0}", months)}`
      : `${T.translate("shared.timeAgo.month").replace("{0}", months)}`;
  } else if (weeks > 0) {
    return weeks > 1
      ? `${T.translate("shared.timeAgo.weeks").replace("{0}", weeks)}`
      : `${T.translate("shared.timeAgo.week").replace("{0}", weeks)}`;
  } else if (days > 0) {
    return days > 1
      ? `${T.translate("shared.timeAgo.days").replace("{0}", days)}`
      : `${T.translate("shared.timeAgo.day").replace("{0}", days)}  `;
  } else if (hours > 0) {
    return hours > 1
      ? `${T.translate("shared.timeAgo.hours").replace("{0}", hours)}`
      : `${hours} ${T.translate("shared.timeAgo.hour").replace("{0}", hours)}`;
  } else if (minutes > 0) {
    return minutes > 1
      ? `${T.translate("shared.timeAgo.minutes").replace("{0}", minutes)}`
      : `${T.translate("shared.timeAgo.minute").replace("{0}", minutes)}`;
  } else {
    return seconds > 1
      ? `${T.translate("shared.timeAgo.seconds").replace("{0}", seconds)}`
      : `${T.translate("shared.timeAgo.second").replace("{0}", seconds)}`;
  }
}

export function formatDateToDay(dateString: string | Date): string {
  //Returns date in MM/DD/YYY HH:mm:ss format
  const date = new Date(dateString);

  const padZero = (num: number) => num.toString().padStart(2, "0");

  const month = padZero(date.getMonth() + 1); // getMonth() returns 0-based month index
  const day = padZero(date.getDate());
  const year = date.getFullYear();

  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}
