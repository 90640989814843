import { Injectable } from "@angular/core";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { ConfigService } from "../config/config.service";
import { T } from "../localization/localization.service";
import { FoldersApi } from "src/app/api/folders/folders";
import { UserService } from "../user/user.service";
import {
  FOLDER_DTO_CREATE_RES,
  FOLDER_DTO_GET_SUBFOLDERS_RES,
  FOLDER_DTO_GET_USER_RES,
  FOLDER_DTO_UPDATE_RES,
  TransformedFolder,
} from "@shared/models/folders/folder";

import { FolderHierarchy } from "@shared/utils/folder-hierarchy";
import { Events } from "../events/events.service";

@Injectable({
  providedIn: "root",
})
export class FoldersService {
  private m_FoldersAPI: FoldersApi;
  private m_FolderHierarchy?: FolderHierarchy;

  public $t = T.translate;

  get FolderHierarchy() {
    return this.m_FolderHierarchy;
  }

  constructor(
    eventsService: Events,
    configService: ConfigService,
    userService: UserService
  ) {
    this.m_FoldersAPI = new FoldersApi(
      eventsService,
      userService,
      configService.get("SERVER_URL")
    );

    //TODO listen for logout and reset folder hierarchy
  }

  //#region Public Methods
  //--------------------------------------------------------------------
  /**
   *  Gets all folders created by the user
   * @param userId
   */
  async getAllFolders(userId: string): Promise<FOLDER_DTO_GET_USER_RES> {
    let res = await this.m_FoldersAPI.getUserFolders(userId);

    if (res.status !== 200) {
      if (res.error?.includes("No folders found")) {
        this.m_FolderHierarchy?.clearData();
      }
      throw ErrorFactory.error(res.status, "Failed to get user folders");
    }

    this.m_FolderHierarchy = new FolderHierarchy(res.folders);
    return res;
  }

  //--------------------------------------------------------------------
  /**
   *  Gets all folders created by the user
   * @param userId
   */
  async getSubFolders(
    userId: string,
    folderId?: string
  ): Promise<FOLDER_DTO_GET_SUBFOLDERS_RES> {
    let res = await this.m_FoldersAPI.getSubFolders(userId, folderId);

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to get folders");
    }

    return res;
  }

  //--------------------------------------------------------------------
  /**
   *  Creates a folder to a user
   * @param userId
   */
  async createFolders(
    userId: string,
    name: string
  ): Promise<FOLDER_DTO_CREATE_RES> {
    let res = await this.m_FoldersAPI.createFolders(userId, name);

    if (res.status !== 200 && res.status !== 201) {
      throw ErrorFactory.error(res.status, "Failed to create folders");
    }

    return res;
  }
  //--------------------------------------------------------------------
  /**
   *  Creates a subfolder to a user
   * @param userId
   */
  async createSubFolders(
    userId: string,
    parentId: string,
    name: string
  ): Promise<FOLDER_DTO_CREATE_RES> {
    let res = await this.m_FoldersAPI.createSubFolders(userId, parentId, name);

    if (res.status !== 200 && res.status !== 201) {
      throw ErrorFactory.error(res.status, "Failed to create subfolders");
    }

    return res;
  }
  //--------------------------------------------------------------------
  /**
   *  Move video to a Folder
   * @param userId
   */
  async moveVideosToFolder(
    videoIds: string[],
    folderId: string
  ): Promise<FOLDER_DTO_CREATE_RES> {
    let res = await this.m_FoldersAPI.moveVideosToFolder(videoIds, folderId);

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to create folders");
    }

    return res;
  }
  //--------------------------------------------------------------------
  /**
   *  Move folders to a subfolder
   * @param userId
   */
  async moveFolders(
    parentId: string,
    folderId: string
  ): Promise<FOLDER_DTO_UPDATE_RES> {
    let res = await this.m_FoldersAPI.moveFolders(parentId, folderId);

    //Update folder hierarchy
    let folder = this.m_FolderHierarchy?.findFolder(folderId);

    if (folder != null) {
      let originalParentId = folder.folderData.parent_id;
      let originalParent: TransformedFolder | undefined;
      if (originalParentId) {
        //Remove folder from original parent
        originalParent = this.m_FolderHierarchy?.findFolder(originalParentId);
        if (originalParent != null) {
          originalParent.items = originalParent.items?.filter(
            (x) => x.folderData.id !== folderId
          );
        }
        //Add folder to new parent
        let parentFolder = this.m_FolderHierarchy?.findFolder(parentId);
        if (parentFolder != null) {
          parentFolder?.items?.push(folder);
        } else {
          let root = this.m_FolderHierarchy?.getRoot();
          root?.items?.push(folder);
        }

        //Update parent id
        folder.folderData.parent_id = parentId;
      }
    }

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to move folders");
    }

    return res;
  }
  //--------------------------------------------------------------------
  /**
   *  Rename Folders
   * @param userId
   */
  async renameFolders(
    newName: string,
    folderId: string
  ): Promise<FOLDER_DTO_UPDATE_RES> {
    let res = await this.m_FoldersAPI.renameFolders(newName, folderId);

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to rename folders");
    }

    return res;
  }
  //--------------------------------------------------------------------
  /**
   *  delete Folders
   * @param userId
   */
  async deleteFolders(
    folderId: string,
    force: boolean
  ): Promise<FOLDER_DTO_UPDATE_RES> {
    let res = await this.m_FoldersAPI.deleteFolders(folderId, force);
    if (res.error?.includes("Folder is not empty")) return res;
    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to delete folders");
    }

    return res;
  }
  //--------------------------------------------------------------------
  /**
   *  Clears Folders Cache
   */
  async clearFoldersData() {
    this.FolderHierarchy?.clearData();
  }
  //#endregion
}
