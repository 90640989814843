import { BaseApiService } from "../base-api-service";
import { UserService } from "src/app/services/user/user.service";

import {
  CREATE_PLAYLIST_DTO_REQ,
  LIST_PLAYLIST_VIDEOS_DTO_RES,
  PLAYLIST_DTO,
  PLAYLIST_DTO_GET_RES,
  PUBLIC_PLAYLIST_DTO,
} from "@shared/models/playlist/playlist";
import { Events } from "src/app/services/events/events.service";

export class PlaylistApi extends BaseApiService {
  //Create private readonly values for playlist endpoints
  private static readonly playlistCreate = {
    method: "POST",
    path: "/api/playlists",
  };

  private static readonly playlistGet = {
    method: "GET",
    path: "/api/playlists/{{playlistID}}",
  };

  private static readonly playlistVideosGet = {
    method: "GET",
    path: "/api/playlists/{{playlistID}}/videos",
  };
  private static readonly playlistAdd = {
    method: "PUT",
    path: "/api/playlists/{{playlistID}}/add",
  };

  private static readonly playlistRemove = {
    method: "PUT",
    path: "/api/playlists/{{playlistID}}/remove",
  };

  private static readonly playlistUpdate = {
    method: "PUT",
    path: "/api/playlists/{{playlistID}}",
  };

  private static readonly playlistThumbnailUpdate = {
    method: "PATCH",
    path: "/api/playlists/{{playlistID}}/thumbnail",
  };

  private static readonly playlistVideoUpdate = {
    method: "PUT",
    path: "/api/playlists/{{playlistId}}/videos/{{videoId}}/update",
  };

  private static readonly playlistDelete = {
    method: "DELETE",
    path: "/api/playlists/{{playlistId}}",
  };

  //--------------------------------------------------------------------
  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }
  //--------------------------------------------------------------------
  //#region Public Methods
  /**
   * Fetch playlists
   * @returns
   */
  async getPlaylist(playlistId: string) {
    let url = PlaylistApi.playlistGet.path.replace(
      "{{playlistID}}",
      playlistId
    );

    let apiRequest = {
      method: PlaylistApi.playlistGet.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
    };

    const response: PLAYLIST_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Error to list playlists",
      3
    );

    return response;
  }

  //--------------------------------------------------------------------
  /**
   * Fetch a playlist videos
   * @returns
   */
  async getPlaylistVideos(playlistId: string) {
    let url = PlaylistApi.playlistVideosGet.path.replace(
      "{{playlistID}}",
      playlistId
    );

    let apiRequest = {
      method: PlaylistApi.playlistVideosGet.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
    };

    const response: LIST_PLAYLIST_VIDEOS_DTO_RES =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Error to list playlists",
        3
      );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Create new playlist
   * @param data
   * @returns
   */
  async createNewPlaylist(
    data: CREATE_PLAYLIST_DTO_REQ
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let url = PlaylistApi.playlistCreate.path;

    let apiRequest = {
      method: PlaylistApi.playlistCreate.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response: PLAYLIST_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Error to add in playlist",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Add in playlist
   * @param data
   * @returns
   */
  async addPlaylist(
    playlistId: string,
    videoIds: string[]
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let url = PlaylistApi.playlistAdd.path.replace(
      "{{playlistID}}",
      playlistId
    );

    let apiRequest = {
      method: PlaylistApi.playlistAdd.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        videoIds,
      }),
    };

    const response: PLAYLIST_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Error to add in playlist",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Remove from playlist
   * @param data
   * @returns
   */
  async removePlaylist(
    playlistId: string,
    videoIds: string[]
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let url = PlaylistApi.playlistRemove.path.replace(
      "{{playlistID}}",
      playlistId
    );

    let apiRequest = {
      method: PlaylistApi.playlistRemove.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        videoIds,
      }),
    };

    const response: PLAYLIST_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Error to remove from playlist",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Updates a Playlist data
   * @param data
   * @returns
   */
  async updatePlaylist(
    playlistData: PLAYLIST_DTO
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let url = PlaylistApi.playlistUpdate.path.replace(
      "{{playlistID}}",
      playlistData.id
    );

    let apiRequest = {
      method: PlaylistApi.playlistUpdate.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: playlistData.name,
        description: playlistData.description,
        visibility: playlistData.visibility || 0,
      }),
    };

    const response: PLAYLIST_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Error to update playlist data",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Updates a Playlist thumbnail
   * @param file
   * @returns
   */
  async updatePlaylistThumbnail(
    playlistId: string,
    thumbnail: File
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let url = PlaylistApi.playlistThumbnailUpdate.path.replace(
      "{{playlistID}}",
      playlistId
    );

    const formdata = new FormData();
    formdata.append("thumbnail", thumbnail);

    let apiRequest = {
      method: PlaylistApi.playlistThumbnailUpdate.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
      body: formdata,
    };

    const response: PLAYLIST_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Error to update playlist thumbnail",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Updates a Playlist video position
   * @param playlistId
     @param videoId
     @param position
   * @returns
   */
  async updateVideoPosition(
    playlistId: string,
    videoId: string,
    position: number
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let url = PlaylistApi.playlistVideoUpdate.path
      .replace("{{playlistId}}", playlistId)
      .replace("{{videoId}}", videoId);

    let apiRequest = {
      method: PlaylistApi.playlistVideoUpdate.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        position: position,
      }),
    };

    const response: PLAYLIST_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Error to update playlist video position",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Deletes a Playlist
   * @param playlistId
   * @returns
   */
  async deletePlaylist(playlistId: string): Promise<PLAYLIST_DTO_GET_RES> {
    let url = PlaylistApi.playlistDelete.path.replace(
      "{{playlistId}}",
      playlistId
    );

    let apiRequest = {
      method: PlaylistApi.playlistDelete.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
    };

    const response: PLAYLIST_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Error to delete playlist",
      3
    );

    return response;
  }
}
