import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { AlertModalComponent } from "./alert-modal.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule],
  declarations: [AlertModalComponent],
  exports: [AlertModalComponent],
})
export class AlertModalModule {}
