// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications .description {
  margin-left: 2.5em;
  margin-top: 0.3em;
  font-size: 12px;
  font-weight: 500;
  opacity: 60%;
}
.notifications ion-checkbox {
  --border-color: #3d657399;
  --border-width: 1px;
  --checkmark-color: white;
  --size: 0.8rem;
  font-size: 14px;
  font-weight: 500;
}

.notifications-item-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 1rem 0;
  padding: 0 4rem;
}

.notifications-checkbox-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile/notifications/notifications.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AAAJ;AAGE;EACE,yBAAA;EACA,mBAAA;EACA,wBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AADJ;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAFF","sourcesContent":[".notifications {\n  .description {\n    margin-left: 2.5em;\n    margin-top: 0.3em;\n    font-size: 12px;\n    font-weight: 500;\n    opacity: 60%;\n  }\n\n  ion-checkbox {\n    --border-color: #3d657399;\n    --border-width: 1px;\n    --checkmark-color: white;\n    --size: 0.8rem;\n    font-size: 14px;\n    font-weight: 500;\n  }\n}\n\n.notifications-item-content {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  margin: 1rem 0;\n  padding: 0 4rem;\n}\n\n.notifications-checkbox-content {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.button-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
