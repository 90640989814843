import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UiService } from "../../ui/ui.service";
import { SessionService } from "../../session/session.service";

@Injectable({
  providedIn: "root",
})
export class ContentPageGuardService {
  constructor(
    private m_SessionService: SessionService,
    private m_Router: Router,
    private m_UiService: UiService
  ) {}
  async canActivate(): Promise<boolean> {
    return new Promise(async (resolve) => {
      await this.m_SessionService.checkForToken();

      if (this.m_SessionService.LoggedIn) {
        this.m_Router.navigate(["/home"], {
          queryParams: {},
        });
        resolve(true);
      } else {
        this.m_UiService.redirectToLanding();
        resolve(false);
      }
    });
  }
}
