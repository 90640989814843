import { BaseApiService } from "../base-api-service";
import {
  VIDEO_DTO_GET_USER_RES,
  VIDEO_PUBLISH_STATE,
} from "@shared/models/video/video";
import {
  LAST_SEEN_RES_DTO,
  USER_DTO_CHECKOUT_SESSION_RES,
  USER_DTO_GET_INFO_RES,
  USER_DTO_GET_SETTINGS_RES,
  USER_DTO_LIMITS_RES,
  USER_DTO_PORTAL_LINK_RES,
  USER_DTO_UPDATE_REQ,
} from "@shared/models/user/user";
import { BASE_RESPONSE } from "@shared/models/api/api";
import { LIST_USER_PLAYLISTS_DTO_RES } from "@shared/models/playlist/playlist";
import { Events } from "src/app/services/events/events.service";

export class UserApi extends BaseApiService {
  //User Routes
  private static readonly lastSeenEndpoint = {
    method: "PUT",
    path: "/api/user/last-seen",
  };
  private static readonly videoGetByUserIdEndpoint = {
    method: "GET",
    path: "/api/user/{{userID}}/videos",
  };
  private static readonly userGetInfoEndpoint = {
    method: "GET",
    path: "/api/user/{{userID}}",
  };
  private static readonly userGetInfoByUsernameEndpoint = {
    method: "GET",
    path: "/api/user/u/{{username}}",
  };
  private static readonly userSetInfoEndpoint = {
    method: "PUT",
    path: "/api/user/{{userID}}",
  };
  private static readonly userGetLimitsEndpoint = {
    method: "GET",
    path: "/api/user/limits",
  };
  private static readonly userAcceptEulaEndpoint = {
    method: "PATCH",
    path: "/api/user/eula-acceptance",
  };
  private static readonly userGetSettingsEndpoint = {
    method: "GET",
    path: "/api/user/settings",
  };
  private static readonly userCreateCheckoutSessionEndpoint = {
    method: "POST",
    path: "/api/user/create-checkout-session",
  };
  private static readonly userCreatePortalLinkEndpoint = {
    method: "POST",
    path: "/api/user/create-portal-link",
  };
  private static readonly userGetPlaylists = {
    method: "GET",
    path: "/api/user/{{userID}}/playlists",
  };

  private m_SessionId: string = "";

  get SessionId() {
    return this.m_SessionId;
  }

  constructor(eventsService: Events, p_ApiUrl: Promise<string>) {
    super(eventsService, p_ApiUrl);
  }

  //#region User Routes
  //--------------------------------------------------------------------
  /**
   * Used to update the last seen time of the user
   * @param sessionId Session Id from Stytch
   * @returns
   */
  async lastSeen(sessionId: string): Promise<LAST_SEEN_RES_DTO> {
    let apiRequest = {
      method: UserApi.lastSeenEndpoint.method,
      headers: {
        sid: sessionId,
      },
    };

    const response: LAST_SEEN_RES_DTO = await this.makeRequestWithRetry(
      UserApi.lastSeenEndpoint.path,
      apiRequest,
      "Login Failed",
      3,
      200
    );

    if (response.status == 200) {
      this.m_SessionId = sessionId;
    }

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get all videos by user id, returned videos depend on the user id and caller's session id
   * @param userId
   * @returns
   */
  async getVideosByUserId(
    userId: string,
    page_number?: number,
    page_size?: number,
    folder_id?: string | null,
    visibility?: VIDEO_PUBLISH_STATE,
    searchTerm?: string,
    fields?: string,
    orderBy?: string,
    orderDirection?: "ASC" | "DESC"
  ): Promise<VIDEO_DTO_GET_USER_RES> {
    let apiRequest = {
      method: UserApi.videoGetByUserIdEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_SessionId,
      },
    };

    let url = UserApi.videoGetByUserIdEndpoint.path.replace(
      "{{userID}}",
      userId
    );

    const params = new Map<string, string>();
    if (
      page_number != null &&
      page_size != null &&
      page_number >= 0 &&
      page_size > 0
    ) {
      params.set("pageNumber", page_number.toString());
      params.set("pageSize", page_size.toString());
    }

    if (folder_id !== undefined && folder_id !== null && folder_id !== "0") {
      // FolderId null should get the videos on the root folder, using the ID "0" should return every video
      params.set("folderId", folder_id);
    } else if (folder_id !== "0") {
      params.set("folderId", "null");
    }

    if (visibility || visibility === 0) {
      params.set("visibility", visibility.toString());
    }

    if (fields && fields.length > 0) {
      params.set("fields", fields);
    }

    if (searchTerm && searchTerm.length > 0) {
      params.set("searchTerm", searchTerm);
    }

    if (orderBy && orderBy.length > 0) {
      params.set("orderBy", orderBy);
    }

    if (orderDirection && orderDirection.length > 0) {
      params.set("orderDirection", orderDirection);
    }

    url = this.addParams(url, params);

    const response: VIDEO_DTO_GET_USER_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Videos Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get user info
   * @param userId
   * @returns
   */
  async getUserInfo(userId: string): Promise<USER_DTO_GET_INFO_RES> {
    let apiRequest = {
      method: UserApi.userGetInfoEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_SessionId,
      },
    };

    const response: USER_DTO_GET_INFO_RES = await this.makeRequestWithRetry(
      UserApi.userGetInfoEndpoint.path.replace("{{userID}}", userId),
      apiRequest,
      "Get User Info failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get user info by username
   * @param userId
   * @returns
   */
  async getUserInfoByUsername(
    username: string
  ): Promise<USER_DTO_GET_INFO_RES> {
    let apiRequest = {
      method: UserApi.userGetInfoByUsernameEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_SessionId,
      },
    };

    const response: USER_DTO_GET_INFO_RES = await this.makeRequestWithRetry(
      UserApi.userGetInfoByUsernameEndpoint.path.replace(
        "{{username}}",
        username
      ),
      apiRequest,
      "Get User Info by username failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Set user info
   * @param userId
   * @returns
   */
  async setUserInfo(
    user: USER_DTO_UPDATE_REQ,
    picture?: File | null,
    banner?: File
  ): Promise<BASE_RESPONSE> {
    const formData = new FormData();
    for (let key in user) {
      let value = (user as any)[key];
      if (value != null) {
        formData.append(key, value);
      }
    }
    if (picture != null) {
      formData.append("profile_picture", picture as any);
    }
    if (banner != null) {
      formData.append("profile_banner", banner as any);
    }

    let apiRequest = {
      method: UserApi.userSetInfoEndpoint.method,
      headers: {
        sid: this.m_SessionId,
      },
      body: formData,
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      UserApi.userSetInfoEndpoint.path.replace("{{userID}}", user.id),
      apiRequest,
      "Set User Info failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get user limits
   * @param userId
   * @returns
   */
  async getUserLimits(userId: string): Promise<USER_DTO_LIMITS_RES> {
    let apiRequest = {
      method: UserApi.userGetLimitsEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_SessionId,
      },
    };

    const response: USER_DTO_LIMITS_RES = await this.makeRequestWithRetry(
      UserApi.userGetLimitsEndpoint.path,
      apiRequest,
      "Get User Limits failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get user settings
   * @param userId
   * @returns
   */
  async getSettings(userId: string): Promise<USER_DTO_GET_SETTINGS_RES> {
    let apiRequest = {
      method: UserApi.userGetSettingsEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_SessionId,
      },
    };

    const response: USER_DTO_GET_SETTINGS_RES = await this.makeRequestWithRetry(
      UserApi.userGetSettingsEndpoint.path,
      apiRequest,
      "Get User Settings failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Accept EULA for user
   * @param userId
   * @returns
   */
  async acceptEULA(): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: UserApi.userAcceptEulaEndpoint.method,
      headers: {
        sid: this.m_SessionId,
      },
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      UserApi.userAcceptEulaEndpoint.path,
      apiRequest,
      "Accept EULA failed",
      3,
      200
    );

    return response;
  }

  /**
   * Create a checkout session for the user
   * @param userId
   * @returns
   */
  async createCheckoutSession(
    priceId: string
  ): Promise<USER_DTO_CHECKOUT_SESSION_RES> {
    let apiRequest = {
      method: UserApi.userCreateCheckoutSessionEndpoint.method,
      headers: {
        sid: this.m_SessionId,
      },
    };

    const response: USER_DTO_CHECKOUT_SESSION_RES =
      await this.makeRequestWithRetry(
        UserApi.userCreateCheckoutSessionEndpoint.path + `?priceId=${priceId}`,
        apiRequest,
        "Create Checkout Session failed",
        3,
        200
      );

    return response;
  }

  /**
   * Create a link for the user to manage his subscription
   * @param userId
   * @returns
   */
  async createPortalLink(): Promise<USER_DTO_PORTAL_LINK_RES> {
    let apiRequest = {
      method: UserApi.userCreatePortalLinkEndpoint.method,
      headers: {
        sid: this.m_SessionId,
      },
    };

    const response: USER_DTO_PORTAL_LINK_RES = await this.makeRequestWithRetry(
      UserApi.userCreatePortalLinkEndpoint.path,
      apiRequest,
      "Create Manage Subscription Link failed",
      3,
      200
    );

    return response;
  }

  /**
   * Get user playlists
   * @param userId
   * @returns
   */
  async getUserPlaylists(
    userId: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<LIST_USER_PLAYLISTS_DTO_RES> {
    let apiRequest = {
      method: UserApi.userGetPlaylists.method,
      headers: {
        sid: this.m_SessionId,
      },
    };

    let url = UserApi.userGetPlaylists.path.replace("{{userID}}", userId);

    if (pageNumber != null && pageSize != null) {
      url += `?page_number=${pageNumber}&page_size=${pageSize}`;
    }

    const response: LIST_USER_PLAYLISTS_DTO_RES =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Get playlist from user failed",
        3,
        200
      );

    return response;
  }

  //#endregion User Routes
}
