import { Injectable } from "@angular/core";
import { RecordApi } from "src/app/api/record/record-api";
import {
  PAGINATED_RECORDS,
  QueryResponse,
  RECORD,
} from "src/app/models/record/record";
import { UserService } from "../user/user.service";
import {
  GENERATE_QNA_PAIRS_RES,
  RECORD_DTO_CREATE_REQ,
  RECORD_DTO_QUERY_ENTRY,
  RECORD_DTO_UPDATE_MEDIA_REQ,
  RECORD_DTO_UPDATE_REQ,
} from "@shared/models/record/record";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { ConfigService } from "../config/config.service";
import { Events } from "../events/events.service";

@Injectable({
  providedIn: "root",
})
export class RecordService {
  private m_RecordAPIService: RecordApi;
  private m_EnableCompare: boolean = true;

  constructor(
    eventsService: Events,
    userService: UserService,
    private m_ConfigService: ConfigService
  ) {
    this.m_RecordAPIService = new RecordApi(
      eventsService,
      userService,
      m_ConfigService.get("SERVER_URL")
    );
    this.initializeConfig();
  }

  async initializeConfig() {
    this.m_EnableCompare =
      (await this.m_ConfigService.get("ENABLE_COMPARE")) === "true";
  }

  //#region Public Methods
  /**
   * Imports a CSV file into the record database for a given video id
   * @param videoID The video id to import the records to
   * @param file The CSV file to import
   * @returns The result of the import, null if failed
   */
  async importCSV(videoID: string, file: File) {
    let result = await this.m_RecordAPIService.bulkCreateRecords(videoID, file);

    //TODO: use a subcode to handle this error
    if (result.status == 452) {
      throw ErrorFactory.error(
        result.status,
        result.error ?? "Failed to import CSV"
      );
    } else if (result.status != 201) {
      throw ErrorFactory.error(
        result.status,
        result.error ?? "Failed to import CSV"
      );
    }

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Exports a CSV file from the record database for a given video id
   * @param videoID The video id to export the records from
   * @returns The CSV file, null if failed
   */
  async exportCSV(videoID: string) {
    let result = await this.m_RecordAPIService.exportCSV(videoID);

    if (result == null) {
      throw ErrorFactory.error(500, "Failed to export CSV");
    }

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Imports a JSON file into the record database for a given video id
   * @param videoID The video id to import the records to
   * @param file The JSON file to import
   * @returns The result of the import, null if failed
   */
  async importJSON(videoID: string, file: File) {
    let result = await this.m_RecordAPIService.importJSON(videoID, file);

    if (result.status != 201) {
      throw ErrorFactory.error(result.status, "Failed to import JSON");
    }

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Creates a record in the database
   * @param videoID
   * @param question
   * @param answer
   * @returns
   */
  async createRecord(videoID: string, record: RECORD) {
    let createRecordReq: RECORD_DTO_CREATE_REQ = {
      question: record.question ?? "",
      answer: record.answer ?? "",
      start_time: record.video_start_time ?? undefined,
      end_time: record.video_end_time ?? undefined,
      web_url: record.web_url ?? undefined,
      media_id: record.media_id ?? undefined,
    };

    let result = await this.m_RecordAPIService.createRecord(
      videoID,
      createRecordReq
    );
    if (result.status != 201) {
      throw ErrorFactory.error(result.status, "Failed to create record");
    }

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Deletes a record from the database
   * @param recordID
   * @returns True if successful, null if failed
   */
  async deleteRecord(recordID: string) {
    let result = await this.m_RecordAPIService.deleteRecord(recordID);

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to delete record");
    }

    return result;
  }
  /**
   * Deletes all records from the video
   * @param recordID
   * @returns True if successful, null if failed
   */
  async deleteAllRecords(videoID: string) {
    let result = await this.m_RecordAPIService.deleteAllRecords(videoID);

    if (result.status != 204) {
      throw ErrorFactory.error(result.status, "Failed to delete record");
    }

    return result;
  }
  //--------------------------------------------------------------------
  async bulkDeleteRecords(recordIDs: string[]) {
    let result = await this.m_RecordAPIService.bulkDeleteRecords(recordIDs);

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to bulk delete records");
    }

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Updates a record in the database
   *
   * @param record The record to update
   * @returns The updated record, null if failed
   */
  async updateRecord(record: RECORD) {
    let updatedRecord = await this.m_RecordAPIService.updateRecord(record);

    if (updatedRecord.status != 200) {
      throw ErrorFactory.error(updatedRecord.status, "Failed to update record");
    }

    return updatedRecord;
  }
  //--------------------------------------------------------------------
  /**
   * Updates multiple records in the database
   *
   * @param record The record to update
   * @returns The updated record, null if failed
   */
  async updateRecords(records: RECORD_DTO_UPDATE_REQ[]) {
    let updatedRecord = await this.m_RecordAPIService.updateRecords(records);

    if (updatedRecord.status != 200) {
      throw ErrorFactory.error(
        updatedRecord.status,
        "Failed to update records"
      );
    }

    return updatedRecord;
  }
  //--------------------------------------------------------------------
  /**
   * Gets all records for a given video id
   * @param videoID The video id to get the records for
   * @returns The records for the given video id, null if failed
   */
  async getRecords(
    videoID: string,
    pageNumber?: number,
    pageSize?: number,
    searchTerm?: string
  ): Promise<PAGINATED_RECORDS | null> {
    let result = await this.m_RecordAPIService.getRecords(
      videoID,
      pageNumber,
      pageSize,
      searchTerm
    );

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to get records");
    }

    //Convert the records to res objects via the PAGINATED_RECORDS object
    return new PAGINATED_RECORDS(result);
  }
  //--------------------------------------------------------------------
  /**
   * Gets all records for a given media id
   * @param videoID The current video id
   * @param mediaID The media id used in the attachment
   * @returns The records that use an attachment with mediaId
   */
  async getRecordsByMediaID(
    videoID: string,
    mediaID: string
  ): Promise<RECORD[] | null> {
    let result = await this.m_RecordAPIService.getRecordsByMediaId(
      videoID,
      mediaID
    );

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to get records");
    }

    //Convert the records to RECORD objects
    let records: RECORD[] = [];
    for (let record of result.records) {
      records.push(new RECORD(record));
    }

    return records;
  }
  //--------------------------------------------------------------------
  async getRandomRecords(videoID: string, count: number): Promise<RECORD[]> {
    let result = await this.m_RecordAPIService.getRandomRecords(videoID);
    if (result.status != 200)
      throw ErrorFactory.error(result.status, "Failed to get random records");

    let records: RECORD[] = [];
    if (result.records == null) return records;
    //Convert the records to RECORD objects
    for (let record of result.records) {
      records.push(new RECORD(record));
    }

    return records;
  }
  //--------------------------------------------------------------------
  async getSuggestions(
    videoID: string,
    pageNumber?: number,
    pageSize?: number,
    pageNumberSeed?: number
  ): Promise<PAGINATED_RECORDS> {
    let result = await this.m_RecordAPIService.getSuggestions(
      videoID,
      pageNumber,
      pageSize,
      pageNumberSeed
    );
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to get suggestions");
    }

    //Convert the records to res objects via the PAGINATED_RECORDS object
    return new PAGINATED_RECORDS(result);
  }
  //--------------------------------------------------------------------
  /**
   * Queries the record database for a given video id and question
   * @param videoID
   * @param question
   * @returns The record results for the given video id and question, null if failed
   */
  async query(videoID: string, question: string): Promise<QueryResponse[]> {
    let result = await this.m_RecordAPIService.query(videoID, question);
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to query records");
    }

    let queryResults: QueryResponse[] = [];
    if (result.records == null) return queryResults;
    //Convert the records to QueryResponses objects
    for (let record of result.records) {
      queryResults.push(new QueryResponse(record));
    }

    return queryResults;
  }
  //--------------------------------------------------------------------
  async advancedQuery(videoID: string, query: string): Promise<string> {
    let result = await this.m_RecordAPIService.advancedQuery(videoID, query);
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to advanced query video");
    }

    return result.data;
  }
  //--------------------------------------------------------------------
  async updateRecordMedia(
    recordId: string,
    mediaId?: string,
    timestamp?: number
  ) {
    let req: RECORD_DTO_UPDATE_MEDIA_REQ = {
      media_id: mediaId,
      video_start_time: timestamp,
    };

    let result = await this.m_RecordAPIService.updateRecordMedia(recordId, req);
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to update record media");
    }

    return result;
  }

  //--------------------------------------------------------------------
  /**
   * Compares multiple records in the database
   *
   * @param record The records Ids to compare
   * @returns The updated record, null if failed
   */
  async compareRecords(recordsIds: Array<string>) {
    if (!this.m_EnableCompare) {
      return [];
    }

    let duplicatedRecords = await this.m_RecordAPIService.compareRecords(
      recordsIds
    );

    if (!duplicatedRecords) {
      throw ErrorFactory.error(
        duplicatedRecords.status,
        "Failed to compare records"
      );
    }

    return duplicatedRecords;
  }

  //--------------------------------------------------------------------
  async generateQnAPairsByPrompt(
    text: string
  ): Promise<GENERATE_QNA_PAIRS_RES> {
    return await this.m_RecordAPIService.generateQnAPairsByPrompt(text);
  }

  //#endregion
}
