import { UserService } from "src/app/services/user/user.service";
import { BaseApiService } from "../base-api-service";
import { Events } from "src/app/services/events/events.service";

export class UtilityAPI extends BaseApiService {
  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }

  //Check URI Malicious
  private static readonly checkURLMaliciousEndpoint = {
    method: "POST",
    path: "/api/action/check-uri",
  };

  //#region Public Methods
  async checkURIMalicious(uri: string) {
    let apiRequest = {
      method: UtilityAPI.checkURLMaliciousEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ uri }),
    };

    const response = await this.makeRequestWithRetry(
      UtilityAPI.checkURLMaliciousEndpoint.path,
      apiRequest,
      "Failed to validate URI",
      1
    );

    return response;
  }
}
