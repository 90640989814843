import { Injectable } from "@angular/core";
import { VoiceApi } from "src/app/api/voice/voice-api";
import { ConfigService } from "../config/config.service";
import { UserService } from "../user/user.service";
import { PAGINATED_VOICE_LIST, VOICE } from "src/app/models/voice/voice";
import { CREATE_VOICE_REQ_DTO } from "@shared/models/voice/voice";
import { SessionService } from "../session/session.service";
import { Events } from "../events/events.service";
import { DUB_LANGUGAGE } from "src/app/models/voice/dub-language";

interface DUB_CONFIG {
  enabled: boolean;
}

@Injectable({
  providedIn: "root",
})
export class VoiceService {
  private m_VoicesApi: VoiceApi;
  private m_VoiceOpts: VOICE[] = [];
  private m_LangOpts: DUB_LANGUGAGE[] = [];
  private m_CurrentVoice: VOICE | null = null;
  private m_DubEnabled: boolean = false;

  get VoiceOpts() {
    return this.m_VoiceOpts;
  }

  get CurrentVoice() {
    return this.m_CurrentVoice;
  }

  get DubEnabled() {
    return this.m_DubEnabled;
  }

  constructor(
    eventsService: Events,
    userService: UserService,
    configService: ConfigService,
    private m_AuthService: SessionService
  ) {
    this.m_VoicesApi = new VoiceApi(
      eventsService,
      userService,
      configService.get("SERVER_URL")
    );
    this.initialize();

    configService.get("DUB_CONFIG").then((config: string) => {
      let dubConfig: DUB_CONFIG = JSON.parse(config);
      this.m_DubEnabled = dubConfig.enabled;
    });
  }

  async initialize() {
    this.m_AuthService.OnLogout.subscribe(() => {
      this.clearVoiceOpts();
      this.m_CurrentVoice = null;
    });
  }

  //Clears the voice options from cache
  clearVoiceOpts() {
    this.m_VoiceOpts = [];
  }

  /**
   * List voices for the current user signed in
   * @param pageSize
   * @param pageNumber
   * @returns Paginated list of voice configurations
   */
  async listVoices(
    pageSize?: number,
    pageNumber?: number
  ): Promise<PAGINATED_VOICE_LIST> {
    let response = await this.m_VoicesApi.listVoices(pageSize, pageNumber);

    if (response.status !== 200) {
      throw new Error("Failed to list voices");
    }

    return new PAGINATED_VOICE_LIST(response.paginatedVoices);
  }

  /**
   * Get voice by id
   * @param voiceId  The id of the voice to get
   * @param overrideCache Whether or not to override the cache in the service
   * @returns
   */
  async getVoiceById(voiceId: string, overrideCache?: boolean): Promise<VOICE> {
    //Check if voice is already in memory
    let voice = this.m_VoiceOpts.find((v) => v.id === voiceId);
    if (voice && !overrideCache) {
      return voice;
    }

    let response = await this.m_VoicesApi.getVoice(voiceId);

    if (response.status !== 200) {
      throw new Error("Failed to get voice");
    }

    voice = new VOICE(response.voice);
    this.m_VoiceOpts.push(voice);
    return voice;
  }

  /**
   * Tracks the active voice for the user session
   * @param voice
   */
  async setActiveVoice(voice: VOICE) {
    this.m_CurrentVoice = voice;
  }

  /**
   * Fetches the voice options for the current user
   * @returns List of voice options, either from cache or from the server
   */
  async getUserVoiceOpts(overrideCache?: boolean) {
    if (this.m_VoiceOpts.length === 0 || overrideCache) {
      let response = await this.listVoices();
      this.m_VoiceOpts = response.voices;
    }
    return this.m_VoiceOpts;
  }

  /**
   * Create a new voice
   * @param voice The voice data to create
   */
  async createVoice(voice: CREATE_VOICE_REQ_DTO) {
    let response = await this.m_VoicesApi.createVoice(voice);

    if (response.status !== 200) {
      throw new Error(response);
    }

    return response;
  }

  async getDubLanguages(
    ignoreCache: boolean = false
  ): Promise<DUB_LANGUGAGE[]> {
    if (this.m_LangOpts.length == 0 || ignoreCache) {
      let response = await this.m_VoicesApi.getDubLanguages();

      if (response.status !== 200) {
        throw new Error("Failed to get dub languages");
      }

      //Cache the languages
      this.m_LangOpts = response.languages.map(
        (lang) => new DUB_LANGUGAGE(lang)
      );
    }

    return this.m_LangOpts;
  }
}
