import { BASE_RESPONSE } from "../api/api";
import { MEDIA_TYPE } from "../media/media";
import { PAGIONATION_DTO } from "../pagination";
import { VOICE_DTO } from "../voice/voice";

export enum RECORD_STATE {
  READY = 0,
  IMPORTING = 1,
  MEDIA_ERROR = 2,
}

export interface RECORD_DTO {
  id: string;
  video_id: string;
  question?: string;
  answer?: string;
  state?: RECORD_STATE;
  assetPath?: string;
  thumbnailPath?: string;
  assetType?: MEDIA_TYPE;
  create_date?: Date;
  last_modified?: Date;
  last_modified_by?: string;
  created_by_id?: string;
  is_suggestible?: boolean;
  is_pinned?: boolean;
}

export interface RECORD_WITH_ATTACHMENT_DTO extends RECORD_DTO {
  media_id?: string;
  start_time?: number;
  end_time?: number;
  web_url?: string;
}

export interface PAGINATED_RECORDS_DTO extends PAGIONATION_DTO {
  records: RECORD_WITH_ATTACHMENT_DTO[];
}

export interface RECORD_SEARCH_DTO {
  id: string;
  video_id: string;
  question: string;
  answer: string;
  state: RECORD_STATE;
  create_date: string;
  attachmentType: string;
}

export interface RECORD_DTO_CREATE_REQ {
  question: string;
  answer?: string;
  start_time?: number;
  end_time?: number;
  web_url?: string;
  media_id?: string;
  is_suggestible?: boolean;
  is_pinned?: boolean;
  state?: RECORD_STATE;
}

export interface RECORD_DTO_GET_RES extends BASE_RESPONSE {
  record: RECORD_DTO;
}

export interface RECORD_DTO_CREATE_RES extends BASE_RESPONSE {
  record: RECORD_DTO;
}

export interface RECORD_DTO_UPDATE_REQ {
  record_id?: string;
  question?: string;
  answer?: string;
  state?: RECORD_STATE;
  is_suggestible?: boolean;
  is_pinned?: boolean;
  media_id?: string | null;
  start_time?: number | null;
  end_time?: number | null;
  web_url?: string | null;
}

export interface RECORD_DTO_UPDATE_MEDIA_REQ {
  media_id?: string;
  video_start_time?: number;
  video_end_time?: number;
  web_url?: string;
}

export interface RECORD_DTO_UPDATE_RES extends BASE_RESPONSE {
  record: RECORD_DTO;
}

export interface RECORD_DTO_GET_BY_VIDEO_REQ {
  video_id: string;
}

export interface RECORDS_DTO_GET_RES extends BASE_RESPONSE {
  records: RECORD_DTO[];
}

export interface IMPORTED_RECORD_DTO extends RECORD_WITH_ATTACHMENT_DTO {
  issue?: string;
}

export interface IMPORT_RECORDS_DTO_RES extends BASE_RESPONSE {
  records: IMPORTED_RECORD_DTO[];
}

export interface RECORDS_DTO_PAGINATED_GET_RES extends BASE_RESPONSE {
  records: PAGINATED_RECORDS_DTO;
  seed?: number;
}

export interface RECORD_DTO_DELETE_REQ {
  record_id: string;
}

export interface RECORD_DTO_DELETE_RES extends BASE_RESPONSE {}

export interface RECORD_DTO_QUERY_REQ {
  query: string;
}

export interface RECORD_DTO_QUERY_ENTRY {
  score: number;
  record?: RECORD_WITH_ATTACHMENT_DTO;
  insight?: INSIGHT_KEY_DTO;
  type: "record" | "insight";
  voice?: VOICE_DTO;
}

export interface RECORD_DTO_QUERY_RESULT {
  records: RECORD_DTO_QUERY_ENTRY[];
  type: "record" | "insight" | "fallback" | "none";
}

export interface RECORD_DTO_QUERY_RES extends BASE_RESPONSE {
  records: RECORD_DTO_QUERY_ENTRY[];
  type: "record" | "insight" | "fallback" | "none";
}

export interface RECORD_DTO_ADVANCED_QUERY_RES extends BASE_RESPONSE {
  data: any;
}

export interface RECORD_DTO_TRAINING_PHRASES_RES extends BASE_RESPONSE {
  recordId: string;
  question: string;
  training_phrases: any[];
}

export interface INSIGHT_KEY_DTO {
  insight_name: string;
  insight_key: string;
}

export interface HIA_STUDIO_TOPIC_DTO {
  name: string;
  records: HIA_STUDIO_RECORD_DTO[];
}

export interface HIA_STUDIO_RECORD_DTO {
  question: string;
  answer: string;
  attached_media_url: string;
  is_suggestible: boolean;
}

export interface QNA_PAIR_DTO {
  question: string;
  answer: string;
  startTime?: number;
  endTime?: number;
}

export interface GENERATE_QNA_PAIRS_RES extends BASE_RESPONSE {
  originalText: string;
  qaPairs: QNA_PAIR_DTO[];
  reason?: string;
}
