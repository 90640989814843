import { Injectable } from "@angular/core";
import {
  VIDEO_DTO_CAPTIONS_RES,
  VIDEO_DTO_COMPLETE_RES,
  VIDEO_DTO_CREATE_REQ,
  VIDEO_DTO_CREATE_RES,
  VIDEO_DTO_GET_REQ,
  VIDEO_DTO_GET_STATUS_RES,
  VIDEO_DTO_REUPLOAD_RES,
  VIDEO_DTO_UPDATE_DETAILS_REQ,
  VIDEO_DUB,
  VIDEO_DUB_RES,
  VIDEO_PUBLISH_STATE,
  VIDEO_STATUS,
} from "@shared/models/video/video";
import { v4 as uuidv4 } from "uuid";
import { INSIGHTS_DTO_GET_WIDGET_RES } from "@shared/models/insights/insights";
import { Subject } from "rxjs";
import { VideoApi } from "src/app/api/video/video-api";
import { UserService } from "src/app/services/user/user.service";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { CAPTIONS, VIDEO } from "src/app/models/video/video";
import { uploadToBlobURLSync } from "src/app/utility/blob-utils";
import { ConfigService } from "../config/config.service";
import * as async from "async";
import { StorageService } from "../storage/storage.service";
import { Language } from "@azure/video-indexer-widgets";
import { LANGUAGES } from "src/app/constants/languages";
import { GENERATE_QNA_PAIRS_RES } from "@shared/models/record/record";
import { Events } from "../events/events.service";

/**
 * Video Poll Queue Item used to track the status of polling for a video's data
 */
interface VideoPollQueueItem {
  video_id: string;
  attempts: number;
  polling: boolean;
  video_ready: boolean;
  index_ready: boolean;
  polling_interval: any;
  id: string;
}

export interface CaptionData {
  mode: "showing" | "disabled";
  label: string;
  language: Language | any;
  default: boolean;
}

export interface LanguageData {
  label: string;
  value: Language;
  default?: boolean;
}

export interface VideoSettingsData {
  id: string;
  duration: number;
  captions: CaptionData[];
}

export interface PollResults {
  video_id: string;
  success: boolean;
  progress?: number;
}

export interface UploadResults {
  success: boolean;
  video_id: string;
}

export interface UploadProgress {
  progress: number;
  video_id: string;
}

@Injectable({
  providedIn: "root",
})
export class VideoService {
  private m_VideoApi: VideoApi;
  private m_PollQueue: Array<VideoPollQueueItem> = [];
  private m_MaxAttempts = 120;
  private m_PollingTime = 5000;
  private m_StorageName = "VideosSettings";
  //--------------------------------------------------------------------
  //Observables
  public OnVideoReady: Subject<PollResults> = new Subject<PollResults>();
  public OnIndexReady: Subject<PollResults> = new Subject<PollResults>();
  public OnVideoProgress: Subject<PollResults> = new Subject<PollResults>();
  public OnVideoTimeout: Subject<PollResults> = new Subject<PollResults>();
  public OnUploadComplete: Subject<UploadResults> =
    new Subject<UploadResults>();
  public OnUploadPrograss: Subject<UploadProgress> =
    new Subject<UploadProgress>();
  //--------------------------------------------------------------------
  constructor(
    eventsService: Events,
    userService: UserService,
    configService: ConfigService,
    private m_StorageService: StorageService
  ) {
    this.m_VideoApi = new VideoApi(
      eventsService,
      userService,
      configService.get("SERVER_URL")
    );
  }
  //--------------------------------------------------------------------
  //#region Public Methods
  /**
   * Gets a video by its id
   * @param videoID
   * @returns Video data response
   * @throws Error if video id is invalid
   * @throws CustomError if video failed to fetch
   */
  async fetchVideo(videoID: string): Promise<VIDEO> {
    if (videoID == null || videoID == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let req: VIDEO_DTO_GET_REQ = {
      video_id: videoID,
    };

    let video_data = await this.m_VideoApi.getVideo(req);

    if (video_data.status == 403) {
      throw ErrorFactory.error(video_data.status, "User does not own video");
    } else if (video_data.status != 200) {
      throw ErrorFactory.error(video_data.status, "Failed to get video");
    }

    return new VIDEO(video_data.video);
  }
  //--------------------------------------------------------------------
  /**
   * Fetches multiple videos by their ids
   * @param videoIds
   * @returns Video data response
   * @throws Error if video id is invalid
   */
  async fetchVideos(videoIds: string[]) {
    const CONCURRENCY_LIMIT = 5;
    let fetchedVideos = new Map<string, VIDEO>();
    // Fetch distinct videos concurrently
    await new Promise<void>((resolve, reject) => {
      async.eachLimit(
        videoIds,
        CONCURRENCY_LIMIT,
        async (videoId, callback) => {
          try {
            let video = await this.fetchVideo(videoId);
            fetchedVideos.set(videoId, video);
            callback();
          } catch (error) {
            console.error(`Error fetching video with ID ${videoId}:`, error);
            callback(error as Error);
          }
        },
        (err) => {
          if (err) reject(err);
          else resolve();
        }
      );
    });

    return fetchedVideos;
  }

  //--------------------------------------------------------------------
  async deleteVideo(videoID: string): Promise<boolean> {
    if (videoID == null || videoID == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let res = await this.m_VideoApi.deleteVideo(videoID);

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to delete video");
    }

    this.handleVideoSettingsDelete(videoID);
    return true;
  }
  //--------------------------------------------------------------------
  /**
   * Start polling for the status of the video. This will
   * add the video to the queue and begin polling for the status
   * @param videoID
   * @return poll id if successful, null otherwise
   * @throws Error if video id is invalid
   */
  async startVideoStatusPolling(videoID: string) {
    if (videoID == null || videoID == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let pollId = uuidv4();
    let queueItem: VideoPollQueueItem = {
      video_id: videoID,
      attempts: 0,
      polling: false,
      video_ready: false,
      index_ready: false,
      polling_interval: null,
      id: pollId,
    };

    this.m_PollQueue.push(queueItem);

    let pollingInterval: any;
    let poll = async () => {
      if (queueItem.polling) return;

      queueItem.polling = true;
      queueItem.attempts++;
      let results = await this.pollVideoStatus(queueItem);
      if (results == null || results == true) {
        clearInterval(pollingInterval);
        return;
      }

      if (queueItem.attempts >= this.m_MaxAttempts) {
        console.log("Max attempts reached");
        this.OnVideoTimeout.next({
          video_id: queueItem.video_id,
          success: false,
        });
        clearInterval(queueItem.polling_interval);
        //Remove from queue
        this.m_PollQueue = this.m_PollQueue.filter((x) => x.id != pollId);
        return;
      }
    };

    pollingInterval = setInterval(poll, this.m_PollingTime);
    poll();

    queueItem.polling_interval = pollingInterval;

    return pollId;
  }
  //--------------------------------------------------------------------
  /**
   * Check if the video is currently being polled for status
   * @param videoID
   * @returns
   */
  pollingVideo(videoID: string) {
    let queueItem = this.m_PollQueue.find((x) => x.video_id == videoID);
    if (queueItem == null) return false;

    return true;
  }
  //--------------------------------------------------------------------
  /**
   * Stop polling for the status of the video and remove it from the queue
   * @param videoID
   */
  async stopVideoStatusPolling(pollId: string) {
    let queueItem = this.m_PollQueue.find((x) => x.id == pollId);
    if (queueItem == null) return;

    queueItem.polling = false;
    clearInterval(queueItem.polling_interval);
    //Remove from queue
    this.m_PollQueue = this.m_PollQueue.filter((x) => x.id != pollId);
  }
  //--------------------------------------------------------------------
  /**
   * Stop polling for the status of all videos and clear the queue
   */
  async stopAllVideoStatusPolling() {
    this.m_PollQueue.forEach((video) => {
      this.stopVideoStatusPolling(video.video_id);
    });
  }
  //--------------------------------------------------------------------
  /**
   * Get the status of the video
   * @returns
   * @throws Error if video id is invalid
   */
  async getVideoStatus(video_id: string): Promise<VIDEO_DTO_GET_STATUS_RES> {
    if (video_id == null || video_id == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let req: VIDEO_DTO_GET_REQ = {
      video_id: video_id,
    };

    let statusRes = await this.m_VideoApi.getVideoStatus(req);

    if (statusRes.status != 200) {
      throw ErrorFactory.error(statusRes.status, "Failed to get video status");
    } else {
      return statusRes;
    }
  }
  //--------------------------------------------------------------------
  /**
   * Update the video details
   * @param name
   * @param description
   * @param video_id
   * @returns
   * @throws Error if video id is invalid
   */
  async updateVideoDetails(
    name: string,
    description: string,
    video_id: string,
    is_360 = false
  ): Promise<VIDEO_DTO_COMPLETE_RES> {
    if (video_id == null || video_id == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let req: VIDEO_DTO_UPDATE_DETAILS_REQ = {
      video_name: name,
      video_description: description,
      is_360_video: is_360,
    };

    let res = await this.m_VideoApi.updateVideoDetails(video_id, req);

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to update video details");
    } else {
      return res;
    }
  }
  //--------------------------------------------------------------------
  /**
   * Get the video insights raw json data
   * @param req
   * @returns
   * @throws Error if video id is invalid
   */
  async getVideoInsights(req: VIDEO_DTO_GET_REQ) {
    if (req.video_id == null || req.video_id == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let res = await this.m_VideoApi.getVideoInsights(req);

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to get video insights");
    }

    return res;
  }
  //--------------------------------------------------------------------
  /**
   * Get the video insights widget
   * @param video_id
   * @returns
   * @throws Error if video id is invalid
   */
  async getVideoInsightsWidget(
    video_id: string,
    allowEdit: boolean = false
  ): Promise<INSIGHTS_DTO_GET_WIDGET_RES> {
    if (video_id == null || video_id == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let req: VIDEO_DTO_GET_REQ = {
      video_id: video_id,
    };

    let res = await this.m_VideoApi.getVideoInsightsWidget(req, allowEdit);

    if (res.status != 200) {
      throw ErrorFactory.error(
        res.status,
        "Failed to get video insights widget"
      );
    }

    return res;
  }
  //--------------------------------------------------------------------
  async getCaptions(video_id: string, language?: string): Promise<CAPTIONS> {
    if (video_id == null || video_id == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let res: VIDEO_DTO_CAPTIONS_RES = await this.m_VideoApi.getCaptions(
      video_id,
      language
    );

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to get captions URL");
    }

    return new CAPTIONS(res, language ?? "en-US");
  }
  //--------------------------------------------------------------------
  async getCaptionsMedia(
    video_id: string,
    media_id: string,
    language?: string
  ): Promise<VIDEO_DTO_CAPTIONS_RES> {
    if (media_id == null || media_id == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let res: VIDEO_DTO_CAPTIONS_RES = await this.m_VideoApi.getCaptionsMedia(
      video_id,
      media_id,
      language
    );

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to get captions URL");
    }

    return res;
  }
  //--------------------------------------------------------------------
  async updateCaptions(video_id: string): Promise<boolean> {
    if (video_id == null || video_id == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let res = await this.m_VideoApi.updateCaptions(video_id);

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to update captions");
    }

    return true;
  }
  //--------------------------------------------------------------------
  /**
   * Upload a file to the server
   * @param file
   * @returns video_id if successful, null otherwise
   * @throws Error if file type is invalid, or if the video failed to upload
   */
  async uploadFile(
    file: File,
    name?: string,
    description?: string,
    video_id?: string,
    folder_id?: string
  ): Promise<string | null> {
    let fileName = file.name;
    let fileNameNoExt = fileName.split(".")[0];
    //Set nameToSet to the name provided or the file name without the extension, as long as name is not null or empty
    let nameToSet = name ? name : fileNameNoExt;
    //Verify file is a video
    if (file.type.split("/")[0] != "video") {
      throw new Error("Invalid file type");
    }

    let isReupload = video_id != null && video_id != "";
    let res: VIDEO_DTO_CREATE_RES | VIDEO_DTO_REUPLOAD_RES | null = null;
    if (!isReupload) {
      //Create video in DB
      let videoReq: VIDEO_DTO_CREATE_REQ = {
        name: nameToSet,
        description: description ? description : "",
        folder_id: folder_id,
      };
      res = await this.m_VideoApi.createVideo(videoReq);
      if (res == null || res.status != 200) {
        throw new Error("Failed to create video");
      }
    } else {
      //Reupload video in DB
      res = await this.m_VideoApi.reuploadVideo(video_id ?? "");
      if (res == null || res.status != 200) {
        throw new Error("Failed to start reupload process video");
      }
    }

    //Upload video to Azure
    if (res.asset.video_url == undefined) {
      throw new Error("No URL provided");
    }
    //Insert the name of the video before the query string
    let fileExt = fileName.split(".").pop();
    let blobName = res.asset.id + "." + fileExt;
    let urlParts = res.asset.video_url.split("?");
    let url = urlParts[0] + "/" + blobName + "?" + urlParts[1];
    this.uploadToStorage(file, res.asset.id, url, isReupload);

    return res.asset.id;
  }
  //--------------------------------------------------------------------
  async generateQnAPairs(video_id: string): Promise<GENERATE_QNA_PAIRS_RES> {
    let response = await this.m_VideoApi.generateQnAPairs(video_id);

    if (response == null || response.status != 200) {
      throw ErrorFactory.error(
        response?.status,
        response.error || "Generate QnA Pairs Failed"
      );
    }

    return response;
  }
  //--------------------------------------------------------------------
  async updateThumbnail(video_id: string, imgFile: File) {
    let ThumbnailData = await this.m_VideoApi.updateThumbnail(
      video_id,
      imgFile
    );

    if (ThumbnailData.status == null || ThumbnailData.status != 200) {
      throw new Error(ThumbnailData.error || "Failed to update thumbnail");
    }

    return ThumbnailData;
  }

  //--------------------------------------------------------------------
  async getEncodingProgress(video_id: string): Promise<number> {
    let progress = 0;
    try {
      progress = (await this.m_VideoApi.getEncodingProgress(video_id)).progress;
    } catch (e) {
      console.error(e);
    }
    return progress;
  }
  //--------------------------------------------------------------------
  async getIndexerProgress(video_id: string): Promise<number> {
    let progress = 0;
    try {
      progress = (await this.m_VideoApi.getIndexerProgress(video_id)).progress;
    } catch (e) {
      console.error(e);
    }
    return progress;
  }
  //--------------------------------------------------------------------
  async setVideoPublishState(
    video_id: string,
    state: VIDEO_PUBLISH_STATE
  ): Promise<boolean> {
    let res = await this.m_VideoApi.publishVideo(video_id, {
      visibility: state,
    });

    if (res?.status != 200) {
      throw ErrorFactory.error(
        res?.status,
        "Failed to set video publish state"
      );
    } else {
      return true;
    }
  }
  //--------------------------------------------------------------------
  async setVideoFiltersState(video_id: string, state: boolean) {
    let res = await this.m_VideoApi.setVideoFiltersState(video_id, state);

    if (res?.status != 200) {
      throw ErrorFactory.error(
        res?.status,
        "Failed to set video filters state"
      );
    } else {
      return true;
    }
  }
  //--------------------------------------------------------------------
  async getVideoPlaylists(video_id: string) {
    let res = await this.m_VideoApi.getVideoPlaylists(video_id);

    if (res?.status != 200) {
      throw ErrorFactory.error(res?.status, "Failed to get video playlists");
    }

    return res;
  }
  async setVideoChatGPTState(video_id: string, state: boolean) {
    let res = await this.m_VideoApi.setVideoChatGPTState(video_id, state);

    if (res?.status != 200) {
      throw ErrorFactory.error(
        res?.status,
        "Failed to set video chat GPT state"
      );
    } else {
      return true;
    }
  }
  //--------------------------------------------------------------------
  async setVideoReadOnlyState(video_id: string, state: boolean) {
    let res = await this.m_VideoApi.setVideoReadOnlyState(video_id, state);

    if (res?.status != 200) {
      throw ErrorFactory.error(
        res?.status,
        "Failed to set video read only state"
      );
    } else {
      return true;
    }
  }
  //--------------------------------------------------------------------
  async setVideoVoice(video_id: string, voice_id: string) {
    let res = await this.m_VideoApi.setVoiceId(video_id, voice_id);

    if (res?.status != 200) {
      throw ErrorFactory.error(res?.status, "Failed to set video voice");
    } else {
      return true;
    }
  }
  //--------------------------------------------------------------------
  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  /**
   * Uploads a file to storage URL provided
   * @param file
   * @param video_id
   * @param url
   * @returns true if successful, false otherwise
   */
  private async uploadToStorage(
    file: File,
    video_id: string,
    url: string | undefined,
    reupload: boolean = false
  ) {
    if (url == undefined) {
      console.error("No URL provided");
      return false;
    }

    try {
      await uploadToBlobURLSync(file, url, (progress) => {
        this.OnUploadPrograss.next({
          progress: progress,
          video_id: video_id,
        });
      });

      await this.onUploadComplete(video_id, reupload);
      return true;
    } catch (e) {
      console.error(e);
      //TODO inform backend upload failed in order to flag video as errored

      this.OnUploadComplete.next({ success: false, video_id: video_id });
      return false;
    }
  }
  /**
   * Handle to delete settings on storage
   * @param video_id
   */
  private async setValueStorage(videoSettings: Array<VideoSettingsData>) {
    return await this.m_StorageService.set(
      this.m_StorageName,
      JSON.stringify(videoSettings)
    );
  }
  /**
   * Handle to get settings on storage
   * @param video_id
   */
  public async handleVideoSettingsGet(
    video_id: string
  ): Promise<VideoSettingsData | null> {
    let storagedVideos = JSON.parse(
      await this.m_StorageService.get(this.m_StorageName)
    );
    if (storagedVideos) {
      return storagedVideos.find((v: VideoSettingsData) => v.id === video_id);
    }
    return null;
  }
  /**
   * Handle to get settings on storage
   * @param video_id
   */
  public async handleVideoSettingsGetId(
    video_id: string
  ): Promise<VideoSettingsData | null> {
    let storagedVideos = JSON.parse(
      await this.m_StorageService.get(this.m_StorageName)
    );
    let foundSetting = null;
    if (!storagedVideos || !Array.isArray(storagedVideos)) storagedVideos = [];
    if (storagedVideos.length > 0) {
      foundSetting =
        storagedVideos.find((v: VideoSettingsData) => v.id === video_id) ||
        null;
    }
    return foundSetting;
  }
  /**
   * Handle to delete settings on storage
   * @param video_id
   */
  public async handleVideoSettingsDelete(video_id: string) {
    let storagedVideos = JSON.parse(
      await this.m_StorageService.get(this.m_StorageName)
    );
    if (!storagedVideos || !Array.isArray(storagedVideos)) storagedVideos = [];
    if (storagedVideos.length > 0) {
      storagedVideos = storagedVideos.filter(
        (v: VideoSettingsData) => v.id !== video_id
      );
    }
    this.setValueStorage(storagedVideos);
  }
  /**
   * Handle to add settings on storage
   * @param video_id
   * @param key
   * @param value
   */
  public async handleVideoSettingsValue(
    video_id: string,
    key: keyof VideoSettingsData,
    value: any
  ) {
    let storagedVideos = JSON.parse(
      await this.m_StorageService.get(this.m_StorageName)
    );

    if (!storagedVideos || !Array.isArray(storagedVideos)) storagedVideos = [];

    //Found video storaged
    const videoStoraged = storagedVideos.find(
      (v: VideoSettingsData) => v.id === video_id
    );

    if (videoStoraged) {
      if (key in videoStoraged) {
        //If video is storaged it will update just the given value
        for (const videoStoragedKey in videoStoraged) {
          if (videoStoragedKey === key) {
            videoStoraged[videoStoragedKey] = value;
          }
        }
      } else {
        videoStoraged[key] = value;
      }
    } else {
      //Create new videoSettings object on store if is not storaged
      let newVideoSettings: VideoSettingsData | any = {
        id: video_id || "",
        duration: 0,
        language: null,
        captions: key === "captions" ? value : [],
      };
      for (const newVideoSettingsProp in newVideoSettings) {
        if (newVideoSettingsProp === key) {
          newVideoSettings[newVideoSettingsProp] = value;
        }
      }
      storagedVideos.push(newVideoSettings);
    }

    this.setValueStorage(storagedVideos);
  }
  /**
   * Called when the upload is complete
   * @param video_id
   */
  private async onUploadComplete(video_id: string, reupload: boolean = false) {
    let success = false;
    let res = await this.m_VideoApi.completeVideo(
      {
        video_id: video_id,
      },
      reupload
    );

    if (res.status != 200) {
      console.error("Failed to complete video");
    } else {
      success = true;
    }

    this.OnUploadComplete.next({ success: success, video_id: video_id });
  }
  //--------------------------------------------------------------------
  /**
   * Poll the status of the video
   * @param videoID
   * @returns
   */
  private async pollVideoStatus(queueItem: VideoPollQueueItem) {
    let latestStats = null;
    try {
      latestStats = await this.getVideoStatus(queueItem.video_id);
    } catch (error) {
      console.error(`Error polling video status: ${queueItem.video_id}`);
    }

    if (latestStats == null) {
      queueItem.polling = false;
      return null;
    }

    let retval = false;
    //Check if the video is ready
    if (
      latestStats.video_status == VIDEO_STATUS.READY &&
      !queueItem.video_ready
    ) {
      //Video is ready, emit ready event
      queueItem.video_ready = true;
      this.OnVideoReady.next({ video_id: queueItem.video_id, success: true });
    } else if (
      latestStats.video_status == VIDEO_STATUS.PROCESSING &&
      !queueItem.video_ready
    ) {
      //Video is still processing, emit progress fetched from backend
      let progress = await this.getEncodingProgress(queueItem.video_id);

      this.OnVideoProgress.next({
        video_id: queueItem.video_id,
        success: true,
        progress: progress,
      });
    } else if (latestStats.video_status == VIDEO_STATUS.ERROR) {
      //Video failed to process
      console.log("Video failed to process");
      queueItem.polling = false;
      this.OnVideoReady.next({ video_id: queueItem.video_id, success: false });
      return null;
    }

    //Check if the video is indexed
    if (
      latestStats.video_index_status == VIDEO_STATUS.READY &&
      !queueItem.index_ready
    ) {
      queueItem.index_ready = true;
      this.OnIndexReady.next({ video_id: queueItem.video_id, success: true });
    } else if (
      latestStats.video_index_status == VIDEO_STATUS.PROCESSING &&
      !queueItem.index_ready &&
      queueItem.video_ready
    ) {
      //Indexer is still processing, emit progress fetched from backend
      let progress = await this.getIndexerProgress(queueItem.video_id);

      this.OnVideoProgress.next({
        video_id: queueItem.video_id,
        success: true,
        progress: progress,
      });
    } else if (latestStats.video_index_status == VIDEO_STATUS.ERROR) {
      console.log("Video failed to index");
      queueItem.polling = false;
      this.OnIndexReady.next({ video_id: queueItem.video_id, success: false });
      return null;
    }

    //We no longer need to poll for status if both are ready
    retval =
      latestStats.video_status == VIDEO_STATUS.READY &&
      latestStats.video_index_status == VIDEO_STATUS.READY;

    queueItem.polling = false;
    return retval;
  }
  /**
   * Get settings from video on storage
   * @param videoID
   */
  async getVideoSettings(videoId: string) {
    let storagedVideos: Array<VideoSettingsData> = JSON.parse(
      await this.m_StorageService.get(this.m_StorageName)
    );

    if (!storagedVideos) {
      storagedVideos = [];
      await this.m_StorageService.set(
        this.m_StorageName,
        JSON.stringify(storagedVideos)
      );
    }

    const settings = storagedVideos.find(
      (v: VideoSettingsData) => v.id === videoId
    );

    return settings;
  }
  /**
   * Get captions from video on storage
   * @param videoID
   */
  async getVideoCaptions(videoId: string): Promise<CaptionData[]> {
    const videoSettings = await this.getVideoSettings(videoId);
    return videoSettings && videoSettings.captions
      ? videoSettings.captions
      : [];
  }
  /**
   * Get caption with "Showing" mode
   * @param videoID
   */
  async getDefaultCaption(videoId: string) {
    const captions = await this.getVideoCaptions(videoId);
    return captions.find((c) => c.default) || null;
  }
  /**
   * Get caption with "Showing" mode
   * @param videoID
   */
  async getShowingCaption(videoId: string) {
    const captions = await this.getVideoCaptions(videoId);
    return captions.find((c) => c.mode === "showing") || null;
  }
  /**
   * Change caption video mode
   * @param videoID
   * @param track
   */
  async setCaptionValue(videoId: string, captions: any) {
    await this.handleVideoSettingsValue(videoId, "captions", captions);
  }
  /**
   * Insight Support Language
   * @param videoID
   * @param track
   */
  async getInsightSupportLanguage(language: any) {
    let currentCaptionLang = undefined;

    //Discover if language is supported by InsightWidget
    const SUPPORTED_LANGUAGES = LANGUAGES;
    const languageSupportedIndex = SUPPORTED_LANGUAGES.findIndex(
      (lang: Language) => lang.toLowerCase() === language.toLowerCase()
    );

    if (languageSupportedIndex === -1) {
      //If language is not supported, set default
      currentCaptionLang = undefined;
    } else if (languageSupportedIndex >= 0) {
      currentCaptionLang = SUPPORTED_LANGUAGES[languageSupportedIndex];
    }

    return currentCaptionLang ? currentCaptionLang : undefined;
  }

  /**
   * Request dub for video
   * @param video_id
   * @param languages
   * @returns
   */
  async requestDub(video_id: string, languages: string[]) {
    let res = await this.m_VideoApi.requestDub(video_id, languages);

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to request dub");
    }

    return res;
  }

  /**
   * Get dub languages
   * @param video_id
   * @returns
   */
  async getDubLanguages(video_id: string): Promise<VIDEO_DUB[]> {
    let res = await this.m_VideoApi.getDubLanguages(video_id);

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to get dub languages");
    }

    return res.dubs;
  }
  //#endregion
}
