import { Injectable } from "@angular/core";
import { ActionApi } from "src/app/api/action/action-api";
import { ConfigService } from "../config/config.service";
import { UserService } from "../user/user.service";
import { SEND_MESSAGE_REQ_DTO } from "@shared/models/actions/send-message";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { SEARCH_LIST } from "src/app/models/action/search";
import { Events } from "../events/events.service";

@Injectable({
  providedIn: "root",
})
export class ActionService {
  private m_ActionAPIService: ActionApi;

  constructor(
    eventsService: Events,
    userService: UserService,
    private m_ConfigService: ConfigService
  ) {
    this.m_ActionAPIService = new ActionApi(
      eventsService,
      userService,
      this.m_ConfigService.get("SERVER_URL")
    );
  }

  //#region Public Methods
  async sendContactMessage(
    name: string,
    email: string,
    message: string,
    subject: string
  ) {
    let msg_dto: SEND_MESSAGE_REQ_DTO = {
      name: name,
      email: email,
      message: message,
      subject: subject,
    };

    let result = await this.m_ActionAPIService.sendMessage(msg_dto);

    if (result.status != 200) {
      throw ErrorFactory.error(
        result.status,
        result.error ?? "Failed to send message"
      );
    }

    return result;
  }
  //--------------------------------------------------------------------------------
  async globalSearch(
    query: string,
    pageNumber: number,
    pageSize: number
  ): Promise<SEARCH_LIST> {
    let result = await this.m_ActionAPIService.globalSearch(
      query,
      pageNumber,
      pageSize
    );

    if (result.status != 200) {
      throw ErrorFactory.error(
        result.status,
        result.error ?? "Failed to search for query: " + query
      );
    }

    return new SEARCH_LIST(result);
  }
  //#endregion
}
