import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { UserService } from "src/app/services/user/user.service";
import {
  convertToFolderDTO,
  dispatchResizeEvent,
  isFolderDTO,
} from "src/app/utility/utils";
import { T } from "src/app/services/localization/localization.service";
import { UiService } from "src/app/services/ui/ui.service";
import { FeedbackModalComponent } from "../../modals/feedback-modal/feedback-modal.component";
import { PricingModalComponent } from "../../modals/pricing-modal/pricing-modal.component";
import {
  AlertModalComponent,
  AlertModalOptions,
  CustomAlertInput,
  DismissResult,
} from "../../modals/alert-modal/alert-modal.component";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { TermsModalComponent } from "../../modals/terms-modal/terms-modal.component";
import { FOLDER_DTO, TransformedFolder } from "@shared/models/folders/folder";
import { FoldersService } from "src/app/services/folders/folders.service";
import {
  DropPosition,
  TreeItem,
  TreeItemDropEvent,
  TreeViewComponent,
} from "@progress/kendo-angular-treeview";
import { shortIdToUuid, uuidToShortId } from "@shared/utils/utils";
import {
  DragTargetDragEvent,
  DropTargetEvent,
} from "@progress/kendo-angular-utils";
import { DragDropItem } from "src/app/utility/drag-drop-collection";
import { VIDEO } from "src/app/models/video/video";
import { StorageService } from "src/app/services/storage/storage.service";
import {
  ContextMenuComponent,
  ContextMenuOption,
} from "../context-menu/context-menu.component";
import { UploadComponent } from "../../upload/upload.component";
import { SessionService } from "src/app/services/session/session.service";
import { LibraryPage } from "src/app/pages/library/library.page";

interface OptionSet {
  content: OptionContent[];
  disabled?: boolean;
}

interface OptionContent {
  text: string;
  icon: string;
  route?: { route: string; queryParams: Params };
  action: () => void;
  disabled?: boolean;
}

@Component({
  selector: "app-root-container",
  templateUrl: "./root-container.component.html",
  styleUrls: ["./root-container.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RootContainerComponent implements OnInit {
  @ViewChild(FeedbackModalComponent, { static: false }) m_FeedbackModal:
    | FeedbackModalComponent
    | undefined;
  @ViewChild(PricingModalComponent)
  m_PricingModalComponent!: PricingModalComponent;
  @ViewChild(AlertModalComponent, { static: false }) m_AlertModal:
    | AlertModalComponent
    | undefined;
  @ViewChild(TermsModalComponent, { static: false }) m_TermsModal:
    | TermsModalComponent
    | undefined;
  @ViewChild("libraryTreeView", { static: false, read: TreeViewComponent })
  m_TreeView: TreeViewComponent | undefined;
  @ViewChild("contextMenu", { read: ContextMenuComponent }) m_CtxMenu:
    | ContextMenuComponent
    | undefined;
  @ViewChild("uploader") m_Uploader: UploadComponent | undefined;

  public m_HideSidebar: boolean = false;
  public m_UserLimits: any = null;
  public m_MaxLength: number = 30;
  public m_MaxQnAs: number = 30;
  public m_CurrentLength: number = 0;
  public m_CurrentQnAs: number = 0;
  public m_TierName: string = "";
  public m_TierValue: string = "";
  public m_CurrentProgress: number = 0;
  public m_CurrentQnAProgress: number = 0;
  public m_SubscriptionId: number = 0;
  public m_Options: OptionSet = {
    content: [],
  };
  public m_PlayerFullscreen: boolean = false;
  public m_Loading: boolean = true;
  public m_SelectedFolder?: FOLDER_DTO | null = null;
  public expandedKeys: any[] = [];
  public selectedKeys: any[] = ["0_1"];
  public m_ContextMenuFolder: FOLDER_DTO | null = null;
  public m_SelectedBackgroundWidth: string = "";
  public m_SelectedBackgroundPosition: string = "";
  public m_DragStatusIcon: string = "";
  public m_HoveredFolder: string = "";
  public m_IsDragginOnRoot: boolean = false;
  public isItemSelected = (_: any, index: string) =>
    this.selectedKeys.indexOf(index) > -1;
  public $t = T.translate;
  public m_Routes: {
    [key: string]: { route: string; queryParams: Params };
  };

  get CurrentRoute() {
    return this.m_CurrentRoute;
  }

  private m_ExpandedFoldersIds: string[] = [];
  private m_CurrentRoute: { route: string; queryParams: Params };
  private m_FolderContextMenuOptions: ContextMenuOption[] = [];
  private AUTO_CLOSE_WIDTH = 900;
  private HIDE_SIDEBAR_WIDTH = 768;

  //Event Listeners
  private m_WindowResizeEvent: EventListener | null = null;
  private m_OnLoginSubscription: Subscription;
  private m_OnLogoutSubscription: Subscription;
  private m_OnAlertSubscription: Subscription;
  private m_OnTermsModalSubscription: Subscription;
  private m_UpdateFolders: Subscription;
  private m_VideoUpload: Subscription;

  get LoggedIn() {
    return this.m_AuthService.LoggedIn;
  }

  get Userlimits() {
    return this.m_UserService.ActiveUserLimits;
  }

  get CanOpenSidebar() {
    return window.innerWidth > this.AUTO_CLOSE_WIDTH;
  }

  get HideNav() {
    return this.m_UIService.playerFullscreen || this.m_UIService.VideoEmbed;
  }

  get IsVideoView() {
    return (
      this.m_UIService.EditorVideo ||
      this.m_CurrentRoute.route === "/edit" ||
      this.m_CurrentRoute.route === "/watch"
    );
  }

  get isProfilePage() {
    return (
      this.m_CurrentRoute.route === "/edit-profile" ||
      this.m_CurrentRoute.route === "/u/:username" ||
      this.m_CurrentRoute.route === "/u"
      // Some browsers will caputre route as /u, others like opera, will capture as /u/:username
    );
  }

  get HideSideBar() {
    return (
      this.m_UIService.Hidesidebar || (this.m_HideSidebar && !this.IsVideoView)
    );
  }

  get FolderHierarchyTree() {
    return this.m_FoldersService.FolderHierarchy?.getTree();
  }

  get FolderHierarchy() {
    return this.m_FoldersService.FolderHierarchy;
  }

  get Video(): VIDEO | null {
    return this.m_UIService.EditorVideo || this.m_UIService.WatchVideo;
  }

  get ExpandSidebar() {
    return this.m_UIService.ExpandSidebar;
  }

  //--------------------------------------------------------------------
  constructor(
    private m_UserService: UserService,
    private m_AuthService: SessionService,
    private m_Router: Router,
    private m_UIService: UiService,
    private m_Events: Events,
    private m_FoldersService: FoldersService,
    private m_StorageService: StorageService,
    private m_Renderer: Renderer2
  ) {
    this.m_CurrentRoute = { route: "/", queryParams: {} };

    this.m_Routes = {
      Home: { route: "/home", queryParams: {} },
      Library: {
        route: "/library",
        queryParams: {},
      },
      AllQvios: {
        route: "/library/all",
        queryParams: {},
      },
      Playlists: { route: "/playlists/manage", queryParams: {} },
      Analytics: { route: "/analytics", queryParams: {} },
    };

    this.m_OnLoginSubscription = this.m_AuthService.OnLogin.subscribe(() => {
      this.initialize();
    });

    this.m_OnLogoutSubscription = this.m_AuthService.OnLogout.subscribe(() => {
      this.resetFolderTree();
    });

    this.m_VideoUpload = this.m_Events.subscribe(EVENTS.UPDATE_LIMITS, () => {
      this.getUserPlan();
    });

    this.m_UpdateFolders = this.m_Events.subscribe(
      EVENTS.FOLDERS_UPDATED,
      () => {
        this.getAllFolders();
      }
    );

    this.m_Router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onRouteChanged(event.url);
      }
    });

    this.m_WindowResizeEvent = (event: any) => this.onWindowResize();
    window.addEventListener("resize", this.m_WindowResizeEvent);

    this.m_OnAlertSubscription = this.m_Events.subscribe(
      EVENTS.ALERT,
      this.onAlert.bind(this)
    );

    this.m_OnTermsModalSubscription = this.m_Events.subscribe(
      EVENTS.SHOW_TERMS_MODAL,
      () => {
        if (this.m_TermsModal != null) this.m_TermsModal.show();
      }
    );

    this.m_Events.subscribe(EVENTS.VIDEO_LOADED, () => {
      if (
        this.m_CurrentRoute.route == "/watch" ||
        this.m_CurrentRoute.route == "/edit"
      ) {
        if (
          this.Video?.video_user_id != this.m_UserService.ActiveUserInfo?.id
        ) {
          this.selectedKeys = ["0"];
          return;
        }
        let folderId = this.Video?.folder_id;

        if (folderId) {
          this.setFolderSelected(folderId, true);
        } else {
          this.selectedKeys = ["0"];
        }
      }
    });
  }
  //--------------------------------------------------------------------
  ngOnInit() {}

  ngAfterViewInit() {
    dispatchResizeEvent(100, 1);
  }

  private async initialize() {
    this.checkExpandedSideBar();
    this.resetFolderTree();
    dispatchResizeEvent(100, 1);
    await this.fetchUserInfo();
    this.getUserPlan();
    this.initMenuOptions();
    this.initContextMenus();
    await this.getAllFolders();
    this.restoreExpandedFolders();
  }

  ngOnDestroy() {
    this.m_OnLoginSubscription.unsubscribe();
    this.m_OnLogoutSubscription.unsubscribe();
    this.m_UpdateFolders.unsubscribe();
    if (this.m_WindowResizeEvent != null)
      window.removeEventListener("resize", this.m_WindowResizeEvent);
    this.m_OnAlertSubscription.unsubscribe();
    this.m_OnTermsModalSubscription.unsubscribe();
  }
  //--------------------------------------------------------------------
  //#region HTML Event Handlers
  onOptionClicked(event: MouseEvent, contentIndex: number) {
    let target = event.target as HTMLElement;
    if (target.classList.contains("expand-icon")) return;

    this.m_Options.content[contentIndex].action();
    if (this.getIsMobile()) {
      this.m_UIService.setExpandSidebar(false);
    }
  }

  async onRouteChanged(url: string) {
    const parsedUrl = new URL(url, window.location.origin);
    const routePath = parsedUrl.pathname;
    const queryParams: { [key: string]: string } = {};
    parsedUrl.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    this.m_CurrentRoute = {
      route: routePath,
      queryParams: queryParams,
    };

    if (this.m_CurrentRoute.route.startsWith("/library")) {
      let folderId = LibraryPage.getFolderIdFromRoute(
        this.m_CurrentRoute.route
      );
      if (this.m_FoldersService.FolderHierarchy != null && folderId != "") {
        this.setFolderSelected(folderId);
      } else {
        this.selectedKeys = ["0"];
      }
    }

    //Wait 500ms to IsVideoView to be loaded and unloaded properly
    await new Promise<void>((resolve) => {
      const initialIsVideoView = this.IsVideoView;
      const startTime = Date.now();

      const checkIsVideoView = () => {
        const elapsedTime = Date.now() - startTime;

        // Check if IsVideoView has changed or remained the same
        if (
          this.IsVideoView === false ||
          (typeof this.IsVideoView === "object" &&
            this.IsVideoView !== initialIsVideoView) ||
          elapsedTime >= 1000
        ) {
          resolve();
        } else {
          setTimeout(checkIsVideoView, 50); // Check every 50ms
        }
      };

      checkIsVideoView();
    });

    // After IsVideoView has been updated or after 1000ms, call checkExpandedSideBar
    this.checkExpandedSideBar();
  }

  onWindowResize() {
    // if (window.innerWidth > this.AUTO_CLOSE_WIDTH) {
    //   //If the window is resized to be larger than the AUTO_CLOSE_WIDTH,
    //   //then we want to open the sidebar if it was open before the resize
    // } else if (window.innerWidth <= this.HIDE_SIDEBAR_WIDTH) {
    //   //Hide the sidebar

    //   this.m_HideSidebar = true;
    //   return;
    // }
    this.setBackgroundWidth();
    this.checkExpandedSideBar();
  }

  async onTermsDismissed() {
    this.m_Events.publish(EVENTS.TERMS_MODAL_DISMISSED);

    try {
      await this.m_UserService.acceptEULA();
    } catch (error) {
      console.error(error);
    }
  }

  setDragIcon(args?: any) {
    if (!args.destinationItem) {
      this.m_DragStatusIcon = "k-color-error k-i-cancel";
      this.m_IsDragginOnRoot = false;
    }
    if (args.originalEvent.target.id == "library-button") {
      this.m_DragStatusIcon = " k-i-folder-up";
      this.m_IsDragginOnRoot = true;
    } else if (this.isFolderBox(args.originalEvent.target) != null) {
      if (
        this.checkFolderMoveToElement(
          args.sourceItem.item.dataItem.folderData.id,
          args.originalEvent.target
        )
      ) {
        this.m_DragStatusIcon = "k-i-arrow-root";
        this.m_HoveredFolder = args.destinationItem.item.dataItem.folderData.id;
      } else {
        this.m_DragStatusIcon = "k-color-error k-i-cancel";
      }
      this.m_IsDragginOnRoot = false;
    } else if (args.destinationItem?.item.dataItem.folderData.id) {
      let sourceFolder = args.sourceItem.item.dataItem as TransformedFolder;
      let destinationFolder = args.destinationItem.item
        .dataItem as TransformedFolder;

      if (sourceFolder != null && destinationFolder != null) {
        if (
          this.checkFolderMove(
            sourceFolder.folderData.id || "",
            destinationFolder
          )
        ) {
          this.m_DragStatusIcon = "k-i-arrow-root";
          this.m_HoveredFolder =
            args.destinationItem.item.dataItem.folderData.id;
        } else {
          this.m_DragStatusIcon = "k-color-error k-i-cancel";
        }
      } else {
        this.m_DragStatusIcon = "k-color-error k-i-cancel";
      }
      this.m_IsDragginOnRoot = false;
    } else {
      this.m_DragStatusIcon = "k-color-error k-i-cancel";
      this.m_IsDragginOnRoot = false;
    }
  }

  checkFolderDrag(folderId: string) {
    if (this.m_HoveredFolder == folderId) return true;
    else return false;
  }

  handleDragEnter(dataItem: string) {
    if (dataItem == "0") {
      this.m_IsDragginOnRoot = true;
    } else {
      this.m_HoveredFolder = dataItem;
    }
  }

  handleDragLeave() {
    if (this.m_IsDragginOnRoot) {
      this.m_IsDragginOnRoot = false;
    }
    this.m_HoveredFolder = "";
  }

  private isFolderBox(target: HTMLElement | null): HTMLElement | null {
    if (target == null) {
      return null;
    } else if (target.classList.contains("folder-box")) {
      return target;
    } else {
      return this.isFolderBox(target.parentElement);
    }
  }

  private checkFolderMove(
    sourceFolderId: string,
    destination: TransformedFolder | string
  ) {
    if (!this.FolderHierarchy) {
      return false;
    } else {
      return !this.FolderHierarchy.isAncestorInTree(
        destination,
        sourceFolderId
      );
    }
  }

  private checkFolderMoveToElement(
    sourceFolderId: string,
    destinationElement: HTMLElement
  ) {
    let folderElement = this.isFolderBox(destinationElement);
    let destinationId = folderElement?.getAttribute("data-folder-id");
    if (destinationId == null) {
      return false;
    } else {
      return this.checkFolderMove(sourceFolderId, destinationId);
    }
  }

  async onFolderMove(args?: any) {
    this.m_HoveredFolder = "";
    // Gets arguments from TreeView and send it to Folders API
    let destination;
    if (args.originalEvent.target.id == "library-button") destination = "0";
    else if (
      args.destinationItem?.item.dataItem.folderData.parent_id == null &&
      args.originalEvent.srcElement.classList[0] == "k-treeview-leaf"
    )
      destination = "0";
    else destination = args.destinationItem?.item.dataItem.folderData.id;

    const item = args.sourceItem.item.dataItem.folderData.id;
    this.m_IsDragginOnRoot = false;
    if (!destination || !item) return;

    let destinationFolder = args.destinationItem?.item
      .dataItem as TransformedFolder;

    if (destination == "0" || this.checkFolderMove(item, destinationFolder)) {
      //this.m_Loading = true;
      try {
        const data = await this.m_FoldersService.moveFolders(destination, item);
        if (data) {
          this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
        }
      } finally {
        //this.m_Loading = false;
      }
    }
  }

  redirectFolder() {
    if (this.m_SelectedFolder) {
      let path = LibraryPage.getRouterPath(this.m_SelectedFolder?.id);
      this.m_Router.navigate(path);

      if (this.getIsMobile()) {
        this.m_UIService.setExpandSidebar(false);
      }
    }
  }

  onSelectedChange(event: any) {
    // Get an Index from Kendo TreeView like [0_1_2] and find it on folders list
    if (!this.FolderHierarchy) return;
    this.selectedKeys = event;
    const path = event[0].split("_").map((index: any) => parseInt(index, 10));

    if (path.length == 1) {
      this.m_SelectedFolder = {
        id: "0",
        name: "My Library",
        created_at: new Date(8.64e15),
        updated_at: new Date(8.64e15),
      };
    } else {
      let selectedFolder = this.FolderHierarchy.getRoot();

      if (!selectedFolder) return;

      for (let i = 1; i < path.length; i++) {
        selectedFolder = selectedFolder.items![path[i]];
      }

      this.m_SelectedFolder = selectedFolder?.folderData;
    }

    this.setBackgroundWidth();
    this.redirectFolder();
  }

  setBackgroundWidth() {
    const optionsContainerWidth =
      this.m_TreeView?.element.nativeElement.offsetWidth;
    if (optionsContainerWidth) {
      this.m_SelectedBackgroundWidth = `${optionsContainerWidth - 20}px`;
      this.m_SelectedBackgroundPosition = `-25px`;
    }
  }

  async expandLibrary() {
    if (!this.expandedKeys.includes(1)) {
      await this.expandFolderByIds(this.m_ExpandedFoldersIds);
      if (!this.expandedKeys.includes(1)) this.expandedKeys.push(1);
      this.m_ExpandedFoldersIds.push("0");
    } else if (this.expandedKeys.includes(1)) {
      this.expandedKeys = this.expandedKeys.filter((key) => key != 1);
      this.m_ExpandedFoldersIds = this.m_ExpandedFoldersIds.filter(
        (key) => key != "0"
      );
    }

    //Persist the expanded folders
    await this.persistExpandedFolders();
    this.setBackgroundWidth();
  }

  async onExpandCollapseFolder(folderNode: TreeItem) {
    if (this.expandedKeys.length == 0) return;
    let id = folderNode.dataItem.id;
    if (this.expandedKeys.includes(id)) {
      this.expandedKeys = this.expandedKeys.filter((key) => key != id);
      this.m_ExpandedFoldersIds = this.m_ExpandedFoldersIds.filter(
        (key) => key != folderNode.dataItem.folderData.id
      );
    } else {
      this.expandedKeys = [...this.expandedKeys, id];
      this.m_ExpandedFoldersIds = [
        ...this.m_ExpandedFoldersIds,
        folderNode.dataItem.folderData.id,
      ];
    }

    //Persist the expanded folders
    await this.persistExpandedFolders();
    this.setBackgroundWidth();
  }

  //--------------------------------------------------------------------
  getContextMenuOpts() {
    return this.m_FolderContextMenuOptions;
  }

  //#endregion
  //--------------------------------------------------------------------
  //#region Render Helpers

  getIsMobile() {
    return this.m_UIService.isMobileSize();
  }

  getSelectedClass(route?: { route: string; queryParams: Params }) {
    if (route != null && route.route == this.m_CurrentRoute.route) {
      if (route.route == "/library" && !this.m_SelectedFolder) {
        return route.queryParams["visibility"] ==
          this.m_CurrentRoute.queryParams["visibility"]
          ? "selected"
          : "";
      } else {
        return "selected";
      }
    }
    return "";
  }

  //Event handler for drag move of a folder node. This is from the
  //drag target directive, and is used to position the hint element properly
  //The hint/clue element from the kendotreeview is not used
  handleFolderDragMove(event: DragTargetDragEvent) {
    //Fix provided by Kendo UI for custom drag and drop hints
    //https://www.telerik.com/kendo-angular-ui/components/knowledge-base/position-custom-drag-drop-hints/

    event.preventDefault();
    //Position the hint element where the center of the element is at the mouse position
    let hintWidth = event.hintElement?.offsetWidth ?? 0;
    let hintHeight = event.hintElement?.offsetHeight ?? 0;
    let topValue = `${event.dragEvent.clientY}px`;
    let leftValue = `${event.dragEvent.clientX - hintWidth / 2}px`;

    this.m_Renderer.setStyle(event.hintElement, "top", topValue);
    this.m_Renderer.setStyle(event.hintElement, "left", leftValue);
    this.m_Renderer.setStyle(event.hintElement, "z-index", "1999");
    this.m_Renderer.setStyle(event.hintElement, "position", "absolute");
    this.m_Renderer.setStyle(event.hintElement, "opacity", "0.8");
  }

  public handleNodeDrop(event: TreeItemDropEvent): void {
    this.m_IsDragginOnRoot = false;
    this.m_HoveredFolder = "";
    // prevent drop if attempting to add to file
    if (
      this.isFile(event.destinationItem.item.dataItem.folderData.name) &&
      event.dropPosition === DropPosition.Over
    ) {
      event.setValid(false);
    }
  }

  async onDragDropFolder(event: DropTargetEvent) {
    //Get the folder id from the dataItem
    let destinationFolderId =
      event.dropTarget.getAttribute("data-folder-id") || "";
    if (event.dragData == null || destinationFolderId == "") return;
    let dragData: DragDropItem[] = event.dragData;

    if (dragData.length > 0) {
      let folder = dragData.find((item) => !(item.dragData instanceof VIDEO));
      // If a folder is being moved, selected videos WON'T be moved
      if (folder != null) {
        let sourceFolderId = folder.dragData.id;

        if (this.checkFolderMove(sourceFolderId, destinationFolderId)) {
          const data = await this.m_FoldersService.moveFolders(
            destinationFolderId,
            sourceFolderId
          );
          if (data) {
            this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
          }
        }
      } else {
        this.m_Events.publish(EVENTS.VIDEO_MOVED, {
          folderId: destinationFolderId,
          videos: dragData.map((item) => item.dragData as VIDEO),
        });
      }
    }

    this.m_IsDragginOnRoot = false;
    this.m_HoveredFolder = "";
  }

  isOfType(fileName: string, ext: string) {
    return new RegExp(`.${ext}\$`).test(fileName);
  }

  isFile(name: string) {
    return name.split(".").length > 1;
  }

  checkExpandedSideBar() {
    if (this.getIsMobile()) {
      this.m_UIService.setExpandSidebar(false);
      return;
    }
    if (this.IsVideoView && !this.LoggedIn) {
      this.m_UIService.setExpandSidebar(false);
    } else if (!this.IsVideoView) {
      this.m_UIService.setExpandSidebar(true);
    }
  }

  isFolderHierarchyValid() {
    return this.FolderHierarchy != null && !this.FolderHierarchy.isEmpty();
  }

  //#endregion
  //--------------------------------------------------------------------
  //#region Public Methods

  async fetchUserInfo() {
    this.m_Loading = true;
    try {
      let userInfo = this.m_UserService.ActiveUserInfo;
      if (userInfo == null)
        userInfo = await this.m_UserService.getUserInfo(
          this.m_AuthService.UserId
        );
    } catch (e) {
      console.log(e);
    } finally {
      this.m_Loading = false;
    }
  }

  getUserPlan() {
    const userLimits = this.m_UserService.ActiveUserLimits;

    if (userLimits) {
      // Set User plan informations
      this.m_UserLimits = userLimits;
      this.m_MaxLength = userLimits.maxLength || 0;
      this.m_CurrentLength = userLimits.currentLength;
      this.m_MaxQnAs = userLimits.maxRecords || 0;
      this.m_CurrentQnAs = userLimits.currentRecordNumber;
      this.m_TierName = userLimits.tierDisplayName || "";
      this.m_TierValue = userLimits.tierName;
      this.getProgress();
    }
  }

  getProfileId() {
    let user = this.m_UserService.ActiveUserInfo;
    return user?.id;
  }

  getProfileUserName() {
    let user = this.m_UserService.ActiveUserInfo;
    return user?.username || "";
  }

  getProgress() {
    this.m_CurrentProgress = (this.m_CurrentLength / this.m_MaxLength) * 100;
    this.m_CurrentQnAProgress = (this.m_CurrentQnAs / this.m_MaxQnAs) * 100;
  }

  redirectToUpgrade() {
    this.m_Router.navigateByUrl("/pricing", { replaceUrl: true });
  }

  async getAllFolders() {
    //this.m_Loading = true;

    try {
      await this.m_FoldersService.getAllFolders(this.m_AuthService.UserId);
      this.onRouteChanged(this.m_CurrentRoute.route);
    } finally {
      //this.m_Loading = false;
    }
  }

  async onCreateSubfolderClicked(parentFolder: TransformedFolder) {
    if (parentFolder == null || !parentFolder.folderData.id) return;
    //this.m_Loading = true;

    try {
      // Check if we have folders with the default name and adds a number to it
      let newFolderName = this.FolderHierarchy?.getFolderNameForCreation();
      if (newFolderName == null) return;

      const data = await this.m_FoldersService.createSubFolders(
        this.m_AuthService.UserId,
        parentFolder.folderData.id,
        newFolderName
      );
      if (data) this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
      //Expand the folder if it's not expanded
      if (!this.expandedKeys.includes(parentFolder.id)) {
        this.expandedKeys = [...this.expandedKeys, parentFolder.id];
      }
    } finally {
      //this.m_Loading = false;
    }
  }

  onFolderOptionsClicked(folderData: FOLDER_DTO, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!isFolderDTO(folderData)) {
      folderData = convertToFolderDTO(folderData);
    }
    this.m_ContextMenuFolder = folderData as FOLDER_DTO;
    this.m_CtxMenu?.open(event);
  }

  async onDeleteClicked() {
    if (!this.m_ContextMenuFolder) return;

    try {
      // this.m_Loading = true;
      const data = await this.m_FoldersService.deleteFolders(
        this.m_ContextMenuFolder?.id,
        false
      );

      if (data.error) {
        // Show alert modal
        let alertConfig: AlertModalOptions = {
          mainText: this.$t("shared.messages.folderDeleteTitle"),
          description: this.$t("shared.messages.folderDelete"),
          allowCancel: true,
          showIcon: true,
          customClass: "delete-modal",
          allowNeverShow: false,
          customButtons: [
            {
              label: this.$t("shared.messages.folderDeleteButton"),
              color: "#dd2222cc",
              close: true,
              callback: async () => {
                if (this.m_ContextMenuFolder == null) return;

                const retryData = await this.m_FoldersService.deleteFolders(
                  this.m_ContextMenuFolder?.id,
                  true
                );

                if (!retryData.error) {
                  this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
                }
              },
            },
          ],
        };

        this.m_Events.publish(EVENTS.ALERT, alertConfig);
      } else {
        if (data) this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
      }
    } finally {
      // this.m_Loading = false;
    }
  }

  onMoveClicked() {
    this.m_Events.publish(EVENTS.SHOW_FOLDERS_MODAL, this.m_ContextMenuFolder);
  }

  async renameFolders(name?: string) {
    if (!this.m_ContextMenuFolder) return;
    if (!name) return;
    if (!this.m_AlertModal) return;
    this.m_AlertModal.m_Error = false;

    this.m_ContextMenuFolder.name = name;

    const folder = this.m_ContextMenuFolder;

    if (folder) {
      folder.name = name.trim();
    }

    if (name != "") {
      try {
        this.m_AlertModal.m_Loading = true;
        const data = await this.m_FoldersService.renameFolders(
          name,
          folder?.id
        );
        if (data && folder) {
          folder.name = name.trim();
          this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
        } else {
          this.m_AlertModal.m_Error = true;
          return;
        }
      } finally {
        this.m_AlertModal.m_Loading = false;
        this.m_AlertModal.m_Ok = true;
        this.m_AlertModal.m_IsOpen = false;
      }
    }
  }

  async onRenameClicked() {
    if (!this.m_ContextMenuFolder) return;
    let folderName = this.m_ContextMenuFolder.name ?? "";
    let input: CustomAlertInput[] = [
      {
        label: "New folder name",
        type: "text",
        value: folderName ?? "",
        onChange: (value: string) => {
          folderName = value;
        },
      },
    ];

    await this.showAlertModal(
      "Rename folder",
      true,
      null,
      input,
      async (value?: string) => {
        await this.renameFolders(value);
      },
      () => {},
      () => {}
    );
  }

  async showAlertModal(
    message: string,
    allowCancel?: boolean,
    buttons?: any[] | null,
    inputs?: CustomAlertInput[] | null,
    okFunction?: (value?: string) => Promise<void>,
    cancelFunction?: () => void,
    inputChangeFunction?: (value: string) => void
  ) {
    if (this.m_AlertModal == null) return Promise.resolve({ ok: false });

    this.m_AlertModal.m_MainText = message;
    this.m_AlertModal.m_AllowCancel = allowCancel ?? true;
    this.m_AlertModal.m_CustomButtons = buttons;
    this.m_AlertModal.m_CustomInputs = inputs;

    return await this.m_AlertModal?.show(
      okFunction ?? undefined,
      cancelFunction ?? (() => {}),
      inputChangeFunction ?? ((value: string) => {})
    );
  }

  onCreateClicked() {
    this.m_Uploader?.openFileDialog();
  }

  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  private initMenuOptions() {
    this.m_Options = {
      disabled: this.m_CurrentRoute.route == "/verify",
      content: [
        {
          text: this.$t("components.navigation.home"),
          icon: "assets/icon/sidebar/home.svg",
          route: this.m_Routes["Home"],
          action: () => {
            this.m_Router.navigate([this.m_Routes["Home"].route], {
              queryParams: this.m_Routes["Home"].queryParams,
            });
            this.m_SelectedFolder = null;
            this.selectedKeys = ["0"];
          },
        },
        {
          text: this.$t("components.navigation.library"),
          icon: "assets/icon/sidebar/library.svg",
          route: this.m_Routes["Library"],
          action: () => {
            let currentSelected = this.m_SelectedFolder?.id;
            //this.m_TreeView?.blur();
            this.m_TreeView?.focus();
            this.onSelectedChange(["0"]);
            if (
              this.m_CurrentRoute.route == "/library" &&
              currentSelected == "0"
            ) {
              this.expandLibrary();
            } else {
              this.m_Router.navigate([this.m_Routes["Library"].route]);
            }
          },
        },
        {
          text: this.$t("components.navigation.allQvios"),
          icon: "assets/icon/sidebar/library.svg",
          route: this.m_Routes["AllQvios"],
          action: () => {
            this.m_Router.navigate([this.m_Routes["AllQvios"].route]);
          },
        },
        {
          text: this.$t("components.navigation.playlists"),
          icon: "assets/icon/sidebar/playlists.svg",
          route: this.m_Routes["Playlists"],
          action: () => {
            this.m_Router.navigate([this.m_Routes["Playlists"].route], {
              queryParams: this.m_Routes["Playlists"].queryParams,
            });
            this.m_SelectedFolder = null;
            this.selectedKeys = ["0"];
          },
        },
        {
          text: this.$t("components.navigation.analytics"),
          icon: "assets/icon/sidebar/analytics.svg",
          route: this.m_Routes["Analytics"],
          action: () => {
            this.m_Router.navigate([this.m_Routes["Analytics"].route], {
              queryParams: this.m_Routes["Analytics"].queryParams,
            });
            this.m_SelectedFolder = null;
            this.selectedKeys = ["0"];
          },
        },
      ],
    };
  }

  private initContextMenus() {
    //Initialize context menu options
    this.m_FolderContextMenuOptions = [
      {
        text: this.$t("shared.button.rename"),
        icon: "assets/icon/card/edit.svg",
        action: this.onRenameClicked.bind(this),
      },
      {
        text: this.$t("shared.button.move") + "...",
        icon: "assets/icon/card/folder.svg",
        action: this.onMoveClicked.bind(this),
      },
      {
        text: this.$t("shared.button.delete"),
        icon: "assets/icon/card/delete.svg",
        action: this.onDeleteClicked.bind(this),
      },
    ];
  }

  private async onAlert(data: AlertModalOptions): Promise<DismissResult> {
    if (this.m_AlertModal != null) {
      this.m_AlertModal.m_MainText = data.mainText;
      this.m_AlertModal.m_Description = data.description ?? "";
      this.m_AlertModal.m_AllowCancel = data.allowCancel ?? true;
      this.m_AlertModal.m_ShowIcon = data.showIcon ?? false;
      this.m_AlertModal.m_CustomClass = data.customClass ?? "";
      this.m_AlertModal.m_AllowNeverShow = data.allowNeverShow ?? false;
      this.m_AlertModal.m_NeverShowText = data.neverShowText ?? "";
      this.m_AlertModal.m_CustomButtons = data.customButtons ?? [];
      this.m_AlertModal.m_AllowBackdropDismiss =
        data.allowBackdropDismiss ?? true;
      this.m_AlertModal.m_CustomInputs = data.customInputs ?? [];
      let result = await this.m_AlertModal.show();
      return result;
    }
    return { ok: true, neverShow: false };
  }
  //--------------------------------------------------------------------
  private resetFolderTree() {
    this.m_SelectedFolder = null;
  }
  //--------------------------------------------------------------------
  private expandFolder(folder: TransformedFolder) {
    if (folder.items) {
      this.expandedKeys = [...this.expandedKeys, folder.id];
      this.m_ExpandedFoldersIds = [
        ...this.m_ExpandedFoldersIds,
        folder.folderData.id || "",
      ];

      // Recursively expand any parent folders not already expanded
      if (folder.folderData.parent_id) {
        const parentFolder = this.FolderHierarchy?.findFolder(
          folder.folderData.parent_id
        );
        if (parentFolder && !this.expandedKeys.includes(parentFolder.id)) {
          this.expandFolder(parentFolder);
        }
      }
    }
  }
  //--------------------------------------------------------------------
  private async restoreExpandedFolders() {
    //Restore the expanded folders
    const expandedFolders = await this.m_StorageService.get(
      "expandedFolderIds"
    );
    if (expandedFolders) {
      this.m_ExpandedFoldersIds = expandedFolders;
      if (this.m_ExpandedFoldersIds.includes("0")) {
        this.expandFolderByIds(this.m_ExpandedFoldersIds);
        this.selectedKeys = ["0"];
        if (this.m_CurrentRoute.route == "/library") {
          let folderId = this.m_CurrentRoute.queryParams["v"];
          if (folderId) {
            await this.setFolderSelected(folderId);
            setTimeout(() => {
              this.setBackgroundWidth();
            }, 100);
          }
        }
      }
    }
  }
  //--------------------------------------------------------------------
  private async expandFolderByIds(folderIds: string[]) {
    //Expand the folders via the transformed folders
    let expandedKeys: number[] = [];
    for (const folderId of folderIds) {
      const folder = this.FolderHierarchy?.findFolder(folderId);
      if (folder) {
        expandedKeys.push(folder.id as number);
      }
    }

    this.expandedKeys = expandedKeys;
    if (this.expandedKeys.length >= 1) {
      this.expandedKeys.push(1);
    }
  }
  //--------------------------------------------------------------------
  private async setFolderSelected(folderId: string, isConverted?: boolean) {
    if (!isConverted) folderId = shortIdToUuid(folderId);
    let folder = this.FolderHierarchy?.findFolder(folderId);

    if (folder) {
      this.m_SelectedFolder = this.FolderHierarchy?.findFolder(
        folder?.folderData.id
      )?.folderData;
      this.expandFolder(folder);
      this.selectedKeys = [
        `0_${await this.FolderHierarchy!.getFolderLeafPath(
          folder?.folderData.id || ""
        )}`,
      ];
      this.setBackgroundWidth();
      await this.persistExpandedFolders();
    }
  }
  //--------------------------------------------------------------------
  private async persistExpandedFolders() {
    //Filter out duplicates
    this.m_ExpandedFoldersIds = this.m_ExpandedFoldersIds.filter(
      (value, index, self) => self.indexOf(value) === index
    );

    await this.m_StorageService.set(
      "expandedFolderIds",
      this.m_ExpandedFoldersIds
    );
  }
  //#endregion
}
