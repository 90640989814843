import { Injectable } from "@angular/core";
import { ConfigService } from "../config/config.service";
import { SocketState, SocketWrapper } from "src/app/utility/socket-wrapper";
import { isSafari } from "src/app/utility/utils";
import { Platform } from "@ionic/angular";
import { VOICE } from "src/app/models/voice/voice";

interface VoiceConfig {
  ID: string;
  STABILITY: number;
  SIMILARITY_BOOST: number;
  STYLE: number;
}
interface ElevenLabsConfig {
  BASE_URL: string;
  WSS_URL: string;
  MODEL_ID: string;
  MALE_VOICE: VoiceConfig;
  FEMALE_VOICE: VoiceConfig;
}

enum SocketSubjects {
  TTS_STREAM = "ttsStream",
  TTS_STREAM_RESPONSE = "ttsStreamResponse",
  TTS_STREAM_CANCEL = "ttsStreamCancel",
  TTS_STREAM_CANCELLED = "ttsStreamCancelled",
  TTS_URL_RESPONSE = "ttsUrlResponse",
}

@Injectable({
  providedIn: "root",
})
export class TextToSpeechService {
  private m_WSSURL: string = "";
  private m_ElevenLabsConfig: ElevenLabsConfig | null = null;

  private m_ActiveSocket: SocketWrapper | null = null;
  private m_ActiveRequestID: string | null = null;

  constructor(
    private m_ConfigService: ConfigService,
    private m_Platform: Platform
  ) {
    this.initialize();
  }

  public async connectToSocket() {
    if (this.m_WSSURL == null || this.m_ElevenLabsConfig == null) {
      console.error("TTS not initialized");
      return;
    }
    this.resetSocket();
    this.m_ActiveSocket = new SocketWrapper();
    this.m_ActiveSocket.connectToSocket(this.m_WSSURL);
  }

  public async disconnectFromSocket() {
    this.resetSocket();
  }

  /**
   * Converts the text input to speech data via a websocket connection to the Eleven Labs API
   * @param textInput  The text to convert to speech
   * @param onStart The function to call when the text to speech process starts
   * @param onEnd The function to call when the text to speech process ends
   * @param onError The function to call when an error occurs
   * @param onMessage The function to call when audio data is received from the websocket
   */
  public async textToSpeech(
    textInput: string,
    onStart?: () => void,
    onEnd?: () => void,
    onError?: (error: any) => void,
    onMessage?: (data: string) => void,
    streamAudioData?: boolean,
    voiceSettings?: VOICE,
    localize?: boolean,
    forceRegen: boolean = false
  ) {
    this.stopTextToSpeech();
    if (this.m_ElevenLabsConfig == null) {
      console.error("TTS not initialized");
      return;
    }

    let voiceConfig: VOICE = new VOICE();
    if (voiceSettings) {
      voiceConfig = voiceSettings;
    } else {
      //Default voice config
      voiceConfig.eleven_labs_id = this.m_ElevenLabsConfig.FEMALE_VOICE.ID;
      voiceConfig.stability = this.m_ElevenLabsConfig.FEMALE_VOICE.STABILITY;
      voiceConfig.style = this.m_ElevenLabsConfig.FEMALE_VOICE.STYLE;
      voiceConfig.similarity_boost =
        this.m_ElevenLabsConfig.FEMALE_VOICE.SIMILARITY_BOOST;
    }

    this.requestTextToSpeech(
      textInput,
      voiceConfig,
      onStart ? onStart : () => {},
      onMessage ? onMessage : (data) => {},
      onEnd ? onEnd : () => {},
      onError ? onError : (error) => console.error(error),
      streamAudioData,
      localize,
      forceRegen
    );
  }

  public async requestTextToSpeech(
    textInput: string,
    voiceSettings: VOICE,
    onStart: () => void,
    onMessage: (event: any) => void,
    onEnd: () => void,
    onError: (error: any) => void,
    streamAudioData?: boolean,
    localize?: boolean,
    forceRegen: boolean = false
  ) {
    try {
      if (!voiceSettings || !textInput) {
        console.log("ERR: Missing parameter");
      }

      const stabilityValue = voiceSettings.stability
        ? voiceSettings.stability
        : 0;
      const similarityBoostValue = voiceSettings.similarity_boost
        ? voiceSettings.similarity_boost
        : 0;
      const style = voiceSettings.style ? voiceSettings.style : 0;
      const voiceID = voiceSettings.eleven_labs_id
        ? voiceSettings.eleven_labs_id
        : "";

      let startTTS = (event: any) => {
        onStart();
        this.onSocketOpen(
          event,
          voiceID,
          textInput,
          stabilityValue,
          style,
          similarityBoostValue,
          onMessage,
          onEnd,
          onError,
          streamAudioData,
          localize,
          forceRegen
        );
      };

      if (this.getSocketState() == SocketState.CLOSED) {
        //Socket is not connected, connect to it and queue the startTTS function
        await this.connectToSocket();
        if (this.m_ActiveSocket == null) {
          throw new Error("Failed to connect to socket");
        }
        this.m_ActiveSocket.onopen = startTTS;
      } else if (this.getSocketState() == SocketState.OPEN) {
        //Socket is already connected, just call startTTS
        startTTS(null);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public stopTextToSpeech() {
    //Send cancel message to the socket
    if (
      this.getSocketState() == SocketState.OPEN &&
      this.m_ActiveSocket != null &&
      this.m_ActiveRequestID != null
    ) {
      this.sendSocketMessage(
        SocketSubjects.TTS_STREAM_CANCEL,
        this.m_ActiveRequestID
      );
    }

    this.m_ActiveRequestID = null;
  }

  //#region Private Socket Functions
  /**
   * Handles the socket open event, indicating the start of the text to speech process
   * This function sends the BOS message, the text message, and the EOS message
   * as well as hook up the socket events
   * @param event
   * @param textInput
   * @param stability
   * @param style
   * @param similarityBoost
   * @param onMessage
   * @param onEnd
   * @param onError
   * @returns
   */
  private onSocketOpen(
    event: any,
    voiceID: string,
    textInput: string,
    stability: number,
    style: number,
    similarityBoost: number,
    onMessage: (event: any) => void,
    onEnd: () => void,
    onError: (error: any) => void,
    streamAudioData?: boolean,
    localize?: boolean,
    forceRegenerate?: boolean
  ) {
    if (this.m_ActiveSocket == null) return;
    // Reset the socket for the next text to speech request
    this.unsubscribeSocketEvents();
    // Hookup the socket events
    this.hookupSocketEvents(onMessage, onEnd, onError);

    // Send the beginning of stream (BOS) message
    const bosMessage = {
      data: {
        textInput: textInput,
        stability: stability,
        similarityBoost: similarityBoost,
        style,
        use_speaker_boost: true,
        voiceID: voiceID,
        audioFormat: "mp3_44100",
        streaming: streamAudioData,
        forceRegen: forceRegenerate,
        localize: localize,
      },
    };

    if (this.m_Platform.is("ios") || isSafari()) {
      //bosMessage.data.audioFormat = "pcm_44100";
    }

    this.sendSocketMessage(SocketSubjects.TTS_STREAM, bosMessage);
  }

  /**
   *  Handles the socket message event, indicating the receipt of audio data
   * @param event
   * @param onMessage
   * @param onEnd
   */
  private onSocketAudioMsg(
    event: any,
    onMessage: (data: string) => void,
    onEnd: () => void
  ) {
    if (event.audio) {
      this.m_ActiveRequestID = event?.requestId;
      onMessage(event.audio);
    } else {
      console.debug("No audio data in the response");
    }

    //Check if the response is final, meaning we have all the audio chunks for use
    if (event.isFinal) {
      onEnd();
    }
  }

  private onSocketUrlMsg(
    event: any,
    onMessage: (data: string) => void,
    onEnd: () => void
  ) {
    if (event.url) {
      //Send the url to the audio player
      onMessage(event.url);
    } else {
      console.debug("No url in the response");
    }

    onEnd();
  }

  /**
   * Sends a message to the socket
   * @param message
   */
  private sendSocketMessage(subject: string, message: any) {
    if (this.m_ActiveSocket != null && this.m_ActiveSocket.Connected) {
      this.m_ActiveSocket.send(subject, message);
    } else {
      console.error("WebSocket is not open: ", this.m_ActiveSocket);
    }
  }

  /**
   * Hooks up the socket events
   * @param onMessage
   * @param onEnd
   * @param onError
   */
  private hookupSocketEvents(
    onMessage: (data: string) => void,
    onEnd: () => void,
    onError: (error: any) => void
  ) {
    if (this.m_ActiveSocket == null) return;

    this.m_ActiveSocket.on(SocketSubjects.TTS_STREAM_RESPONSE, (data: any) => {
      this.onSocketAudioMsg(data, onMessage, onEnd);
    });

    this.m_ActiveSocket.on(SocketSubjects.TTS_URL_RESPONSE, (data: any) => {
      this.onSocketUrlMsg(data, onMessage, onEnd);
    });

    this.m_ActiveSocket.onerror = (error) => {
      onError(error);
      console.error(`WebSocket Error: ${error}`);
    };

    this.m_ActiveSocket.onclose = (event) => {
      //console.log("Socket closed", event);
      onEnd();
    };
  }

  private unsubscribeSocketEvents() {
    if (this.m_ActiveSocket == null) return;

    this.m_ActiveSocket.off(SocketSubjects.TTS_STREAM_RESPONSE);
    this.m_ActiveSocket.off(SocketSubjects.TTS_URL_RESPONSE);
    this.m_ActiveSocket.onopen = () => {};
    this.m_ActiveSocket.onerror = () => {};
    this.m_ActiveSocket.onclose = () => {};
  }

  // Resets the socket for the next text to speech request
  private resetSocket() {
    if (this.m_ActiveSocket) this.m_ActiveSocket.close();
    this.m_ActiveSocket = null;
    this.m_ActiveRequestID = null;
    this.unsubscribeSocketEvents();
  }

  // Gets the current socket state
  private getSocketState() {
    if (this.m_ActiveSocket) return this.m_ActiveSocket.SocketState;
    return SocketState.CLOSED;
  }
  //#endregion

  private async initialize() {
    try {
      this.m_ElevenLabsConfig = JSON.parse(
        await this.m_ConfigService.get("EL_CONFIG")
      );
      if (this.m_ElevenLabsConfig == null) {
        throw new Error("Failed to fetch TTS config");
      }

      this.m_WSSURL = await this.m_ConfigService.get("TTS_WS_URL");
      //this.m_WSSURL = "ws://localhost:8101";
      if (this.m_WSSURL == null) {
        throw new Error("Failed to fetch TTS config");
      }
    } catch (error) {
      console.error(error);
    }
  }
}
