export class ErrorFactory {
  static error(status: number, error: string): CustomError {
    switch (status) {
      case 400:
        return new ValidationError(error);
      case 401:
        return new AuthorizationError(error);
      case 403:
        return new ForbiddenError(error);
      case 404:
        return new NotFoundError(error);
      case 500:
        return new ServerError(error);
      default:
        return new UnknownError(error, status);
    }
  }
}

export abstract class CustomError extends Error {
  abstract status: number;

  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class UnknownError extends CustomError {
  status: number;

  constructor(message = "Unknown Error", status: number = 500) {
    super(message);
    this.name = "UnknowError";
    this.status = status;
  }
}

export class AuthorizationError extends CustomError {
  status = 401;

  constructor(message = "Unauthorized") {
    super(message);
    this.name = "AuthorizationError";
  }
}

export class ForbiddenError extends CustomError {
  status = 403;
  constructor(message = "Forbidden") {
    super(message);
    this.name = "ForbiddenError";
  }
}

export class ServerError extends CustomError {
  status = 500;
  constructor(message = "Server Error") {
    super(message);
    this.name = "ServerError";
  }
}

export class NotFoundError extends CustomError {
  status = 404;

  constructor(message = "Not Found") {
    super(message);
    this.name = "NotFoundError";
  }
}

export class ValidationError extends CustomError {
  status = 400;
  constructor(message = "Validation Error") {
    super(message);
    this.name = "ValidationError";
  }
}
