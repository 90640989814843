import { BaseApiService } from "../base-api-service";
import { GET_CONFIG_RES } from "@shared/models/config/config";
import { Events } from "src/app/services/events/events.service";

export class ConfigApi extends BaseApiService {
  private static readonly getConfigEndpoint = {
    method: "GET",
    path: "/api/config",
  };

  //--------------------------------------------------------------------
  constructor(eventsService: Events, p_ApiUrl: Promise<string>) {
    super(eventsService, p_ApiUrl);
  }
  //--------------------------------------------------------------------
  //#region Public Methods

  public async getConfig(
    apiKey: string,
    cryptoKey: CryptoKey
  ): Promise<GET_CONFIG_RES> {
    let headerKey = {
      key: apiKey,
      timestamp: new Date().getTime(),
    };

    let encryptedKey = await this.encryptData(cryptoKey, headerKey);

    const apiRequest = {
      method: ConfigApi.getConfigEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": encryptedKey,
      },
    };

    const response = await this.makeRequestWithRetry(
      ConfigApi.getConfigEndpoint.path,
      apiRequest,
      "Failed to get config",
      3
    );

    return response;
  }
  //#endregion

  private async encryptData(publicKey: any, data: any) {
    // Encode the data into a format suitable for encryption (e.g., a UTF-8 encoded string)
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(JSON.stringify(data));

    // Encrypt the data using the public key and RSA-OAEP
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      publicKey,
      encodedData
    );

    // Convert the encrypted data to base64 to send as a string
    return window.btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
  }

  //--------------------------------------------------------------------
}
