//#region DO_NOT_EDIT
/*
  USED BY BUILD SCRIPT, DO NOT CHANGE THESE VALUES!!!
*/
const MAJOR = "1";
const MINOR = "7";
const POINT = "3";
const REVISION = "4311";
const HASH = "e926919e66591128d757772f6630e32541ef67bc";
//#endregion DO_NOT_EDIT

export class VERSION {
  public static SIMPLE = MAJOR + "." + MINOR;
  public static DEFAULT = MAJOR + "." + MINOR + "." + POINT + "." + REVISION;
  public static FULL =
    MAJOR + "." + MINOR + "." + POINT + "." + REVISION + "-" + HASH;
}
