import {
  Component,
  Input,
  Output,
  EventEmitter,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  OVERLAY_ANIMATION_TYPE,
  OVERLAY_BACKGROUND_FITTING,
  OVERLAY_LOCATION,
  OVERLAY_TYPE,
} from "@shared/models/overlays/overlay";
import { EVENTS } from "src/app/constants/events";
import { OVERLAY } from "src/app/models/overlays/overlay";
import { Events } from "src/app/services/events/events.service";

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
  constructor(private m_Sanitizer: DomSanitizer) {}
  transform(url: string) {
    return this.m_Sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: "app-video-overlay",
  templateUrl: "./video-overlay.component.html",
  styleUrls: ["./video-overlay.component.css"],
})
export class VideoOverlayComponent {
  constructor(private m_Events: Events) {}

  @Input() set Overlays(value: OVERLAY[]) {
    this.m_Overlays = value;
  }
  get Overlays(): OVERLAY[] {
    return this.m_Overlays || [];
  }

  @Input() set CurrentTime(value: number) {
    this.m_CurrentTime = value;

    let visibleOverlays = new Set<OVERLAY>();
    for (let data of this.m_Overlays ?? []) {
      if (this.isVisible(data)) {
        if (!this.m_CurrentOverlays?.has(data)) {
          this.overlayEvent.emit({
            event: "VIDEO_OVERLAY_SHOWN",
            data: { id: data.id },
          });
        }
        visibleOverlays.add(data);
      }
    }

    this.m_CurrentOverlays = visibleOverlays;
  }
  get CurrentTime(): number {
    return this.m_CurrentTime || 0.0;
  }

  @Output() overlayEvent = new EventEmitter<{
    event: "VIDEO_OVERLAY_SHOWN" | "VIDEO_OVERLAY_CLICKED";
    data: any;
  }>();

  private m_Overlays?: OVERLAY[];
  private m_CurrentTime?: number;
  private m_CurrentOverlays?: Set<OVERLAY>;

  updateOverlays(overlays: OVERLAY[]) {
    this.m_Overlays = overlays;
  }

  getStyle(data: OVERLAY): any {
    let style: any = {
      width: this.getCssWidth(data),
      height: this.getCssHeight(data),
      opacity: data?.opacity || 1.0,
      "border-radius": (data?.border_radius || 0) + "px",
    };

    this.addPositionToStyle(data, style);

    if (data?.border_color) {
      style.border = "1px solid " + data?.border_color;
    }

    if (data?.background_color != null) {
      style["background-color"] = data.background_color;
    }

    if (data?.background_url != null) {
      style["background-image"] = "url(" + data.background_url + ")";
      style["background-repeat"] = "no-repeat";
      style["background-position"] = "center";
    }

    if (data?.fitting == OVERLAY_BACKGROUND_FITTING.FIT_TO_AREA) {
      style["background-size"] = "contain";
    }

    if (data?.z_index != null) {
      style["z-index"] = data.z_index;
    }

    style["transition-property"] = this.getTransitionProperties(data);
    let duration = (data?.animation_duration ?? 1000) / 1000;
    style["transition-duration"] = Number(duration).toFixed(2) + "s";

    return style;
  }

  getTextStyle(data: OVERLAY): any {
    let style: any = {
      color: data?.foreground_color || "black",
    };

    return style;
  }

  isEmbed(data: OVERLAY) {
    return data?.overlay_type == OVERLAY_TYPE.IFRAME;
  }

  private getCssWidth(data: OVERLAY) {
    if (data?.width != null) {
      return data.width + "%";
    } else {
      return "100%";
    }
  }

  private getCssHeight(data: OVERLAY) {
    if (data?.height != null) {
      return data.height + "%";
    } else {
      return "100%";
    }
  }

  private addPositionToStyle(data: OVERLAY, style: any) {
    switch (data?.location) {
      case OVERLAY_LOCATION.TOP_LEFT:
        style.top = (data?.offset_y || 0) + "%";
        style.left = (data?.offset_x || 0) + "%";
        break;
      case OVERLAY_LOCATION.TOP_RIGHT:
        style.top = (data?.offset_y || 0) + "%";
        style.right = (data?.offset_x || 0) + "%";
        break;
      case OVERLAY_LOCATION.BOTTOM_LEFT:
        style.bottom = (data?.offset_y || 0) + "%";
        style.left = (data?.offset_x || 0) + "%";
        break;
      case OVERLAY_LOCATION.BOTTOM_RIGHT:
        style.bottom = (data?.offset_y || 0) + "%";
        style.right = (data?.offset_x || 0) + "%";
        break;
      case OVERLAY_LOCATION.CENTER:
        style.top = (data?.offset_y || 0) + 50 + "%";
        style.left = (data?.offset_x || 0) + 50 + "%";
        style.transform = "translate(-50%, -50%)";
        break;
    }
  }

  private getHorizontalAnchor(location: OVERLAY_LOCATION) {
    switch (location) {
      case OVERLAY_LOCATION.TOP_LEFT:
      case OVERLAY_LOCATION.BOTTOM_LEFT:
        return "left";
      case OVERLAY_LOCATION.TOP_RIGHT:
      case OVERLAY_LOCATION.BOTTOM_RIGHT:
        return "right";
      case OVERLAY_LOCATION.CENTER:
        return "center";
    }
  }

  public getHideClass(data: OVERLAY) {
    if (!this.isVisible(data)) {
      if (data.animation_type == OVERLAY_ANIMATION_TYPE.FADE) {
        return "hidden-animated-fade";
      } else if (data.animation_type == OVERLAY_ANIMATION_TYPE.SLIDE) {
        return "hidden-animated-" + this.getHorizontalAnchor(data?.location);
      } else {
        return "hidden";
      }
    } else {
      return "";
    }
  }

  private isVisible(data: OVERLAY) {
    let startTime = data?.start_time || 0.0;
    let endTime = data?.end_time || Number.POSITIVE_INFINITY;
    let currentTimeMS = this.CurrentTime * 1000;
    if (data.start_time === 0 && data.end_time === 0 && !data.whole_video) {
      return false;
    }
    return currentTimeMS >= startTime && currentTimeMS <= endTime;
  }

  private getTransitionProperties(data: OVERLAY) {
    if (data?.animation_type == OVERLAY_ANIMATION_TYPE.SLIDE) {
      let animProperty = this.getHorizontalAnchor(data?.location);
      if (animProperty == "center") animProperty = "left";
      return "transform, " + animProperty;
    } else if (data?.animation_type == OVERLAY_ANIMATION_TYPE.FADE) {
      return "transform, opacity";
    } else {
      return "";
    }
  }

  public onOverlayClick(data: OVERLAY) {
    this.overlayEvent.emit({
      event: "VIDEO_OVERLAY_CLICKED",
      data: { id: data.id },
    });
  }
}
