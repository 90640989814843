import { Injectable } from "@angular/core";
import introJs from "intro.js";
import { IntroJs } from "node_modules/intro.js/src/intro";
import { IntroSequence } from "./sequences/base-sequence";
import { EditIntro } from "./sequences/edit-intro/edit-intro";
import { Router } from "@angular/router";
import { StorageService } from "../storage/storage.service";
import { IntroStep } from "intro.js/src/core/steps";

@Injectable({
  providedIn: "root",
})
export class IntroService {
  private m_IntroSequences: IntroSequence[] = [];
  private m_CurrentSequence: IntroSequence | undefined;
  private m_IntroJS: IntroJs | undefined;
  private m_CompletedSequences: string[] = [];

  constructor(private m_Router: Router, private m_Storage: StorageService) {}

  //#region Public Methods
  async init() {
    let completedSequences = await this.m_Storage.get("CompletedSequences");
    if (completedSequences) this.m_CompletedSequences = completedSequences;
    await this.registerIntroSequences();
  }

  //Stops the current sequence if there is one
  public stopSequence() {
    if (this.m_IntroJS) {
      this.m_IntroJS.exit(true);
      this.m_CurrentSequence = undefined;
    }
  }

  //Starts a sequence by name, if the sequence is already completed, it will not start
  public async startSequenceByName(sequenceName: string) {
    let sequence = this.m_IntroSequences.find((seq) => {
      return seq.getName() == sequenceName;
    });

    if (sequence) this.verifySequenceAndStart(sequence);
  }

  //Clears the completed sequences list, all sequences will be triggiable again after this
  public async clearCompletedSequences() {
    this.m_CompletedSequences = [];
    await this.m_Storage.set("CompletedSequences", this.m_CompletedSequences);
  }
  //#endregion
  //#region Private Methods
  //Registers all intro sequences
  private async registerIntroSequences() {
    this.m_IntroSequences.push(new EditIntro());
  }

  //Starts a sequence
  private async startSequence(sequence: IntroSequence) {
    console.log("Starting sequence: " + sequence.getName());
    this.m_CurrentSequence = sequence;
    this.m_CurrentSequence.registerSteps();
    this.m_IntroJS = await introJs()
      .setOptions({ steps: sequence.Steps })
      .start();

    this.hookupEvents();
  }

  //Hooks up events for the current introJS sequence
  private hookupEvents() {
    if (this.m_IntroJS) {
      this.m_IntroJS.onbeforeexit((targetElement: HTMLElement) => {
        this.sequenceExited(this.m_CurrentSequence?.getName() || "");
        return true;
      });
    }
  }

  //Called when a sequence is exited, stores the sequence name in the completed sequences list
  private async sequenceExited(sequenceName: string) {
    console.log("Sequence exited: " + sequenceName);
    this.m_CompletedSequences.push(sequenceName);
    await this.m_Storage.set("CompletedSequences", this.m_CompletedSequences);
  }

  //Verifies that a sequence is not already completed and starts it if it isn't
  private async verifySequenceAndStart(sequence: IntroSequence) {
    if (sequence && sequence.getName() != "") {
      if (this.m_CompletedSequences.includes(sequence.getName())) return;
      this.startSequence(sequence);
    }
  }
  //#endregion
}
