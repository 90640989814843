import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Events } from "../events/events.service";
import { Subject, Subscription } from "rxjs";
import { EVENTS } from "src/app/constants/events";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertModalOptions } from "src/app/components/modals/alert-modal/alert-modal.component";
import { UserService } from "../user/user.service";
import { T } from "../localization/localization.service";
import { ConfigService } from "../config/config.service";
import { VIDEO } from "src/app/models/video/video";
import {
  InspectletService,
  MetadataTag,
} from "../inspectlet/inspectlet.service";

export enum ORIENTATION {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
}

@Injectable({
  providedIn: "root",
})
export class UiService {
  private m_OnPlayerFullscreenSubscription: Subscription;
  private m_PlayerFullscreen: boolean = false;
  private m_HideSidebar: boolean = false;
  private m_VideoEmbed: boolean = false;
  private m_PricingRedirect = "";
  private m_LandingRedirect = "";
  private m_EditVideo: VIDEO | null = null;
  private m_WatchVideo: VIDEO | null = null;
  private m_ExpandSidebar: boolean = false;

  private $t = T.translate;

  public OnEditorVideoChanged = new Subject<VIDEO | null>();

  get VideoEmbed() {
    return this.m_VideoEmbed;
  }

  set VideoEmbed(value: boolean) {
    this.m_VideoEmbed = value;
  }

  get playerFullscreen() {
    return this.m_PlayerFullscreen;
  }

  get Hidesidebar() {
    return this.m_HideSidebar;
  }

  get ExpandSidebar() {
    return this.m_ExpandSidebar;
  }

  get EditorVideo(): VIDEO | null {
    return this.m_EditVideo;
  }

  get WatchVideo(): VIDEO | null {
    return this.m_WatchVideo;
  }

  constructor(
    private m_Platform: Platform,
    private m_Events: Events,
    private m_Route: ActivatedRoute,
    private m_Router: Router,
    private m_UserService: UserService,
    private m_ConfigService: ConfigService,
    private m_InspectletService: InspectletService
  ) {
    this.m_OnPlayerFullscreenSubscription = this.m_Events.subscribe(
      EVENTS.PLAYER_FULLSCREEN,
      (isFullscreen: boolean) => {
        this.onPlayerFullscreenChanged(isFullscreen);
      }
    );

    //Subscribe to route change and look for payment_success query param
    this.m_Route.queryParams.subscribe((params) => {
      if (params["payment_success"]) {
        let trialDays = params["trial_period_days"];
        let tier = this.m_UserService.ActiveUserLimits?.tierDisplayName || "";
        let msg = "";
        if (tier == "Free Plan") {
          msg = this.$t("components.alert.freemiumSuccess");
        } else if (trialDays != null && trialDays > 0) {
          msg = this.$t("components.alert.trialSuccess")
            .replace("{0}", trialDays)
            .replace("{1}", tier);
        } else {
          msg = this.$t("components.alert.paymentSuccess").replace("{0}", tier);
        }

        //Clear the query param
        this.m_Router.navigate([], {
          queryParams: {},
          replaceUrl: true,
          relativeTo: this.m_Route,
        });

        //Show alert
        let alertConfig: AlertModalOptions = {
          mainText: msg,
          allowCancel: false,
          allowNeverShow: false,
          allowBackdropDismiss: false,
          customButtons: [
            {
              label: tier == "Free Plan" ? "Ok" : "Start",
              close: true,
            },
          ],
        };

        this.m_InspectletService.tagSessionMetadata(MetadataTag.PURCHASE);
        this.m_Events.publish(EVENTS.ALERT, alertConfig);
      }
    });

    this.fetchConfig();
  }

  dispose() {
    this.m_OnPlayerFullscreenSubscription.unsubscribe();
  }

  //Helper to redirect to pricing page
  redirectToPricing() {
    if (this.m_PricingRedirect != "" && this.m_PricingRedirect != null) {
      window.open(this.m_PricingRedirect);
    } else {
      this.m_Router.navigate(["/pricing"], {
        queryParams: {},
      });
    }
  }

  redirectToLanding() {
    if (this.m_LandingRedirect != "" && this.m_LandingRedirect != null) {
      window.location.href = this.m_LandingRedirect;
    } else {
      this.m_Router.navigate(["/"], {
        queryParams: {},
      });
    }
  }

  /**
   * Sets the current video being edited
   * @param video
   */
  setEditVideo(video: VIDEO | null) {
    this.OnEditorVideoChanged.next(video);
    this.m_EditVideo = video;
  }
  setWatchVideo(video: VIDEO | null) {
    this.m_WatchVideo = video;
  }
  //#region Helpers
  isMobile(): boolean {
    return this.m_Platform.is("android") || this.m_Platform.is("ios");
  }

  isMobileSize(): boolean {
    return window.innerWidth <= 768;
  }

  isAndroid(): boolean {
    return this.m_Platform.is("android");
  }

  currentOrientation(): ORIENTATION {
    return this.m_Platform.isPortrait()
      ? ORIENTATION.PORTRAIT
      : ORIENTATION.LANDSCAPE;
  }

  setExpandSidebar(expand: boolean) {
    this.m_ExpandSidebar = expand;
  }
  //#endregion
  //#region Event Handlers
  onPlayerFullscreenChanged(isFullscreen: boolean) {
    this.m_PlayerFullscreen = isFullscreen;
  }
  onHideSidebar(isFullscreen: boolean) {
    this.m_HideSidebar = isFullscreen;
  }
  //#endregion
  private async fetchConfig() {
    let redirectConifg = await this.m_ConfigService.get("REDIRECT_CONFIG");
    if (redirectConifg == null) return;
    let redirectConfigJson = JSON.parse(redirectConifg);
    this.m_PricingRedirect = redirectConfigJson["PRICING_REDIRECT"];
    this.m_LandingRedirect = redirectConfigJson["LANDING_REDIRECT"];
  }
}
