import { Injectable } from "@angular/core";
import { getVideoFileMetaInfo } from "src/app/utility/video-utils";
import { UserService } from "../user/user.service";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { T } from "../localization/localization.service";
import { LIMITS_DTO } from "@shared/models/user/user";
import {
  UploadProgress,
  UploadResults,
  VideoService,
} from "../video/video.service";
import { Subscription } from "rxjs";
import { Events } from "../events/events.service";
import { EVENTS } from "src/app/constants/events";
import { VIDEO } from "src/app/models/video/video";
import { SessionService } from "../session/session.service";

export interface UploadInfo {
  videoId: string;
  file: File;
  uploading: boolean;
  uploadProgress: number;
  onUploadProgress?: Subscription;
  onUploadComplete?: Subscription;
  duration?: number;
}

@Injectable({
  providedIn: "root",
})
export class UploadService {
  private $t = T.translate;
  private m_UploadInfos: UploadInfo[] = [];
  private m_UserLimits: LIMITS_DTO | null = null;

  constructor(
    private m_VideoService: VideoService,
    private m_UserService: UserService,
    private m_AuthService: SessionService,
    private m_Events: Events
  ) {}

  async startUpload(
    file: File,
    qvio?: VIDEO,
    folderId?: string
  ): Promise<string | null> {
    await this.fetchUserLimits();

    //Return if file is not video
    if (!file.type.includes("video")) {
      throw ErrorFactory.error(
        400,
        this.$t("components.modals.invalidFileType")
      );
    }
    //Verify the video metadata against the user limits
    let videoMetaData = await getVideoFileMetaInfo(file);
    await this.verifyMetadata(videoMetaData, file.size);

    let uploadInfo: UploadInfo = {
      videoId: "",
      file: file,
      uploading: true,
      uploadProgress: 0,
      duration: videoMetaData.duration,
    };
    this.m_UploadInfos.push(uploadInfo);

    let videoID = await this.m_VideoService.uploadFile(
      file,
      qvio?.video_name,
      qvio?.video_description,
      qvio?.video_id,
      folderId
    );

    if (videoID != null) {
      this.m_Events.publish(EVENTS.VIDEO_UPDATED);
      uploadInfo.videoId = videoID;
      this.subscribeToUploadEvents(uploadInfo);
    }

    return videoID;
  }

  getVideoUploadInfo(videoId: string) {
    return this.m_UploadInfos.find((info) => info.videoId == videoId);
  }

  /**
   *  Verifies the video metadata against the user limits
   * @param videoMetaData The video metadata in the form of an HTMLVideoElement
   * @param fileSize The size of the video file in bytes
   * @returns
   */
  private async verifyMetadata(
    videoMetaData: HTMLVideoElement,
    fileSize: number
  ) {
    if (this.m_UserLimits == null) return false;

    //Check video length against user limits
    let minsRemaining =
      this.m_UserLimits.maxLength - this.m_UserLimits.currentLength;
    if (
      this.m_UserLimits.maxLength != -1 &&
      minsRemaining < videoMetaData.duration / 60
    ) {
      throw ErrorFactory.error(
        400,
        this.$t("shared.messages.videoTooLong").replace(
          "{0}",
          minsRemaining.toString()
        )
      );
    }

    //Check video file size against user limits
    let sizeRemaining =
      this.m_UserLimits.maxUpload - this.m_UserLimits.currentUpload;
    let fileSizeMB = fileSize / 1000000;
    if (this.m_UserLimits.maxUpload != -1 && sizeRemaining < fileSizeMB) {
      throw ErrorFactory.error(
        400,
        this.$t("shared.messages.fileTooLarge").replace("{0}", sizeRemaining)
      );
    }

    //Check video resolution against user limits
    if (
      this.m_UserLimits.maxResolution != "" &&
      !this.checkVideoResolution(videoMetaData, this.m_UserLimits.maxResolution)
    ) {
      throw ErrorFactory.error(
        400,
        this.$t("shared.messages.resolutionInvalid").replace(
          "{0}",
          this.m_UserLimits.maxResolution
        )
      );
    }
    return true;
  }

  /**
   * Subscribes to upload events for the given video
   * @param videoID
   */
  private subscribeToUploadEvents(uploadInfo: UploadInfo) {
    uploadInfo.onUploadProgress =
      this.m_VideoService.OnUploadPrograss.subscribe(
        (progress: UploadProgress) => {
          if (uploadInfo.videoId == progress.video_id) {
            uploadInfo.uploadProgress = progress.progress;
          }
        }
      );

    uploadInfo.onUploadComplete =
      this.m_VideoService.OnUploadComplete.subscribe(
        (results: UploadResults) => {
          if (uploadInfo.videoId == results.video_id) {
            uploadInfo.onUploadComplete?.unsubscribe();
            uploadInfo.onUploadProgress?.unsubscribe();
            uploadInfo.uploading = false;
            //Tells the user limits bar at root component to update the video lengh usage
            if (uploadInfo.duration) {
              this.m_UserService.updateUserVideoLimits(uploadInfo.duration);
              this.m_Events.publish(EVENTS.UPDATE_LIMITS);
            }
            if (results.success == false) {
              throw ErrorFactory.error(
                400,
                this.$t("components.modals.uploadFailed")
              );
            }
          }
        }
      );
  }

  private async fetchUserLimits() {
    if (this.m_UserService.ActiveUserLimits) {
      this.m_UserLimits = this.m_UserService.ActiveUserLimits;
      return;
    } else {
      let limitsInfo = await this.m_UserService.getUserLimits(
        this.m_AuthService.UserId
      );
      if (limitsInfo) {
        this.m_UserLimits = limitsInfo.subInfo;
      }
    }
  }

  /**
   *  Heler function to check the video resolution against the given limit
   * @param videoMetaData
   * @param resolutionLimit
   * @returns
   */
  private checkVideoResolution(
    videoMetaData: HTMLVideoElement,
    resolutionLimit: string
  ) {
    let videoHeight = videoMetaData.videoHeight;
    let videoWidth = videoMetaData.videoWidth;
    let res = videoWidth * videoHeight;

    switch (resolutionLimit) {
      case "480p":
        return res <= 640 * 480;
      case "720p":
        return res <= 1280 * 720;
      case "1080p":
        return res <= 1920 * 1080;
      case "4k":
        return res <= 3840 * 2160;
      default:
        return false;
    }
  }
}
