import { USER_DTO } from "@shared/models/user/user";
import { DataCache, DataCacheEntry } from "./base-data-cache";
export class UserDataCache extends DataCache<USER_DTO> {
  async getUserByUsername(username: string): Promise<USER_DTO | undefined> {
    for (const [userId, cachedEntry] of this.dataCache) {
      if (
        await cachedEntry.fetchPromise.then(
          (user) => user.username === username
        )
      ) {
        return await cachedEntry.fetchPromise;
      }
    }

    return undefined;
  }
}
