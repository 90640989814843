//#region DO_NOT_EDIT
/*
  USED BY BUILD SCRIPT, DO NOT CHANGE THESE VALUES!!!
*/
const MAJOR = "1";
const MINOR = "7";
const POINT = "6";
const REVISION = "4545";
const HASH = "5e8f47b35a51286a595a8ef2ca4a92614198c8ba";
//#endregion DO_NOT_EDIT

export class VERSION {
  public static SIMPLE = MAJOR + "." + MINOR;
  public static DEFAULT = MAJOR + "." + MINOR + "." + POINT + "." + REVISION;
  public static FULL =
    MAJOR + "." + MINOR + "." + POINT + "." + REVISION + "-" + HASH;
}
