import {
  ANALYTICS_AGGREGATED_QUERIES_DTO,
  ANALYTICS_DTO,
} from "@shared/models/analytics/analytics";
import { ANALYTICS_GET_INDIVIDUAL_QUERIES_RES } from "@shared/models/analytics/analytics-responses";
import {
  ANALYTICS_QUERY,
  ParsedIndividualQueryAnalytics,
  ParsedQueryAnalytics,
  ParsedViewAnalytics,
  RawAggregratedQueryData,
  RawAnalytics,
} from "../models/analytics/analytics";
import { watchTimeSince } from "./utils";

//Parse the analytics data into a more usable format
export async function parseViewAnalyticsData(
  data: ANALYTICS_DTO[][]
): Promise<ParsedViewAnalytics> {
  let retValue: ParsedViewAnalytics = {
    totalViews: 0,
    totalWatchTime: 0,
    totalWatchTimeFormatted: "",
    rawAnalytics: [],
  };

  let analytics = data;
  //Loop over each video and tally the total views
  let allViews = 0;
  let allWatchTime = 0;
  let allRawAnalytics: RawAnalytics[][] = [];
  for (let i = 0; i < analytics.length; i++) {
    if (analytics[i] == null || analytics[i].length == 0) continue;
    let videoViews = 0;
    let videoWatchTime = 0;
    let videoRawAnalytics: RawAnalytics[] = [];
    for (let j = 0; j < analytics[i].length; j++) {
      videoViews += parseInt(analytics[i][j].views as any);
      videoWatchTime += parseInt(analytics[i][j].total_watch_time as any);

      videoRawAnalytics.push(new RawAnalytics(analytics[i][j]));
    }
    allRawAnalytics.push(videoRawAnalytics);
    allViews += videoViews;
    allWatchTime += videoWatchTime;
  }
  let formattedWatchTime = watchTimeSince(allWatchTime);
  retValue.totalViews = allViews;
  retValue.totalWatchTime = allWatchTime;
  retValue.totalWatchTimeFormatted = formattedWatchTime;
  retValue.rawAnalytics = allRawAnalytics;

  return retValue;
}

//Helper function that parses client side converted analytics data to a more usable format
export async function parseRawAnalyticsData(
  data: RawAnalytics[]
): Promise<ParsedViewAnalytics> {
  let retValue: ParsedViewAnalytics = {
    totalViews: 0,
    totalWatchTime: 0,
    totalWatchTimeFormatted: "",
    rawAnalytics: [data],
  };

  let analytics = data;
  //Loop over each entry and tally the total views and watch time
  let allViews = 0;
  let allWatchTime = 0;
  for (let i = 0; i < analytics.length; i++) {
    if (analytics[i] == null) continue;
    allViews += analytics[i].views;
    allWatchTime += analytics[i].total_watch_time;
  }
  let formattedWatchTime = watchTimeSince(allWatchTime);
  retValue.totalViews = allViews;
  retValue.totalWatchTime = allWatchTime;
  retValue.totalWatchTimeFormatted = formattedWatchTime;

  return retValue;
}

//Parse the aggregated queries data into a more usable format
export async function parseAggregatedQueriesData(
  data: {
    videoId: string;
    data: ANALYTICS_AGGREGATED_QUERIES_DTO[];
  }[]
): Promise<ParsedQueryAnalytics> {
  let retValue: ParsedQueryAnalytics = {
    totalQueries: 0,
    totalQueryCorrectPercentage: 0,
    totalQueryFallbackPercentage: 0,
    rawAggregatedQueries: [],
  };

  let totalQuestionsAsked = 0;
  let totalCorrect = 0;
  let totalFallback = 0;
  let total = 0;
  let allRawAggregatedQueries: RawAggregratedQueryData[][] = [];

  for (let i = 0; i < data.length; i++) {
    let videoQuestionsAsked = 0;
    let videoCorrect = 0;
    let videoFallback = 0;
    let videoTotal = 0;
    let videoRawAggregatedQueries: RawAggregratedQueryData[] = [];

    for (let j = 0; j < data[i].data.length; j++) {
      let validData = data[i].data[j].questions_asked > 0;
      if (!validData) continue;

      let entryCorrect = Math.round(
        (data[i].data[j].questions_asked * data[i].data[j].correct_percentage) /
          100
      );
      let entryFallback = Math.round(
        (data[i].data[j].questions_asked *
          data[i].data[j].fallback_percentage) /
          100
      );

      videoQuestionsAsked += data[i].data[j].questions_asked;
      videoCorrect += entryCorrect;
      videoFallback += entryFallback;
      videoTotal++;

      videoRawAggregatedQueries.push(
        new RawAggregratedQueryData(data[i].videoId, data[i].data[j])
      );
    }

    allRawAggregatedQueries.push(videoRawAggregatedQueries);

    totalQuestionsAsked += videoQuestionsAsked;
    totalCorrect += videoCorrect;
    totalFallback += videoFallback;
    total += videoTotal;
  }

  if (total == 0) return retValue;

  let avgCorrect = (totalCorrect / totalQuestionsAsked) * 100;
  let avgFallback = (totalFallback / totalQuestionsAsked) * 100;

  retValue.totalQueries = totalQuestionsAsked;
  retValue.totalQueryCorrectPercentage = avgCorrect;
  retValue.totalQueryFallbackPercentage = avgFallback;
  retValue.rawAggregatedQueries = allRawAggregatedQueries;

  return retValue;
}

export async function parseRawAggregatedQueriesData(
  data: RawAggregratedQueryData[]
): Promise<ParsedQueryAnalytics> {
  let retValue: ParsedQueryAnalytics = {
    totalQueries: 0,
    totalQueryCorrectPercentage: 0,
    totalQueryFallbackPercentage: 0,
    rawAggregatedQueries: [],
  };

  let totalQuestionsAsked = 0;
  let totalCorrect = 0;
  let totalFallback = 0;
  let total = 0;

  for (let i = 0; i < data?.length ?? 0; i++) {
    let validData = data[i].questions_asked > 0;
    if (!validData) continue;

    let entryCorrect = Math.round(
      (data[i].questions_asked * data[i].correct_percentage) / 100
    );
    let entryFallback = Math.round(
      (data[i].questions_asked * data[i].fallback_percentage) / 100
    );

    totalQuestionsAsked += data[i].questions_asked;
    totalCorrect += entryCorrect;
    totalFallback += entryFallback;
    total++;
  }

  if (total == 0) return retValue;

  retValue.totalQueries = totalQuestionsAsked;
  retValue.totalQueryCorrectPercentage =
    (totalCorrect / totalQuestionsAsked) * 100;
  retValue.totalQueryFallbackPercentage =
    (totalFallback / totalQuestionsAsked) * 100;
  retValue.rawAggregatedQueries = [data];

  return retValue;
}

export async function parseIndividualQueriesData(
  data: ANALYTICS_GET_INDIVIDUAL_QUERIES_RES,
  videoId: string
): Promise<ParsedIndividualQueryAnalytics> {
  let retValue: ParsedIndividualQueryAnalytics = {
    data: [],
    total: 0,
    page: 0,
    pageSize: 0,
    totalPages: 0,
  };

  for (let i = 0; i < data.video_query_analytics.length; i++) {
    let validData = data.video_query_analytics[i].question != "";
    if (!validData) continue;
    let videoQuery = data.video_query_analytics[i];
    retValue.data.push(new ANALYTICS_QUERY(videoQuery, videoId));
  }

  retValue.total = data.totalItems;
  retValue.page = data.currentPage;
  retValue.pageSize = data.pageSize;
  retValue.totalPages = data.totalPages;

  return retValue;
}

export function formatLargeNumber(num: number): string {
  if (num > 999999) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num > 999) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
}
