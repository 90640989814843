import { Injectable } from "@angular/core";
import {
  AppConfigurationClient,
  ConfigurationSetting,
} from "@azure/app-configuration";
import { ConfigApi } from "src/app/api/config/config-api";
import { CONFIG } from "src/app/models/config/config";
import { AzureConfigSettings } from "src/environments/environment";
import { StorageService } from "../storage/storage.service";
import { Events } from "../events/events.service";

enum ConfigState {
  Uninitialized,
  Initializing,
  Initialized,
}

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  get State() {
    return this.m_AzureConfigState;
  }

  private m_AzureConfig: { [key: string]: any } = {};
  private m_HiaConfig: CONFIG | null = null;
  private m_AzureConfigState: ConfigState = ConfigState.Uninitialized;
  private m_HiaConfigState: ConfigState = ConfigState.Uninitialized;
  private m_ConfigApi: ConfigApi | null = null;

  constructor(
    private m_eventsService: Events,
    private m_Storage: StorageService
  ) {
    this.initialize();
  }

  private async initialize() {
    this.m_HiaConfigState = ConfigState.Initializing;
    this.m_ConfigApi = new ConfigApi(
      this.m_eventsService,
      this.get("SERVER_URL")
    );
    try {
      let configKey = await this.get("CONFIG_KEY");
      let encryptionKey = await this.get("PUBLIC_ENCRYPTION_KEY");
      let cryptoKey: CryptoKey = await this.importPublicKey(encryptionKey);
      let configResDTO = await this.m_ConfigApi.getConfig(configKey, cryptoKey);
      if (configResDTO == null || configResDTO.status !== 200)
        throw new Error("Failed to load config");

      this.m_HiaConfig = new CONFIG(configResDTO.config);
      this.m_HiaConfigState = ConfigState.Initialized;
    } catch (error) {
      console.error("Failed to load HIA config", error);
      this.m_HiaConfigState = ConfigState.Uninitialized;
    }
  }

  /**
   * Gets the HIA config, a secondary config that is retrieved
   * from the server rather than Azure
   * @returns
   */
  public async getHiaConfig(): Promise<CONFIG | null> {
    if (this.m_HiaConfig == null) {
      if (this.m_HiaConfigState == ConfigState.Initializing) {
        await this.waitForHiaConfig();
      } else {
        this.m_HiaConfigState = ConfigState.Initializing;
        await this.initialize();
        this.m_HiaConfigState = ConfigState.Initialized;
      }
    }

    return this.m_HiaConfig;
  }

  /**
   * Gets a config value from the fetched the primary azure config
   * @param key
   * @returns
   */
  public async get(key: string) {
    if (
      this.m_AzureConfigState == ConfigState.Uninitialized ||
      this.State == ConfigState.Initializing
    ) {
      if (this.State == ConfigState.Initializing)
        //Wait for the config to load if it is initializing
        await this.waitForConfig();
      else {
        //Load the config if it is uninitialized
        await this.loadAzureConfig(
          AzureConfigSettings.connectionString,
          AzureConfigSettings.environment
        );
      }
    }

    return this.m_AzureConfig[key];
  }

  /**
   * Loads the primary config from Azure
   * @param connectionString
   * @param label
   */
  public async loadAzureConfig(connectionString: string, label: string) {
    if (
      this.State == ConfigState.Initialized ||
      this.State == ConfigState.Initializing
    )
      return;

    //Check for enableDev cookie
    if (
      document.cookie.includes("overrideEnvironment") &&
      label != "Production"
    ) {
      //get the environment from the cookie
      const environment = document.cookie
        .split(";")
        .find((row) => row.includes("overrideEnvironment"))
        ?.split("=")[1];

      if (environment) {
        label = environment;
      }
    }

    //Check for clearHiaStorage cookie
    if (document.cookie.includes("clearHIAStorage")) {
      let clear = document.cookie
        .split(";")
        .find((row) => row.includes("clearHIAStorage"))
        ?.split("=")[1];
      if (clear) {
        //Clear the storage
        this.m_Storage.clear();
        //Remove the cookie
        document.cookie =
          "clearHIAStorage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    }

    this.m_AzureConfigState = ConfigState.Initializing;
    let appConfigClient = new AppConfigurationClient(connectionString);

    const labels = [label, "\0"].join(",");
    const settingsIterator = appConfigClient.listConfigurationSettings({
      labelFilter: labels,
    });

    const settings: { [key: string]: ConfigurationSetting<string> } = {};

    for await (const setting of settingsIterator) {
      // If a setting with the same key exists and has a label, don't overwrite it with an unlabeled setting
      if (
        settings[setting.key] &&
        settings[setting.key].label &&
        !setting.label
      ) {
        continue;
      }
      settings[setting.key] = setting;
      this.m_AzureConfig[setting.key] = setting.value;
    }

    this.m_AzureConfigState = ConfigState.Initialized;
  }

  //#region Private Methods
  //Helper method to wait for the config to load
  private async waitForConfig() {
    return new Promise<void>((resolve, reject) => {
      let max = 100;
      let count = 0;
      let interval = setInterval(() => {
        if (this.m_AzureConfigState == ConfigState.Initialized) {
          clearInterval(interval);
          resolve();
        }
        count++;
        if (count >= max) {
          clearInterval(interval);
          reject("Failed to load config");
        }
      }, 100);
    });
  }

  //Helper method to wait for the HIA config to load
  private async waitForHiaConfig() {
    return new Promise<void>((resolve, reject) => {
      let max = 100;
      let count = 0;
      let interval = setInterval(() => {
        if (this.m_HiaConfigState == ConfigState.Initialized) {
          clearInterval(interval);
          resolve();
        }
        count++;
        if (count >= max) {
          clearInterval(interval);
          reject("Failed to load HIA config");
        }
      }, 100);
    });
  }

  private importPublicKey(pKey: string) {
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pKey);
    const binaryDer = this.str2ab(binaryDerString);

    // Import the key
    return window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"]
    );
  }

  private str2ab(str: string) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  //#endregion
}
