import { PAGINATED_VOICES_DTO, VOICE_DTO } from "@shared/models/voice/voice";

export class VOICE {
  id: string;
  eleven_labs_id: string;
  name: string;
  description: string;
  user_id: string;
  stability: number;
  similarity_boost: number;
  style: number;

  constructor(voiceDTO: VOICE_DTO | null = null) {
    if (!voiceDTO) {
      this.id = "";
      this.eleven_labs_id = "";
      this.name = "";
      this.description = "";
      this.user_id = "";
      this.stability = 0;
      this.similarity_boost = 0;
      this.style = 0;
      return;
    }
    this.id = voiceDTO.id;
    this.eleven_labs_id = voiceDTO.eleven_labs_id;
    this.name = voiceDTO.name;
    this.description = voiceDTO.description;
    this.user_id = voiceDTO.user_id;
    this.stability = voiceDTO.stability;
    this.similarity_boost = voiceDTO.similarity_boost;
    this.style = voiceDTO.style || 0;
  }
}

export class PAGINATED_VOICE_LIST {
  voices: VOICE[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;

  constructor(voicesDTO: PAGINATED_VOICES_DTO) {
    this.voices = voicesDTO.voices.map(
      (voiceDTO: VOICE_DTO) => new VOICE(voiceDTO)
    );
    this.pageNumber = voicesDTO.currentPage;
    this.pageSize = voicesDTO.pageSize;
    this.totalItems = voicesDTO.totalItems;
  }
}
