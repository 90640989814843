//----------------------------------------------------------------------------------------

import { NavigationEnd } from "@angular/router";
import { T } from "../services/localization/localization.service";
import { formatLargeNumber } from "./analytics-utils";
import { FOLDER_DTO } from "@shared/models/folders/folder";
import { FastAverageColor } from "fast-average-color";

/*
  code based on: https://stackoverflow.com/questions/38213668/promise-retry-design-patterns
  */
export function tryMultipleTimes(
  fn: () => Promise<any>,
  numberOfTries = 1,
  waitBeforeRetry = 200
): Promise<any> {
  let rejectDelay = (reason: any, waitBeforeRetry: any) => {
    return new Promise(function (resolve, reject) {
      setTimeout(reject.bind(null, reason), waitBeforeRetry);
    });
  };

  return new Promise<any>((resolve, reject) => {
    let p: Promise<any> = Promise.reject();
    for (let i = 0; i < numberOfTries; i++) {
      p = p.catch(fn).catch((reason) => rejectDelay(reason, waitBeforeRetry));
    }
    p = p.then(resolve).catch(reject);
  });
}

//Function that takes a data string and returns string in format of "x years ago/x days ago/x hours ago/x minutes ago"
export function timeSince(
  date: Date,
  date2?: Date,
  suffix?: string,
  ceil: boolean = false
) {
  if (date2 == null) date2 = new Date();
  if (suffix == null) suffix = "";

  var seconds = Math.floor((date2.getTime() - date.getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return constructTimeLabel(
      interval,
      interval < 2
        ? T.translate("shared.labels.year")
        : T.translate("shared.labels.years"),
      suffix,
      ceil
    );
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return constructTimeLabel(
      interval,
      interval < 2
        ? T.translate("shared.labels.month")
        : T.translate("shared.labels.months"),
      suffix,
      ceil
    );
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return constructTimeLabel(
      interval,
      interval < 2
        ? T.translate("shared.labels.day")
        : T.translate("shared.labels.days"),
      suffix,
      ceil
    );
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return constructTimeLabel(
      interval,
      interval < 2
        ? T.translate("shared.labels.hour")
        : T.translate("shared.labels.hours"),
      suffix,
      ceil
    );
  }
  interval = seconds / 60;
  if (interval > 1) {
    return constructTimeLabel(
      interval,
      interval < 2
        ? T.translate("shared.labels.minute")
        : T.translate("shared.labels.minutes"),
      suffix,
      ceil
    );
  }
  return constructTimeLabel(
    seconds,
    interval < 2
      ? T.translate("shared.labels.second")
      : T.translate("shared.labels.seconds"),
    suffix,
    ceil
  );
}

function constructTimeLabel(
  interval: number,
  label: string,
  suffix: string,
  ceil: boolean = false
) {
  if (ceil) {
    return Math.ceil(interval) + " " + label + suffix;
  } else {
    return Math.floor(interval) + " " + label + suffix;
  }
}

//Function that takes total watch time in seconds and returns string in format of "x hours or x minutes or x seconds"
export function watchTimeSince(totalWatchTime: number) {
  var seconds = totalWatchTime;

  var interval = seconds / 3600;

  if (interval > 1) {
    return (
      formatLargeNumber(Math.floor(interval)) +
      " " +
      T.translate("shared.labels.hours") +
      " "
    );
  }
  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) + " " + T.translate("shared.labels.minutes") + " "
    );
  }
  return Math.floor(seconds) + " " + T.translate("shared.labels.seconds") + " ";
}

//Function that takes a string and returns a string with all URLs replaced with anchor tags
export function linkifyText(inputText: string) {
  // The regex pattern to identify URLs
  var urlPattern =
    /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  // Replace the URLs found with anchor tags
  var linkedText = inputText.replace(urlPattern, function (url) {
    var protocolPattern = /^(?:(?:https?):\/\/)/i;
    var href = protocolPattern.test(url) ? url : "http://" + url;
    return '<a href="' + href + '" target="_blank">' + url + "</a>";
  });

  // Return the modified string
  return linkedText;
}

//Function to convert entries to params js object
export function paramsToObject(entries: IterableIterator<[string, string]>) {
  const result = {};
  for (const [key, value] of entries) {
    (result as any)[key] = value;
  }
  return result;
}

//Function to create metadata for page_view event from NavigationEnd event
export function getPageViewMetadata(event: NavigationEnd) {
  let page = event.url.split("?")[0];
  let urlParams = event.url.split("?")[1];

  let searchParams = new URLSearchParams(urlParams);
  let params = paramsToObject(searchParams.entries());

  return { page: page, params: params };
}

//Function to create metadata for page_view event from URL
export function getPageViewMetadataFromURL(url: string) {
  let urlObj = new URL(url);
  let page = urlObj.pathname;
  let params = paramsToObject(urlObj.searchParams.entries());

  return { page: page, params: params };
}

/**
 * Dispatches a resize event every interval milliseconds, times times
 * @param interval interval in milliseconds
 * @param times number of times to dispatch the event
 */
export function dispatchResizeEvent(interval: number, times: number) {
  let i = 0;
  let intervalId = setInterval(() => {
    window.dispatchEvent(new Event("resize"));
    i++;
    if (i >= times) {
      clearInterval(intervalId);
    }
  }, interval);
}

export function downloadBlob(blob: Blob, filename: string) {
  //Download the file
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
}

export function isSafari(): boolean {
  const userAgent = window.navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
  return isSafari;
}

export function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function isCSVFile(file: File) {
  return (
    file.name.endsWith(".csv") &&
    (file.type === "text/csv" || file.type === "application/vnd.ms-excel")
  );
}

export function validateLink(link: string) {
  const re = /^(http|https):\/\/[^ "]+$/;
  return re.test(link);
}

export function isFolderDTO(data: any): data is FOLDER_DTO {
  return (
    "id" in data &&
    "name" in data &&
    "created_at" in data &&
    "updated_at" in data
  );
}

export function convertToFolderDTO(data: any): FOLDER_DTO {
  return {
    id: data.folderId || "",
    parent_id: data.parentFolderId || "",
    name: data.text || "",
    created_at: new Date(),
    updated_at: new Date(),
  };
}

export async function allowIframe(link: string) {
  //Check if window protocol is https, if not, return true (DEV MODE)
  if (window.location.protocol !== "https:") {
    return true;
  }

  return fetch(link, { method: "HEAD" })
    .then((response) => {
      const xFrameOptions = response.headers.get("X-Frame-Options");
      if (xFrameOptions) {
        if (
          xFrameOptions.toLowerCase() === "deny" ||
          xFrameOptions.toLowerCase() === "sameorigin"
        ) {
          //Does not allow iframing
          return false;
        }
      }
      return true;
    })
    .catch(() => {
      return false;
    });
}

export function convertTierName(name: string | undefined): string {
  switch (name) {
    case "starter_annual":
      return "Starter Annual Tier";
    case "starter_monthly":
      return "Starter Monthly Tier";
    case "admin":
      return "Admin Tier";
    case "basic_perpetual":
      return "Basic Free";
    case "pro_annual":
      return "Pro Annual Tier";
    case "pro_monthly":
      return "Pro Monthly Tier";
    default:
      if (name) return name;
      return "Unknown Tier";
  }
}

export function getDominantColorHexImage(src: string) {
  const fac = new FastAverageColor();
  const img = new Image();

  img.src = src;
  img.crossOrigin = "anonymous";

  img.onload = () => {
    return fac.getColor(img).hex;
  };
}

export function checkIfPointIsInRect(point: MouseEvent, rect: DOMRect) {
  return (
    point.x >= rect.x &&
    point.x <= rect.x + rect.width &&
    point.y >= rect.y &&
    point.y <= rect.y + rect.height
  );
}
