import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../user/user.service";
import { SessionService } from "../../session/session.service";

@Injectable({
  providedIn: "root",
})
export class IsAdminGuardService {
  constructor(
    private m_Router: Router,
    private m_UserService: UserService,
    private m_SessionService: SessionService
  ) {}

  //Check if user is logged in and is an admin
  //If not, redirect to login page
  async canActivate(): Promise<boolean> {
    //User needs to be signed in first
    await this.m_SessionService.checkForToken();

    if (this.m_SessionService.LoggedIn === false) {
      const searchParams = window.location.search;
      const pathname = window.location.pathname;
      const paramsAndPathname = pathname + searchParams;

      this.m_Router.navigate(["/login"], {
        queryParams: { redirect: paramsAndPathname },
      });
      return false;
    }

    if (this.m_UserService.IsAdmin) {
      return true;
    } else {
      this.m_Router.navigate(["/home"]);
      return false;
    }
  }
}
