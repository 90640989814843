// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal {
  --border-radius: 0.3rem;
  --height: auto;
  --background: var(--main-background-color);
  border: 1px solid gray;
}
ion-modal::part(content) {
  width: 90%;
  height: 90%;
  padding: 1rem;
  overflow: auto;
}

#pricing-modal-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#pricing-modal-root app-pricing-component {
  flex: 1;
}

.button-icon {
  width: 30px;
  padding-right: 7px;
  filter: brightness(100);
}

.button-container {
  margin: 2rem auto;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modals/pricing-modal/pricing-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,cAAA;EACA,0CAAA;EACA,sBAAA;AACF;AACE;EACE,UAAA;EACA,WAAA;EACA,aAAA;EACA,cAAA;AACJ;;AAGA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AAAF;AAEE;EACE,OAAA;AAAJ;;AAIA;EACE,WAAA;EACA,kBAAA;EACA,uBAAA;AADF;;AAIA;EACE,iBAAA;EACA,kBAAA;AADF","sourcesContent":["ion-modal {\n  --border-radius: 0.3rem;\n  --height: auto;\n  --background: var(--main-background-color);\n  border: 1px solid gray;\n\n  &::part(content) {\n    width: 90%;\n    height: 90%;\n    padding: 1rem;\n    overflow: auto;\n  }\n}\n\n#pricing-modal-root {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  app-pricing-component {\n    flex: 1;\n  }\n}\n\n.button-icon {\n  width: 30px;\n  padding-right: 7px;\n  filter: brightness(100);\n}\n\n.button-container {\n  margin: 2rem auto;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
