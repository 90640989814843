export const CHANGE_LOGS = [
  {
    date: "20 Mar 2024",
    version: "1.1.0",
    title: "System Enhancements and Feature Additions",
    changes: [
      {
        type: "new",
        tag: "Q&A User Experience",
        descriptions: [
          "Add ability to embed Web URLs to Q&A pairs",
          "Update backend to support attaching links",
          "Add ability to attach a start and end timestamp data to a Q&A pair",
          "Updated Q&A flow to show timestamp attachments that start the main video as an attachment at the time provided",
        ],
      },
      {
        type: "new",
        tag: "User Interaction",
        descriptions: [
          "QR Share Link Support",
          "Dedicated Embed Page for easy embedding of Qvios",
          "UI to set a Q&A pair as 'Excluded from suggestions'",
          "UI to set a Q&A pair as 'Pinned'",
          "Add Voice Selection option when creating a video (Contact us for more info)",
          "Change log modal added",
        ],
      },
      {
        type: "improved",
        tag: "System Usability",
        descriptions: [
          "Update automatic audio generation to use voice data",
          "Track filters on analytics via query params and restore on load",
          "Show loading state better on Analytics page",
          "Update client so it uses API for voice data",
          "Update root route to handle new landing page flow",
        ],
      },
      {
        type: "fix",
        tag: "Bug Corrections",
        descriptions: [
          "Small embedded Q&A view is not legible",
          "Qvio loads without the default thumbnail",
          "AIM Analytics Attachment View doesn’t load videos shown in answered questions",
          "Save All Q&A's not functioning correctly",
          "Remove 'Max Q&A' text from delete media modal.",
          "Gallery window is too small",
          "Share modal blank with Safari",
          "Cache issue with ChartGPT in edge case",
          "Subtitles can only be changed the first time used",
          "Changing language for cc is not saved when switching to View mode",
          "Record does not span whole editor after using splitter",
          "Fix grammar on age display",
          "Context menus render offscreen in several areas",
        ],
      },
    ],
  },
  {
    date: "29 Feb 2024",
    version: "1.0.1066",
    title: "Revamped UI and Enhanced Interactivity",
    changes: [
      {
        type: "new",
        tag: "Q&A UI Update",
        descriptions: [
          "Updated Q&A UI to a new visual design",
          "STT support on PC and iOS",
          "Infinite scroll to load more suggestions",
          "Adds ask more button to answers",
        ],
      },
      {
        type: "improved",
        tag: "CC Update",
        descriptions: [
          "Adds ability to switch language from the insights tab and have the player CC update to that language",
        ],
      },
      {
        type: "improved",
        tag: "Qvio Player",
        descriptions: [
          "Adds Thumbnail preview on hover",
          "Updated title space in description to better use horizontal space before truncating",
          "Qvios resume where a user left off on the same browser and machine now if viewed from watch page",
          "Links in Qvio descriptions are clickable now",
        ],
      },
      {
        type: "fix",
        tag: "Embedded Qvios",
        descriptions: [
          "Embedded Qvios no longer start autoplaying",
          "Added autoplay flag to enable autoplay on a Qvio if passed and browser allows",
        ],
      },
      {
        type: "new",
        tag: "Q&A Editor",
        descriptions: ["Search and Replace All widget added"],
      },
      {
        type: "improved",
        tag: "Edit page",
        descriptions: [
          "Splitter can be clicked once to toggle fullscreen states between the editor and player",
        ],
      },
      {
        type: "new",
        tag: "Qvio Dashboard",
        descriptions: [
          "Search field added",
          "Dashboard pager should consistently show 12, 25, and 50 items as selection items now",
        ],
      },
      {
        type: "new",
        tag: "Analytics",
        descriptions: [
          "Updated table to show ALL Qvios, not just top ones",
          "Adds drop down selection to select/search your Qvio under AIM performance widget",
          "Adds questions asked graph",
        ],
      },
      {
        type: "improved",
        tag: "Other Improvements",
        descriptions: [
          "Adds warning when leaving unsaved profile changes",
          "Adds CTA to upgrade plan when user is presented with limitations reached warning",
          "Adds numeric progress to all progress bars",
          "Several text updates throughout the app",
        ],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "Fixed ability to log in after logging out",
          "Updated sidebar to show limited info when in verification state",
          "Fix to screen flashes after accepting EULA after sign up",
          "Fix to empty profile info in header for unverified accounts",
          "Fix to profile page not allowing saving after changing a profile image",
          "Fix to profile image not updating after saving changes to it",
          "Fix to add improvement button not working in edge case",
          "Bug fix to save all not saving media changes",
        ],
      },
    ],
  },
  {
    date: "13 Feb 2024",
    version: "0.9.1468",
    title: "Enhanced Workflow Efficiency and User Experience",
    changes: [
      {
        type: "new",
        tag: "AIM Workflow",
        descriptions: [
          "Added media previewing for answered questions",
          "Added review workflow for unanswered questions",
        ],
      },
      {
        type: "improved",
        tag: "Login/Sign Up Flow",
        descriptions: [
          "Split the flow",
          "Create Account brings user to pricing first",
          "On select of plan, user creates account, verifies email, and is automatically brought to the plan checkout they chose",
          "Added sign out button to mandatory pricing modal to get out if you don't want to make a selection",
          "Users won't receive renewal email if they cancelled their trial",
        ],
      },
      {
        type: "improved",
        tag: "Q&A modal",
        descriptions: [
          "Added video thumbnails to video attachments in history",
        ],
      },
      {
        type: "improved",
        tag: "Q&A Editor",
        descriptions: [
          "Record answers no longer allow empty text answers such as a tab or space character",
        ],
      },
      {
        type: "improved",
        tag: "Upload Flow",
        descriptions: [
          "Uploading a video now will wait for at least a title, prompting the user to enter one on upload complete if they haven't already",
        ],
      },
      {
        type: "new",
        tag: "Profile Page",
        descriptions: ["Update profile page to allow editing with more ease"],
      },
      {
        type: "new",
        tag: "Dashboard",
        descriptions: [
          "Added share option on video cards",
          "Added warning when sharing a private Qvio",
          "Added admin read-only mode for setting Qvios as locked",
        ],
      },
      {
        type: "fix",
        tag: "Notifications",
        descriptions: ["User consumption of minutes refreshes now"],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "No longer able to see analytics of previously signed-in accounts",
          "Video cards on mobile don't grow beyond the screen size",
          "User can no longer navigate to edit pages they do not own",
          "Longer Qvio titles no longer cause view/edit button to go off-screen on mobile",
          "Trial email logos are no longer stretched on mobile",
          "Users can preview video attachments from Q&A editor correctly now",
          "Description box no longer truncates aggressively",
          "Ensuring we show the same subscription information on mobile and desktop on edit profile page",
          "Minor text fixes in several places",
        ],
      },
    ],
  },

  {
    date: "02 Feb 2024",
    version: "0.9.1354",
    title: "Streamlined Payments and Enhanced Editing",
    changes: [
      {
        type: "improved",
        tag: "Payment Process",
        descriptions: [
          "Payment all goes through a single tab payment process",
          "Update starter category to show 7-day trial text",
        ],
      },
      {
        type: "improved",
        tag: "QnA Editor",
        descriptions: [
          "Save All button added",
          "Exit warning provides save all button as well",
        ],
      },
      {
        type: "improved",
        tag: "Landing Page",
        descriptions: ["Update to landing page sign up button text"],
      },
    ],
  },
  {
    date: "31 Jan 2024",
    version: "0.9.1227",
    title: "Efficient Media Loading and Enhanced Pricing",
    changes: [
      {
        type: "improved",
        tag: "Media Optimization",
        descriptions: [
          "System integration of image optimization service to help speed up the loading of media",
        ],
      },
      {
        type: "fix",
        tag: "Pricing Modal",
        descriptions: ["Contact Us button works now"],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "Fix to suggestions in fallback having a blue suggestion when ChatGPT was disabled",
        ],
      },
    ],
  },
  {
    date: "26 Jan 2024",
    version: "0.9.1227",
    title: "Streamlined Pricing and Voice Improvements",
    changes: [
      {
        type: "new",
        tag: "Pricing Workflow",
        descriptions: ["Updated pricing workflow for improved user experience"],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "Fixed scratchy voice issue",
          "Fix to voice ending on iOS",
        ],
      },
      {
        type: "improved",
        tag: "Localization Strings",
        descriptions: ["Updated and improved localization strings"],
      },
      {
        type: "improved",
        tag: "Landing Page Update",
        descriptions: ["Refreshed landing page for a more engaging experience"],
      },
      {
        type: "improved",
        tag: "Subscription Management",
        descriptions: ["User consumption is reset now as per subscription"],
      },
    ],
  },

  {
    date: "24 Jan 2024",
    version: "0.9.1196",
    title: "Revamped Experience and Enhanced Functionality",
    changes: [
      {
        type: "new",
        tag: "New URL",
        descriptions: [
          "https://qvio.hia.ai/ with redirects in place for the old URLs",
        ],
      },
      {
        type: "improved",
        tag: "Landing Page Update",
        descriptions: [
          "Refreshed landing page banner video for a more engaging experience",
        ],
      },
      {
        type: "improved",
        tag: "Branding Update",
        descriptions: ["Updated branding from Aivio to Qvio"],
      },
      {
        type: "fix",
        tag: "Autoplay Changes",
        descriptions: ["Video should no longer autoplay on the edit page"],
      },
      {
        type: "improved",
        tag: "Analytics Dashboard",
        descriptions: [
          "Basic analytics dashboard showing data at global and per Qvio level",
        ],
      },
      {
        type: "new",
        tag: "Delete Modal Update",
        descriptions: [
          "Updated delete modal for gallery items with simplified view",
        ],
      },
      {
        type: "new",
        tag: "Email System Integration",
        descriptions: [
          "Integrated email system for sending templated emails",
          "Added notification for unanswered questions, activated in the profile page",
        ],
      },
      {
        type: "improved",
        tag: "Audio Generation Queue",
        descriptions: [
          "Now queue audio generation on save of a QnA pair for quicker access",
        ],
      },
      {
        type: "fix",
        tag: "Subscription Limitation",
        descriptions: [
          "Added max QnA pairs per video limitation per user subscription",
        ],
      },
      {
        type: "improved",
        tag: "UI Updates",
        descriptions: [
          "Updated header links to hide when logged in",
          "Updated side panel links to new design",
        ],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "Video autoplay should be more consistent",
          "Fixed playback issues with certain utterances containing links",
          "Fixed CSV import not working on Firefox",
          "Fixed profile image rendering issues on watch page in some cases",
          "Text below media is now legible",
        ],
      },
    ],
  },
  {
    date: "15 Jan 2024",
    version: "0.0.1099",
    title: "Enhanced Sharing and Streamlined QnA Workflow",
    changes: [
      {
        type: "new",
        tag: "Sharing",
        descriptions: [
          "Social Media Meta Tag Support: QIVIO links now render with additional meta data in 3rd party apps/websites",
          "Added share modal for easy sharing to various social media platforms",
        ],
      },
      {
        type: "new",
        tag: "Embed/iFrame Support",
        descriptions: [
          "Added ability to embed QIVIOs into 3rd party websites",
          "Embed option now available in the share modal",
        ],
      },
      {
        type: "improved",
        tag: "QnA/QnA Editor",
        descriptions: [
          "Improved QnA workflow with faster response times",
          "ChatGPT enable/disable based on subscription tier (Admins only)",
          "Added warning next to ChatGPT responses",
          "Enhanced handling of video and image attachments without text answers",
          "Added tooltips throughout the QnA editor",
          "Warning for QnA pairs with no answer or attached media",
          "Prompt for unsaved changes on exit of QnA editing",
        ],
      },
      {
        type: "improved",
        tag: "QIVIO Dashboard",
        descriptions: [
          "Optimized display logic for better dashboard utilization",
        ],
      },
      {
        type: "",
        tag: "Media Gallery",
        descriptions: [
          "Proper deletion of video media from the gallery",
          "Updated design with ability to close from the header",
          "Display of upload and processing status for media",
        ],
      },
      {
        type: "improved",
        tag: "Landing Page Update",
        descriptions: [
          "Refreshed landing page with card carousel for example QIVIOs",
        ],
      },
      {
        type: "new",
        tag: "Insights and Profile Updates",
        descriptions: [
          "Filters renamed to insights",
          "Updated sidebar settings to My Profile",
        ],
      },
      {
        type: "new",
        tag: "CC Logic and Public Setting",
        descriptions: [
          "Video media attachments now follow main player CC logic",
          "Temporary restriction on setting QIVIOs to public",
        ],
      },
      {
        type: "",
        tag: "User Experience Enhancements",
        descriptions: [
          "Warning when leaving unsubmitted feedback modal",
          "Option to show/hide insights on watch page from properties tab",
        ],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "QnA modal now closes properly when navigating away",
          "Arrows keys no longer affect video playback while editing QnA",
          "Fixed crash when going fullscreen on watch page",
          "Media gallery now refreshes correctly on new edit pages",
          "Fixed MOV files not encoding",
          "Long URLs now wrap properly in QnA chat history",
        ],
      },
    ],
  },
  {
    date: "15 Jan 2024",
    version: "0.0.868",
    title: "Enhanced Mobile Experience and Streamlined Navigation",
    changes: [
      {
        type: "improved",
        tag: "Mobile Support",
        descriptions: [
          "Most pages now support optimized views for mobile devices",
          "Added bottom tabbar for screens with limited width",
          "Improved video playback on tap for mobile devices",
        ],
      },
      {
        type: "",
        tag: "Sidebar Optimization",
        descriptions: [
          "Sidebar now auto-collapses after reaching a certain width",
        ],
      },
      {
        type: "new",
        tag: "Feedback Modal",
        descriptions: [
          "Added feedback modal for easier user feedback submission",
        ],
      },
    ],
  },
  {
    date: "05 Dec 2023",
    version: "0.0.816",
    title: "Streamlined Player Experience and Enhanced Accessibility",
    changes: [
      {
        type: "new",
        tag: "Video Information Widget",
        descriptions: [
          "Video information now in a separate widget below the player",
          "Added expandable video description to the widget",
        ],
      },
      {
        type: "improved",
        tag: "Player Interface",
        descriptions: [
          "Moved 'Ask a Question' button into the player for easier access",
        ],
      },
      {
        type: "improved",
        tag: "Closed Captioning (CC)",
        descriptions: [
          "CC now disabled by default for improved user control",
          "Last set CC setting persists between videos and sessions",
        ],
      },
      {
        type: "improved",
        tag: "Dashboard Improvements",
        descriptions: [
          "Updated dashboard video cards for quicker access to edit mode",
        ],
      },
      {
        type: "new",
        tag: "Attachment Indicators",
        descriptions: [
          "Added icon indicator for attachments in suggested QnA questions",
        ],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "Fixed TTS audio playback on iOS devices",
          "Resolved question modal overflowing boundaries",
          "Fixed fullscreen QnA not pausing video on open",
        ],
      },
    ],
  },

  {
    date: "30 Nov 2023",
    version: "0.0.766",
    title: "Enhanced Fullscreen Interaction and Improved User Tools",
    changes: [
      {
        type: "new",
        tag: "Fullscreen Mode",
        descriptions: [
          "Added ability to ask questions and view insights in fullscreen mode",
        ],
      },
      {
        type: "new",
        tag: "Delete Confirmations",
        descriptions: [
          "Added delete confirmations for QnA pairs and delete all button",
        ],
      },
      {
        type: "improved",
        tag: "User Contact Page",
        descriptions: [
          "Prepopulated email and name fields on contact page if user is signed in",
        ],
      },
      {
        type: "new",
        tag: "Export QnA to CSV",
        descriptions: [
          "Added export button to export QnA pairs as CSV for reimporting",
        ],
      },
      {
        type: "improved",
        tag: "Visual Tweaks",
        descriptions: ["Made small visual tweaks for improved aesthetics"],
      },
    ],
  },

  {
    date: "27 Nov 2023",
    version: "0.0.729",
    title: "Enhanced User Introductions and Seamless Video Playback",
    changes: [
      {
        type: "",
        tag: "Intro UX Framework",
        descriptions: [
          "Added framework for creating introductions to elements and pages",
          "Introduced edit page intro for smoother user onboarding (Not final language)",
        ],
      },
      {
        type: "",
        tag: "Theoplayer Integration",
        descriptions: [
          "First integration of Theoplayer for enhanced video playback experience",
          "Player now maintains 16:9 aspect ratio, adapting to available width",
        ],
      },
      {
        type: "improved",
        tag: "Media Gallery Updates",
        descriptions: [
          "Added file name display to newly uploaded media",
          "Existing media will now display IDs",
        ],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "Fixed issue with video media thumbnails not generating properly",
          "Resolved problem with video media processing without audio",
          "Corrected loader position during media fetching",
        ],
      },
    ],
  },

  {
    date: "15 Nov 2023",
    version: "0.0.672",
    title: "Revamped Desktop Experience and Enhanced QnA Interactions",
    changes: [
      {
        type: "improved",
        tag: "Desktop Sidebar Enhancements",
        descriptions: [
          "Added headers to subsections for better organization",
          "Highlighted current navigation position for improved user guidance",
        ],
      },
      {
        type: "improved",
        tag: "EULA Updates",
        descriptions: [
          "EULA acceptance now saved server-side for user convenience",
        ],
      },
      {
        type: "improved",
        tag: "QnA Improvements",
        descriptions: [
          "Video media attachments now play after audio utterance",
          "Removed QnA from right tabs for streamlined interface",
          "Question and Answer editing now uses a dynamic textarea",
          "Hover over QnA pairs to view full text when not editing",
          "Added option to delete all QnA pairs",
          "Introduced Import HIA Studio Topic functionality",
        ],
      },
      {
        type: "new",
        tag: "Page Updates",
        descriptions: [
          "Updated design of Verify Email page",
          "Implemented Contact Us page",
          "Implemented Pricing page",
        ],
      },
      {
        type: "improved",
        tag: "User Interface Tweaks",
        descriptions: [
          "Side panel and header now display username instead of email",
        ],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "Fixed issue with video streaming when indexer is unavailable",
          "Resolved bug with video media when video has no audio",
        ],
      },
    ],
  },

  {
    date: "02 Nov 2023",
    version: "0.0.548",
    title: "Introducing Desktop Navigation and Enhanced Video Presentation",
    changes: [
      {
        type: "new",
        tag: "Desktop Sidebar Navigation",
        descriptions: [
          "Added sidebar navigation to the desktop version of Aivio-V",
          "Navigate easily to home, profile edit, dashboard, and logout",
          "Stay tuned for more updates",
        ],
      },
      {
        type: "new",
        tag: "Video Cards Enhancement",
        descriptions: [
          "Added author name and profile image to video cards for better context",
        ],
      },
      {
        type: "improved",
        tag: "Video Player Refinement",
        descriptions: [
          "Moved author name and profile image in video player footer to reduce footer height",
        ],
      },
      {
        type: "new",
        tag: "Login/SignUp Page Design",
        descriptions: [
          "First pass at matching design for Login/SignUp page",
          "Stay tuned for updates on the onboarding flow",
        ],
      },
      {
        type: "improved",
        tag: "Design Refresh",
        descriptions: ["Updated primary color for a refreshed look"],
      },
      {
        type: "new",
        tag: "Versioning System Implementation",
        descriptions: [
          "Added versioning system for better tracking of deployments",
        ],
      },
      {
        type: "fix",
        tag: "Bug Fixes",
        descriptions: [
          "Fixed issue with video player playing in background during fast navigation",
        ],
      },
    ],
  },
];
