import { Injectable } from "@angular/core";
import * as uuid from "uuid";
import { WATCH_TIME_REQUEST_DTO } from "@shared/models/analytics/analytics";
import {
  ANALYTICS_GET_INDIVIDUAL_QUERIES_REQ,
  ANALYTICS_GET_QUERIES_REQ,
  ANALYTICS_DTO_GET_REQ,
  MARK_QUERY_REVIEWED_REQ,
  MARK_MULTIPLE_QUERIES_REVIEWED_REQ,
} from "@shared/models/analytics/analytics-requests";
import { TRACK_EVENT_DTO } from "@shared/models/analytics/events";
import { BASE_RESPONSE } from "@shared/models/api/api";
import { AnalyticsApi } from "src/app/api/analytics/analytics-api";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { UserService } from "../user/user.service";
import { ConfigService } from "../config/config.service";
import {
  ParsedIndividualQueryAnalytics,
  ParsedQueryAnalytics,
  ParsedViewAnalytics,
  TRACK_EVENT,
} from "src/app/models/analytics/analytics";
import {
  parseAggregatedQueriesData,
  parseIndividualQueriesData,
  parseViewAnalyticsData,
} from "src/app/utility/analytics-utils";
import { Events } from "../events/events.service";

export type ReviewedStatusType = "all" | "reviewed" | "notReviewed";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private m_AnalyticsApi: AnalyticsApi;
  private m_Interval?: NodeJS.Timeout;
  private m_MaxNumberOfEvents: number;
  private m_InProgress: boolean;
  public m_Events: TRACK_EVENT_DTO[];

  private m_CurrWatchSessionId: string | null = null;
  private m_CurrWatchTrackId?: string;

  get CurrWatchSessionId() {
    return this.m_CurrWatchSessionId;
  }

  get CurrWatchTrackId() {
    return this.m_CurrWatchTrackId;
  }

  constructor(
    eventsService: Events,
    userService: UserService,
    private m_ConfigService: ConfigService
  ) {
    this.m_AnalyticsApi = new AnalyticsApi(
      eventsService,
      userService,
      this.m_ConfigService.get("SERVER_URL")
    );
    this.m_Events = [];
    this.m_MaxNumberOfEvents = 0;
    this.m_InProgress = false;
  }

  initialize(interval = 10, maxNumberOfEvents = 10) {
    if (this.m_Interval != null) return;

    this.m_Events = [];
    this.m_MaxNumberOfEvents = maxNumberOfEvents;
    this.m_InProgress = false;
    this.m_Interval = setInterval(() => {
      this.update();
    }, interval * 1000);
  }

  trackEvent(eventName: TRACK_EVENT, metadata: any, force: boolean = false) {
    // Current time in UTC, in seconds
    let now = Math.floor(new Date().getTime() / 1000);

    let event: TRACK_EVENT_DTO = {
      event_name: eventName,
      timestamp: now,
      metadata: metadata,
    };

    this.m_Events.push(event);

    if (force || this.m_Events.length >= this.m_MaxNumberOfEvents) {
      this.update(force);
    }
  }

  trackClickEvent(buttonName: string, page: string) {
    this.trackEvent(TRACK_EVENT.CLICK, {
      button: buttonName,
      page: page,
    });
  }

  startWatchSession(trackId?: string) {
    this.m_CurrWatchSessionId = uuid.v4();
    this.m_CurrWatchTrackId = trackId;
  }

  endWatchSession() {
    this.m_CurrWatchSessionId = null;
  }

  async getAnalytics(
    startTime: number,
    endTime: number,
    interval: "hourly" | "daily" | "weekly" | "monthly",
    videoId?: string,
    abortSignal?: AbortSignal
  ): Promise<ParsedViewAnalytics> {
    let request: ANALYTICS_DTO_GET_REQ = {
      video_id: videoId,
      start_time: startTime,
      end_time: endTime,
      interval: interval,
    };

    let response = await this.m_AnalyticsApi.getAnalytics(request, abortSignal);

    if (response.status != 200) {
      throw ErrorFactory.error(response.status, "Failed to get analytics");
    }

    return await parseViewAnalyticsData(response.analytics);
  }

  async getSessionsAnalytics(
    videoId: string,
    pageNumber: number,
    pageSize: number,
    startTime: number,
    endTime: number
  ) {
    let request = {
      video_id: videoId,
      page_number: pageNumber,
      page_size: pageSize,
      start_time: startTime,
      end_time: endTime,
    };

    let response = await this.m_AnalyticsApi.getSessionsAnalytics(request);

    if (response.status != 200) {
      throw ErrorFactory.error(
        response.status,
        "Failed to get sessions analytics"
      );
    }

    return response;
  }

  async getAggregatedQueries(
    startTime: number,
    endTime: number,
    interval: "hourly" | "daily" | "weekly" | "monthly",
    videoId?: string,
    abortSignal?: AbortSignal
  ): Promise<ParsedQueryAnalytics> {
    let request: ANALYTICS_GET_QUERIES_REQ = {
      video_id: videoId,
      start_time: startTime,
      end_time: endTime,
      interval: interval,
    };

    let response = await this.m_AnalyticsApi.getAggregatedQueries(
      request,
      abortSignal
    );

    if (response.status != 200) {
      throw ErrorFactory.error(
        response.status,
        "Failed to get aggregated queries"
      );
    }

    return await parseAggregatedQueriesData(response.video_query_analytics);
  }

  async getIndividualQueries(
    startTime: number,
    endTime: number,
    videoId: string,
    pageNumber: number,
    pageSize: number,
    reviewedStatus?: ReviewedStatusType,
    response_types?: ("record" | "fallback" | "insight" | "none")[]
  ): Promise<ParsedIndividualQueryAnalytics> {
    let request: ANALYTICS_GET_INDIVIDUAL_QUERIES_REQ = {
      video_id: videoId,
      start_time: startTime,
      end_time: endTime,
      page_number: pageNumber,
      page_size: pageSize,
      response_types: response_types,
    };

    if (reviewedStatus == "reviewed") {
      request.reviewed = true;
    } else if (reviewedStatus == "notReviewed") {
      request.reviewed = false;
    }

    let response = await this.m_AnalyticsApi.getIndividualQueries(request);

    if (response.status != 200) {
      throw ErrorFactory.error(
        response.status,
        "Failed to get individual queries"
      );
    }

    return await parseIndividualQueriesData(response, videoId);
  }

  async setQueryReviewed(queryId: string): Promise<void> {
    let request: MARK_QUERY_REVIEWED_REQ = { queryId: queryId };

    let response = await this.m_AnalyticsApi.setQueryReviewed(request);

    if (response.status != 200) {
      throw ErrorFactory.error(
        response.status,
        "Failed to set query as reviewed"
      );
    }
  }

  async setMultipleQueriesReviewed(queryIds: string[]): Promise<void> {
    let request: MARK_MULTIPLE_QUERIES_REVIEWED_REQ = {
      queryIds: queryIds,
    };

    let response = await this.m_AnalyticsApi.setMultipleQueriesReviewed(
      request
    );

    if (response.status != 200) {
      throw ErrorFactory.error(
        response.status,
        "Failed to set multiple queries as reviewed"
      );
    }
  }

  async reportWatchTime(videoId: string, time: number): Promise<void> {
    let request: WATCH_TIME_REQUEST_DTO = { vid: videoId, cp: time };

    let response = await this.m_AnalyticsApi.reportWatchTime(request);

    if (response.status != 204) {
      throw ErrorFactory.error(response.status, "Failed to report watch time");
    }
  }
  //--------------------------------------------------------------------
  private update(force: boolean = false) {
    if (force || (this.m_InProgress == false && this.m_Events.length > 0)) {
      this.trackEvents();
    }
  }
  //--------------------------------------------------------------------
  /**
   * Send analytics events
   * @returns
   */
  public async trackEvents(): Promise<BASE_RESPONSE> {
    this.m_InProgress = true;

    let events = {
      events: [...this.m_Events],
    };
    this.m_Events.splice(0, events.events.length);
    let statusRes = await this.m_AnalyticsApi.trackEvents(events);

    this.m_InProgress = false;

    if (statusRes.status != 200) {
      // Add the events back to the queue
      this.m_Events = [...events.events, ...this.m_Events];
      throw ErrorFactory.error(
        statusRes.status,
        "Failed to send analytics events"
      );
    } else {
      return statusRes;
    }
  }
  //--------------------------------------------------------------------
  /**
   * Get Session details
   * @returns
   */
  async getSessionsDetails(sessionId: string): Promise<any> {
    let response = await this.m_AnalyticsApi.getSessionsDetails(sessionId);

    if (response.status != 200) {
      throw ErrorFactory.error(
        response.status,
        "Failed to get sessions analytics"
      );
    }

    return response;
  }
}
