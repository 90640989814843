import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { EVENTS } from "src/app/constants/events";
import { Events } from "src/app/services/events/events.service";
import { T } from "src/app/services/localization/localization.service";
import { FoldersService } from "src/app/services/folders/folders.service";
import { FOLDER_DTO } from "@shared/models/folders/folder";
import {
  TreeItemDropEvent,
  DropPosition,
  TreeItemLookup,
  DropAction,
} from "@progress/kendo-angular-treeview";
import {
  HIANotificationService,
  HIAToastOpts,
} from "src/app/services/notification/notification.service";
import { VIDEO_DTO } from "@shared/models/video/video";
import { FolderHierarchy } from "@shared/utils/folder-hierarchy";
import { SessionService } from "src/app/services/session/session.service";

@Component({
  selector: "app-folders-modal",
  templateUrl: "./folders-modal.component.html",
  styleUrls: ["./folders-modal.component.scss"],
  encapsulation: ViewEncapsulation.None, //Needed in order to override Kendo styles
})
export class FolderModalComponent implements AfterViewInit {
  @ViewChild("m_RootContainer", { read: ViewContainerRef, static: false })
  m_RootContainer: ViewContainerRef | undefined;

  public $t = T.translate;

  public m_IsOpen: boolean = false;
  public m_Loading: boolean = false;
  private m_ShowFoldersModal: Subscription;
  public m_VideoIds: string[] | null = [];
  public m_OldVideoIds: string[] = [];
  public m_Videos: VIDEO_DTO[] = [];
  public m_FolderId: string = "";
  public m_OldFolderId: string | undefined = "";
  public m_OldParentId: string | undefined = "";
  public m_SelectedVideos: number = 0;
  public m_SelectedFolder?: FOLDER_DTO | null = null;

  private m_FolderHierarchy?: FolderHierarchy | null;

  get IsOpen() {
    return this.m_IsOpen;
  }

  get UserId() {
    return this.m_AuthService.UserId;
  }

  get FolderHierarchyTree() {
    return this.m_FolderHierarchy?.getTree();
  }

  constructor(
    private m_Events: Events,
    private m_FoldersService: FoldersService,
    private m_AuthService: SessionService,
    private m_NotificationService: HIANotificationService
  ) {
    this.m_ShowFoldersModal = this.m_Events.subscribe(
      EVENTS.SHOW_FOLDERS_MODAL,
      async (msg) => {
        if (!this.m_IsOpen) {
          if (msg.video_ids) {
            this.m_Videos = msg.videos;
            this.m_VideoIds = msg.video_ids;
            if (this.m_VideoIds) this.m_SelectedVideos = this.m_VideoIds.length;
          }
          if (msg.created_at) {
            this.m_FolderId = msg.id;
            this.m_VideoIds = null;
            this.m_SelectedVideos = 0;
          }

          this.show();
        }
      }
    );
  }

  //#region Html Handlers
  ngAfterViewInit() {}

  ngOnDestroy() {
    this.m_ShowFoldersModal?.unsubscribe();
  }

  async show() {
    this.m_IsOpen = true;
    if (this.m_SelectedVideos < 1) this.m_SelectedVideos = 1;
    this.m_SelectedFolder = null;
    this.getAllFolders();
  }

  onCancelClicked() {
    this.m_IsOpen = false;
  }

  onWillPresent() {
    this.m_IsOpen = true;
  }

  onWillDismiss() {
    this.m_IsOpen = false;
    this.m_FolderId = "";
    this.m_VideoIds = null;
    this.m_SelectedVideos = 0;
  }

  onSelectedChange(event: any) {
    // Get an Index from Kendo TreeView like [0_1_2] and find it on folders list
    if (!this.m_FolderHierarchy) return;
    const path = event[0].split("_").map((index: any) => parseInt(index, 10));

    if (path.length == 1) {
      this.m_SelectedFolder = {
        id: "0",
        name: "Library",
        created_at: new Date(8.64e15),
        updated_at: new Date(8.64e15),
      };
    } else {
      let selectedFolder = this.m_FolderHierarchy.getRoot();

      if (!selectedFolder) return;

      for (let i = 1; i < path.length; i++) {
        selectedFolder = selectedFolder.items![path[i]];
      }

      this.m_SelectedFolder = selectedFolder?.folderData;
    }
  }

  async onCreateClicked() {
    this.m_Loading = true;

    try {
      // Check if we have folders with the default name and adds a number to it
      let newFolderName = this.m_FolderHierarchy?.getFolderNameForCreation();
      if (newFolderName == null) return;

      const data = await this.m_FoldersService.createFolders(
        this.UserId,
        newFolderName
      );
      if (data) {
        this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
        this.getAllFolders();
      }
    } finally {
      this.m_Loading = false;
    }
  }

  async onCreateSubfolderClicked() {
    if (!this.m_SelectedFolder) return;
    this.m_Loading = true;

    try {
      // Check if we have folders with the default name and adds a number to it
      let newFolderName = this.m_FolderHierarchy?.getFolderNameForCreation();
      if (newFolderName == null) return;

      const data = await this.m_FoldersService.createSubFolders(
        this.UserId,
        this.m_SelectedFolder.id,
        newFolderName
      );
      if (data) this.getAllFolders();
      this.m_SelectedFolder = null;
      this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
    } finally {
      this.m_Loading = false;
    }
  }

  async moveVideosClicked(showUndo?: boolean) {
    if (!this.m_SelectedFolder) return;
    this.m_Loading = true;
    if (this.m_VideoIds || this.m_OldVideoIds.length) {
      try {
        let data;
        if (!showUndo && this.m_VideoIds) {
          this.m_OldFolderId = this.m_Videos[0].folder_id;
          this.m_OldVideoIds = this.m_VideoIds;
          data = await this.m_FoldersService.moveVideosToFolder(
            this.m_VideoIds,
            this.m_SelectedFolder.id
          );
        } else {
          if (!this.m_OldFolderId) {
            this.m_OldFolderId = "0";
          }
          data = await this.m_FoldersService.moveVideosToFolder(
            this.m_OldVideoIds,
            this.m_OldFolderId
          );
          this.m_OldVideoIds = [];
          this.m_OldFolderId = undefined;
        }

        if (data) {
          this.m_Events.publish(EVENTS.FOLDERS_UPDATED);

          let toastOpts: HIAToastOpts = {
            message: this.$t("pages.library.move_sucess"),
            duration: 15000,
            position: "bottom",
            buttons: !showUndo
              ? [
                  {
                    text: "Undo",
                    handler: () => {
                      this.moveVideosClicked(true);
                    },
                  },
                ]
              : [],
          };

          this.m_NotificationService.showToast(toastOpts);
          this.onCancelClicked();
        } else {
          this.m_NotificationService.showError(
            this.$t("pages.library.move_error"),
            5000
          );
        }
      } finally {
        this.m_Loading = false;
      }
    } else if (this.m_FolderId || this.m_OldParentId) {
      this.m_Loading = true;
      let data;
      if (!showUndo) {
        this.m_OldFolderId = this.m_FolderId;
        this.m_OldParentId = this.m_FolderHierarchy?.findFolder(
          this.m_FolderId
        )?.folderData.parent_id;
        data = await this.m_FoldersService.moveFolders(
          this.m_SelectedFolder.id,
          this.m_FolderId
        );
      } else {
        if (!this.m_OldFolderId) {
          this.m_OldFolderId = "0";
        }
        if (!this.m_OldParentId) {
          this.m_OldParentId = "0";
        }
        data = await this.m_FoldersService.moveFolders(
          this.m_OldParentId,
          this.m_OldFolderId
        );
        this.m_OldParentId = undefined;
        this.m_OldFolderId = undefined;
      }
      try {
        if (data) {
          this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
          let toastOpts: HIAToastOpts = {
            message: this.$t("components.foldersModal.move_sucess"),
            duration: 15000,
            position: "bottom",
            buttons: !showUndo
              ? [
                  {
                    text: "Undo",
                    handler: () => {
                      this.moveVideosClicked(true);
                    },
                  },
                ]
              : [],
          };
          this.m_NotificationService.showToast(toastOpts);
          this.onCancelClicked();
        } else {
          this.m_NotificationService.showError(
            this.$t("components.foldersModal.move_error"),
            5000
          );
        }
      } finally {
        this.m_Loading = false;
      }
    }
  }

  async getAllFolders() {
    this.m_Loading = true;

    try {
      const data = await this.m_FoldersService.getAllFolders(this.UserId);
      if (data && data.folders) {
        this.m_FolderHierarchy = this.m_FoldersService.FolderHierarchy;
      }
    } finally {
      this.m_Loading = false;
    }
  }

  async onFolderMove(args?: any) {
    // Gets arguments from TreeView and send it to Folders API
    const destination = args.destinationItem.item.dataItem.folderData.id;

    const item = args.sourceItem.item.dataItem.folderData.id;

    if (!destination || !item) return;
    this.m_Loading = true;
    try {
      const data = await this.m_FoldersService.moveFolders(destination, item);
      if (data) {
        this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
      }
    } finally {
      this.m_Loading = false;
    }
  }
  //#endregion
  //#region Private Helpers
  isOfType(fileName: string, ext: string) {
    return new RegExp(`.${ext}\$`).test(fileName);
  }

  isFile(name: string) {
    return name.split(".").length > 1;
  }

  public getDragStatus(
    action: DropAction,
    destinationItem: TreeItemLookup
  ): string {
    if (
      destinationItem &&
      action === DropAction.Add &&
      this.isFile(destinationItem.item.dataItem.folderData.name)
    ) {
      return "k-i-cancel";
    }

    switch (action) {
      case DropAction.Add:
        return "k-i-plus";
      case DropAction.InsertTop:
        return "k-i-insert-top";
      case DropAction.InsertBottom:
        return "k-i-insert-bottom";
      case DropAction.InsertMiddle:
        return "k-i-insert-middle";
      case DropAction.Invalid:
      default:
        return "k-i-cancel";
    }
  }

  public handleDrop(event: TreeItemDropEvent): void {
    // prevent drop if attempting to add to file
    if (
      this.isFile(event.destinationItem.item.dataItem.folderData.name) &&
      event.dropPosition === DropPosition.Over
    ) {
      event.setValid(false);
    }
  }
  //#endregion

  //#region Render Helpers
  isFolderHierarchyValid() {
    return this.m_FolderHierarchy != null && !this.m_FolderHierarchy.isEmpty();
  }
  //#endregion
}
