import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";
import { FeedbackModalComponent } from "./feedback-modal.component";
import { AlertModalModule } from "../alert-modal/alert-modal.module";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AlertModalModule],
  declarations: [FeedbackModalComponent],
  exports: [FeedbackModalComponent],
})
export class FeedbackModalModule {}
