import { BASE_RESPONSE } from "../api/api";
import { PAGIONATION_DTO } from "../pagination";

export enum VIDEO_PUBLISH_STATE {
  PRIVATE = 0,
  PUBLIC = 1,
  UNLISTED = 2,
}

export enum VIDEO_STATUS {
  PENDING = 0,
  PROCESSING = 1,
  READY = 2,
  ERROR = 3,
  UPLOADED = 4,
  DUBBING = 5,
}

export enum VIDEO_DUBBING_STATUS {
  IN_PROGRESS = 0,
  COMPLETED = 1,
  FAILED = 2,
}

export interface PUBLIC_VIDEO_DTO {
  id: string;
  video_name: string; //Name of the video
  video_description?: string; //Description of the video
  video_url?: string; //URL of the video, different based on status
  user_id: string; //ID of the user who uploaded the video
  is_360_video: boolean; //Whether the video is a 360 video
  video_status: VIDEO_STATUS; //Status of the video
  video_publish_state?: VIDEO_PUBLISH_STATE; //Publish state of the video
  video_index_status: VIDEO_STATUS; //Status of the video in Azure Video Indexer
  create_date?: string; //Date the video was created
  video_thumb_url?: string; //URL of the video thumbnail
  duration?: number; //Duration of the video in seconds
  show_filters?: boolean; //Whether to show filters on the video
  can_ask_chatgpt: boolean; //Whether the video viewer can ask ChatGPT questions
  voice_id: string; //ID of the voice used to generate the video
  timeline_vtt_url?: string; //URL of the timeline VTT file
  current_position?: number; //Current playback position in seconds
  playback_speed?: number; //Playback speed of the video
  quality_preference?: string; //Preferred quality of the video
  volume_level?: number; //Volume level of the video
  source_language?: string; //Language of the video
  views?: number; //Number of views the video has
  total_questions?: number; //Total number of questions asked on the video
  can_multilang?: boolean; //Whether to enable multilanguage
}

export interface VIDEO_DTO extends PUBLIC_VIDEO_DTO {
  folder_id?: string; //ID of the folder containing the video
  video_asset_id?: string; //ID of the raw video asset in Azure Media Services
  video_encoded_asset_id?: string; //ID of the encoded video asset in Azure Media Services
  video_indexer_id?: string; //ID of the video in Azure Video Indexer
  video_asset_name?: string; //Name of the raw video asset in Azure Media Services
  video_encoded_asset_name?: string; //Name of the encoded video asset in Azure Media Services
  last_modified?: string; //Date the video was last modified
  publish_date?: string; //Date the video was published
  read_only?: boolean; //Whether the video is read only
}

export interface PAGINATED_VIDEOS_DTO extends PAGIONATION_DTO {
  videos: VIDEO_DTO[];
}

export interface VIDEO_SEARCH_DTO {
  id: string;
  user_id: string;
  video_name: string;
  video_description: string;
  video_thumb_url: string;
  duration: number;
  create_date: string;
  last_modified: string;
}

export interface VIDEO_DTO_CREATE_REQ {
  name: string;
  description?: string;
  voice_id?: string;
  folder_id?: string;
  is_360_video?: boolean;
}

export interface VIDEO_DTO_CREATE_RES extends BASE_RESPONSE {
  asset: VIDEO_DTO;
}

export interface VIDEO_DTO_REUPLOAD_RES extends BASE_RESPONSE {
  asset: VIDEO_DTO;
}

export interface VIDEO_DTO_COMPLETE_REQ {
  video_id: string;
}

export interface VIDEO_DTO_COMPLETE_RES extends BASE_RESPONSE {}

export interface VIDEO_DTO_PROGRESS_RES extends BASE_RESPONSE {
  progress: number;
}

export interface VIDEO_DTO_UPDATE_REQ {
  id: string;
  video_asset_id?: string;
  video_encoded_asset_id?: string;
  indexer_preset_id?: string;
  video_indexer_id?: string | null;
  video_asset_name?: string;
  video_encoded_asset_name?: string;
  video_user_id?: string;
  video_status?: VIDEO_STATUS;
  video_name?: string;
  video_description?: string;
  video_url?: string;
  duration?: number;
  create_date?: string;
  last_modified?: string;
  publish_date?: string;
  video_publish_state?: VIDEO_PUBLISH_STATE;
  video_index_status?: VIDEO_STATUS;
  video_thumb_url?: string;
  show_filters?: boolean;
  can_ask_chatgpt?: boolean;
  read_only?: boolean;
  voice_id?: string;
  folder_id?: string;
  is_360_video?: boolean;
}

export interface VIDEO_DTO_UPDATE_DETAILS_REQ {
  video_name: string;
  video_description: string;
  is_360_video: boolean;
}

export interface VIDEO_DTO_UPDATE_PUBLISH_STATE_REQ {
  visibility: VIDEO_PUBLISH_STATE;
}

export interface VIDEO_DTO_GET_REQ {
  video_id: string;
}

export interface VIDEO_DTO_GET_RES extends BASE_RESPONSE {
  video: VIDEO_DTO | PUBLIC_VIDEO_DTO;
}

export interface VIDEO_DTO_GET_STATUS_RES extends BASE_RESPONSE {
  video_status: VIDEO_STATUS;
  video_publish_state?: VIDEO_PUBLISH_STATE;
  video_index_status?: VIDEO_STATUS;
}

export interface VIDEO_DTO_GET_USER_REQ {
  video_user_id: string;
}

export interface VIDEO_DTO_GET_USER_RES extends BASE_RESPONSE {
  videos?: PAGINATED_VIDEOS_DTO;
}

export interface VIDEO_DTO_GET_PUBLIC_RES extends BASE_RESPONSE {
  videos?: VIDEO_DTO[];
}

export interface VIDEO_DTO_DELETE_RES extends BASE_RESPONSE {}

export interface VIDEO_DTO_CAPTIONS_RES extends BASE_RESPONSE {
  captions: string;
}

export interface VIDEO_DTO_UPDATE_CHATGPT_FLAG_REQ {
  can_ask_chatgpt: boolean;
}

export interface VIDEO_DTO_UPDATE_CHATGPT_FLAG_RES extends BASE_RESPONSE {}

export interface VIDEO_DTO_MOVE_REQ {
  videoIds: string[];
}

export interface VIDEO_DUB {
  language: string;
  status: VIDEO_DUBBING_STATUS;
}

export interface VIDEO_DUB_RES extends BASE_RESPONSE {
  dubs: VIDEO_DUB[];
}
