import {
  MEDIA_DTO,
  MEDIA_STATUS,
  MEDIA_TYPE,
} from "@shared/models/media/media";

export class Media {
  id: string;
  url: string;
  video_id: string;
  type: MEDIA_TYPE;
  status: MEDIA_STATUS;
  created_at?: Date;
  updated_at?: Date;
  thumbnail_url?: string;
  file_name?: string;

  constructor(mediaDTO: MEDIA_DTO) {
    this.id = mediaDTO.id;
    this.url = mediaDTO.url;
    this.type = mediaDTO.type;
    this.video_id = mediaDTO.video_id;
    this.status = mediaDTO.status;
    this.thumbnail_url = mediaDTO.thumbnail_url;
    this.file_name = mediaDTO.file_name;
    if (mediaDTO.created_at) this.created_at = new Date(mediaDTO.created_at);
    if (mediaDTO.updated_at) this.updated_at = new Date(mediaDTO.updated_at);
  }

  copy(media: Media) {
    this.id = media.id;
    this.url = media.url;
    this.type = media.type;
    this.video_id = media.video_id;
    this.status = media.status;
    this.thumbnail_url = media.thumbnail_url;
    this.file_name = media.file_name;
    this.created_at = media.created_at;
    this.updated_at = media.updated_at;
  }
}

export interface MEDIA_LIST {
  data: Media[];
  total: number;
}
