import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { Media } from "src/app/models/media/media";
import { ATTACHMENT_TYPE, RECORD } from "src/app/models/record/record";
import { T } from "src/app/services/localization/localization.service";
import { RecordComponent } from "../../record/record.component";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { FILTER_TYPE } from "src/app/components/media/media-gallery/media-gallery.component";
import {
  CustomAlertInput,
  AlertModalComponent,
} from "src/app/components/modals/alert-modal/alert-modal.component";
import { downloadBlob, isCSVFile } from "src/app/utility/utils";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { Subscription } from "rxjs";
import { UserService } from "src/app/services/user/user.service";
import { RECORD_DTO_UPDATE_REQ } from "@shared/models/record/record";
import { ConfigService } from "src/app/services/config/config.service";
import {
  ContextMenuComponent,
  ContextMenuOption,
} from "src/app/components/shared/context-menu/context-menu.component";
import {
  GENERATE_TYPES,
  GenerateQuestionsModalComponent,
} from "src/app/components/modals/generate-questions-modal/generate-questions-modal.component";
import { VideoService } from "src/app/services/video/video.service";
import { UtilityService } from "src/app/services/utility/utility.service";
import { validateLink } from "src/app/utility/utils";
import { RecordManagement } from "../../record-management";
import { RecordService } from "src/app/services/record/record.service";
import { AdminService } from "src/app/services/admin/admin.service";
import { StorageService } from "src/app/services/storage/storage.service";
import { Router } from "@angular/router";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";

export interface RecordEditorConfig {
  recordSimilarityThreshold: number;
  enableQNAGeneration: boolean;
  enableAutoSegmentGeneration: boolean;
}

@Component({
  selector: "app-record-editor",
  templateUrl: "./record-editor.component.html",
  styleUrls: ["./record-editor.component.scss"],
})
export class RecordEditorComponent implements OnInit {
  @Input()
  set Data(
    value: {
      videoId?: string;
      mediaId?: string;
      records?: RECORD[];
      readOnly?: boolean;
      getUnsaved?: boolean;
      disableVirtualScrolling?: boolean;
    } | null
  ) {
    if (value != null) {
      let oldVideoId = this.m_VideoId;
      this.m_VideoId = value.videoId ?? "";
      this.m_MediaId = value.mediaId;
      this.m_Readonly = value.readOnly ?? false;
      this.m_DisableVirtualScroling = value.disableVirtualScrolling ?? false;

      if (this.m_RecordManagement == null) {
        this.m_RecordManagement = new RecordManagement(
          this.m_RecordService,
          this.m_AdminService,
          this.m_Storage
        );
      }

      if (oldVideoId != this.m_VideoId)
        this.m_RecordManagement.initialize(this.m_VideoId, this.m_MediaId);

      if (value.records != null) {
        this.m_RecordManagement.setRecords(value.records, value.getUnsaved);
        this.m_Loading = false;
      } else if (value.videoId != null && value.videoId.length > 0) {
        this.fetchRecords(true, true);
      }
    }
  }

  @Input()
  set shouldSubscribe(value: boolean) {
    if (value) {
      this.subscribeToUpdate();
    } else {
      this.m_OnRecordUpdate?.unsubscribe();
    }
  }

  @Input()
  set ShowHeader(value: boolean) {
    this.m_ShowHeader = value;
  }

  @Input()
  set ShouldUpdate(value: boolean) {
    this.m_ShouldUpdate = value;
  }

  @Input()
  set ShowMaxRecords(value: boolean) {
    this.m_ShowMaxRecords = value;
  }

  @Input()
  set isAdmin(value: boolean) {
    this.m_IsAdmin = value;
  }

  get ShowMaxRecords() {
    return this.m_ShowMaxRecords;
  }

  get ShowHeader() {
    return this.m_ShowHeader;
  }

  get ShouldUpdate() {
    return this.m_ShouldUpdate;
  }

  get VideoId(): string {
    return this.m_VideoId;
  }

  get MediaId(): string | null {
    return this.m_MediaId ?? null;
  }

  get disableSaveAllButton() {
    if (this.m_RecordComponents == null) return true;
    return !this.m_RecordComponents.filter((r) => r.UnsavedChanges).length;
  }

  get UnsavedChanges(): boolean {
    if (this.m_RecordComponents == null) return false;

    let unsavedChanges = false;
    for (let component of this.m_RecordComponents) {
      if (component.UnsavedChanges) {
        unsavedChanges = true;
        break;
      }
    }

    return unsavedChanges;
  }

  get ReadOnly(): boolean {
    return this.m_Readonly;
  }

  get RecordsList(): RECORD[] | undefined {
    return this.m_RecordManagement?.Records;
  }

  public customButtoms = [
    /*
    {
      label: "Upgrade",
      callback: () => {
        console.log("Upgrade");
      },
    },
    */
    {
      label: "Close",
      close: true,
    },
  ];

  @ViewChild("m_RootContainer", { read: ViewContainerRef, static: false })
  m_RootContainer: ViewContainerRef | undefined;
  @ViewChild("m_CSVUploader") m_FileUploader: ElementRef | undefined;
  @ViewChildren(RecordComponent, { read: RecordComponent }) m_RecordComponents:
    | QueryList<RecordComponent>
    | undefined;
  @ViewChild("m_EditorAlertModal", { read: AlertModalComponent })
  m_AlertModal: AlertModalComponent | undefined;
  @ViewChild("m_GenerateQuestionsModal", {
    read: GenerateQuestionsModalComponent,
  })
  m_GenerateQuestionsModal: GenerateQuestionsModalComponent | undefined;
  @ViewChild("contextMenu", { read: ContextMenuComponent }) m_CtxMenu:
    | ContextMenuComponent
    | undefined;
  @ViewChild(CdkVirtualScrollViewport)
  m_VirtualSrolling: CdkVirtualScrollViewport | null = null;

  public $t = T.translate;
  public m_LoadingMsg: string = this.$t(
    "components.qnaEditor.loadingQuestions"
  );
  public m_MediaSelectId: string | null;
  public m_MediaFilter: FILTER_TYPE = FILTER_TYPE.IMAGES;
  public m_ShowReplace: boolean = false;
  public m_IsAdmin: boolean = false;
  public m_Search: string = "";
  public m_Replace: string = "";

  private m_OpenGallery: boolean = false;
  private m_VideoId: string = "";
  private m_MediaId?: string;
  private m_Loading: boolean = true;
  private m_LoadingRecords: boolean = false;
  private m_ShowHeader: boolean = true;
  private m_ShouldUpdate: boolean = true;
  private m_ShowMaxRecords: boolean = true;
  private m_Readonly: boolean = false;
  private m_IsModalOpen: boolean = false;
  private m_Generating: boolean = false;
  private m_ContextOptions: ContextMenuOption[] = [];
  private m_RecordConfig: RecordEditorConfig | null = null;
  private m_RecordManagement?: RecordManagement;
  private m_PageNumber: number = 0;
  private m_PageSize: number = 16;
  private m_SearchTimeout: any;
  private m_DisableVirtualScroling: boolean = false;

  private m_OnRecordUpdate: Subscription | null = null;
  private m_OnModalClose: Subscription | null = null;
  private m_OnRecordRefreshQuestions: Subscription | null = null;
  private m_OnRecordGenerated: Subscription | null = null;
  private m_OnRecordDelete: Subscription | null = null;
  private m_OnRecordUpdateId: Subscription | null = null;
  private m_OnRecordAddAttachmentClicked: Subscription | null = null;
  private m_OnRecordSetAttachment: Subscription | null = null;
  private m_OnRecordRemoveAttachment: Subscription | null = null;

  constructor(
    private m_NotificationService: HIANotificationService,
    private m_UserService: UserService,
    private m_Events: Events,
    private m_ConfigService: ConfigService,
    private m_VideoService: VideoService,
    private m_UtilityService: UtilityService,
    private m_RecordService: RecordService,
    private m_AdminService: AdminService,
    private m_Storage: StorageService,
    private m_Router: Router
  ) {
    this.m_OnModalClose = this.m_Events.subscribe(
      EVENTS.RECORD_DUPLICATED_CONFIRMED,
      async (data) => {
        this.m_IsModalOpen = false;
        if (!data.cancel) {
          for (const Ids of data.ids) {
            this.refreshQuestions(Ids.toUpperCase());
          }
          let highScoreRecords = await this.getHighScoreRecords(data.ids);
          if (highScoreRecords != null && highScoreRecords.length > 0) {
            if (!this.m_IsModalOpen) {
              this.m_IsModalOpen = true;
              await this.showDuplicateModal(highScoreRecords);
            }
          }
        }
      }
    );
    this.m_OnRecordRefreshQuestions = this.m_Events.subscribe(
      EVENTS.RECORD_REFRESH_QUESTIONS,
      (data) => {
        this.refreshQuestions(data.id);
      }
    );
    this.m_OnRecordDelete = this.m_Events.subscribe(
      EVENTS.RECORD_DELETE,
      (data) => {
        this.onDeleteRecord(data.id);
      }
    );
    this.m_OnRecordUpdateId = this.m_Events.subscribe(
      EVENTS.RECORD_UPDATE_ID,
      (data) => {
        this.onUpdateId(data.oldId, data.newId);
      }
    );
    this.m_OnRecordAddAttachmentClicked = this.m_Events.subscribe(
      EVENTS.RECORD_ADD_ATTACHMENT_CLICKED,
      (data) => {
        this.onAddAttachmentClicked(data.id, data.type);
      }
    );
    this.m_OnRecordSetAttachment = this.m_Events.subscribe(
      EVENTS.RECORD_SET_ATTACHMENT,
      (data) => {
        this.onSetAttachment(data.id, data.attachment);
      }
    );
    this.m_OnRecordRemoveAttachment = this.m_Events.subscribe(
      EVENTS.RECORD_REMOVE_ATTACHMENT,
      (data) => {
        this.onRemoveAttachment(data.id);
      }
    );

    this.m_MediaSelectId = null;

    this.m_ConfigService.get("RECORD_CONFIG").then((config) => {
      let recordConfig: RecordEditorConfig = JSON.parse(config);
      if (recordConfig == null) return;
      this.m_RecordConfig = recordConfig;
    });
  }

  ngOnInit() {
    this.initContextMenu();
  }

  ngAfterViewInit() {
    this.m_VirtualSrolling?.elementScrolled().subscribe((event: any) => {
      this.onScroll(event);
    });
  }

  onScroll(event: Event) {
    if (!this.m_VirtualSrolling) return;
    const end = this.m_VirtualSrolling?.measureScrollOffset("bottom") <= 200;

    if (end) {
      this.loadMoreRecords();
    }
  }

  ngOnDestroy() {
    this.m_OnRecordRefreshQuestions?.unsubscribe();
    this.m_OnRecordDelete?.unsubscribe();
    this.m_OnRecordUpdateId?.unsubscribe();
    this.m_OnRecordAddAttachmentClicked?.unsubscribe();
    this.m_OnRecordSetAttachment?.unsubscribe();
    this.m_OnRecordRemoveAttachment?.unsubscribe();
    this.m_OnModalClose?.unsubscribe();
    this.m_OnRecordUpdate?.unsubscribe();
    this.m_OnRecordGenerated?.unsubscribe();
  }

  subscribeToUpdate() {
    this.m_OnRecordUpdate = this.m_Events.subscribe(
      EVENTS.RECORD_UPDATED,
      async (data) => {
        if (!this.m_IsModalOpen) {
          let highScoreRecords = await this.getHighScoreRecords([data.id]);
          if (highScoreRecords != null && highScoreRecords.length > 0) {
            if (!this.m_IsModalOpen) {
              this.m_IsModalOpen = true;
              await this.showDuplicateModal(highScoreRecords);
            }
          }
        }
      }
    );

    this.m_OnRecordGenerated = this.m_Events.subscribe(
      EVENTS.RECORD_GENERATED_CONFIRMED,
      (data) => {
        this.addGeneratedQuestions(data.records);
      }
    );
  }

  hasErrorSaving() {
    if (!this.m_RecordComponents) return false;
    return this.m_RecordComponents.find((r) => r.shouldShowSaveBtn());
  }

  async addGeneratedQuestions(records: RECORD[]) {
    this.m_Loading = true;
    this.m_LoadingMsg = this.$t("components.qnaEditor.generatingQnA");
    this.m_RecordManagement?.addGeneratedQuestions(records);
    setTimeout(async () => {
      if (!this.m_RecordComponents) return;
      for (let record of records) {
        const recordComp = this.m_RecordComponents.find(
          (r) => r.Record?.record_id === record.record_id
        );
        await recordComp?.onSaveClicked();
      }
      this.m_Loading = false;
    }, 1000);
  }

  addQuestions(questions: string[], queue: boolean = false) {
    let shouldQueue = queue || this.m_Loading;
    this.m_RecordManagement?.addQuestions(questions, shouldQueue);
  }

  //#region HTML Event Handlers
  searchRecord(timeout: number = 1000) {
    if (this.m_SearchTimeout) clearTimeout(this.m_SearchTimeout);

    this.m_SearchTimeout = setTimeout(() => {
      if (this.m_Search) {
        this.m_Loading = true;
        this.m_LoadingMsg = "Searching records...";
        return this.m_RecordManagement?.searchRecord(this.m_Search).then(() => {
          this.m_Loading = false;
        });
      }

      //Reset settings
      this.m_PageNumber = 0;
      this.m_RecordManagement?.clearRecords();

      return this.fetchRecords(true);
    }, timeout);
  }

  onClearSearch() {
    this.m_Search = "";
    this.searchRecord(1);
  }

  onImportClicked() {
    this.m_FileUploader?.nativeElement.click();
  }

  onGalleryClicked() {
    this.m_OpenGallery = !this.m_OpenGallery;

    if (!this.m_OpenGallery) {
      this.m_MediaSelectId = null;
    }
  }

  async loadMoreRecords() {
    if (!this.m_RecordManagement || this.m_LoadingRecords) return;

    //Fully loaded
    if (this.m_PageNumber + 1 >= this.m_RecordManagement?.TotalPages) return;

    //Fetch records paginated
    try {
      this.m_LoadingRecords = true;
      this.m_PageNumber++;
      await this.fetchRecords(false);
    } finally {
      setTimeout(() => {
        this.m_LoadingRecords = false;
      }, 500);
    }
  }

  async onExportClicked() {
    try {
      this.m_Loading = true;
      this.m_LoadingMsg = this.$t("components.qnaEditor.exportCSV");
      let blob = await this.m_RecordManagement?.exportCSV();
      let filename = `${this.m_VideoId}.csv`;
      downloadBlob(blob!, filename);
      this.m_NotificationService.showSuccess(
        this.$t("components.qnaEditor.exportCSVSuccess"),
        5000
      );
    } catch (error) {
      this.m_NotificationService.showError(
        this.$t("components.qnaEditor.exportCSVFailed"),
        5000
      );
    }
    this.m_Loading = false;
  }

  async onFileSelected($event: any) {
    if ($event.target.files.length == 0) return;

    let file = $event.target.files[0];
    //Verify file is csv
    if (!isCSVFile(file) && file.type != "application/json") {
      this.m_NotificationService.showError(
        this.$t("shared.messages.invalidFileType"),
        5000
      );
      this.clearInput();
      return;
    }
    //Verify user limits
    let maxRecords = this.m_UserService.ActiveUserLimits?.maxRecords ?? 0;
    let currentRecords =
      this.m_UserService.ActiveUserLimits?.currentRecordNumber ?? 0;

    if (maxRecords >= 0 && currentRecords >= maxRecords) {
      this.m_NotificationService.showError(
        this.$t("shared.messages.qnaLimitsReached"),
        5000
      );
      this.clearInput();
      return;
    }

    if (isCSVFile(file)) {
      await this.importCSV(file);
    } else if (file.type == "application/json") {
      await this.importJSON(file);
    }

    this.clearInput();
  }

  refreshQuestions(id: string) {
    this.m_MediaSelectId = null;

    if (this.m_RecordComponents != null) {
      let recordComp = this.m_RecordComponents.find(
        (recordComp) => recordComp.Record?.record_id == id
      );

      if (recordComp != null) recordComp.UnsavedChanges = false;
    }

    this.fetchRecords(false);
  }

  getContextMenuOpts() {
    return this.m_ContextOptions;
  }

  initContextMenu() {
    this.m_ContextOptions = [
      {
        text: "Generate questions from transcript",
        action: () => this.generateQuestions("transcript"),
      },
      {
        text: "Generate questions from prompt",
        action: () => this.generateQuestions("prompt"),
      },
    ];
  }

  async generateQuestions(type: GENERATE_TYPES) {
    if (!this.m_GenerateQuestionsModal) return;

    if (type === "prompt")
      this.m_GenerateQuestionsModal.openModal(this.m_VideoId, this.m_MediaId);
    else
      this.m_GenerateQuestionsModal.openModal(
        this.m_VideoId,
        this.m_MediaId,
        true
      );
  }

  async onAddClicked() {
    let maxRecords = this.m_UserService.ActiveUserLimits?.maxRecords ?? 0;
    let currentRecords =
      this.m_UserService.ActiveUserLimits?.currentRecordNumber ?? 0;

    if (maxRecords >= 0 && currentRecords >= maxRecords) {
      await this.showAlertModal(
        [this.$t("shared.messages.maxRecordsExceeded")],
        false,
        true,
        "",
        this.customButtoms
      );
    } else {
      let newRecord = this.m_RecordManagement?.addEmptyRecord();
      this.scrollToTop();

      setTimeout(() => {
        if (newRecord) {
          let newRecComp = this.m_RecordComponents?.find(
            (r) => r.Record?.record_id === newRecord?.record_id
          );
          newRecComp?.focusQuestion();
        }
      }, 100);
    }
  }

  onDeleteRecord(id: string) {
    this.m_RecordManagement?.deleteRecord(id);
  }

  async onDeleteAllClicked() {
    await this.showAlertModal(
      [
        this.$t("shared.messages.deleteAllRecords"),
        this.$t("shared.messages.deleteAllRecords_description"),
      ],
      true,
      true,
      "delete-modal",
      [
        {
          label: this.$t("shared.messages.deleteAllRecords").replace("?", ""),
          close: true,
          color: "#dd2222cc",
          callback: async () => {
            if (this.m_RecordManagement?.Records == null) return;
            this.m_Loading = true;
            this.m_LoadingMsg = this.$t(
              "components.qnaEditor.deletingQuestions"
            );

            try {
              await this.m_RecordManagement.deleteAllRecords();
            } catch (error) {
              this.m_NotificationService.showError(
                this.$t("components.qnaEditor.failedToDeleteQuestions"),
                5000
              );
            }

            this.m_Loading = false;
          },
        },
      ]
    );
  }

  async onRevertClicked() {
    if (this.m_RecordComponents) {
      await Promise.all(
        this.m_RecordComponents.map((recordComponent) =>
          recordComponent.deleteCachedRecordChanges()
        )
      );
    }
    await this.m_RecordManagement?.cleanUnsavedRecords();
    this.m_RecordManagement?.clearRecords();
    this.fetchRecords();
  }

  async onSaveAllClicked() {
    if (!this.m_RecordComponents || !this.m_RecordManagement?.Records) return;

    let recordsToSave: RECORD_DTO_UPDATE_REQ[] = [];

    let unsavedRecords = this.m_RecordComponents.filter(
      (r) => r.UnsavedChanges
    );

    // Saving all status
    unsavedRecords.forEach((record) => {
      record.m_Saving = true;
    });

    let newRecords: RECORD[] = [];
    for (const recordComp of unsavedRecords) {
      if (!recordComp.Record) return;
      if (
        recordComp.Record.record_id === "" ||
        recordComp.Record.record_id.startsWith("unsaved")
      ) {
        // Create new record
        let newRecordRes = await this.m_RecordManagement.createRecord(
          recordComp.Record
        );
        this.m_UserService.updateUserQnALimits(1);
        this.m_Events.publish(EVENTS.UPDATE_LIMITS);

        let is_suggestible = recordComp.Record.is_suggestible;
        let is_pinned = recordComp.Record.is_pinned;
        recordComp.Record = new RECORD(newRecordRes.record);
        recordComp.Record.is_suggestible = is_suggestible;
        recordComp.Record.is_pinned = is_pinned;
        newRecords.push(recordComp.Record);
      }

      // Include on list to update records
      recordsToSave.push({
        record_id: recordComp.Record.record_id,
        question: recordComp.m_Question,
        answer: recordComp.m_Answer,
        media_id: recordComp.m_MediaAttachment?.id ?? null,
        web_url: recordComp.Record.web_url ?? null,
        start_time: recordComp.Record.video_start_time ?? null,
        end_time: recordComp.Record.video_end_time ?? null,
        is_pinned: recordComp.Record.is_pinned,
        is_suggestible: recordComp.Record.is_suggestible,
      });
    }

    if (recordsToSave.length) {
      // Update list of records
      let success = await this.m_RecordManagement.saveAll(
        recordsToSave,
        this.m_RecordConfig?.recordSimilarityThreshold ?? 0.75
      );
      if (success) {
        await this.m_RecordManagement.cleanUnsavedRecords();
        //Reverse newRecords to keep the order of the records
        newRecords = newRecords.reverse();
        this.m_RecordManagement.addRecords(newRecords, false);
        for (const record of unsavedRecords) {
          record.clearStatesValues();
        }
      } else {
        this.m_NotificationService.showError(
          this.$t("shared.messages.failedToSaveQuestions"),
          5000
        );
      }
    }

    // Set records UnsavedChanges to false after saving
    unsavedRecords.forEach(async (r) => {
      r.UnsavedChanges = false;
      r.m_Saving = false;
    });

    this.m_RecordManagement.removeUnsaved();
  }

  get searchList() {
    return this.m_RecordManagement?.Records.slice();
  }

  isRecordListEmpty(): boolean {
    return this.m_RecordManagement?.Records?.length === 0;
  }

  async getHighScoreRecords(ids: string[]): Promise<Array<any> | undefined> {
    return this.m_RecordManagement?.compareRecords(
      ids,
      this.m_RecordConfig?.recordSimilarityThreshold ?? 0.75
    );
  }

  showDuplicateModal(highScoreRecords: any[]) {
    let recordIds = highScoreRecords.map((record) => record.recordId);
    let closestMatchIds = highScoreRecords.map(
      (record) => record.closestMatchId
    );

    let recordsIds = recordIds.concat(closestMatchIds);

    this.m_Events.publish(EVENTS.RECORD_DUPLICATED, {
      RecordsIds: recordsIds,
      Records: this.m_RecordManagement?.Records,
    });
    this.m_IsModalOpen = true;
  }

  /**
   * Asynchronously replaces text within the record components.
   *
   * This function iterates over the record components and updates the inner HTML
   * of the question and answer containers by replacing marked text with a specified replacement.
   * It also updates the corresponding records in the record management system and marks them as unsaved.
   */
  async replaceText() {
    // Check if searchList is not null or undefined and if there are record components to process
    if (!this.searchList || this.m_RecordComponents == null) return;

    /**
     * Asynchronously sets new text in the specified container.
     *
     * @param container - The container element whose inner HTML will be updated.
     * @param recordComp - The record component whose record will be updated.
     * @param isAnswer - A boolean indicating whether the container is for an answer (default is false).
     */
    const setNewText = async (
      container: ElementRef,
      recordComp: RecordComponent,
      isAnswer = false
    ) => {
      // Check if there are records in the record management system
      if (this.m_RecordManagement?.Records) {
        // Find the corresponding record by matching the record ID
        const record = this.m_RecordManagement.Records.find(
          (record) => recordComp.Record?.record_id === record.record_id
        );

        // If a matching record is found, update its question or answer and mark it as unsaved
        if (record) {
          // Replace marked text with the specified replacement in the container's inner HTML
          container.nativeElement.innerHTML =
            container.nativeElement.innerHTML.replaceAll(
              /<mark>(.*?)<\/mark>/g,
              this.m_Replace
            );

          if (!isAnswer) {
            record.question = container.nativeElement.innerHTML;
            recordComp.m_Question = container.nativeElement.innerHTML;
            if (recordComp.Record) {
              recordComp.Record.question = container.nativeElement.innerHTML;
            }
          } else {
            record.answer = container.nativeElement.innerHTML;
            recordComp.m_Answer = container.nativeElement.innerHTML;
            if (recordComp.Record) {
              recordComp.Record.answer = container.nativeElement.innerHTML;
            }
          }
          record.unsaved = true;
        }
      }
    };

    // Use Promise.all to ensure all asynchronous operations complete before proceeding
    await Promise.all(
      this.m_RecordComponents.map(async (r) => {
        // Update the question container if it exists
        if (r.m_QuestionContainer && this.m_RecordManagement?.Records)
          await setNewText(r.m_QuestionContainer, r);

        // Update the answer container if it exists
        if (r.m_AnswerContainer && this.m_RecordManagement?.Records)
          await setNewText(r.m_AnswerContainer, r, true);
      })
    );

    // Save the updated records via AutoSave
    this.onSaveAllClicked();
  }

  toggleExpandReplace() {
    this.m_ShowReplace = !this.m_ShowReplace;
  }

  onUpdateId(oldId: string, newId: string) {
    if (this.m_RecordManagement?.Records == null) return;

    let record = this.m_RecordManagement?.findRecord(oldId);
    if (record != null) {
      record.record_id = newId;
    }
  }

  /**
   * Fired when the user selects one of the add attachment context options. Looks as the
   * type of attachment the user wants to add and either opens the gallery or prompts the user for a web url
   * @param id The record ID to add the attachment to
   * @param type The requested attachment type
   * @returns
   */
  async onAddAttachmentClicked(id: string, type: ATTACHMENT_TYPE) {
    if (this.m_RecordManagement?.Records == null) return;
    let record = this.m_RecordManagement?.findRecord(id);
    if (record == null) return;

    if (type == ATTACHMENT_TYPE.IMAGE) this.m_MediaFilter = FILTER_TYPE.IMAGES;
    else if (type == ATTACHMENT_TYPE.VIDEO)
      this.m_MediaFilter = FILTER_TYPE.VIDEOS;

    this.m_MediaSelectId = id;

    if (type == ATTACHMENT_TYPE.WEBURL) {
      if (this.m_UserService?.ActiveUserLimits?.enableURLEmbedding) {
        //Get web url from user via alert modal
        let url = await this.getWebUrlFromUser(record.web_url ?? null);
        if (url != "") {
          //Set web url on record component
          let recordComp = this.m_RecordComponents?.find(
            (recordComp) => recordComp.Record?.record_id == id
          );
          if (recordComp != null) {
            recordComp?.setWebUrlAttachment(url);
          }
        }
      } else {
        this.showTrialAlert();
      }
    } else if (type == ATTACHMENT_TYPE.VIDEO_SEGMENT) {
      let recordComp = this.m_RecordComponents?.find(
        (recordComp) => recordComp.Record?.record_id == id
      );
      if (recordComp != null) {
        recordComp?.openSegmentEditor();
      }
    } else {
      this.m_OpenGallery = true;
    }
  }

  /**
   * Event listener for when the user sets a media attachment on a record component.
   * @param id
   * @param attachment
   * @returns
   */
  onSetAttachment(id: string, attachment: Media) {
    if (this.m_RecordComponents == null) return;

    let recordComp = this.m_RecordComponents.find(
      (recordComp) => recordComp.Record?.record_id == id
    );

    if (recordComp != null) {
      recordComp.setMediaAttachment(attachment, false);
      if (recordComp.Record?.record_id != null) {
        if (this.m_RecordManagement?.Records) {
          let matchingRecord = this.m_RecordManagement?.findRecord(
            recordComp?.Record?.record_id
          );

          if (matchingRecord != null) {
            matchingRecord.media_id = attachment.id;
            matchingRecord.video_start_time = undefined;
            matchingRecord.video_end_time = undefined;
          }
        }
      }
    }
  }

  /**
   * Fired when the user removes an attachment from a record component,
   * updates any other instances of the record since the editor is a shared component
   * @param id
   * @returns
   */
  onRemoveAttachment(id: string) {
    if (this.m_RecordComponents == null) return;

    let recordComp = this.m_RecordComponents.find(
      (recordComp) => recordComp.Record?.record_id == id
    );

    if (recordComp != null) {
      recordComp.removeAttachment(false);
    }
  }

  async handleGalleryEvt(selection: Media | null) {
    if (
      selection == null ||
      this.m_MediaSelectId == null ||
      this.m_RecordManagement?.Records == null ||
      this.m_RecordComponents == null
    )
      return;

    let recordComp = this.m_RecordComponents.find(
      (recordComp) => recordComp.Record?.record_id == this.m_MediaSelectId
    );

    if (recordComp != null) {
      recordComp?.setMediaAttachment(selection);
      if (recordComp.Record?.record_id != null) {
        let matchingRecord = this.m_RecordManagement?.findRecord(
          recordComp?.Record?.record_id
        );

        if (matchingRecord != null) {
          matchingRecord.media_id = selection.id;
        }
        this.m_MediaSelectId = null;
      }
    }
  }

  async handleGalleryDeleteEvt(selection: Media | null) {
    if (selection?.id != null) {
      this.m_RecordComponents?.forEach((component) => {
        if (component.m_MediaAttachment?.id == selection?.id) {
          component.removeAttachment();
        }
      });
    }
  }

  onOpenContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.m_CtxMenu?.open(event);
  }

  onRecordError(index: number, error: string) {
    this.m_NotificationService.showError(error, 5000);
  }
  //#endregion
  //#region Render Helpers
  shouldDisableReplace() {
    return this.m_Search == "" || this.m_Replace == "";
  }

  shouldRenderEmpty() {
    return (
      !this.shouldRenderError() && this.m_RecordManagement?.Records?.length == 0
    );
  }

  shouldRenderError() {
    return this.m_RecordManagement?.Records == null;
  }

  shouldRenderRecords() {
    return !this.shouldRenderEmpty() && !this.shouldRenderError();
  }

  shouldDisplayLoading() {
    return this.m_VideoId == "" || this.m_Loading;
  }

  shouldDisplayGenerateLoading() {
    return this.m_Generating;
  }

  shouldRenderGallery() {
    return this.m_OpenGallery;
  }

  shouldUseVirtualScrolling() {
    return !this.m_DisableVirtualScroling;
  }

  shouldDisplayLoadingMore() {
    return this.m_LoadingRecords;
  }

  getHeaderTooltip() {
    if (this.m_Readonly) {
      return this.$t("shared.messages.readOnly");
    }

    return "";
  }

  getTotalRecords() {
    return this.m_RecordManagement?.TotalRecords ?? 0;
  }

  shouldDisplayGenerateButton() {
    return (
      (this.m_RecordConfig?.enableQNAGeneration ?? false) &&
      this.m_UserService.ActiveUserLimits?.enableGenerateQnA
    );
  }
  //#endregion
  //#region Private Methods
  private async fetchRecords(
    showLoading: boolean = true,
    resetPagination: boolean = false
  ) {
    if (!this.m_ShouldUpdate) return;

    this.m_Loading = showLoading;
    this.m_LoadingMsg = this.$t("components.qnaEditor.loadingQuestions");

    if (resetPagination) {
      this.m_PageNumber = 0;
      this.m_RecordManagement?.clearRecords();
      this.scrollToTop();
    }

    let updateResults = await this.m_RecordManagement?.fetchRecords(
      this.m_IsAdmin,
      this.m_PageNumber,
      this.m_PageSize,
      this.m_Search
    );

    this.m_Loading = false;

    if (updateResults != null) {
      updateResults.recordsUpdated.forEach(async (record) => {
        let component = this.m_RecordComponents?.find(
          (c) => c.Record?.record_id == record.record_id
        );

        if (component) {
          await component.updateRecord(record);
        }
      });

      if (updateResults.restored) {
        this.m_NotificationService.showWarning(
          this.$t("shared.messages.restoredChanges"),
          5000
        );
      }

      if (updateResults.queueScroll) {
        this.m_VirtualSrolling?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }

  private scrollToTop() {
    setTimeout(() => {
      this.m_VirtualSrolling?.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }

  private async importCSV(file: File) {
    this.m_Loading = true;
    this.m_LoadingMsg = this.$t("components.qnaEditor.importCSV");
    try {
      const recordsImport = await this.m_RecordManagement?.importCSV(file);
      //TODO appent result.records to m_Records rather refetching?
      if (recordsImport) {
        this.m_UserService.updateUserQnALimits(recordsImport.records.length);
        this.m_Events.publish(EVENTS.UPDATE_LIMITS);
      }
      this.fetchRecords();
    } catch (e: any) {
      this.m_Loading = false;

      if (e?.status == 452) {
        await this.showAlertModal(
          [this.$t("shared.messages.maxRecordsExceeded")],
          false,
          true,
          "",
          this.customButtoms
        );
      } else if (e.message?.includes("reached the limit")) {
        this.m_NotificationService.showError(
          this.$t("shared.messages.qnaLimitsReached"),
          5000
        );
      } else {
        this.m_NotificationService.showError(
          this.$t("shared.messages.failedToImportCSV"),
          5000
        );
      }
    }
  }

  private async importJSON(file: File) {
    this.m_Loading = true;
    this.m_LoadingMsg = this.$t("components.qnaEditor.importJSON");
    try {
      const recordsImport = await this.m_RecordManagement?.importJSON(file);
      //TODO appent result.records to m_Records rather refetching?
      this.fetchRecords();
      if (recordsImport) {
        this.m_UserService.updateUserQnALimits(recordsImport.records.length);
        this.m_Events.publish(EVENTS.UPDATE_LIMITS);
      }
    } catch (error) {
      this.m_Loading = false;
      this.m_NotificationService.showError(
        this.$t("shared.messages.failedToImportJSON"),
        5000
      );
    }
  }

  private clearInput() {
    if (
      this.m_FileUploader != null &&
      this.m_FileUploader.nativeElement != null
    )
      this.m_FileUploader.nativeElement.value = "";
  }

  async testMaliciousURL(url?: string) {
    if (!url) return;
    if (!this.m_AlertModal) return;
    this.m_AlertModal.m_Error = false;
    this.m_AlertModal.m_Loading = true;
    let validLink = validateLink(url);
    let isThreatLink = await this.m_UtilityService.validateUrlMalicious(url);
    this.m_AlertModal.m_Loading = false;

    if (!validLink) {
      this.m_AlertModal.showNotification(
        this.$t("shared.messages.invalidWebUrl")
      );
      this.m_AlertModal.m_Error = true;
      this.m_AlertModal.setButtonDisableStatus(true);
      return Promise.resolve();
    }
    if (isThreatLink) {
      this.m_AlertModal.showNotification(
        this.$t("shared.messages.threatWebUrl")
      );
      this.m_AlertModal.setButtonDisableStatus(true);
      this.m_AlertModal.m_Error = true;
      return Promise.resolve();
    } else {
      this.m_AlertModal.m_Error = false;
      this.m_AlertModal.setButtonDisableStatus(false);
      return Promise.resolve();
    }
  }

  private async getWebUrlFromUser(existingURL: string | null) {
    let webURL = existingURL ?? "";
    let input: CustomAlertInput[] = [
      {
        label: "Enter a valid Web URL",
        type: "text",
        value: existingURL ?? "",
        onChange: (value: string) => {
          webURL = value;
          if (this.m_AlertModal)
            this.m_AlertModal.setButtonDisableStatus(false);
        },
      },
    ];

    let result = await this.showAlertModal(
      ["Enter a valid Web URL"],
      true,
      false,
      "",
      null,
      input,
      undefined,
      () => {},
      async (value?: string) => {
        await this.testMaliciousURL(value);
      }
    );

    if (result.ok) {
      return webURL;
    } else {
      return "";
    }
  }

  private async showTrialAlert() {
    this.showAlertModal(
      [this.$t("shared.messages.notAvailableOnTrial")],
      true,
      false,
      "",
      [
        {
          label: "Upgrade",
          close: true,
          callback: async () => {
            setTimeout(() => {
              this.m_Router.navigate(["/pricing"]);
            }, 50);
          },
        },
      ],
      null
    );
  }

  private async showAlertModal(
    message: string[],
    allowCancel?: boolean,
    showIcon?: boolean,
    customClass?: string,
    buttons?: any[] | null,
    inputs?: CustomAlertInput[] | null,
    okFunction?: (value?: string) => Promise<void>,
    cancelFunction?: () => void,
    inputChangeFunction?: (value: string) => void
  ) {
    if (this.m_AlertModal == null) return Promise.resolve({ ok: false });

    this.m_AlertModal.m_MainText = message[0] ?? "";
    this.m_AlertModal.m_Description = message[1] ?? "";
    this.m_AlertModal.m_AllowCancel = allowCancel ?? true;
    this.m_AlertModal.m_CustomButtons = buttons;
    this.m_AlertModal.m_CustomInputs = inputs;
    this.m_AlertModal.m_ShowIcon = showIcon || false;
    this.m_AlertModal.m_CustomClass = customClass || "";

    return await this.m_AlertModal?.show(
      okFunction ?? undefined,
      cancelFunction ?? (() => {}),
      inputChangeFunction ?? ((value: string) => {})
    );
  }

  //#endregion
}
