// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafbfc;
  color: #3d6573;
  padding: 0.5rem;
  font-size: 10px;
  box-shadow: none;
}
.app-footer .link {
  text-decoration: underline;
  color: var(--hia-primary-color);
  font-weight: 700;
  margin-left: 0.1rem;
  cursor: pointer;
}
.app-footer .changelog {
  float: right;
  position: absolute;
  right: 1rem;
  font-size: 9px;
}
.app-footer .changelog ion-badge {
  font-size: 8px;
  border-radius: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AACF;AACE;EACE,0BAAA;EACA,+BAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAEE;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;AAAJ;AAEI;EACE,cAAA;EACA,mBAAA;AAAN","sourcesContent":[".app-footer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #fafbfc;\n  color: #3d6573;\n  padding: 0.5rem;\n  font-size: 10px;\n  box-shadow: none;\n\n  .link {\n    text-decoration: underline;\n    color: var(--hia-primary-color);\n    font-weight: 700;\n    margin-left: 0.1rem;\n    cursor: pointer;\n  }\n\n  .changelog {\n    float: right;\n    position: absolute;\n    right: 1rem;\n    font-size: 9px;\n\n    ion-badge {\n      font-size: 8px;\n      border-radius: 30px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
