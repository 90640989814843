import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProgressColor } from "@progress/kendo-angular-progressbar";
import { T } from "src/app/services/localization/localization.service";

@Component({
  selector: "app-media-progress",
  templateUrl: "./media-progress.component.html",
  styleUrls: ["./media-progress.component.scss"],
})
export class MediaProgressComponent implements OnInit {
  @Input() m_ShowTimedOut: boolean = false;
  @Input() m_ShowVisual: boolean = false;
  @Input() m_ShowError: boolean = false;
  @Input() m_Overlay: boolean = false;
  @Input()
  set Progress(value: number) {
    this.setProgress(value);
  }
  @Input()
  set ProgressText(value: string) {
    this.setProgressText(value);
  }
  @Input() m_TextOnly: boolean = false;
  @Input() m_IsUploading: boolean = false;
  @Input() m_UploadProgress: number = 0;

  @Output() RefreshClicked: EventEmitter<void> = new EventEmitter();

  public $t = T.translate;
  public m_ProgressBarColors: ProgressColor[] = [
    { color: "#1f859e", from: 0, to: 100 },
  ];

  private m_Progress: number = 0;
  private m_Text: string = "";

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
  //#region Public Methods
  setProgress(progress: number) {
    if (progress < 1) progress = 1;

    this.m_Progress = progress;
  }

  setProgressText(text: string) {
    this.m_Text = text;
  }
  //#endregion
  //#region Event Handlers
  onRefreshClicked(event: any) {
    event.stopPropagation();
    this.RefreshClicked.emit();
  }
  //#region HTML Render Methods
  getProgress() {
    return this.m_Progress;
  }

  getProgressTxt() {
    return this.m_Text;
  }

  getUploadProgressTxt() {
    if (this.m_UploadProgress === 100) {
      return this.$t("components.modals.finalizingUpload");
    } else {
      return (
        this.$t("components.modals.uploadingVideo") +
        " " +
        this.m_UploadProgress +
        "%"
      );
    }
  }
  //#endregion
}
