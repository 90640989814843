// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generate-modal::part(content) {
  width: 80%;
  height: 80%;
  border-radius: 0.3rem;
}
.generate-modal .generate-container {
  --offset-bottom: auto !important;
  --overflow: auto;
  overflow: auto;
}
.generate-modal .generate-container .hidden-bar {
  overflow: hidden;
}
.generate-modal ion-textarea {
  height: -moz-fit-content;
  height: fit-content;
  width: 95%;
  --background: #f0f0f1;
  --padding-end: 10px;
  --padding-start: 10px;
  --border-radius: 0.3rem;
}
.generate-modal ion-button {
  --border-radius: 0.3rem;
}
.generate-modal ion-footer ion-button {
  margin: 0 1rem;
}
.generate-modal .modal-header {
  text-align: center;
}

.loading-generate-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--hia-primary-color);
  font-weight: 700;
}
.loading-generate-spinner ion-spinner {
  color: var(--hia-primary-color);
  width: 5rem;
  height: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modals/generate-questions-modal/generate-questions-modal.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,WAAA;EACA,qBAAA;AAAJ;AAEE;EACE,gCAAA;EACA,gBAAA;EACA,cAAA;AAAJ;AACI;EACE,gBAAA;AACN;AAEE;EACE,wBAAA;EAAA,mBAAA;EACA,UAAA;EACA,qBAAA;EACA,mBAAA;EACA,qBAAA;EACA,uBAAA;AAAJ;AAGE;EACE,uBAAA;AADJ;AAKI;EACE,cAAA;AAHN;AAOE;EACE,kBAAA;AALJ;;AASA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,+BAAA;EACA,gBAAA;AANF;AAOE;EACE,+BAAA;EACA,WAAA;EACA,YAAA;AALJ","sourcesContent":[".generate-modal {\n  &::part(content) {\n    width: 80%;\n    height: 80%;\n    border-radius: 0.3rem;\n  }\n  .generate-container {\n    --offset-bottom: auto !important;\n    --overflow: auto;\n    overflow: auto;\n    .hidden-bar {\n      overflow: hidden;\n    }\n  }\n  ion-textarea {\n    height: fit-content;\n    width: 95%;\n    --background: #f0f0f1;\n    --padding-end: 10px;\n    --padding-start: 10px;\n    --border-radius: 0.3rem;\n  }\n\n  ion-button {\n    --border-radius: 0.3rem;\n  }\n\n  ion-footer {\n    ion-button {\n      margin: 0 1rem;\n    }\n  }\n\n  .modal-header {\n    text-align: center;\n  }\n}\n\n.loading-generate-spinner {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  color: var(--hia-primary-color);\n  font-weight: 700;\n  ion-spinner {\n    color: var(--hia-primary-color);\n    width: 5rem;\n    height: 5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
