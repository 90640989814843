import { Events } from "../services/events/events.service";
import { SessionService } from "../services/session/session.service";

export abstract class BaseApiService {
  private m_ApiURL: Promise<string> = Promise.resolve("");

  constructor(private m_EventsService: Events, p_ApiUrl: Promise<string>) {
    this.m_ApiURL = p_ApiUrl;
  }

  //#region Protected Methods
  /**
   * Makes a request to the server multiple times using our utility function
   * Catches any fatal errors and returns a 500 status code
   * @param endpointPath
   * @param request
   * @param defaultErrorMessage
   * @param numberOfTimes
   * @param interval
   * @returns
   */
  protected makeRequestWithRetry(
    endpointPath: string,
    request: RequestInit,
    defaultErrorMessage: string,
    numberOfTimes = 1,
    interval = 200,
    abortSignal?: AbortSignal | null
  ): Promise<any> {
    let fn = () =>
      this.makeRequest(endpointPath, request, defaultErrorMessage, abortSignal);

    const makeRequestWithCheck = (attempt: number): Promise<any> => {
      return fn().then((response) => {
        if (response.status >= 400 && response.status < 500) {
          if (response.status == 401) {
            this.m_EventsService.publish("session-expired");
          }
          // If status is 400, stop making requests
          return Promise.reject({
            status: 400,
            ok: false,
            error:
              response.error ||
              response.reason ||
              defaultErrorMessage ||
              "Bad Request",
          });
        } else if (response.status >= 500) {
          // Retry if status is 500 or higher
          if (attempt < numberOfTimes) {
            return new Promise((resolve) => setTimeout(resolve, interval)).then(
              () => makeRequestWithCheck(attempt + 1)
            );
          } else {
            return Promise.reject({
              status: response.status,
              ok: false,
              error: "Max retries reached",
            });
          }
        } else {
          // For all other status codes, resolve with the response
          return response;
        }
      });
    };

    return makeRequestWithCheck(1).catch((err) => {
      console.error(err);
      return err; // Return the error object
    });
  }

  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  protected async makeRequest(
    endpointPath: string,
    request: RequestInit,
    defaultErrorMessage: string,
    abortSignal?: AbortSignal | null
  ): Promise<any> {
    let promise = new Promise<Response>(async (resolve, reject) => {
      request.signal = abortSignal;

      let url = await this.m_ApiURL;
      if (url == "") {
        reject("API URL is not set");
      }

      let text: string = "";
      try {
        let response = await fetch(url + endpointPath, request);

        if (response.status == 204) {
          resolve(response);
          return;
        }

        text = await response.text();
        let data = JSON.parse(text);

        //Check if response is 5xx, reject if so
        if (response.status >= 500 && response.status < 600) {
          reject({
            status: response.status,
            ok: false,
            error: data?.error || data?.payload.error || defaultErrorMessage,
          });
        }

        if (data?.payload) {
          resolve(data.payload);
        } else {
          reject(data?.error ?? defaultErrorMessage);
        }
      } catch (error) {
        let errorMessage = "\n";
        errorMessage += "endpoint: " + endpointPath + "\n";
        errorMessage += "body: " + request?.body?.toString() + "\n";
        errorMessage += "response: " + text + "\n";
        errorMessage += "details: " + error;

        //this.m_logger.error('API Helper', errorMessage);
        console.error(errorMessage);

        reject(error);
      }
    });

    return promise;
  }

  protected async makeRequestWithFile(
    endpointPath: string,
    requestMethod: string,
    file: File,
    sid: string,
    onProgress: (event: ProgressEvent) => void
  ): Promise<any> {
    let promise = new Promise<Response>(async (resolve, reject) => {
      let url = await this.m_ApiURL;
      if (url == "") {
        reject("API URL is not set");
      }

      const xhr = new XMLHttpRequest();
      xhr.open(requestMethod, url + endpointPath, true);
      xhr.setRequestHeader("sid", sid);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          let data = JSON.parse(xhr.response);
          resolve(data.payload);
        } else {
          reject(xhr.statusText);
        }
      };

      xhr.onerror = () => reject(xhr.statusText);

      xhr.upload.addEventListener("progress", onProgress, false);

      const formData = new FormData();
      formData.append("file", file);
      xhr.send(formData);
    });

    return promise;
  }

  /**
   * Makes a request to the server for a file. Returns a blob of the file
   * @param endpointPath
   * @param requestMethod
   * @param sid
   * @returns
   */
  protected async makeRequestForFile(
    endpointPath: string,
    requestMethod: string,
    sid: string
  ): Promise<Blob> {
    let promise = new Promise<Blob>(async (resolve, reject) => {
      let url = await this.m_ApiURL;
      if (url == "") {
        reject("API URL is not set");
      }

      const xhr = new XMLHttpRequest();
      xhr.open(requestMethod, url + endpointPath, true);
      xhr.setRequestHeader("sid", sid);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          //Create a blob from the response
          let contentType = xhr.getResponseHeader("content-type");
          if (contentType == null) contentType = "text/plain";

          let blob = new Blob([xhr.response], {
            type: contentType,
          });

          resolve(blob);
        } else {
          reject(xhr.statusText);
        }
      };

      xhr.onerror = () => reject(xhr.statusText);

      xhr.send();
    });

    return promise;
  }

  /**
   * Adds parameters to the URL in the form of ?key1=value1&key2=value2, encoding the keys and values
   * @param url
   * @param params
   * @returns URL with parameters
   */
  protected addParams(url: string, params: Map<string, string>): string {
    let newUrl = url;
    let first = true;

    params.forEach((value, key) => {
      if (first) {
        newUrl += "?";
        first = false;
      } else {
        newUrl += "&";
      }

      //URL encode the key and value
      newUrl += encodeURIComponent(key) + "=" + encodeURIComponent(value);
    });

    return newUrl;
  }
  //#endregion Private Methods
}
