import { Injectable, ViewContainerRef } from "@angular/core";
import { ToastButton, ToastController } from "@ionic/angular";
import {
  NotificationRef,
  NotificationService,
  NotificationSettings,
  Position,
  Type,
} from "@progress/kendo-angular-notification";

export interface HIAToastOpts {
  message: string;
  duration?: number;
  position?: "top" | "bottom" | "middle";
  cssClass?: string | string[];
  buttons?: (ToastButton | string)[];
  icon?: string;
}

@Injectable({
  providedIn: "root",
})
export class HIANotificationService {
  constructor(
    private m_NotificationService: NotificationService,
    private m_ToastController: ToastController
  ) {}

  //#region Public Methods
  showSuccess(
    message: any,
    timeout?: number,
    position?: "top" | "bottom" | "middle"
  ) {
    let toastOpts: HIAToastOpts = {
      message: message,
      duration: timeout || 10000,
      position: position || "bottom",
      cssClass: ["hia-toast", "hia-toast-success"],
    };

    this.showToast(toastOpts);
  }

  showError(
    message: any,
    timeout?: number,
    position?: "top" | "bottom" | "middle"
  ) {
    let toastOpts: HIAToastOpts = {
      message: message,
      duration: timeout || 10000,
      position: position || "bottom",
      cssClass: ["hia-toast", "hia-toast-error"],
    };

    this.showToast(toastOpts);
  }

  showWarning(
    message: any,
    timeout?: number,
    position?: "top" | "bottom" | "middle"
  ) {
    let toastOpts: HIAToastOpts = {
      message: message,
      duration: timeout || 10000,
      position: position || "bottom",
      cssClass: ["hia-toast", "hia-toast-warning"],
    };

    this.showToast(toastOpts);
  }

  showInfo(
    message: any,
    timeout?: number,
    position?: "top" | "bottom" | "middle"
  ) {
    let toastOpts: HIAToastOpts = {
      message: message,
      duration: timeout || 10000,
      position: position || "bottom",
      cssClass: "hia-toast",
    };

    this.showToast(toastOpts);
  }

  showToast(opts: HIAToastOpts) {
    this.m_ToastController
      .create({
        message: opts.message,
        duration: opts.duration || 5000,
        position: opts.position || "bottom",
        cssClass: opts.cssClass || "hia-toast",
        buttons: opts.buttons || [],
      })
      .then((toast) => {
        toast.present();
      });
  }
  //#endregion
  //#region Private Methods
  //Helper method to show the notification
  private showNotification(
    content: any,
    type: Type,
    timeout?: number,
    appendTo?: ViewContainerRef,
    position?: Position
  ): NotificationRef {
    let notificationSettings = this.getBaseNotificationSettings();
    notificationSettings.content = content;
    notificationSettings.type = type;
    if (timeout != null) notificationSettings.hideAfter = timeout;
    else notificationSettings.closable = true;
    if (appendTo != null) notificationSettings.appendTo = appendTo;
    else notificationSettings.appendTo = undefined;
    if (position != null) notificationSettings.position = position;

    return this.m_NotificationService.show(notificationSettings);
  }

  //Helper method to get base notification settings that are common to all notifications
  private getBaseNotificationSettings(): NotificationSettings {
    return {
      content: "",
      animation: { type: "fade", duration: 250 },
      position: { horizontal: "center", vertical: "top" },
    };
  }
  //#endregion
}
