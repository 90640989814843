//Function to check if string is a valid uuid
export function isUUID(uuid: string): boolean {
  const re =
    /^[0-9a-f]{8}-?[0-9a-f]{4}-?4[0-9a-f]{3}-?[0-9a-f]{4}-?[0-9a-f]{12}$/i;
  return re.test(uuid);
}

//Function that takes a string and returns a string with all URLs removed
export function removeLinks(inputText: string) {
  // The regex pattern to identify URLs
  var urlPattern =
    /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  // Replace the URLs found with empty string
  var linkedText = inputText.replace(urlPattern, function (url) {
    return "";
  });

  // Return the modified string
  return linkedText;
}

//Function to convert uuid to short id
export function uuidToShortId(uuid: string): string {
  const hex = uuid.replace(/-/g, "");
  let binaryString = "";
  for (let i = 0; i < hex.length; i += 2) {
    binaryString += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }

  let base64;
  if (typeof btoa === "function") {
    base64 = btoa(binaryString);
  } else {
    base64 = Buffer.from(binaryString, "binary").toString("base64");
  }

  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

//Function to convert short id to uuid of the form xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
export function shortIdToUuid(shortId: string): string {
  if (isUUID(shortId)) return shortId;

  let base64 = shortId.replace(/-/g, "+").replace(/_/g, "/");
  while (base64.length % 4) {
    base64 += "=";
  }

  let binaryString;
  if (typeof atob === "function") {
    binaryString = atob(base64);
  } else {
    binaryString = Buffer.from(base64, "base64").toString("binary");
  }

  let hex = "";
  for (let i = 0; i < binaryString.length; i++) {
    let hexPart = binaryString.charCodeAt(i).toString(16);
    hex += ("00" + hexPart).slice(-2);
  }
  //Upper case the hex as uuids are upper case
  hex = hex.toUpperCase();

  //Add dashes to the uuid
  return (
    hex.slice(0, 8) +
    "-" +
    hex.slice(8, 12) +
    "-" +
    hex.slice(12, 16) +
    "-" +
    hex.slice(16, 20) +
    "-" +
    hex.slice(20)
  );
}

export function convertRange(
  value: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
) {
  return ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
}
