import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "../../storage/storage.service";
import { SessionService } from "../../session/session.service";

@Injectable({
  providedIn: "root",
})
export class SignedInGuardService {
  constructor(
    private m_SessionService: SessionService,
    private m_Router: Router,
    private m_Storage: StorageService
  ) {}

  //Check if user is logged in
  //If not, redirect to login page
  async canActivate(): Promise<boolean> {
    await this.m_SessionService.checkForToken();

    if (this.m_SessionService.LoggedIn) {
      return true;
    } else {
      const searchParams = window.location.search;
      const pathname = window.location.pathname;
      const paramsAndPathname = pathname + searchParams;

      this.m_Router.navigate(["/login"], {
        queryParams: { redirect: paramsAndPathname },
      });
      return false;
    }
  }
}
