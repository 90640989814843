import { Injectable } from "@angular/core";

export enum MetadataTag {
  PRICING_VIEWED = "viewed pricing options",
  PURCHASE = "purchase",
}

@Injectable({
  providedIn: "root",
})
export class InspectletService {
  private m_InspectletScriptId = "inspectlet-script";
  private m_InspectletAutoId = "inspsync";

  constructor() {}

  //Destroy the inspectlet script and auto generated script
  //NOTE this does not stop inspectlet from initializing, it just removes the script tags
  //To stop inspectlet from initializing, see how we do it in `embed-inspectlet.js`
  destoryInspectlet() {
    if ((window as any)["__insp"]) {
      (window as any)["__insp"] = null;
    }

    //Search for the script tag and remove it
    let scripts = document.getElementById(this.m_InspectletScriptId);
    if (scripts) {
      scripts.remove();
    }

    //Search for the auto script tag and remove it
    let autoScripts = document.getElementById(this.m_InspectletAutoId);
    if (autoScripts) {
      autoScripts.remove();
    }
  }

  tagSessionMetadata(metadata: string | { [key: string]: string }) {
    if ((window as any)["__insp"]) {
      (window as any)["__insp"].push(["tagSession", metadata]);
    }
  }
}
