import { BASE_RESPONSE } from "../api/api";

export enum MEDIA_TYPE {
  IMAGE = 0,
  VIDEO = 1,
  WEBURL = 2,
}

export enum MEDIA_STATUS {
  PENDING = 0,
  UPLOADED = 1,
  PROCESSING = 2,
  READY = 3,
  ERROR = 4,
}

export interface MEDIA_DTO {
  id: string;
  video_id: string;
  url: string;
  type: MEDIA_TYPE;
  status: MEDIA_STATUS;
  created_at?: Date;
  updated_at?: Date;
  thumbnail_url?: string;
  duration?: number;
  file_name?: string;
}

export interface MEDIA_DTO_LIST_MEDIA_RES extends BASE_RESPONSE {
  media: MEDIA_DTO[];
  totalCount: number;
  totalPages: number;
}

export interface MEDIA_DTO_UPLOAD_IMAGE_RES extends BASE_RESPONSE {
  media: MEDIA_DTO;
}

export interface MEDIA_DTO_GET_MEDIA_RES extends BASE_RESPONSE {
  media: MEDIA_DTO;
}
