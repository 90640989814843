import * as THEOplayer from "theoplayer";

export interface HIATheoButtonConfig {
  name: string;
  handler: () => void;
  tooltip?: string;
  customClass?: string;
  customIcon?: string;
  customOrder?: number;
}

export function addButtonToPlayer(
  theoPlayer: any,
  buttonConfig: HIATheoButtonConfig
) {
  let buttonTemplate = (THEOplayer as any).videojs.getComponent("Button");
  let buttonElem = (THEOplayer as any).videojs.extend(buttonTemplate, {
    constructor: function () {
      buttonTemplate.apply(this, arguments);
      /* initialize your button */
      if (buttonConfig.customIcon) {
        let icon = document.createElement("ion-icon");
        icon.setAttribute(
          "style",
          "position:relative; font-size: 20px; top: -1px"
        );
        icon.setAttribute("name", buttonConfig.customIcon);
        this.el().appendChild(icon);
      }
      // add tooltip
      var tooltipSpan = document.createElement("span");
      tooltipSpan.className = "theo-button-tooltip vjs-hidden";
      tooltipSpan.innerText = buttonConfig.tooltip ?? "";
      function toggleTooltip() {
        tooltipSpan.classList.toggle("vjs-hidden");
      }
      this.el().addEventListener("mouseover", toggleTooltip);
      this.el().addEventListener("mouseout", toggleTooltip);
      this.el().appendChild(tooltipSpan);

      // add order to button if specified
      if (buttonConfig.customOrder) {
        this.el().style.order = buttonConfig.customOrder;
      }
    },
    handleClick: function () {
      /* handle click */
      buttonConfig.handler();
    },
    buildCSSClass: function () {
      return buttonConfig.customClass || "vjs-button";
    },
  });
  (THEOplayer as any).videojs.registerComponent(
    buttonConfig.name ?? "CustomButton",
    buttonElem
  );
  theoPlayer.ui
    .getChild("controlBar")
    .addChild(buttonConfig.name ?? "CustomButton", {});
}
