import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { ChangeLogComponent } from "./change-log-modal.component";

@NgModule({
  imports: [IonicModule, CommonModule],
  declarations: [ChangeLogComponent],
  exports: [ChangeLogComponent],
})
export class ChangeLogModule {}
