import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Renderer2,
} from "@angular/core";
import { VideoWidgetComponent } from "src/app/components/video/video-widget/video-widget.component";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { StorageService } from "src/app/services/storage/storage.service";
import { VideoPropertiesComponent } from "src/app/components/video/video-properties/video-properties.component";
import { Subscription } from "rxjs";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { IntroService } from "src/app/services/intro/intro.service";
import { EditIntro } from "src/app/services/intro/sequences/edit-intro/edit-intro";
import { VideoDetailsComponent } from "src/app/components/video/video-details/video-details.component";
import { AlertModalComponent } from "src/app/components/modals/alert-modal/alert-modal.component";
import { shortIdToUuid, uuidToShortId } from "@shared/utils/utils";
import { RecordEditorComponent } from "src/app/components/record-management/record-editor/record-editor/record-editor.component";
import { Title } from "@angular/platform-browser";
import { T } from "src/app/services/localization/localization.service";
import { VoiceService } from "src/app/services/voice/voice.service";
import { QvioViewMode } from "src/app/models/video/video";
import { UiService } from "src/app/services/ui/ui.service";
import { MediaService } from "src/app/services/media/media.service";
import { SessionService } from "src/app/services/session/session.service";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.page.html",
  styleUrls: ["./edit.page.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditPage implements OnInit {
  @ViewChild(VideoWidgetComponent) m_VideoWidgetComponent:
    | VideoWidgetComponent
    | undefined;
  @ViewChild(VideoPropertiesComponent) m_VideoPropertiesComponent:
    | VideoPropertiesComponent
    | undefined;
  @ViewChild(VideoDetailsComponent) m_VideoDetailsComponent:
    | VideoDetailsComponent
    | undefined;
  @ViewChild("m_VideoContainer") m_VideoContainer: ElementRef | undefined;
  @ViewChild(RecordEditorComponent) m_RecordEditorComponent:
    | RecordEditorComponent
    | undefined;
  @ViewChild(AlertModalComponent) m_AlertModalComponent:
    | AlertModalComponent
    | undefined;

  public $t = T.translate;
  public m_SplitterOrientation: "vertical" | "horizontal" = "horizontal";
  public m_SplitterCollapsed: boolean = false;
  public m_SplitterExpanded: boolean = false;
  public m_SplitterSize: number = 250;
  public m_IsFullscreen: boolean = false;
  public m_Loading: boolean = false;
  public m_QvioViewMode: QvioViewMode = QvioViewMode.EDIT;
  private splitterChangedFlag: boolean = false;
  private m_VideoID: string | null = null;
  private m_MIN_DETAILS_HEIGHT: number = 120;

  //Subscriptions
  private m_OnEditClicked: Subscription | null = null;
  //Event Emitters/Listners
  private m_WindowResizeEvent: EventListener | null = null;
  private m_OnVideoFullscreenEvent: EventListener | null = null;
  private m_QueryParamsSub: Subscription | null = null;

  private m_OnBeforeUploadHandler = (event: any) => this.onBeforeUnload(event);

  //--------------------------------------------------------------------
  get VideoData() {
    return this.m_VideoWidgetComponent?.VideoData;
  }
  get CurrentVideoTime() {
    return this.m_VideoWidgetComponent?.CurrentVideoTime ?? 0;
  }
  //--------------------------------------------------------------------
  constructor(
    private m_Route: ActivatedRoute,
    private m_Router: Router,
    public m_StorageService: StorageService,
    private m_AuthService: SessionService,
    private m_Events: Events,
    private m_IntroService: IntroService,
    private m_TitleService: Title,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private m_VoiceService: VoiceService,
    private m_UiService: UiService,
    private m_MediaService: MediaService
  ) {}
  //--------------------------------------------------------------------
  ngOnInit() {
    this.m_QueryParamsSub = this.m_Route.queryParams.subscribe(
      async (params) => {
        await this.initialize(params);
      }
    );

    this.m_WindowResizeEvent = () => {
      this.resizePage();
      this.checkPageOrientation();
    };
    this.m_OnVideoFullscreenEvent = (evt: any) => this.onVideoFullscreen(evt);
  }
  //--------------------------------------------------------------------
  ngOnDestroy() {
    this.dispose();
    this.m_QueryParamsSub?.unsubscribe();
    this.m_QueryParamsSub = null;
    this.m_MediaService.clearMediaCache();
  }
  //--------------------------------------------------------------------
  async ionViewWillEnter() {
    //Set window title
    this.m_TitleService.setTitle(this.$t("pages.tabTitles.editGeneric"));
    if (this.m_WindowResizeEvent != null)
      window.addEventListener("resize", this.m_WindowResizeEvent);
    window.dispatchEvent(new Event("resize"));
    if (this.m_OnVideoFullscreenEvent != null) {
      this.m_Events.subscribe(
        EVENTS.PLAYER_FULLSCREEN,
        this.m_OnVideoFullscreenEvent
      );
    }
    this.m_VideoWidgetComponent?.setDisabled(false);
    this.m_VideoWidgetComponent?.setAutoPlay(false);
    this.m_VideoWidgetComponent?.addInsightEvents();

    window.addEventListener("beforeunload", this.m_OnBeforeUploadHandler);
  }
  //--------------------------------------------------------------------
  async ionViewDidEnter() {
    this.m_Events.publish(EVENTS.PAGE_SHOW);
  }
  //--------------------------------------------------------------------
  ionViewWillLeave() {
    this.m_VideoWidgetComponent?.removeInsightEvents();
    this.m_VideoWidgetComponent?.setDisabled(true);
    this.m_VideoWidgetComponent?.dispose();
    if (this.m_WindowResizeEvent != null)
      window.removeEventListener("resize", this.m_WindowResizeEvent);
    if (this.m_OnVideoFullscreenEvent != null) {
      this.m_Events.destroy(EVENTS.PLAYER_FULLSCREEN);
    }
    this.m_UiService.setEditVideo(null);
  }
  //--------------------------------------------------------------------
  onBeforeUnload(event: any) {
    if (this.m_VideoWidgetComponent?.isUploading()) {
      event?.preventDefault();
      event.returnValue = true; //Included for legacy support, e.g. Chrome/Edge < 119
    }
  }
  //--------------------------------------------------------------------
  dispose() {
    this.resetPage();
    window.removeEventListener("beforeunload", this.m_OnBeforeUploadHandler);
    this.m_OnEditClicked?.unsubscribe();
  }
  //--------------------------------------------------------------------
  async canDeactivate(): Promise<boolean> {
    if (this.m_AlertModalComponent == null) return true;

    if (this.m_VideoWidgetComponent?.isUploading()) {
      (this.m_AlertModalComponent.m_MainText = this.$t(
        "shared.messages.uploadInProgress"
      )),
        (this.m_AlertModalComponent.m_CustomButtons = [
          {
            label: this.$t("shared.button.ok"),
            callback: () => this.m_AlertModalComponent?.onOkClicked(),
          },
        ]);
      let result = await this.m_AlertModalComponent?.show();
      if (result?.ok) {
        return true;
      } else {
        return false;
      }
    }

    if (this.m_VideoPropertiesComponent == null) {
      return true;
    } else {
      let unsavedChanges = this.m_VideoPropertiesComponent.UnsavedChanges;
      if (unsavedChanges) {
        this.m_AlertModalComponent.m_MainText =
          this.m_VideoPropertiesComponent.UnsavedChangesText;
        this.m_AlertModalComponent.m_CustomButtons = [
          {
            label: this.$t("shared.button.ok"),
            callback: () => this.m_AlertModalComponent?.onOkClicked(),
          },
          {
            label: this.$t("shared.button.saveAll"),
            callback: async () => {
              if (
                !this.m_VideoPropertiesComponent ||
                !this.m_VideoPropertiesComponent.m_RecordEditorComponent ||
                !this.m_AlertModalComponent
              )
                return;
              try {
                this.m_AlertModalComponent.setButtonLoadingStatus(true);
                await this.m_VideoPropertiesComponent.m_RecordEditorComponent.onSaveAllClicked();
                await this.m_VideoPropertiesComponent.m_VideoPublishComponent?.onSaveClicked();
              } finally {
                this.m_AlertModalComponent.setButtonLoadingStatus(false);
                this.m_AlertModalComponent.onOkClicked();
              }
            },
          },
        ];
        let result = await this.m_AlertModalComponent?.show();
        if (result?.ok) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }
  //--------------------------------------------------------------------
  /**
   * Initialize the page and any needed services
   */
  async initialize(params: Params) {
    let split = params["qna"];
    if (split) {
      this.m_SplitterCollapsed = true;
    }

    let videoID = params["v"];
    if (videoID != this.m_VideoWidgetComponent?.VideoId) {
      videoID = shortIdToUuid(videoID);
      this.m_VideoID = videoID;
      this.m_MediaService.clearMediaCache();
      await this.resetPage();
      await this.m_AuthService.checkForToken();
      await this.fetchVideo();
      this.m_UiService.setEditVideo(this.VideoData || null);
      this.m_Events.publish(EVENTS.VIDEO_LOADED);
      this.m_IntroService.startSequenceByName(EditIntro.Name);
    }
    this.m_SplitterOrientation = this.getSplitterOrientation();

    if (this.m_SplitterOrientation == "vertical" && !split)
      this.m_SplitterSize = 70;
    this.resizePage();
    this.checkPageOrientation();
  }
  //--------------------------------------------------------------------
  //Fetch video data
  async fetchVideo() {
    if (this.m_VideoID == null || this.m_VideoID == "") {
      this.m_Router.navigate(["/library"]);
      return;
    }

    let success = await this.m_VideoWidgetComponent?.initVideo(this.m_VideoID);

    if (success == "Unauthorized") {
      this.onWatchClicked();
      return;
    }
    if (!success || this.m_VideoWidgetComponent?.VideoData == null) {
      this.m_Router.navigate(["/library"]);
      return;
    }

    this.m_TitleService.setTitle(
      this.$t("pages.tabTitles.edit").replace(
        "{0}",
        this.VideoData?.video_name || ""
      )
    );

    this.m_VideoPropertiesComponent?.intializeProperties(
      this.m_VideoWidgetComponent.VideoData,
      this.checkForQuestionsToAdd(),
      this.checkForQueuedSearch()
    );

    try {
      let voiceID = this.VideoData?.voice_id;
      if (voiceID != null && voiceID != "") {
        let voice = await this.m_VoiceService.getVoiceById(voiceID);
        this.m_VoiceService.setActiveVoice(voice);
      }
    } catch (error) {
      console.error("Edit Page: Failed to get voice", error);
    }
  }

  splitterChanged() {
    this.resizePage();
    this.checkPageOrientation();
    this.splitterChangedFlag = true;
  }

  onWatchClicked() {
    this.m_Router.navigate(["/watch"], {
      queryParams: { v: uuidToShortId(this.m_VideoID || "") },
    });
  }

  handleNewChapterList(videoId: string) {
    if (videoId === this.m_VideoID) {
      this.m_VideoWidgetComponent?.handleNewChapterList();
    }
  }

  async onLanguageChanged() {
    if (!this.m_VideoID) return;
    this.m_Loading = true;
    await this.resetPage();
    await this.fetchVideo();
    this.m_Loading = false;
  }

  getSplitterOrientation() {
    if (window.innerWidth < 768) return "vertical";
    else return "horizontal";
  }

  collapseChange(event: any) {
    this.splitterChangedFlag = true;

    if (this.m_SplitterCollapsed) {
      this.m_SplitterCollapsed = false;
      //we wait 70ms for Kendosplitpane to change its size, so that videowidget can update its size
      setTimeout(() => {
        this.resizePage();
      }, 70);
      this.checkPageOrientation();
    }
  }

  isMobile() {
    return this.m_UiService.isMobileSize();
  }
  //--------------------------------------------------------------------
  //#region Private Methods]
  private resetPage() {
    this.m_VideoWidgetComponent?.reset();
    this.m_VideoPropertiesComponent?.reset();
    this.m_VideoDetailsComponent?.reset();
  }

  //On fullscreen is clicked
  private onVideoFullscreen(isFullscreen: boolean) {
    this.m_IsFullscreen = isFullscreen;
  }

  //Resize the video player and details to fit the screen as best as possible
  private async resizePage() {
    let containerSize =
      this.m_VideoContainer?.nativeElement.getBoundingClientRect();
    if (containerSize == null) return;
    let suggestedHeight = containerSize.height - this.m_MIN_DETAILS_HEIGHT;
    this.m_VideoWidgetComponent?.resizeWidget({
      width: containerSize.width,
      height: suggestedHeight,
    });
    this.checkPageOrientation();
  }

  private async checkPageOrientation() {
    if (this.m_SplitterOrientation != this.getSplitterOrientation()) {
      this.m_SplitterOrientation = this.getSplitterOrientation();
      await this.fetchVideo();
    } else {
      this.m_SplitterOrientation = this.getSplitterOrientation();
    }
  }

  //Helper function to check for questions to add queued from the navigation state
  private checkForQuestionsToAdd(): string[] {
    let questionsToAdd: string[] = [];
    let lastNav = this.m_Router.lastSuccessfulNavigation;
    if (lastNav?.extras.state && lastNav?.previousNavigation != null) {
      let state = lastNav.extras.state;
      if (state != null && state["questionsToAdd"]) {
        let queuedQuestions = state["questionsToAdd"];
        if (queuedQuestions != null) {
          questionsToAdd = queuedQuestions;

          //Filter out duplicates
          questionsToAdd = questionsToAdd.filter((item, index) => {
            return questionsToAdd.indexOf(item) === index;
          });
        }
      }
    }

    return questionsToAdd;
  }

  private checkForQueuedSearch() {
    let lastNav = this.m_Router.lastSuccessfulNavigation;
    if (lastNav?.extras.state && lastNav?.previousNavigation != null) {
      let state = lastNav.extras.state;
      if (state != null && state["searchQuery"]) {
        let searchQuery = state["searchQuery"];
        if (searchQuery != null) {
          return searchQuery;
        } else {
          return "";
        }
      }
    }
  }
  //#endregion
}
