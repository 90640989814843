import { Subject } from "rxjs";
import { Socket, io } from "socket.io-client";

export enum SocketState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export class SocketWrapper {
  private m_Socket: Socket | null = null;
  private m_SocketState: SocketState = SocketState.CLOSED;

  public onopen: (event: any) => void = () => {};
  public onclose: (event: any) => void = () => {};
  public onerror: (error: any) => void = () => {};
  public onmessage: (data: any) => void = () => {};

  private m_Timeout: number = 600000; // 10 minutes
  private m_TimeoutTimer: any = null;

  // Expose the current socket state
  get SocketState() {
    return this.m_SocketState;
  }

  get Connected() {
    return this.m_Socket?.connected;
  }

  constructor() {}

  //#region Public Methods
  public connectToSocket(url: string) {
    try {
      this.m_Socket = io(url);
      this.m_SocketState = SocketState.CONNECTING; // Set state to CONNECTING
      this.hookUpSocketEvents();
    } catch (error) {
      console.error(error);
      this.m_SocketState = SocketState.CLOSED; // Set state to CLOSED on error
      return false;
    }

    this.resetTimeout();

    return true;
  }

  /**
   * Closes the socket
   */
  public close() {
    this.m_Socket?.disconnect();
    this.m_SocketState = SocketState.CLOSING; // Set state to CLOSING

    if (this.m_TimeoutTimer) clearTimeout(this.m_TimeoutTimer);
  }

  /**
   * Sends a message to the server
   * @param channel
   * @param msg
   */
  public send(channel: string, msg: string) {
    this.m_Socket?.emit(channel, msg);
  }

  /**
   * Adds a callback for the specified channel
   * @param channel
   * @param callback
   */
  public on(channel: string, callback: (data: any) => void) {
    this.m_Socket?.on(channel, callback);
  }

  /**
   * Removes all callbacks for the specified channel
   * @param channel
   */
  public off(channel: string) {
    this.m_Socket?.off(channel);
  }
  //#endregion

  //#region Private Methods
  private hookUpSocketEvents() {
    this.m_Socket?.on("connect", () => {
      this.m_SocketState = SocketState.OPEN; // Set state to OPEN
      if (this.onopen) this.onopen({});
    });

    this.m_Socket?.on("disconnect", () => {
      this.m_SocketState = SocketState.CLOSED; // Set state to CLOSED
      if (this.onclose) this.onclose({});
    });

    this.m_Socket?.on("error", (error: any) => {
      this.m_SocketState = SocketState.CLOSED; // Set state to CLOSED on error
      if (this.onerror) this.onerror(error);
    });

    this.m_Socket?.on("data", (data: any) => {
      this.resetTimeout();
      if (this.onmessage) this.onmessage(data);
    });
  }

  // Resets the timeout timer
  private resetTimeout() {
    if (this.m_TimeoutTimer) clearTimeout(this.m_TimeoutTimer);
    this.m_TimeoutTimer = setTimeout(() => {
      if (this.onclose) this.onclose({});
      this.close();
      this.m_SocketState = SocketState.CLOSED; // Set state to CLOSED
    }, this.m_Timeout);
  }
  //#endregion
}
