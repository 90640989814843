import { Component, OnInit } from "@angular/core";
import { EVENTS } from "src/app/constants/events";
import { Events } from "src/app/services/events/events.service";
import { T } from "src/app/services/localization/localization.service";
import { UserService } from "src/app/services/user/user.service";
import { Router, NavigationEnd } from "@angular/router";
import { SessionService } from "src/app/services/session/session.service";
import { Subscription } from "rxjs";
import {
  InspectletService,
  MetadataTag,
} from "src/app/services/inspectlet/inspectlet.service";
@Component({
  selector: "app-pricing-modal",
  templateUrl: "./pricing-modal.component.html",
  styleUrls: ["./pricing-modal.component.scss"],
})
export class PricingModalComponent implements OnInit {
  public m_ShowPricingModal: boolean = false;
  public $t = T.translate;

  private m_LastSeenSubscription: Subscription | null = null;

  constructor(
    private m_UserService: UserService,
    private m_AuthService: SessionService,
    private m_Router: Router,
    private m_Events: Events,
    private m_InspectletService: InspectletService
  ) {
    this.m_Events.subscribe(EVENTS.SHOW_PRICING_MODAL, async () => {
      this.showPricingModal();
    });

    this.m_Router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.m_UserService.LastSeenObserver) {
          this.m_LastSeenSubscription?.unsubscribe();
          this.m_LastSeenSubscription =
            this.m_UserService.LastSeenObserver.subscribe(() => {
              this.checkIfShouldShow();
            });
        } else {
          this.checkIfShouldShow();
        }
      }
    });
  }

  ngOnInit() {}

  showPricingModal() {
    this.m_ShowPricingModal = true;
    this.m_InspectletService.tagSessionMetadata(MetadataTag.PRICING_VIEWED);
  }

  onSignOutClicked() {
    this.m_AuthService.logoutUser();
    this.m_ShowPricingModal = false;
  }

  private checkIfShouldShow() {
    // Check if router changes
    if (
      this.m_AuthService.isEmailVerified() &&
      !this.m_UserService.HasSubscription
    ) {
      // Block modal to open on landing and contact page
      if (["/contact", "/"].includes(this.m_Router.url)) {
        this.m_ShowPricingModal = false;
      } else {
        this.showPricingModal();
      }
    }
  }
}
