import { IntroStep } from "intro.js/src/core/steps";
import { T } from "../../localization/localization.service";

export abstract class IntroSequence {
  //The steps of the sequence
  get Steps(): IntroStep[] {
    return this.steps;
  }

  get Registred(): boolean {
    return this.m_Registred;
  }

  //The name of the sequence, needs to be unique and overriden in the child class
  public static Name: string = "BaseSequence";
  /**
   * The steps of the sequence (must be set in the registerSteps
   * method due to the need to get DOM elements from the page)
   **/
  protected steps: IntroStep[] = [];
  protected $t = T.translate;
  protected m_Registred: boolean = false;

  /**
   * Registers the steps of the sequence, you set the steps in this method
   **/
  public abstract registerSteps(): Promise<void>;
  public abstract getName(): string;
  constructor() {}
}
