import { Injectable } from "@angular/core";
import { UtilityAPI } from "src/app/api/utility/utility-api";
import { UserService } from "../user/user.service";
import { ConfigService } from "../config/config.service";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { Events } from "../events/events.service";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  private m_UtilityAPIService: UtilityAPI;

  constructor(
    eventsService: Events,
    userService: UserService,
    configService: ConfigService
  ) {
    this.m_UtilityAPIService = new UtilityAPI(
      eventsService,
      userService,
      configService.get("SERVER_URL")
    );
  }

  //#region Public Methods
  /**
   * Validate Malicious from given URL
   * @param url The web url
   */
  async validateUrlMalicious(url: string) {
    let result = await this.m_UtilityAPIService.checkURIMalicious(url);

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to check URL");
    }

    return result.isThreat;
  }
}
