import { Injectable } from "@angular/core";
import { MediaApi } from "src/app/api/media/media-api";
import { UserService } from "../user/user.service";
import { ErrorFactory } from "src/app/errors/custom-errors";
import {
  MEDIA_DTO,
  MEDIA_DTO_GET_MEDIA_RES,
  MEDIA_DTO_UPLOAD_IMAGE_RES,
  MEDIA_TYPE,
} from "@shared/models/media/media";
import { MEDIA_LIST, Media } from "src/app/models/media/media";
import { uploadToBlobURLSync } from "src/app/utility/blob-utils";
import { ConfigService } from "../config/config.service";
import { MediaDataCache } from "../caching/media-data-cache";
import { Events } from "../events/events.service";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  private m_MediaAPIService: MediaApi;
  private m_MediaCache: MediaDataCache = new MediaDataCache(60 * 60 * 1000); // 1 hour expiry in milliseconds

  constructor(
    eventsService: Events,
    userService: UserService,
    configService: ConfigService
  ) {
    this.m_MediaAPIService = new MediaApi(
      eventsService,
      userService,
      configService.get("SERVER_URL")
    );
  }

  //#region Public Methods
  /**
   * Uploads an image to the database
   * @param videoID
   * @param file
   * @returns
   */
  async uploadImage(
    videoID: string,
    file: File,
    onProgress: (event: ProgressEvent) => void
  ): Promise<MEDIA_DTO_UPLOAD_IMAGE_RES> {
    let result = await this.m_MediaAPIService.uploadImage(
      videoID,
      file,
      onProgress
    );

    if (result.status != 201) {
      throw ErrorFactory.error(result.status, "Failed to upload image");
    }

    return result;
  }
  //--------------------------------------------------------------------
  async createVideoMedia(videoID: string, file: File) {
    let result = await this.m_MediaAPIService.createVideoMedia(
      videoID,
      file.name
    );

    if (result.status != 201) {
      throw ErrorFactory.error(result.status, "Failed to create video media");
    }

    return result;
  }
  //--------------------------------------------------------------------
  async uploadVideoMedia(
    videoID: string,
    mediaID: string,
    blobURL: string,
    file: File,
    onProgress: (progress: number) => void
  ) {
    let fileName = file.name;
    let fileExt = fileName.split(".")[1];
    let blobName = mediaID + "." + fileExt;
    let urlParts = blobURL.split("?");
    let url = urlParts[0] + "/" + blobName + "?" + urlParts[1];

    let result = await uploadToBlobURLSync(file, url, onProgress);

    if (!result) {
      throw ErrorFactory.error(500, "Failed to upload video media");
    }

    //Call the API to update the media status
    let completeRes = await this.m_MediaAPIService.completeVideoMedia(
      videoID,
      mediaID
    );
    if (completeRes.status != 200) {
      throw ErrorFactory.error(
        completeRes.status,
        "Failed to upload video media"
      );
    }

    return true;
  }
  //--------------------------------------------------------------------
  /**
   * Deletes a media item from the database
   * @param videoID
   * @param mediaID
   * @returns
   */
  async deleteMedia(videoID: string, mediaID: string) {
    let result = await this.m_MediaAPIService.deleteMedia(videoID, mediaID);

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to delete media");
    }

    //Remove the media from the cache
    this.m_MediaCache.remove(mediaID);

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Gets a list of media items for the given video and type, paginated
   * @param videoID
   * @param type
   * @param page_number
   * @param page_size
   * @returns
   */
  async getMedia(
    videoID: string,
    type: MEDIA_TYPE,
    page_number: number,
    page_size: number,
    query?: string,
    nlp?: boolean
  ): Promise<MEDIA_LIST> {
    let result = await this.m_MediaAPIService.getMedia(
      videoID,
      type,
      page_number,
      page_size,
      query,
      nlp
    );

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to get media");
    }

    //Convert the result.media DTOs to our media model
    let media = result.media.map((mediaDTO: MEDIA_DTO) => {
      return new Media(mediaDTO);
    });

    let mediaList: MEDIA_LIST = {
      data: media,
      total: result.totalCount,
    };

    return mediaList;
  }
  //--------------------------------------------------------------------
  /**
   * Gets a media DTO for a video
   * @param videoID
   * @param mediaID
   */
  async getAMedia(
    videoID: string,
    mediaID: string,
    ignoreCache?: boolean
  ): Promise<Media> {
    if (!ignoreCache) {
      let cachedMedia = await this.m_MediaCache.getCachedData(mediaID);

      if (cachedMedia) {
        return cachedMedia;
      }
    }

    // Fetch from the network
    const fetchPromise = this.m_MediaAPIService
      .getAMedia(videoID, mediaID)
      .then((result: MEDIA_DTO_GET_MEDIA_RES) => {
        if (result.status != 200) {
          throw ErrorFactory.error(result.status, "Failed to get media");
        }

        let media = new Media(result.media);
        return media;
      });

    this.m_MediaCache.setCachedData(mediaID, fetchPromise);
    return fetchPromise;
  }
  //--------------------------------------------------------------------
  /**
   * Gets the processing progress for a video media item
   * @param videoID
   * @param mediaID
   * @returns
   */
  async getVideoProcessingProgress(videoID: string, mediaID: string) {
    let result = await this.m_MediaAPIService.getEncodingProgress(
      videoID,
      mediaID
    );

    if (result.status != 200) {
      throw ErrorFactory.error(
        result.status,
        "Failed to get media processing progress"
      );
    }

    return result.progress;
  }
  //--------------------------------------------------------------------
  async getVideoIndexerProgress(videoID: string, mediaID: string) {
    let result = await this.m_MediaAPIService.getIndexingProgress(
      videoID,
      mediaID
    );

    if (result.status != 200) {
      throw ErrorFactory.error(
        result.status,
        "Failed to get media index progress"
      );
    }

    return result.progress;
  }
  //--------------------------------------------------------------------
  clearMediaCache() {
    this.m_MediaCache.clear();
  }
}
