import { DragTargetDataFn } from "@progress/kendo-angular-utils";

export interface DragDropItem {
  dragData: any;
  id: string;
}

/**
 * DragDropCollection is a class that manages the drag and drop operations for the library.
 * It keeps track of the items that are registered for drag and drop.
 */
export class DragDropCollection {
  private m_DraggedItem: DragDropItem | null = null;
  private m_RegisteredItems: DragDropItem[] = [];

  get DraggedItem() {
    return this.m_DraggedItem;
  }

  get RegisteredItems() {
    return this.m_RegisteredItems;
  }

  get NumberOfRegisteredItems() {
    return this.m_RegisteredItems?.length ?? 0;
  }

  //----------------------------------------------
  registerDragItem(item: DragDropItem) {
    this.m_DraggedItem = item;
  }
  //----------------------------------------------
  unregisterDragItem(tem: DragDropItem) {
    this.m_DraggedItem = null;
  }
  //----------------------------------------------
  registerItem(item: DragDropItem): void {
    if (this.m_RegisteredItems.findIndex((i) => i.id === item.id) < 0) {
      this.m_RegisteredItems.push(item);
    }
  }
  //----------------------------------------------
  unregisterItem(item: DragDropItem): void {
    const index = this.m_RegisteredItems.findIndex((i) => i.id === item.id);
    if (index !== -1) {
      this.m_RegisteredItems.splice(index, 1);
    }
  }
  //----------------------------------------------
  isItemRegistered(id: string): boolean {
    const index = this.m_RegisteredItems.findIndex((i) => i.id === id);
    return index !== -1;
  }
  //----------------------------------------------
  getDragData(): DragTargetDataFn {
    return () => {
      let result = [...this.RegisteredItems];

      let hasDraggedItem =
        this.m_RegisteredItems.findIndex(
          (i) => i.id === this.m_DraggedItem?.id
        ) !== -1;

      if (!hasDraggedItem && this.m_DraggedItem != null) {
        result.push(this.m_DraggedItem);
      }

      return result;
    };
  }
  //----------------------------------------------
  reset() {
    this.m_DraggedItem = null;
    this.m_RegisteredItems = [];
  }
}
