import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { AddPlaylistModalComponent } from "./add-playlist-modal.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule],
  declarations: [AddPlaylistModalComponent],
  exports: [AddPlaylistModalComponent],
})
export class AddPlaylistModalModule {}
