import { BASE_RESPONSE } from "@shared/models/api/api";
import {
  ANALYTICS_DTO_GET_REQ,
  ANALYTICS_GET_INDIVIDUAL_QUERIES_REQ,
  ANALYTICS_GET_QUERIES_REQ,
  ANALYTICS_SESSIONS_LIST_REQ,
  MARK_MULTIPLE_QUERIES_REVIEWED_REQ,
  MARK_QUERY_REVIEWED_REQ,
} from "@shared/models/analytics/analytics-requests";
import {
  ANALYTICS_DTO_GET_RES,
  ANALYTICS_GET_INDIVIDUAL_QUERIES_RES,
  ANALYTICS_GET_QUERIES_RES,
  ANALYTICS_SESSIONS_LIST_RES,
} from "@shared/models/analytics/analytics-responses";
import { WATCH_TIME_REQUEST_DTO } from "@shared/models/analytics/analytics";
import { TRACK_EVENT_REQUEST_DTO } from "@shared/models/analytics/events";
import { BaseApiService } from "../base-api-service";
import { UserService } from "src/app/services/user/user.service";
import { Events } from "src/app/services/events/events.service";

export class AnalyticsApi extends BaseApiService {
  private static readonly trackEventsEndpoint = {
    method: "POST",
    path: "/api/analytics/trackEvents",
  };

  private static readonly watchTimeEndpoint = {
    method: "GET",
    path: "/api/analytics/watchtime?vid={{vid}}&cp={{cp}}",
  };

  private static readonly getAnalyticsEndpoint = {
    method: "POST",
    path: "/api/analytics",
  };

  private static readonly getWatchSessionsEndpoint = {
    method: "POST",
    path: "/api/analytics/sessions",
  };

  private static readonly getSessionsDetailsEndpoint = {
    method: "GET",
    path: "/api/analytics/sessions/{sessionId}/events",
  };

  private static readonly getAggregatedQueriesEndpoint = {
    method: "POST",
    path: "/api/analytics/aggregatedQueries",
  };

  private static readonly getIndividualQueriesEndpoint = {
    method: "POST",
    path: "/api/analytics/individualQueries",
  };

  private static readonly setQueryReviewedEndpoint = {
    method: "PATCH",
    path: "/api/analytics/query/{{queryId}}/reviewed",
  };

  private static readonly setMultipleQueriesReviewedEndpoint = {
    method: "PATCH",
    path: "/api/analytics/query/reviewed",
  };

  //--------------------------------------------------------------------
  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }
  //--------------------------------------------------------------------
  //#region Public Methods

  public async trackEvents(
    request: TRACK_EVENT_REQUEST_DTO
  ): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: AnalyticsApi.trackEventsEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(request),
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      AnalyticsApi.trackEventsEndpoint.path,
      apiRequest,
      "Failed to send events",
      3
    );

    return response;
  }

  public async reportWatchTime(
    request: WATCH_TIME_REQUEST_DTO
  ): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: AnalyticsApi.watchTimeEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      AnalyticsApi.watchTimeEndpoint.path
        .replace("{{vid}}", request.vid)
        .replace("{{cp}}", request.cp.toString()),
      apiRequest,
      "Failed to report watch time",
      3
    );

    return response;
  }

  public async getAnalytics(
    request: ANALYTICS_DTO_GET_REQ,
    abortSignal?: AbortSignal | null
  ): Promise<ANALYTICS_DTO_GET_RES> {
    let apiRequest = {
      method: AnalyticsApi.getAnalyticsEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(request),
    };

    const response: ANALYTICS_DTO_GET_RES = await this.makeRequestWithRetry(
      AnalyticsApi.getAnalyticsEndpoint.path,
      apiRequest,
      "Failed to get analytics",
      3,
      200,
      abortSignal
    );

    return response;
  }

  public async getSessionsAnalytics(
    request: ANALYTICS_SESSIONS_LIST_REQ
  ): Promise<ANALYTICS_SESSIONS_LIST_RES> {
    let apiRequest = {
      method: AnalyticsApi.getWatchSessionsEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(request),
    };

    const response: ANALYTICS_SESSIONS_LIST_RES =
      await this.makeRequestWithRetry(
        AnalyticsApi.getWatchSessionsEndpoint.path,
        apiRequest,
        "Failed to get sessions analytics",
        3
      );

    return response;
  }

  public async getAggregatedQueries(
    request: ANALYTICS_GET_QUERIES_REQ,
    abortSignal?: AbortSignal
  ): Promise<ANALYTICS_GET_QUERIES_RES> {
    let apiRequest = {
      method: AnalyticsApi.getAggregatedQueriesEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(request),
    };

    const response: ANALYTICS_GET_QUERIES_RES = await this.makeRequestWithRetry(
      AnalyticsApi.getAggregatedQueriesEndpoint.path,
      apiRequest,
      "Failed to get aggregated queries analytics",
      3,
      200,
      abortSignal
    );

    return response;
  }

  public async getIndividualQueries(
    request: ANALYTICS_GET_INDIVIDUAL_QUERIES_REQ
  ): Promise<ANALYTICS_GET_INDIVIDUAL_QUERIES_RES> {
    let apiRequest = {
      method: AnalyticsApi.getIndividualQueriesEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(request),
    };

    const response: ANALYTICS_GET_INDIVIDUAL_QUERIES_RES =
      await this.makeRequestWithRetry(
        AnalyticsApi.getIndividualQueriesEndpoint.path,
        apiRequest,
        "Failed to get individual queries analytics",
        3
      );

    return response;
  }

  public async setQueryReviewed(
    request: MARK_QUERY_REVIEWED_REQ
  ): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: AnalyticsApi.setQueryReviewedEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      AnalyticsApi.setQueryReviewedEndpoint.path.replace(
        "{{queryId}}",
        request.queryId
      ),
      apiRequest,
      "Failed to set query as reviewed",
      3
    );

    return response;
  }

  public async setMultipleQueriesReviewed(
    request: MARK_MULTIPLE_QUERIES_REVIEWED_REQ
  ): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: AnalyticsApi.setMultipleQueriesReviewedEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(request),
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      AnalyticsApi.setMultipleQueriesReviewedEndpoint.path,
      apiRequest,
      "Failed to set multiple queries as reviewed",
      3
    );

    return response;
  }

  public async getSessionsDetails(sessionId: string): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: AnalyticsApi.getSessionsDetailsEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: ANALYTICS_SESSIONS_LIST_RES =
      await this.makeRequestWithRetry(
        AnalyticsApi.getSessionsDetailsEndpoint.path.replace(
          "{sessionId}",
          sessionId
        ),
        apiRequest,
        "Failed to get sessions details",
        3
      );

    return response;
  }
  //#endregion
}
