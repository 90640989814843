import { Injectable } from "@angular/core";
import { StorageService } from "../storage/storage.service";
import { BehaviorSubject } from "rxjs";

export enum LANGUAGES {
  EN = "en",
  ES = "es",
}

export const STTModelToLang: { [key: string]: LANGUAGES } = {
  "en-US": LANGUAGES.EN,
  "es-MX": LANGUAGES.ES,
  es: LANGUAGES.ES,
};

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  private m_AvailableLangs = ["es", "en"];
  private m_CurrLang: string = LANGUAGES.EN;
  private m_CurrLangTable: any;
  private m_DefaultLangTable: any;
  private m_Initialized = false;

  private translationReadySource = new BehaviorSubject<boolean>(false);
  public translationReady$ = this.translationReadySource.asObservable();
  //--------------------------------------------------------------------
  get CurrLang() {
    return this.m_CurrLang;
  }

  get Initialized() {
    return this.m_Initialized;
  }

  //--------------------------------------------------------------------
  constructor(private m_Storage: StorageService) {
    this.init(LANGUAGES.EN).then(() => {
      this.translationReadySource.next(true);
    });
  }
  //--------------------------------------------------------------------
  /**
   * Initialize localization service with optional override lang
   */
  async init(overrideLang?: string) {
    let langToSet = await this.getUserLanguage();
    if (overrideLang) {
      let lang = STTModelToLang[overrideLang];
      if (lang != null) {
        langToSet = lang;
      }
    }

    this.m_CurrLang = langToSet;
    this.m_CurrLangTable = await this.importLang(langToSet);
    if (!this.m_DefaultLangTable) {
      this.m_DefaultLangTable = this.m_CurrLangTable;
    }
    T.init(this);
    this.m_Initialized = true;
    //TODO Set lang in storage if override?
  }
  //--------------------------------------------------------------------
  //Gets the user language from browser
  async getUserLanguage(): Promise<LANGUAGES> {
    const savedLang = await this.m_Storage.get("language");
    if (savedLang) return savedLang;

    let browserLanguage = LANGUAGES.EN;

    switch (navigator.language) {
      case "es":
        browserLanguage = LANGUAGES.ES;
        break;
    }

    return browserLanguage;
  }
  //--------------------------------------------------------------------
  /**
   * Gets translated string from the localization table
   * @param key The key of the string to retrieve
   * @param defaultMsg The default msg should the key not resolve, defaults to "---"
   * @returns The localized string or defaultMsg
   */
  translate(key: string, defaultMsg: string = "---") {
    //Look up key in loaded lang table first
    return (
      this.getTranslation(key, this.m_CurrLangTable) ||
      this.getTranslation(key, this.m_DefaultLangTable) ||
      defaultMsg
    );
  }
  //--------------------------------------------------------------------
  /**
   * Helper function used to look through a localization table for a given key
   * @param key
   * @param localizationTable
   * @returns
   */
  private getTranslation(key: string, localizationTable: any) {
    if (!localizationTable) return "";

    const keys = key.split(".");
    let retVal = localizationTable;
    for (const k of keys) {
      if (retVal.hasOwnProperty(k)) {
        retVal = retVal[k];
      } else {
        return "";
      }
    }

    return retVal;
  }
  //--------------------------------------------------------------------
  /**
   * Gets all available translations
   */
  private async getTranslations() {
    let i = 0;

    const allTranslations: any = {};

    const loop = async () => {
      const lang = this.m_AvailableLangs[i];
      if (!lang) return allTranslations;
      allTranslations[lang] = await this.importLang(lang);
      i++;
      await loop();
    };
    await loop();

    return allTranslations;
  }
  //--------------------------------------------------------------------
  /**
   * Imports a given language's translation files
   * @param lang
   * @returns
   */
  private async importLang(lang: string) {
    const pages = await import(`./lang/${lang}/pages.json`);
    const shared = await import(`./lang/${lang}/shared.json`);
    const component = await import(`./lang/${lang}/component.json`);
    const termsOfService = await import(`./lang/${lang}/termsOfService.json`);
    const intros = await import(`./lang/${lang}/intros.json`);

    const translations = {
      ...{ pages },
      ...{ component },
      ...{ shared },
      ...{ termsOfService },
      ...{ intros },
    } as any;

    const obj = {} as any;

    for (const key in translations) {
      for (const translation in translations[key]) {
        obj[translation] = translations[key][translation];
      }
    }

    return obj;
  }
}
//--------------------------------------------------------------------
export class T {
  private static m_LocalizationService: LocalizationService;
  static get LocalizationService() {
    return T.m_LocalizationService;
  }
  static get CurrLang() {
    return T.m_LocalizationService.CurrLang;
  }
  static init(pLocalization: LocalizationService) {
    T.m_LocalizationService = pLocalization;
  }
  static translate(lookupCode: string, defaultMsg?: string) {
    if (T.m_LocalizationService == null) {
      return defaultMsg;
    }
    return T.m_LocalizationService.translate(lookupCode, defaultMsg);
  }
}
