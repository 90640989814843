import { Component, ElementRef, ViewChild } from "@angular/core";
import {
  PLAYLIST_DTO,
  PUBLIC_PLAYLIST_DTO,
} from "@shared/models/playlist/playlist";
import { VIDEO_PUBLISH_STATE } from "@shared/models/video/video";
import { EVENTS } from "src/app/constants/events";
import { Events } from "src/app/services/events/events.service";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { PlaylistService } from "src/app/services/playlist/playlist.service";
import { UserService } from "src/app/services/user/user.service";
import { VideoService } from "src/app/services/video/video.service";

@Component({
  selector: "app-add-playlist-modal",
  templateUrl: "./add-playlist-modal.component.html",
  styleUrls: ["./add-playlist-modal.component.scss"],
})
export class AddPlaylistModalComponent {
  public $t = T.translate;

  public m_IsOpen: boolean = false;
  public m_ShowInputCreating: boolean = false;
  public m_Loading: boolean = false;
  public m_Creating: boolean = false;
  public m_FullyLoaded: boolean = false;
  public m_LoadingMore: boolean = false;
  public m_ModalTitle: string = this.$t("components.modals.playlist.title");

  public m_NewPlaylistName: string = "";
  public m_NewPlaylistPrivacy: VIDEO_PUBLISH_STATE = 0;
  public m_Playlists: (PLAYLIST_DTO | PUBLIC_PLAYLIST_DTO | any)[] = [];

  private m_TotalPages: number = 0;
  private m_CurrentPage: number = 0;
  private m_onBottom: boolean = false;

  public m_SelectedPlaylists: string[] = [];

  private m_VideoIds: string[] = [];

  @ViewChild("playlistScroll", { static: false })
  m_PlaylistScroll: ElementRef | null = null;

  constructor(
    private m_PlaylistService: PlaylistService,
    private m_UserService: UserService,
    private m_VideoService: VideoService,
    private m_NotificationService: HIANotificationService,
    private m_Events: Events
  ) {
    this.m_Events.subscribe(
      EVENTS.OPEN_PLAYLIST_MODAL,
      (config: { videoIds: string[]; startEdit?: boolean }) => {
        this.open(config.videoIds, config.startEdit);
      }
    );
  }

  open(videoIds: string[], enableEdit: boolean = false) {
    this.m_IsOpen = true;
    this.m_VideoIds = videoIds;
    if (videoIds.length) {
      this.m_ModalTitle = this.$t("components.modals.playlist.title");
      this.fetchPlaylists();
    } else {
      this.m_FullyLoaded = true;
      this.m_Loading = false;
      this.m_LoadingMore = false;
    }

    if (enableEdit) {
      this.showInputCreate();
      this.m_ModalTitle = this.$t("components.modals.playlist.createTitle");
    }
  }

  close() {
    this.m_IsOpen = false;
    this.m_ShowInputCreating = false;
    this.m_NewPlaylistName = "";
    this.m_NewPlaylistPrivacy = 0;
    this.m_SelectedPlaylists = [];
    this.m_CurrentPage = 0;
    this.m_FullyLoaded = false;
    this.m_onBottom = false;
    this.m_TotalPages = 0;
  }

  showInputCreate() {
    this.m_ShowInputCreating = true;
  }

  async fetchPlaylists(loadMore = false) {
    if (this.m_FullyLoaded) return;

    let id = this.m_UserService.ActiveUserInfo?.id;
    if (!id) return;

    if (!loadMore) {
      this.m_Loading = true;
    } else {
      this.m_LoadingMore = true;
    }

    try {
      let result = await this.m_UserService.getUserPlaylists(
        id,
        8,
        this.m_CurrentPage
      );

      if (!loadMore) {
        if (result) this.m_Playlists = result.playlists;

        //Set at 1 after first fetch
        this.m_TotalPages = result.totalPages;
        this.m_CurrentPage = 1;

        //Mark as checked playlists that has video already included
        if (this.m_VideoIds.length === 1) {
          let videoPlaylists = await this.m_VideoService.getVideoPlaylists(
            this.m_VideoIds[0]
          );

          if (videoPlaylists.playlists) {
            this.m_SelectedPlaylists = videoPlaylists.playlists.map(
              (p) => p.id
            );
          }
        }

        if (this.m_CurrentPage === this.m_TotalPages) {
          this.m_FullyLoaded = true;
        }
      } else {
        //Pagination logic
        if (result) {
          this.m_Playlists = this.m_Playlists.concat(result.playlists);

          if (this.m_CurrentPage === this.m_TotalPages - 1) {
            this.m_FullyLoaded = true;
            return;
          }

          this.m_CurrentPage += 1;
          this.m_onBottom = false;
        }
      }
    } finally {
      this.m_Loading = false;

      if (loadMore) {
        setTimeout(() => {
          this.m_LoadingMore = false;
        }, 50);
      }

      if (!loadMore) {
        //Add handler scroller after first fetch
        this.m_PlaylistScroll?.nativeElement.addEventListener(
          "scroll",
          (event: any) => {
            this.onScroll(event);
          }
        );
      }
    }
  }

  onScroll(event: Event) {
    if (this.m_onBottom) return;

    const target = event.target as HTMLElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      //Reach bottom call fetch more playlist
      this.m_onBottom = true;
      this.scrollToBottom();
      this.fetchPlaylists(true);
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      //Scroll to bottom of list
      this.m_PlaylistScroll?.nativeElement.scrollTo({
        top: this.m_PlaylistScroll.nativeElement.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  }

  async createNewPlaylist() {
    if (this.m_NewPlaylistName === "") return;

    this.m_Creating = true;

    try {
      let newPlaylist = await this.m_PlaylistService.createPlaylist({
        description: "",
        name: this.m_NewPlaylistName,
        visibility: this.m_NewPlaylistPrivacy,
      });

      if (this.m_VideoIds && this.m_VideoIds.length) {
        //Add video into new playlist
        await this.m_PlaylistService.addPlaylist(
          newPlaylist.playlist.id,
          this.m_VideoIds
        );

        this.m_NotificationService.showSuccess(
          this.$t("components.modals.playlist.createSuccessfully"),
          10000,
          "top"
        );
      } else {
        this.m_Events.publish(EVENTS.PLAYLIST_REFRESH);
      }

      this.close();
    } finally {
      this.m_Creating = false;
    }
  }

  handleCheckbox(value: any, playlist: PUBLIC_PLAYLIST_DTO | PLAYLIST_DTO) {
    if (value.detail.checked) {
      this.m_PlaylistService.addPlaylist(playlist.id, this.m_VideoIds);
      this.m_SelectedPlaylists.push(playlist.id);
      this.m_NotificationService.showSuccess(
        this.$t("components.modals.playlist.addedSuccessfully"),
        2000,
        "bottom"
      );
    } else {
      this.m_PlaylistService.removePlaylist(playlist.id, this.m_VideoIds);
      this.m_SelectedPlaylists = this.m_SelectedPlaylists.filter(
        (id) => id !== playlist.id
      );
      this.m_NotificationService.showSuccess(
        this.$t("components.modals.playlist.removedSuccessfully"),
        2000,
        "bottom"
      );
    }
  }

  //Render helpers
  disableButtonCreate() {
    return this.m_NewPlaylistName === "" || this.m_Creating;
  }

  showEmptyList() {
    return !this.m_Loading && this.m_Playlists.length === 0;
  }

  showLoading() {
    return this.m_Loading;
  }

  hideList() {
    return this.m_VideoIds.length === 0 || !this.m_VideoIds;
  }

  isSelected(playlistId: string) {
    return this.m_SelectedPlaylists.includes(playlistId);
  }

  getVisiblityIcon(visibility: VIDEO_PUBLISH_STATE) {
    switch (visibility) {
      case 0:
        return "lock-closed-outline";
      case 1:
        return "eye-off-outline";
      default:
        return "earth-outline";
    }
  }
}
