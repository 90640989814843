import { GENERAL_ANALYTICS_DTO } from "@shared/models/analytics/analytics";
import {
  LIMITS_DTO,
  USER_DTO,
  USER_DTO_LIMITS_RES,
  USER_WITH_SUBSCRIPTION_DTO,
} from "@shared/models/user/user";

export class USER_LIMITS {
  hasActiveSubscription: boolean;
  canUseFreeTrial: boolean;
  is_trial: boolean;
  subInfo: LIMITS_DTO;

  constructor(limits_res: USER_DTO_LIMITS_RES) {
    this.hasActiveSubscription = limits_res.hasActiveSubscription;
    this.canUseFreeTrial = limits_res.canUseFreeTrial;
    this.is_trial = limits_res.is_trial;
    this.subInfo = limits_res.limits;
  }
}

export interface USER_LIST {
  users: USER_DTO[];
  total: number;
  totalPages: number;
}

export interface USER_LIST_ADMIN {
  users: USER_WITH_SUBSCRIPTION_DTO[];
  total: number;
  totalPages: number;
}

export interface USER_ANALYTICS_LIST_ADMIN {
  users: GENERAL_ANALYTICS_DTO[];
  total: number;
  totalPages: number;
}
