import {
  VIDEO_DTO_COMPLETE_REQ,
  VIDEO_DTO_COMPLETE_RES,
  VIDEO_DTO_CREATE_REQ,
  VIDEO_DTO_CREATE_RES,
  VIDEO_DTO_DELETE_RES,
  VIDEO_DTO_GET_REQ,
  VIDEO_DTO_GET_RES,
  VIDEO_DTO_GET_STATUS_RES,
  VIDEO_DTO_PROGRESS_RES,
  VIDEO_DTO_REUPLOAD_RES,
  VIDEO_DTO_UPDATE_DETAILS_REQ,
  VIDEO_DTO_UPDATE_PUBLISH_STATE_REQ,
  VIDEO_DUB_RES,
} from "@shared/models/video/video";
import { INSIGHTS_DTO_GET_WIDGET_RES } from "@shared/models/insights/insights";
import { UserService } from "../../services/user/user.service";
import { BaseApiService } from "../base-api-service";
import { GET_VIDEO_PLAYLISTS_DTO_RES } from "@shared/models/playlist/playlist";
import { Events } from "src/app/services/events/events.service";

export class VideoApi extends BaseApiService {
  //Video Routes
  private static readonly videoCreateEndpoint = {
    method: "POST",
    path: "/api/video/",
  };

  private static readonly videoDetailsEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/details",
  };

  private static readonly videoCompeteEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/complete",
  };

  private static readonly videoGetEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}",
  };

  private static readonly videoPublishEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/publish",
  };

  private static readonly videoStatusEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/status",
  };

  private static readonly videoInsightsEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/insights",
  };

  private static readonly videoInsightsWidgetEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/insights/widget",
  };

  private static readonly videoEncodingProgressEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/encoding/progress",
  };

  private static readonly videoIndexerProgressEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/indexer/progress",
  };

  private static readonly videoDeleteEndpoint = {
    method: "DELETE",
    path: "/api/video/{{videoID}}",
  };

  private static readonly videoGetCaptionsEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/captions",
  };

  private static readonly mediaGetCaptionsEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/media/{{mediaID}}/captions",
  };

  private static readonly videoGenerateQnAPairs = {
    method: "GET",
    path: "/api/video/{{videoID}}/records/generate-qna-pairs",
  };

  private static readonly videoUpdateThumbnail = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/thumbnail",
  };

  private static readonly updateVideoCaptionEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/captions",
  };

  private static readonly videoFiltersStateEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/show-filters",
  };

  private static readonly videoChatGPTStateEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/can-ask-chatgpt",
  };

  private static readonly videoSetReadOnlyStateEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/read-only",
  };

  private static readonly videoSetVoiceIdEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/voice-id",
  };

  private static readonly videoReuploadEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/reupload",
  };

  private static readonly videoGetPlaylists = {
    method: "GET",
    path: "/api/video/{{videoID}}/playlists",
  };

  private static readonly requestDubEndpoint = {
    method: "POST",
    path: "/api/video/{{videoID}}/dub",
  };

  private static readonly videoGetDubs = {
    method: "GET",
    path: "/api/video/{{videoID}}/dub",
  };

  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }

  //#region Video Routes
  //--------------------------------------------------------------------
  async getVideo(data: VIDEO_DTO_GET_REQ): Promise<VIDEO_DTO_GET_RES> {
    let apiRequest = {
      method: VideoApi.videoGetEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: VIDEO_DTO_GET_RES = await this.makeRequestWithRetry(
      VideoApi.videoGetEndpoint.path.replace("{{videoID}}", data.video_id),
      apiRequest,
      "Get Video Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async createVideo(
    data: VIDEO_DTO_CREATE_REQ
  ): Promise<VIDEO_DTO_CREATE_RES | null> {
    let apiRequest = {
      method: VideoApi.videoCreateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    const response: VIDEO_DTO_CREATE_RES = await this.makeRequestWithRetry(
      VideoApi.videoCreateEndpoint.path,
      apiRequest,
      "Create Video Failed",
      1,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async reuploadVideo(videoId: string): Promise<VIDEO_DTO_REUPLOAD_RES> {
    let apiRequest = {
      method: VideoApi.videoReuploadEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: VIDEO_DTO_REUPLOAD_RES = await this.makeRequestWithRetry(
      VideoApi.videoReuploadEndpoint.path.replace("{{videoID}}", videoId),
      apiRequest,
      "Reupload Video Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async completeVideo(
    data: VIDEO_DTO_COMPLETE_REQ,
    reupload: boolean = false
  ): Promise<VIDEO_DTO_COMPLETE_RES> {
    let apiRequest = {
      method: VideoApi.videoCompeteEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    let url = VideoApi.videoCompeteEndpoint.path.replace(
      "{{videoID}}",
      data.video_id
    );
    const params = new Map<string, string>();

    if (reupload) {
      params.set("reupload", "true");
    }

    url = this.addParams(url, params);

    const response: VIDEO_DTO_COMPLETE_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Complete Video Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async deleteVideo(videoId: string): Promise<VIDEO_DTO_DELETE_RES> {
    let apiRequest = {
      method: VideoApi.videoDeleteEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: VIDEO_DTO_DELETE_RES = await this.makeRequestWithRetry(
      VideoApi.videoDeleteEndpoint.path.replace("{{videoID}}", videoId),
      apiRequest,
      "Delete Video Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getVideoPlaylists(
    videoId: string
  ): Promise<GET_VIDEO_PLAYLISTS_DTO_RES> {
    let apiRequest = {
      method: VideoApi.videoGetPlaylists.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: GET_VIDEO_PLAYLISTS_DTO_RES =
      await this.makeRequestWithRetry(
        VideoApi.videoGetPlaylists.path.replace("{{videoID}}", videoId),
        apiRequest,
        "Get Video Playlists Failed",
        3,
        200
      );

    return response;
  }
  //--------------------------------------------------------------------
  async getVideoStatus(
    data: VIDEO_DTO_GET_REQ
  ): Promise<VIDEO_DTO_GET_STATUS_RES> {
    let apiRequest = {
      method: VideoApi.videoStatusEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: VIDEO_DTO_GET_STATUS_RES = await this.makeRequestWithRetry(
      VideoApi.videoStatusEndpoint.path.replace("{{videoID}}", data.video_id),
      apiRequest,
      "Get Video Status Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getVideoInsights(data: VIDEO_DTO_GET_REQ) {
    let apiRequest = {
      method: VideoApi.videoInsightsEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      VideoApi.videoInsightsEndpoint.path.replace("{{videoID}}", data.video_id),
      apiRequest,
      "Get Video Insights Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getVideoInsightsWidget(
    data: VIDEO_DTO_GET_REQ,
    allowEdit: boolean = false
  ): Promise<INSIGHTS_DTO_GET_WIDGET_RES> {
    if (allowEdit == null) allowEdit = false;

    let apiRequest = {
      method: VideoApi.videoInsightsWidgetEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url =
      VideoApi.videoInsightsWidgetEndpoint.path.replace(
        "{{videoID}}",
        data.video_id
      ) +
      "?edit=" +
      allowEdit;

    const response: INSIGHTS_DTO_GET_WIDGET_RES =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Get Video Insights Widget Failed",
        3,
        200
      );

    return response;
  }
  //--------------------------------------------------------------------
  async publishVideo(
    videoId: string,
    data: VIDEO_DTO_UPDATE_PUBLISH_STATE_REQ
  ): Promise<VIDEO_DTO_COMPLETE_RES> {
    let apiRequest = {
      method: VideoApi.videoPublishEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    const response = await this.makeRequestWithRetry(
      VideoApi.videoPublishEndpoint.path.replace("{{videoID}}", videoId),
      apiRequest,
      "Publish Video Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async updateVideoDetails(
    videoId: string,
    data: VIDEO_DTO_UPDATE_DETAILS_REQ
  ): Promise<VIDEO_DTO_COMPLETE_RES> {
    let apiRequest = {
      method: VideoApi.videoDetailsEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    const response: VIDEO_DTO_COMPLETE_RES = await this.makeRequestWithRetry(
      VideoApi.videoDetailsEndpoint.path.replace("{{videoID}}", videoId),
      apiRequest,
      "Update Video Details Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getEncodingProgress(videoId: string): Promise<VIDEO_DTO_PROGRESS_RES> {
    let apiRequest = {
      method: VideoApi.videoEncodingProgressEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      VideoApi.videoEncodingProgressEndpoint.path.replace(
        "{{videoID}}",
        videoId
      ),
      apiRequest,
      "Get Video Encoding Progress Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getIndexerProgress(videoId: string): Promise<VIDEO_DTO_PROGRESS_RES> {
    let apiRequest = {
      method: VideoApi.videoIndexerProgressEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      VideoApi.videoIndexerProgressEndpoint.path.replace(
        "{{videoID}}",
        videoId
      ),
      apiRequest,
      "Get Video Indexer Progress Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getCaptions(video_id: string, language = "en-US") {
    let apiRequest = {
      method: VideoApi.videoGetCaptionsEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url =
      VideoApi.videoGetCaptionsEndpoint.path.replace("{{videoID}}", video_id) +
      "?language=" +
      language;

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Video Captions Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async generateQnAPairs(video_id: string) {
    let apiRequest = {
      method: VideoApi.videoGenerateQnAPairs.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url =
      VideoApi.videoGenerateQnAPairs.path.replace("{{videoID}}", video_id) +
      "?numberOfPairs=5";

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Generate QnA Pairs Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async updateThumbnail(video_id: string, imgFile: File) {
    const formData = new FormData();
    if (imgFile != null) {
      formData.append("file", imgFile as any);
    }
    let apiRequest = {
      method: VideoApi.videoUpdateThumbnail.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
      body: formData,
    };

    let url = VideoApi.videoUpdateThumbnail.path.replace(
      "{{videoID}}",
      video_id
    );

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Update Thumbnail Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getCaptionsMedia(
    video_id: string,
    media_id: string,
    language = "en-US"
  ) {
    let apiRequest = {
      method: VideoApi.mediaGetCaptionsEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url =
      VideoApi.mediaGetCaptionsEndpoint.path
        .replace("{{videoID}}", video_id)
        .replace("{{mediaID}}", media_id) +
      "?language=" +
      language;

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Video Captions Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  //Tells the backend to update the video captions with the latest captions from the indexer
  async updateCaptions(video_id: string) {
    let apiRequest = {
      method: VideoApi.updateVideoCaptionEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      VideoApi.updateVideoCaptionEndpoint.path.replace("{{videoID}}", video_id),
      apiRequest,
      "Update Video Caption Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async setVideoFiltersState(videoId: string, state: boolean) {
    let apiRequest = {
      method: VideoApi.videoFiltersStateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    let url =
      VideoApi.videoFiltersStateEndpoint.path.replace("{{videoID}}", videoId) +
      "?visibility=" +
      state;

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Set Video Filters State Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async setVideoChatGPTState(videoId: string, state: boolean) {
    let apiRequest = {
      method: VideoApi.videoChatGPTStateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ canAskChatGPT: state }),
    };

    let url = VideoApi.videoChatGPTStateEndpoint.path.replace(
      "{{videoID}}",
      videoId
    );

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Set Video Chat GPT State Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async setVideoReadOnlyState(videoId: string, state: boolean) {
    let apiRequest = {
      method: VideoApi.videoSetReadOnlyStateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    let url =
      VideoApi.videoSetReadOnlyStateEndpoint.path.replace(
        "{{videoID}}",
        videoId
      ) +
      "?readOnly=" +
      state;

    const response: VIDEO_DTO_COMPLETE_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Set Video Read Only State Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async setVoiceId(videoId: string, voiceId: string) {
    if (voiceId === null || voiceId === undefined || voiceId === "") {
      throw new Error("Voice Id is required");
    }
    if (videoId === null || videoId === undefined || videoId === "") {
      throw new Error("Video Id is required");
    }

    let apiRequest = {
      method: VideoApi.videoSetVoiceIdEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    let url = VideoApi.videoSetVoiceIdEndpoint.path.replace(
      "{{videoID}}",
      videoId
    );
    url += `?voiceId=${voiceId}`;

    const response: VIDEO_DTO_COMPLETE_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Set Video Voice Id Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async requestDub(videoId: string, languages: string[]) {
    let apiRequest = {
      method: VideoApi.requestDubEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ languages: languages }),
    };

    const response = await this.makeRequestWithRetry(
      VideoApi.requestDubEndpoint.path.replace("{{videoID}}", videoId),
      apiRequest,
      "Request Dub Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getDubLanguages(videoId: string): Promise<VIDEO_DUB_RES> {
    let apiRequest = {
      method: VideoApi.videoGetDubs.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      VideoApi.videoGetDubs.path.replace("{{videoID}}", videoId),
      apiRequest,
      "Get Dubs Failed",
      3,
      200
    );

    return response;
  }
  //#endregion Video Routes
}
