import { VIDEO_PUBLISH_STATE } from "@shared/models/video/video";
import { ChapterMap, VIDEO } from "../models/video/video";
import { timeSince } from "./utils";
import { secondsToTimeDisplay } from "./time-utils";
import {
  addMSToTimestamp,
  isZeroTime,
  timeStringToSeconds,
} from "./time-utils";

export const MAX_DESCRIPTION_LENGTH = 1000;
export const MAX_TITLE_LENGTH = 100;

export function getMetaInfo(videoData: VIDEO | null) {
  //Check publish status, if no published, show created date, if published show published date
  if (
    videoData?.video_publish_state == VIDEO_PUBLISH_STATE.PUBLIC &&
    videoData?.publish_date != null
  ) {
    return timeSince(new Date(videoData?.publish_date), new Date(), " ago");
  } else {
    if (videoData?.create_date == null) {
      return "";
    }

    return timeSince(new Date(videoData?.create_date), new Date(), " ago");
  }
}

export function getVideoCreateDate(videoData: VIDEO | null) {
  //Check publish status, if no published, show created date, if published show published date
  if (
    videoData?.video_publish_state == VIDEO_PUBLISH_STATE.PUBLIC &&
    videoData?.publish_date != null
  ) {
    return new Date(videoData?.publish_date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  } else {
    if (videoData?.create_date == null) {
      return "";
    }

    return new Date(videoData?.create_date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }
}

export async function getVideoFileMetaInfo(
  file: File
): Promise<HTMLVideoElement> {
  let video: HTMLVideoElement = document.createElement("video");
  video.preload = "metadata";

  let loadedPromise = new Promise<HTMLVideoElement>((resolve, reject) => {
    video.onloadedmetadata = () => {
      resolve(video);
    };
    video.onerror = () => {
      reject();
    };
  });

  video.src = URL.createObjectURL(file);

  return loadedPromise;
}

export function getVideoQnAModalId(edit: boolean) {
  return edit ? "edit-qna-modal" : "qna-modal";
}

/**
 *  Parses the timestamps from a string and returns a map of timestamps to chapter names
 * @param str - The string to parse the timestamps from
 * @returns A map of timestamps to chapter names
 */
export function parseTimestampsFromString(str: string): ChapterMap {
  // Regex to match timestamps in the format HH:MM:SS or MM:SS
  const timestampRegex =
    /(\d{2}:\d{2}:\d{2}(?:\.\d{3})?|\d{2}:\d{2}(?:\.\d{3})?)/g;
  const chapterMap: ChapterMap = {};
  let match;
  let firstTimestampFound = false;
  let chapterCount = 1;
  let previousTimestamp: string | null = null;

  // Loop through all the timestamps in the string
  while ((match = timestampRegex.exec(str)) !== null) {
    // Extract the timestamp and the title of the chapter
    // Titles are assumed to be the text between the current timestamp and the next timestamp
    const timestamp = match[0];
    const titleStart = match.index + timestamp.length;
    const titleEnd = str.slice(titleStart).search(timestampRegex);
    const title =
      titleEnd === -1
        ? str.slice(titleStart).trim()
        : str.slice(titleStart, titleStart + titleEnd).trim();

    if (!firstTimestampFound) {
      // Ensure the first timestamp is 00:00:00 or 00:00
      if (isZeroTime(timestamp)) {
        // We have a valid chapter list
        firstTimestampFound = true;
        chapterMap[timestamp] =
          title.length > 0
            ? title.length <= 50
              ? title
              : `${title.slice(0, 47)}...`
            : `Chapter ${chapterCount}`;
        previousTimestamp = timestamp;
        chapterCount++;
      }
    } else {
      // Add next chapter to map
      chapterMap[timestamp] =
        title.length > 0
          ? title.length <= 50
            ? title
            : `${title.slice(0, 47)}...`
          : `Chapter ${chapterCount}`;
      previousTimestamp = timestamp;
      chapterCount++;
    }
  }

  return chapterMap;
}

/**
 * Genearates a VTT file from a map of chapters
 * @param chapters  The map of chapters
 * @param duration The duration of the video
 * @returns
 */
export function generateChaptersVTT(
  chapters: ChapterMap,
  duration: number
): string {
  let vtt = "WEBVTT\n\n";
  let i = 0;

  const timestamps = Object.keys(chapters).sort((a, b) => {
    return timeStringToSeconds(a) - timeStringToSeconds(b);
  });

  for (let j = 0; j < timestamps.length; j++) {
    let startTime = timestamps[j];
    let endTime = timestamps[j + 1]
      ? timestamps[j + 1]
      : secondsToTimeDisplay(duration + 1, true);

    vtt += `${i + 1}\n${addMSToTimestamp(startTime)} --> ${addMSToTimestamp(
      endTime
    )}\n${chapters[startTime]}\n\n`;
    i++;
  }

  const vttBlob = new Blob([vtt], { type: "text/vtt" });
  return URL.createObjectURL(vttBlob);
}

/**
 * Genearates an ordered array with chapter times
 * @param chapters  The map of chapters
 * @returns
 */
export function getChapterTimes(chapters: ChapterMap): number[] {
  return Object.keys(chapters)
    .map((timeString) => timeStringToSeconds(timeString))
    .sort();
}

/**
 * Checks if the track type is metadata
 * @param trackType - The type of the track
 * @returns True if the track is metadata, false otherwise
 */
export function isTextTrackMetadata(trackType: string): boolean {
  return trackType == "metadata" || trackType == "chapters";
}

/**
 * Compares two chapter maps to see if they are the same
 * @param map1
 * @param map2
 * @returns
 */
export function compareChapterMaps(
  map1: ChapterMap,
  map2: ChapterMap
): boolean {
  if (Object.keys(map1).length !== Object.keys(map2).length) {
    return false;
  }

  for (const timestamp in map1) {
    if (!map2[timestamp] || map1[timestamp] !== map2[timestamp]) {
      return false;
    }
  }

  return true;
}

export function formatViews(views: number): string {
  if (views < 1000) {
    return views.toString();
  } else if (views < 1000000) {
    return `${(views / 1000).toFixed(1)}k`;
  } else {
    return `${(views / 1000000).toFixed(1)}m`;
  }
}

export function getLanguageName(code: string) {
  const languageGroups: { [key: string]: string } = {
    ar: "العربية",
    zh: "中文 (简体)",
    Zh: "中文 (简体)",
    en: "English (United States)",
    En: "English (United States)",
    fr: "Français (France)",
    Fr: "Français (France)",
    es: "Español (España)",
    pt: "Português (Brasil)",

    // Add more language groups as needed
  };
  // Extract language code prefix (e.g., "en" from "en-US")
  const languageCodePrefix = code.split("-")[0];

  // Check if the language code belongs to a language group
  const groupName = languageGroups[languageCodePrefix];
  if (groupName) {
    return groupName;
  } else {
    switch (code.toLowerCase()) {
      case "ar-eg":
        return "العربية (مصر)";
      case "ar-sy":
        return "العربية (سوريا)";
      case "zh-hans":
        return "中文 (简体)";
      case "en-us":
        return "English (United States)";
      case "en-gb":
        return "English (United Kingdom)";
      case "en-au":
        return "English (Australia)";
      case "fr-fr":
        return "Français (France)";
      case "de-de":
        return "Deutsch (Deutschland)";
      case "it-it":
        return "Italiano (Italia)";
      case "ja-jp":
        return "日本語 (日本)";
      case "pt-br":
        return "Português (Brasil)";
      case "ru-ru":
        return "Русский (Россия)";
      case "es-es":
        return "Español (España)";
      case "es-mx":
        return "Español (México)";
      case "fil-ph":
        return "Filipino (Pilipinas)";
      case "af-za":
        return "Afrikaans (Suid-Afrika)";
      case "bn-bd":
        return "বাংলা (বাংলাদেশ)";
      case "bs-latn":
        return "Bosanski (Bosna i Hercegovina)";
      case "bg-bg":
        return "Български (България)";
      case "ca-es":
        return "Català (Espanya)";
      case "hr-hr":
        return "Hrvatski (Hrvatska)";
      case "cs-cz":
        return "Čeština (Česká republika)";
      case "da-dk":
        return "Dansk (Danmark)";
      case "nl-nl":
        return "Nederlands (Nederland)";
      case "en-fj":
        return "English (Fiji)";
      case "en-ws":
        return "English (Samoa)";
      case "et-ee":
        return "Eesti (Eesti)";
      case "fi-fi":
        return "Suomi (Suomi)";
      case "el-gr":
        return "Ελληνικά (Ελλάδα)";
      case "fr-ht":
        return "Français (Haïti)";
      case "he-il":
        return "עברית (ישראל)";
      case "hi-in":
        return "हिन्दी (भारत)";
      case "hu-hu":
        return "Magyar (Magyarország)";
      case "id-id":
        return "Bahasa Indonesia (Indonesia)";
      case "sw-ke":
        return "Kiswahili (Kenya)";
      case "ko-kr":
        return "한국어 (대한민국)";
      case "lv-lv":
        return "Latviešu (Latvija)";
      case "lt-lt":
        return "Lietuvių (Lietuva)";
      case "mg-mg":
        return "Malagasy (Madagasikara)";
      case "ms-my":
        return "Bahasa Melayu (Malaysia)";
      case "mt-mt":
        return "Malti (Malta)";
      case "nb-no":
        return "Norsk (Norge)";
      case "fa-ir":
        return "فارسی (ایران)";
      case "pl-pl":
        return "Polski (Polska)";
      case "ro-ro":
        return "Română (România)";
      case "sr-cyrl-rs":
        return "Српски (Србија)";
      case "sr-latn-rs":
        return "Srpski (Srbija)";
      case "sk-sk":
        return "Slovenčina (Slovensko)";
      case "sl-si":
        return "Slovenščina (Slovenija)";
      case "sv-se":
        return "Svenska (Sverige)";
      case "ta-in":
        return "தமிழ் (இந்தியா)";
      case "th-th":
        return "ไทย (ไทย)";
      case "to-to":
        return "lea faka-Tonga (Tonga)";
      case "tr-tr":
        return "Türkçe (Türkiye)";
      case "uk-ua":
        return "Українська (Україна)";
      case "ur-pk":
        return "اردو (پاکستان)";
      case "vi-vn":
        return "Tiếng Việt (Việt Nam)";
      case "auto":
        return "Auto-Detect";
      case "zh-hant":
        return "中文 (繁體)";
      case "zh-cn":
        return "中文 (中国)";
      case "multi":
        return "Multiple Languages";
      default:
        return "Unknown";
    }
  }
}
