import { Injectable } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LocalizeGuardService {
  constructor(private m_Localize: LocalizationService) {}
  //Checks if the localization service has been initialized
  async canActivate(): Promise<boolean> {
    // Wait for the localization service to signal it's ready
    return new Promise((resolve) => {
      this.m_Localize.translationReady$.pipe(take(2)).subscribe((isReady) => {
        if (isReady) {
          resolve(isReady);
        }
      });
    });
  }
}
