import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ATTACHMENT_MIME_TYPE,
  ATTACHMENT_TYPE,
} from "src/app/models/record/record";
import {
  HIA_ATTACHMENT_CONTENT,
  HIA_MSG,
} from "src/app/services/chat/chat.service";
import { T } from "src/app/services/localization/localization.service";
import {
  UtteranceService,
  UtteranceState,
} from "src/app/services/utterance/utterance.service";
import { VideoService } from "src/app/services/video/video.service";
import { linkifyText } from "src/app/utility/utils";
import { secondsToTimeDisplay } from "src/app/utility/time-utils";

import { VideoPlayerComponent } from "../../video/video-player/video-player.component";
import { VIDEO } from "src/app/models/video/video";
import { VIDEO_STATUS } from "@shared/models/video/video";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import {
  hasMoreThanAHour,
  timeStringToSeconds,
} from "src/app/utility/time-utils";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import {
  TRACK_EVENT,
  TRACK_QUERY_DTO,
} from "src/app/models/analytics/analytics";
import { UiService } from "src/app/services/ui/ui.service";

@Component({
  selector: "app-qna-attachment-viewer",
  templateUrl: "./qna-attachment-viewer.component.html",
  styleUrls: ["./qna-attachment-viewer.component.scss"],
})
export class QnaAttachmentViewerComponent implements OnInit {
  @ViewChild("videoContainer") m_VideoContainer: ElementRef | undefined;
  @ViewChild("iframeContainer") m_IframeContainer: ElementRef | undefined;
  @ViewChild("mediaStartInput") m_MediaStartInput: ElementRef | null = null;
  @ViewChild("mediaEndInput") m_MediaEndInput: ElementRef | null = null;
  @ViewChild(VideoPlayerComponent) m_VideoPlayerComponent:
    | VideoPlayerComponent
    | undefined;

  @Output() resumeQvio = new EventEmitter<void>();
  @Input() m_AskMoreButton: boolean = true;

  public $t = T.translate;
  public m_FetchingMedia = false;

  get ActiveAttachmentMsg(): HIA_MSG | null {
    return this.m_ActiveAttachmentMsg;
  }

  private m_ActiveAttachmentMsg: HIA_MSG | null = null;

  public m_MediaStart?: number;
  public m_MediaEnd?: number;
  public m_isMediaStartValid?: boolean = true;
  public m_isMediaEndValid?: boolean = true;
  public m_MediaStartValue: string | number = 0;
  public m_MediaEndValue: string | number = 0;
  public m_HasHoursDuration: boolean = false;
  public m_ThumbsUp: boolean = false;
  public m_ThumbsDown: boolean = false;

  constructor(
    private m_UtteranceService: UtteranceService,
    private m_VideoService: VideoService,
    private m_Events: Events,
    private m_Analytics: AnalyticsService,
    private m_UiService: UiService
  ) {}

  ngOnInit() {}

  async initVideo() {
    if (
      this.ActiveAttachmentMsg &&
      this.ActiveAttachmentMsg.attachments &&
      this.ActiveAttachmentMsg.attachments.length > 0
    ) {
      let attachmentContents: HIA_ATTACHMENT_CONTENT =
        this.ActiveAttachmentMsg.attachments[0].content;

      this.m_MediaStart = attachmentContents?.segment?.start ?? 0.0;
      this.m_MediaEnd = attachmentContents?.segment?.end ?? 0.0;

      let videoId =
        attachmentContents?.segment != null
          ? attachmentContents.main_video_id
          : attachmentContents.video_id;

      if (videoId == null) return;

      let videoUrl: string | undefined;
      if (attachmentContents?.segment != null) {
        let mainVideoData = await this.m_VideoService.fetchVideo(videoId);
        videoUrl = mainVideoData.video_url;
      } else {
        videoUrl = this.getAttachmentUrl();
      }

      let video: VIDEO = new VIDEO({
        id: videoId,
        video_url: videoUrl,
        user_id: "",
        video_status: VIDEO_STATUS.READY,
        video_index_status: VIDEO_STATUS.READY,
        video_name: "",
        voice_id: "",
        can_ask_chatgpt: false,
        is_360_video: false, //Todo support 360 video on attachment viewer
      });

      let mainVideoId =
        this.ActiveAttachmentMsg.attachments[0].content.main_video_id;

      if (videoId != null && mainVideoId != null) {
        let tracks = null;
        if (attachmentContents?.segment == null) {
          tracks = await this.getTextTracksFrom(videoId, mainVideoId);
        }

        let showingCaption = await this.m_VideoService.getShowingCaption(
          mainVideoId
        );

        this.m_VideoPlayerComponent?.buildVideoPlayer(
          video,
          tracks,
          showingCaption,
          this.m_ActiveAttachmentMsg?.onSetSegment == null
            ? attachmentContents.segment
            : null,
          0,
          false,
          () => {
            let autoplay = this.m_ActiveAttachmentMsg?.attachmentOnly ?? true;
            this.onVideoLoaded(autoplay);
          }
        );
      }
    }
  }

  //#region Event handlers
  onShowAttachment(msg: HIA_MSG) {
    this.m_ActiveAttachmentMsg = msg;
    this.m_FetchingMedia = true;
    if (this.shouldDisplayVideoAttachment()) {
      setTimeout(() => {
        this.initVideo();
      }, 100);
    } else if (this.shouldDisplayIframeAttachment()) {
      setTimeout(() => {
        this.m_IframeContainer?.nativeElement.setAttribute(
          "src",
          this.getAttachmentUrl()
        );
      }, 100);
    }
  }

  onShowAttachmentURL(url: string, contentType: string) {
    this.m_ActiveAttachmentMsg = {
      msgUID: "",
      author: { id: "" },
      attachments: [
        {
          content: {
            src: url,
          },
          contentType: contentType,
        },
      ],
    };
    this.m_FetchingMedia = true;
    if (this.shouldDisplayVideoAttachment()) {
      setTimeout(() => {
        this.initVideo();
      }, 100);
    }
  }

  onCloseAttachmentClicked(closeParent = false) {
    this.m_UtteranceService.stop(true);
    this.m_ActiveAttachmentMsg = null;
    this.cleanupVideoPlayer();
    this.m_IframeContainer?.nativeElement.setAttribute("src", "");
    this.m_ThumbsUp = false;
    this.m_ThumbsDown = false;
    this.m_Events.publish(EVENTS.ATTACHMENT_HIDE, closeParent);
  }

  onMediaWillLoad() {
    this.m_FetchingMedia = true;
  }

  onMediaLoaded() {
    this.m_FetchingMedia = false;
  }

  onThumbsClicked(value: boolean) {
    if (!this.ActiveAttachmentMsg?.attachments) return;
    //Blocks the user to spam thumbs up or down
    if (this.m_ThumbsUp || this.m_ThumbsDown) return;
    this.m_ThumbsUp = value;
    this.m_ThumbsDown = !value;

    let sessionId = this.m_Analytics.CurrWatchSessionId;
    let originalQuestion = this.ActiveAttachmentMsg.msgUID;

    let response_type = this.m_ActiveAttachmentMsg?.response_type;
    let videoId =
      this.ActiveAttachmentMsg?.attachments[0]?.content.main_video_id;
    let answerText = this.m_ActiveAttachmentMsg?.text;
    let recordId = this.m_ActiveAttachmentMsg?.recordId;
    let recordScore = this.m_ActiveAttachmentMsg?.record_score;
    let insightKey = this.m_ActiveAttachmentMsg?.insightInfo?.insight_key;
    let insightName = this.m_ActiveAttachmentMsg?.insightInfo?.insight_name;
    if (!sessionId) return;

    let metadata: TRACK_QUERY_DTO = {
      sessionId: sessionId,
      videoId: videoId,
      question: originalQuestion,
      questionTyped: false,
      response_type: response_type ?? "none",
      answer: answerText ?? "",
      record_id: recordId ?? "",
      record_score: recordScore ?? undefined,
      insight_key: insightKey ?? "",
      insight_name: insightName ?? "",
      thumbsUpDown: value,
    };

    this.m_Analytics.trackEvent(TRACK_EVENT.VIDEO_FEEDBACK, metadata);
  }
  //#endregion

  //#region Render helpers
  //Helper function used to get the attachment url for display in attachment mode
  getAttachmentUrl(): string {
    if (
      this.m_ActiveAttachmentMsg == null ||
      this.m_ActiveAttachmentMsg.attachments == null ||
      this.m_ActiveAttachmentMsg.attachments?.length == 0
    )
      return "";

    return this.m_ActiveAttachmentMsg?.attachments[0]?.content?.src;
  }

  //Helper function used to get the attachment type for display in attachment mode
  getAttachmentType(): ATTACHMENT_TYPE | null {
    if (
      this.m_ActiveAttachmentMsg == null ||
      this.m_ActiveAttachmentMsg.attachments == null ||
      this.m_ActiveAttachmentMsg.attachments?.length == 0
    )
      return null;

    let attachment = this.m_ActiveAttachmentMsg.attachments[0];

    if (attachment.content.segment != null)
      return ATTACHMENT_TYPE.VIDEO_SEGMENT;
    if (attachment.contentType == ATTACHMENT_MIME_TYPE.IMAGE)
      return ATTACHMENT_TYPE.IMAGE;
    else if (attachment.contentType == ATTACHMENT_MIME_TYPE.VIDEO)
      return ATTACHMENT_TYPE.VIDEO;
    else if (attachment.contentType == ATTACHMENT_MIME_TYPE.WEBURL)
      return ATTACHMENT_TYPE.WEBURL;
    else return null;
  }

  //Helper function used to get the attachment description for display in attachment mode
  getAttachmentDesc() {
    let text = "";
    if (!this.m_ActiveAttachmentMsg?.attachmentOnly) {
      text = this.m_ActiveAttachmentMsg?.text ?? "";
    }
    return linkifyText(text);
  }
  shouldDisplayImageAttachment(): boolean {
    return this.getAttachmentType() == ATTACHMENT_TYPE.IMAGE;
  }

  shouldDisplayVideoAttachment(): boolean {
    return (
      this.getAttachmentType() == ATTACHMENT_TYPE.VIDEO ||
      this.getAttachmentType() == ATTACHMENT_TYPE.VIDEO_SEGMENT
    );
  }

  shouldDisplayIframeAttachment(): boolean {
    return this.getAttachmentType() == ATTACHMENT_TYPE.WEBURL;
  }

  shouldDisplayAttachmentText(): boolean {
    return (
      this.m_ActiveAttachmentMsg != null &&
      this.m_ActiveAttachmentMsg.attachmentOnly != true
    );
  }

  shouldDisplayAskMoreButton(): boolean {
    return this.m_AskMoreButton;
  }
  //#endregion

  private async getTextTracksFrom(
    videoId: string,
    mainVideoId: string
  ): Promise<Array<any>> {
    let captions = await this.m_VideoService.getVideoCaptions(mainVideoId);

    let newTrack = [];

    if (captions.length) {
      for (let i = 0; i < captions.length; i++) {
        try {
          const videoCaptions = await this.m_VideoService.getCaptionsMedia(
            mainVideoId,
            videoId,
            captions[i].language
          );

          const blob = new Blob([videoCaptions.captions], {
            type: "text/vtt",
          });

          let track = {
            kind: "captions",
            label: captions[i].label,
            srclang: captions[i].language,
            src: URL.createObjectURL(blob),
            default: false,
          };

          newTrack.push(track);
        } catch {
          console.log("Error to get captions for: ", captions[i].label);
        }
      }
    }

    return newTrack;
  }

  private onVideoLoaded(autoplay: boolean) {
    this.m_VideoPlayerComponent?.setAutoPlay(autoplay);

    //If the utterance service is loading, wait for it to finish before displaying the video paused
    if (this.m_UtteranceService.State == UtteranceState.LOADING) {
      let loadSub = this.m_UtteranceService.m_OnAudioPlay.subscribe(() => {
        loadSub.unsubscribe();
        this.onVideoLoaded(autoplay);
      });
      this.m_VideoPlayerComponent?.pause();
      return;
    }
    if (this.m_VideoPlayerComponent?.Duration)
      this.m_HasHoursDuration = hasMoreThanAHour(
        this.m_VideoPlayerComponent?.Duration
      );

    this.m_MediaStartValue = secondsToTimeDisplay(
      this.m_MediaStart || 0,
      this.m_HasHoursDuration
    );
    this.m_MediaEndValue = secondsToTimeDisplay(
      this.m_MediaEnd || 0,
      this.m_HasHoursDuration
    );

    //Flag loading as false
    this.m_FetchingMedia = false;
    //Wait for the utterance to finish before playing the video
    if (this.m_UtteranceService.State == UtteranceState.PLAYING) {
      this.m_VideoPlayerComponent?.pause();
      let audioEndSub = this.m_UtteranceService.m_OnAudioStop.subscribe(() => {
        this.m_VideoPlayerComponent?.play();
        audioEndSub.unsubscribe();
      });
    }
  }

  onSetStart() {
    let currentTime = this.m_VideoPlayerComponent?.CurrentTime;
    if (currentTime == null) return;
    this.m_isMediaStartValid = true;
    this.m_MediaStart = Math.round(currentTime);
    this.m_MediaStartValue = secondsToTimeDisplay(
      currentTime,
      this.m_HasHoursDuration
    );
  }

  onSetEnd() {
    let currentTime = this.m_VideoPlayerComponent?.CurrentTime;
    if (currentTime == null) return;
    this.m_isMediaEndValid = true;
    this.m_MediaEnd = Math.round(currentTime);
    this.m_MediaEndValue = secondsToTimeDisplay(
      currentTime,
      this.m_HasHoursDuration
    );
  }

  onSetStartInput(value: any) {
    let currentTime = value.target?.value;
    if (currentTime == null) return;

    let totalTime = timeStringToSeconds(currentTime);
    this.m_MediaStart = totalTime;

    if (currentTime.includes(":")) {
      this.m_isMediaStartValid = true;
    } else this.m_isMediaStartValid = false;
  }

  onSetEndInput(value: any) {
    let currentTime = value.target?.value;
    if (currentTime == null) return;

    let totalTime = timeStringToSeconds(currentTime);
    this.m_MediaEnd = totalTime;

    if (currentTime.includes(":")) {
      this.m_isMediaEndValid = true;
    } else this.m_isMediaEndValid = false;
  }

  getInputTooltip() {
    let tooltipText = "";
    if (this.m_HasHoursDuration)
      tooltipText = this.$t("components.qnaEditor.timeHoursFormat");
    else tooltipText = this.$t("components.qnaEditor.timeFormat");

    if (!this.isSegmentValid())
      tooltipText = `${tooltipText}\n\n${this.$t(
        "components.qnaEditor.validTimeFormat"
      )}`;

    return tooltipText;
  }

  isSegmentValid() {
    let duration = this.m_VideoPlayerComponent?.Duration;

    if (
      this.m_MediaStart == null ||
      this.m_MediaEnd == null ||
      duration == null
    ) {
      return false;
    }

    return (
      this.m_MediaStart >= 0 &&
      this.m_MediaStart <= duration &&
      this.m_MediaEnd >= 0 &&
      this.m_MediaEnd <= duration &&
      this.m_MediaStart < this.m_MediaEnd
    );
  }

  onSaveSegment() {
    if (
      this.getAttachmentType() == ATTACHMENT_TYPE.VIDEO_SEGMENT &&
      this.m_ActiveAttachmentMsg?.onSetSegment != null &&
      this.m_MediaStart != null &&
      this.m_MediaEnd != null
    ) {
      this.m_ActiveAttachmentMsg.onSetSegment({
        start: this.m_MediaStart,
        end: this.m_MediaEnd,
      });

      this.onCloseAttachmentClicked(true);
    }
  }

  shouldDisplaySegmentControls(): boolean {
    return (
      this.getAttachmentType() == ATTACHMENT_TYPE.VIDEO_SEGMENT &&
      this.m_ActiveAttachmentMsg?.onSetSegment != null
    );
  }

  private cleanupVideoPlayer() {
    if (this.m_VideoPlayerComponent != null) {
      this.m_VideoPlayerComponent.dispose();
    }
  }

  shouldDisplayThumbs() {
    return this.m_UiService.WatchVideo;
  }
}
