import { DUB_LANGUAGE_DTO } from "@shared/models/voice/voice";

export class DUB_LANGUGAGE {
  language_id: string;
  name: string;

  constructor(dto: DUB_LANGUAGE_DTO) {
    this.language_id = dto.language_id;
    this.name = dto.name;
  }
}
