import { BaseApiService } from "../base-api-service";
import { SEND_MESSAGE_REQ_DTO } from "@shared/models/actions/send-message";
import { UserService } from "src/app/services/user/user.service";
import { BASE_RESPONSE } from "@shared/models/api/api";
import { SEARCH_DTO_RES } from "@shared/models/actions/search";
import { Events } from "src/app/services/events/events.service";

export class ActionApi extends BaseApiService {
  private static readonly sendMessageEndpoint = {
    method: "POST",
    path: "/api/action/send-message",
  };

  private static readonly globalSearchEndpoint = {
    method: "POST",
    path: "/api/action/search",
  };

  //--------------------------------------------------------------------
  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }
  //--------------------------------------------------------------------
  //#region Public Methods

  public async sendMessage(
    request: SEND_MESSAGE_REQ_DTO
  ): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: ActionApi.sendMessageEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(request),
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      ActionApi.sendMessageEndpoint.path,
      apiRequest,
      "Failed to send message",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  public async globalSearch(
    query: string,
    pageNumber: number,
    pageSize: number
  ): Promise<SEARCH_DTO_RES> {
    let apiRequest = {
      method: ActionApi.globalSearchEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({
        query: query,
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    };

    const response: SEARCH_DTO_RES = await this.makeRequestWithRetry(
      ActionApi.globalSearchEndpoint.path,
      apiRequest,
      "Failed to search for query: " + query,
      3
    );

    return response;
  }
  //#endregion
}
