import { Component, ElementRef, ViewChild } from "@angular/core";
import { UploadService } from "src/app/services/upload/upload.service";
import {
  AlertModalComponent,
  CustomAlertInput,
} from "../modals/alert-modal/alert-modal.component";
import { uuidToShortId } from "@shared/utils/utils";
import { Router } from "@angular/router";
import { T } from "src/app/services/localization/localization.service";
import { VIDEO } from "src/app/models/video/video";

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.css"],
})
export class UploadComponent {
  @ViewChild("alertModal", { read: AlertModalComponent })
  m_AlertModal: AlertModalComponent | undefined;
  @ViewChild("uploader", { read: ElementRef, static: true })
  m_Uploader: ElementRef | undefined;

  public $t = T.translate;

  private m_Qvio?: VIDEO;
  private m_FolderId?: string;

  constructor(
    private m_Router: Router,
    private m_UploadService: UploadService
  ) {}

  openFileDialog(qvio?: VIDEO, folderId?: string) {
    this.m_Qvio = qvio;
    this.m_FolderId = folderId;
    this.m_Uploader?.nativeElement?.click();
  }

  async onFileInputChanged(event: any, folderId?: string) {
    //Verify a file was selected
    if (event.target == null || event.target.files.length == 0) {
      this.reset();
      return;
    }

    if (folderId != null) this.m_FolderId = folderId;

    let file = event.target.files[0];
    event.target.value = null;

    this.showAlertModal(this.$t("shared.messages.initiatingUpload"), false);

    let videoId: string | null = null;
    try {
      videoId = await this.m_UploadService.startUpload(
        file,
        this.m_Qvio,
        this.m_FolderId
      );
    } catch (error: any) {
      this.m_AlertModal?.onCancelClicked();

      let errorMessage = error["message"] || "Unknown error";
      await this.showAlertModal(errorMessage, false, [
        {
          label: "Close",
          close: true,
        },
      ]);
    }

    if (this.m_AlertModal?.m_IsOpen && videoId != null) {
      this.m_AlertModal?.onCancelClicked();

      setTimeout(() => {
        this.reset();
        this.m_Router.navigate(["/edit"], {
          queryParams: { v: uuidToShortId(videoId ?? "") },
        });
      }, 10);
    }
  }

  private async showAlertModal(
    message: string,
    allowCancel?: boolean,
    buttons?: any[] | null,
    inputs?: CustomAlertInput[] | null,
    okFunction?: (value?: string) => Promise<void>,
    cancelFunction?: () => void,
    inputChangeFunction?: (value: string) => void
  ) {
    if (this.m_AlertModal == null) return Promise.resolve({ ok: false });

    this.m_AlertModal.m_MainText = message;
    this.m_AlertModal.m_AllowCancel = allowCancel ?? true;
    this.m_AlertModal.m_CustomButtons = buttons;
    this.m_AlertModal.m_CustomInputs = inputs;
    this.m_AlertModal.m_AllowBackdropDismiss = false;

    return await this.m_AlertModal?.show(
      okFunction ?? undefined,
      cancelFunction ?? (() => {}),
      inputChangeFunction ?? ((value: string) => {})
    );
  }

  private reset() {
    this.m_Qvio = undefined;
    this.m_FolderId = undefined;
  }
}
