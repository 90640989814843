// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal::part(content) {
  width: 600px;
  height: 440px;
}

.feedback-title {
  text-align: center;
}

.feedback-container {
  width: 100%;
  height: 100%;
}
.feedback-container .feedback-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 3rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e5e5;
}
.feedback-container .feedback-header .feedback-subtitle h2 {
  font-size: 1.2rem;
  font-weight: 400;
}
.feedback-container .feedback-body {
  margin: 0 1rem;
}
.feedback-container .feedback-body .feedback-input {
  width: 100%;
  height: 250px;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}
.feedback-container .feedback-body .feedback-input ion-textarea {
  height: 100%;
}
.feedback-container .feedback-body .feedback-submit {
  display: flex;
}
.feedback-container .feedback-body .feedback-submit ion-button {
  margin-left: auto;
}

@media (max-width: 600px) {
  ion-modal::part(content) {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/modals/feedback-modal/feedback-modal.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,aAAA;AAAJ;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;AADF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;EACA,gCAAA;AADJ;AAIM;EACE,iBAAA;EACA,gBAAA;AAFR;AAOE;EACE,cAAA;AALJ;AAOI;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AALN;AAOM;EACE,YAAA;AALR;AASI;EACE,aAAA;AAPN;AAQM;EACE,iBAAA;AANR;;AAYA;EAEI;IACE,UAAA;EAVJ;AACF","sourcesContent":["ion-modal {\n  &::part(content) {\n    width: 600px;\n    height: 440px;\n  }\n}\n\n.feedback-title {\n  text-align: center;\n}\n\n.feedback-container {\n  width: 100%;\n  height: 100%;\n\n  .feedback-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 1rem;\n    height: 3rem;\n    background-color: #f5f5f5;\n    border-bottom: 1px solid #e5e5e5;\n\n    .feedback-subtitle {\n      h2 {\n        font-size: 1.2rem;\n        font-weight: 400;\n      }\n    }\n  }\n\n  .feedback-body {\n    margin: 0 1rem;\n\n    .feedback-input {\n      width: 100%;\n      height: 250px;\n      font-size: 1rem;\n      font-weight: 400;\n      margin-bottom: 1.5rem;\n\n      ion-textarea {\n        height: 100%;\n      }\n    }\n\n    .feedback-submit {\n      display: flex;\n      ion-button {\n        margin-left: auto;\n      }\n    }\n  }\n}\n\n@media (max-width: 600px) {\n  ion-modal {\n    &::part(content) {\n      width: 90%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
