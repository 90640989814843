import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";

export enum StorageState {
  NotInitialized,
  Initializing,
  Browser,
  Custom,
}

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private m_StorageState: StorageState;
  private m_CustomStorage: { [key: string]: any };
  private m_Storage: Storage | null = null;
  private m_InitPromise: Promise<void> | null = null;

  constructor(private m_BrowserStorage: Storage) {
    this.m_StorageState = StorageState.NotInitialized;
    this.m_CustomStorage = {};
  }

  async initializeStorage(): Promise<void> {
    if (this.m_InitPromise == null) {
      this.m_InitPromise = this.initialize();
    }

    return this.m_InitPromise;
  }

  async clear(): Promise<void> {
    if (
      this.m_Storage == null ||
      this.m_StorageState == StorageState.NotInitialized ||
      this.m_StorageState == StorageState.Initializing
    ) {
      await this.initializeStorage.call(this);
    }

    try {
      if (
        this.m_Storage == null ||
        this.m_StorageState == StorageState.NotInitialized ||
        this.m_StorageState == StorageState.Initializing
      ) {
        throw new Error("Storage not initialized yet.");
      } else if (this.m_StorageState == StorageState.Browser) {
        await this.m_Storage.clear();
      } else {
        this.m_CustomStorage = {};
      }
    } catch (error) {
      throw error;
    }
  }

  private async initialize(): Promise<void> {
    //Resolve if already initialized
    if (this.m_StorageState != StorageState.NotInitialized) {
      return;
    }

    this.m_StorageState = StorageState.Initializing;

    try {
      this.m_Storage = await this.m_BrowserStorage.create();
      await this.m_Storage.set("HIA_TEST_KEY", "HIA_TEST_VALUE");
      await this.m_Storage.get("HIA_TEST_KEY");
      await this.m_Storage.remove("HIA_TEST_KEY");

      this.m_StorageState = StorageState.Browser;
    } catch (error) {
      //this.m_logger.warn("Browser storage not available!");

      this.m_StorageState = StorageState.Custom;
    }
  }

  async get(key: string): Promise<any> {
    if (
      this.m_Storage == null ||
      this.m_StorageState == StorageState.NotInitialized ||
      this.m_StorageState == StorageState.Initializing
    ) {
      await this.initializeStorage.call(this);
    }

    try {
      if (
        this.m_Storage == null ||
        this.m_StorageState == StorageState.NotInitialized ||
        this.m_StorageState == StorageState.Initializing
      ) {
        throw new Error("Storage not initialized yet.");
      } else if (this.m_StorageState == StorageState.Browser) {
        const result = await this.m_Storage.get(key);
        return result;
      } else {
        return this.m_CustomStorage[key];
      }
    } catch (error) {
      throw error;
    }
  }

  set(key: string, value: any): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (
        this.m_Storage == null ||
        this.m_StorageState == StorageState.NotInitialized ||
        this.m_StorageState == StorageState.Initializing
      ) {
        reject("Storage not initialized yet.");
      } else if (this.m_StorageState == StorageState.Browser) {
        this.m_Storage
          .set(key, value)
          .then((result: any) => resolve(result))
          .catch((error: any) => reject(error));
      } else {
        this.m_CustomStorage[key] = value;
        resolve(value);
      }
    });
  }

  remove(key: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (
        this.m_Storage == null ||
        this.m_StorageState == StorageState.NotInitialized ||
        this.m_StorageState == StorageState.Initializing
      ) {
        reject("Storage not initialized yet.");
      } else if (this.m_StorageState == StorageState.Browser) {
        this.m_Storage
          .remove(key)
          .then((result: any) => resolve(result))
          .catch((error: any) => reject(error));
      } else {
        delete this.m_CustomStorage[key];
        resolve();
      }
    });
  }
}
