export interface DataCacheEntry<T> {
  fetchPromise: Promise<T>;
  timestamp: number; // Unix timestamp in milliseconds
}

export class DataCache<T> {
  protected dataCache: Map<string, DataCacheEntry<T>>;
  protected cacheExpiryTime: number;

  constructor(cacheExpireTime: number = 0) {
    this.dataCache = new Map<string, DataCacheEntry<T>>();
    this.cacheExpiryTime = cacheExpireTime;
  }

  /**
   * Gets the cached data if it exists and is not expired
   * @param key
   */
  getCachedData(key: string): T | undefined {
    const cachedEntry = this.dataCache.get(key);

    if (cachedEntry && !this.isCacheExpired(cachedEntry.timestamp)) {
      return cachedEntry.fetchPromise as T;
    }

    return undefined;
  }

  /**
   * Sets the cached data with the new value
   * @param key
   * @param value
   */
  setCachedData(key: string, value: Promise<T>): void {
    this.dataCache.set(key, {
      fetchPromise: value,
      timestamp: Date.now(),
    });
  }

  /**
   * Updates the cached data with the new value
   * @param key
   * @param value
   */
  updateCachedData(key: string, value: T): void {
    const cachedEntry = this.dataCache.get(key);

    if (cachedEntry) {
      let cachedData = cachedEntry.fetchPromise as T;
      const updatedData = { ...cachedData, ...value };

      this.dataCache.set(key, {
        fetchPromise: Promise.resolve(updatedData),
        timestamp: Date.now(),
      });
    }
  }

  /**
   * Clears the cache entirely
   */
  clear(): void {
    this.dataCache.clear();
  }

  /**
   * Removes a specific key from the cache
   * @param unique key
   */
  remove(key: string): void {
    this.dataCache.delete(key);
  }

  /**
   * Checks if the cache is expired
   * @param timestamp
   */
  protected isCacheExpired(timestamp: number): boolean {
    return Date.now() - timestamp > this.cacheExpiryTime;
  }
}
