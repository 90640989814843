import { Component, Input, OnInit } from "@angular/core";
import { Params, Router } from "@angular/router";
import { T } from "src/app/services/localization/localization.service";
import { SessionService } from "src/app/services/session/session.service";

interface NavbarOption {
  text: string;
  route: string;
  icon?: string;
  iconSrc?: string;
  queryParams?: Params;
  action?: () => void;
}

@Component({
  selector: "app-tabs-navbar",
  templateUrl: "./tabs-navbar.component.html",
  styleUrls: ["./tabs-navbar.component.scss"],
})
export class TabsNavbarComponent implements OnInit {
  @Input() m_CurrentRoute: { route: string; queryParams: Params };

  get LoggedIn() {
    return this.m_AuthService.LoggedIn;
  }

  get Options() {
    return this.LoggedIn ? this.m_LoggedInOptions : this.m_LoggedOutOptions;
  }

  public m_LoggedInOptions: NavbarOption[] = [];
  public m_LoggedOutOptions: NavbarOption[] = [];
  public $t = T.translate;

  constructor(private m_Router: Router, private m_AuthService: SessionService) {
    this.m_CurrentRoute = { route: "/", queryParams: {} };
    this.initOptions();
  }

  ngOnInit() {}

  onOptionClicked(optionIndex: number) {
    const option = this.Options[optionIndex];
    if (option.action) {
      option.action();
    } else {
      this.m_Router.navigate([option.route], {
        queryParams: option.queryParams,
      });
    }
  }

  getSelectedClass(opt: NavbarOption) {
    return opt.route === this.m_CurrentRoute.route;
  }

  private initOptions() {
    this.m_LoggedInOptions = [
      { text: this.$t("components.navigation.home"), icon: "home", route: "/" },
      {
        text: this.$t("components.navigation.myQvios"),
        iconSrc: "assets/icon/tabbar/switch_video.svg",
        route: "/library",
      },
      {
        text: this.$t("components.navigation.account"),
        icon: "person-circle",
        route: "/edit-profile",
      },
      {
        text: this.$t("components.navigation.contactUs"),
        icon: "mail",
        route: "/contact",
      },
    ];

    this.m_LoggedOutOptions = [
      { text: this.$t("components.navigation.home"), icon: "home", route: "/" },
      {
        text: this.$t("components.navigation.contactUs"),
        icon: "mail",
        route: "/contact",
      },
      {
        text: this.$t("components.navigation.account"),
        icon: "log-in",
        route: "/login",
      },
    ];
  }
}
