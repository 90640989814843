import { NgModule } from "@angular/core";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicStorageModule } from "@ionic/storage-angular";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ListViewModule } from "@progress/kendo-angular-listview";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { SharedModule } from "./components/shared/shared.module";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { TreeListModule } from "@progress/kendo-angular-treelist";
import "hammerjs";
import { SortableModule } from "@progress/kendo-angular-sortable";

class CustomHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    press: { time: 400, threshold: 24 },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: "md" }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    ProgressBarModule,
    BrowserAnimationsModule,
    ListViewModule,
    NotificationModule,
    SharedModule,
    ChartsModule,
    TreeListModule,
    HammerModule,
    SortableModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
