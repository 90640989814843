import { FOLDER_DTO, TransformedFolder } from "@shared/models/folders/folder";
import { T } from "src/app/services/localization/localization.service";

export class FolderHierarchy {
  private $t = T.translate;
  private m_FoldersTree: TransformedFolder[];
  private m_FoldersMap?: Map<string, TransformedFolder>;

  constructor(data: FOLDER_DTO[]) {
    this.m_FoldersTree = this.transformFoldersToTree(data);
  }

  //Needed for kendo
  getTree(): TransformedFolder[] {
    return this.m_FoldersTree;
  }

  getRoot(): TransformedFolder | undefined {
    if (this.isEmpty()) return;
    else return this.m_FoldersTree[0];
  }

  clearData() {
    this.m_FoldersMap = undefined;
    this.m_FoldersTree = [];
  }

  isEmpty() {
    return this.m_FoldersTree.length <= 0 || this.m_FoldersMap == undefined;
  }

  findFolder(folderID: string): TransformedFolder | undefined {
    return this.m_FoldersMap?.get(folderID.toLowerCase());
  }

  findFolders(
    predicate: (value: TransformedFolder) => boolean
  ): TransformedFolder[] | undefined {
    if (this.m_FoldersMap == null) return;
    else
      return Array.from(this.m_FoldersMap, ([_, value]) => value).filter(
        predicate
      );
  }

  isAncestorInTree(
    folder: TransformedFolder | string,
    ancestorId: string
  ): boolean {
    let currentFolder: TransformedFolder | undefined;

    if (typeof folder === "string") {
      currentFolder = this.findFolder(folder as string);
    } else {
      currentFolder = folder;
    }

    while (currentFolder != null) {
      if (currentFolder.folderData.id == ancestorId) {
        return true;
      }
      currentFolder = currentFolder.parent;
    }

    return false;
  }

  //TODO: assign a child number during tree creation so we don't need to search by index
  getFolderLeafPath(folderId: string, path: string = ""): string {
    let node = this.findFolder(folderId);

    while (node?.parent != null) {
      let suffix = path != "" ? "_" + path : "";
      path = this.getNodeIndex(node) + suffix;
      node = node.parent;
    }

    return path;
  }

  getFolderLeafPathArray(folderId: string, limit?: number): FOLDER_DTO[] {
    let node = this.findFolder(folderId);

    let path = [];
    let count = 0;
    while (node?.parent != null) {
      count++;
      path.unshift(node.folderData);
      node = node.parent;

      if (limit != null && count >= limit) break;
    }

    return path;
  }

  getFolderNameForCreation(folders?: FOLDER_DTO[]): string {
    let newFolderName = this.$t("pages.library.untitled_folder");
    const untitledFolders =
      folders != null
        ? folders.filter((folder) => folder.name.startsWith(newFolderName))
        : this.findFolders((item) =>
            item.folderData.name.startsWith(newFolderName)
          )?.map((item) => item.folderData);

    if (untitledFolders != null && untitledFolders.length > 0) {
      const numbers = untitledFolders.map((folder) => {
        const match = folder.name.match(new RegExp(`${newFolderName} (\\d+)`));
        return match ? parseInt(match[1], 10) : 0;
      });
      const maxNumber = Math.max(...numbers);
      newFolderName = `${this.$t("pages.library.untitled_folder")} ${
        maxNumber + 1
      }`;
    }

    return newFolderName;
  }

  private getNodeIndex(node: TransformedFolder): number {
    if (node.parent == null) {
      return 0;
    } else {
      return node.parent.items!.findIndex(
        (item) => item.folderData.id == node?.folderData.id
      );
    }
  }

  private transformFoldersToTree(folders: FOLDER_DTO[]): TransformedFolder[] {
    const folderMap = new Map<string, TransformedFolder>();
    const addedFolders = new Set<string>();
    let currentId = 2; // We start at two, since the first folder will always be "My Library"

    // Set an index for each folder
    folders.forEach((folder) => {
      folderMap.set(folder.id.toLowerCase(), {
        id: currentId++,
        folderData: folder,
        items: [],
      });
    });

    // Set the folders order and hierarchy
    folders.forEach((folder) => {
      if (folder.parent_id) {
        const parentFolder = folderMap.get(folder.parent_id.toLowerCase());
        const currentFolder = folderMap.get(folder.id.toLowerCase());
        if (parentFolder && currentFolder) {
          parentFolder.items!.push(currentFolder);
          currentFolder.parent = parentFolder;
          addedFolders.add(folder.id.toLowerCase()); // Add fodlers to each other
        }
      }
    });

    const libraryNode: TransformedFolder = {
      id: 1,
      folderData: {
        id: "0",
        name: "My Library",
        created_at: new Date(2000, 0),
        updated_at: new Date(2000, 0),
      },
      items: [],
    };

    // Create the final array using TransformedFolders Type
    const rootFolders: TransformedFolder[] = [];
    folderMap.forEach((folder, id) => {
      if (!addedFolders.has(id.toLowerCase())) {
        folder.parent = libraryNode;
        rootFolders.push(folder);
      }
    });

    this.m_FoldersMap = folderMap;

    // add "My Library" as the root folder
    libraryNode.items = rootFolders;

    return [libraryNode];
  }
}
