import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { FormsModule } from "@angular/forms";
import { FolderModalComponent } from "./folders-modal.component";
import { TreeViewModule } from "@progress/kendo-angular-treeview";

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, TreeViewModule],
  declarations: [FolderModalComponent],
  exports: [FolderModalComponent],
})
export class FolderModalModule {}
