import { Injectable } from "@angular/core";
import { ConfigService } from "../config/config.service";
import { UiService } from "../ui/ui.service";

var SpeechRecognition =
  (window as any).SpeechRecognition ||
  (window as any).webkitSpeechRecognition ||
  null;

@Injectable({
  providedIn: "root",
})
export class SttService {
  private m_ActiveRecognition: any;
  private m_ResultText: string = "";
  private m_LastRecognitionList: any;
  private m_IsActive: boolean = false;
  private m_TIMEOUT: number = 5000;
  private m_EnableSTT: boolean = true;
  private m_SpeechDetectionTimeout: any;

  get EnableSTT() {
    return this.m_EnableSTT;
  }

  constructor(
    private m_ConfigService: ConfigService,
    private m_UiManager: UiService
  ) {
    this.init();
  }

  async init() {
    if (!SpeechRecognition) {
      console.log("STT not supported");
      return;
    }

    this.m_ActiveRecognition = new SpeechRecognition();

    let sttConfig = await this.m_ConfigService.get("STT_CONFIG");
    if (sttConfig == null) return;
    let sttConfigJson = JSON.parse(sttConfig);
    this.m_TIMEOUT = parseInt(sttConfigJson.timeout);
    this.m_EnableSTT = sttConfigJson.enabled;
  }

  startSTT(
    onResultCB: (result: string) => void,
    onEndCB: () => void,
    onError: (error: any) => void
  ) {
    if (!this.m_ActiveRecognition) return;
    this.m_ResultText = "";
    this.hookupEvents(onResultCB, onEndCB, onError);
    this.m_ActiveRecognition.interimResults = true;
    this.m_ActiveRecognition.continuous = true;
    this.m_ActiveRecognition.start();
  }

  stopSTT() {
    if (!this.m_ActiveRecognition) return;
    this.m_ActiveRecognition.continuous = false;
    this.m_ActiveRecognition.stop();
    this.reset();
  }

  isSTTActive() {
    return this.m_IsActive;
  }

  private onSpeechRecognitionStart() {
    this.m_IsActive = true;
  }

  //Fired when speech recognition ends entirely
  private onSpeechRecognitionEnd(error: any) {
    if (error) console.log(error);

    this.m_IsActive = false;
    this.unhookEvents();
    this.onResult(this.m_LastRecognitionList);
    this.reset();
  }

  //Fired when speech detection is lost
  private startDetectTimeout() {
    //Start timeout to stop the recognition
    this.m_SpeechDetectionTimeout = setTimeout(() => {
      this.stopSTT();
    }, this.m_TIMEOUT);
  }

  //Fired when speech is detected
  private onSpeechDetectionStart() {
    clearTimeout(this.m_SpeechDetectionTimeout);
  }

  private onResult(result: any) {
    clearTimeout(this.m_SpeechDetectionTimeout);

    if (!result) return;

    let tmp = "";
    //Add all the results together
    for (let i = 0; i < result.results.length; i++) {
      tmp += result.results[i][0].transcript;
    }

    this.m_ResultText = tmp;

    this.startDetectTimeout();
  }

  private onError(error: any) {
    console.log(error);
    this.m_IsActive = false;
    this.unhookEvents();
    this.reset();
  }

  private hookupEvents(
    onResultCB: (result: string) => void,
    onEndCB: () => void,
    onError: (error: any) => void
  ) {
    this.m_ActiveRecognition.onresult = (result: any) => {
      this.m_LastRecognitionList = result;
      this.onResult(result);
      onResultCB(this.m_ResultText);
    };
    this.m_ActiveRecognition.onstart = this.onSpeechRecognitionStart.bind(this);
    this.m_ActiveRecognition.speechstart =
      this.onSpeechDetectionStart.bind(this);
    this.m_ActiveRecognition.onend = () => {
      this.onSpeechRecognitionEnd(null);
      onEndCB();
    };
    this.m_ActiveRecognition.onerror = (error: any) => {
      this.onError(error);
      onError(error);
    };
  }

  private unhookEvents() {
    this.m_ActiveRecognition.onresult = null;
    this.m_ActiveRecognition.onstart = null;
    this.m_ActiveRecognition.onend = null;
    this.m_ActiveRecognition.onerror = null;
  }

  private reset() {
    this.m_ActiveRecognition.stop();
    this.m_ResultText = "";
    this.m_LastRecognitionList = null;
  }
}
