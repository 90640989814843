import { Injectable } from "@angular/core";
import {
  CREATE_PLAYLIST_DTO_REQ,
  LIST_PLAYLIST_VIDEOS_DTO_RES,
  PLAYLIST_DTO,
  PLAYLIST_DTO_GET_RES,
} from "@shared/models/playlist/playlist";
import { PlaylistApi } from "src/app/api/playlist/playlist-api";
import { UserService } from "../user/user.service";
import { ConfigService } from "../config/config.service";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { Events } from "../events/events.service";

@Injectable({
  providedIn: "root",
})
export class PlaylistService {
  private m_PlaylistAPIService: PlaylistApi;

  constructor(
    eventsService: Events,
    userService: UserService,
    configService: ConfigService
  ) {
    this.m_PlaylistAPIService = new PlaylistApi(
      eventsService,
      userService,
      configService.get("SERVER_URL")
    );
  }
  //--------------------------------------------------------------------
  /**
   * Fetch playlists from the database
   * @returns
   */
  async getPlaylist(playlistId: string): Promise<PLAYLIST_DTO_GET_RES> {
    let result = await this.m_PlaylistAPIService.getPlaylist(playlistId);

    if (result.status != 200) {
      throw ErrorFactory.error(
        result.status,
        result.error || "Error to get playlists"
      );
    }

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Fetch playlist videos
   * @returns
   */
  async getPlaylistVideos(
    playlistId: string
  ): Promise<LIST_PLAYLIST_VIDEOS_DTO_RES> {
    let result = await this.m_PlaylistAPIService.getPlaylistVideos(playlistId);

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Error to get playlist videos");
    }

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Create a playlist from the database
   * @param data
   * @returns
   */
  async createPlaylist(
    data: CREATE_PLAYLIST_DTO_REQ
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let result = await this.m_PlaylistAPIService.createNewPlaylist(data);

    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to create new playlist");
    }

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Add video on playlist
   * @param data
   * @returns
   */
  async addPlaylist(
    playlistId: string,
    videoIds: string[]
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let result = await this.m_PlaylistAPIService.addPlaylist(
      playlistId,
      videoIds
    );

    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Remove video on playlist
   * @param data
   * @returns
   */
  async removePlaylist(
    playlistId: string,
    videoIds: string[]
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let result = await this.m_PlaylistAPIService.removePlaylist(
      playlistId,
      videoIds
    );
    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Updates playlists data, like name, description and visibility
   * @param playlistData
   * @returns
   */
  async updatePlaylist(
    playlistData: PLAYLIST_DTO
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let result = await this.m_PlaylistAPIService.updatePlaylist(playlistData);
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to update playlist");
    }
    return result;
  }

  //--------------------------------------------------------------------
  /**
   * Updates playlists thumbnail
   * @param PlaylistId
   * @param File
   * @returns
   */
  async updatePlaylistThumbnail(
    playlistId: string,
    thumbnail: File
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let result = await this.m_PlaylistAPIService.updatePlaylistThumbnail(
      playlistId,
      thumbnail
    );
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to update thumbnail");
    }
    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Updates playlists thumbnail
   * @param PlaylistId
   * @param VideoId
   * @param Position
   * @returns
   */
  async updateVideoPosition(
    playlistId: string,
    videoId: string,
    position: number
  ): Promise<PLAYLIST_DTO_GET_RES> {
    let result = await this.m_PlaylistAPIService.updateVideoPosition(
      playlistId,
      videoId,
      position
    );
    if (result.status != 200) {
      throw ErrorFactory.error(
        result.status,
        "Failed to update video position"
      );
    }
    return result;
  }
  //--------------------------------------------------------------------
  /**
   * Deletes a playlists
   * @param PlaylistId
   * @returns
   */
  async deletePlaylist(playlistId: string): Promise<PLAYLIST_DTO_GET_RES> {
    let result = await this.m_PlaylistAPIService.deletePlaylist(playlistId);
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to delete playlist");
    }
    return result;
  }
}
