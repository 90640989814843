import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ContextMenuComponent,
  ContextMenuOption,
} from "../context-menu/context-menu.component";
import { LocalizationService } from "src/app/services/localization/localization.service";
import { UserService } from "src/app/services/user/user.service";
import { Subject, Subscription, take } from "rxjs";
import { UiService } from "src/app/services/ui/ui.service";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import {
  AutoCompleteComponent,
  PreventableEvent,
} from "@progress/kendo-angular-dropdowns";
import { SEARCH_LIST } from "src/app/models/action/search";
import { ActionService } from "src/app/services/action/action.service";
import { uuidToShortId } from "@shared/utils/utils";
import { UploadComponent } from "../../upload/upload.component";
import { VIDEO } from "src/app/models/video/video";
import { getMetaInfo } from "src/app/utility/video-utils";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { VIDEO_PUBLISH_STATE } from "@shared/models/video/video";
import { VideoService } from "src/app/services/video/video.service";
import { SessionService } from "src/app/services/session/session.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @ViewChild("privateMenu", { read: ContextMenuComponent }) m_PrivateMenu:
    | ContextMenuComponent
    | undefined;
  @ViewChild("shareMenu", { read: ContextMenuComponent }) m_ShareMenu:
    | ContextMenuComponent
    | undefined;
  @ViewChild("contextMenu", { read: ContextMenuComponent }) m_CtxMenu:
    | ContextMenuComponent
    | undefined;
  @ViewChild("m_Autocomplete", { static: false })
  public m_Autocomplete: AutoCompleteComponent | null = null;
  @ViewChild("uploader") m_Uploader: UploadComponent | undefined;

  public m_MenuOptions: any[] = [];
  public $t;
  public m_DisplayProfileImage: boolean = false;
  public m_SearchValue: string = "";
  public m_Searching: boolean = false;
  public m_CurrentSearchResults: SEARCH_LIST = new SEARCH_LIST();
  public m_PreviousSearchValue: string = "";
  public m_StatusOptions: ContextMenuOption[] = [];
  public m_ShareOptions: ContextMenuOption[] = [];
  public m_SelectedStatusOption: VIDEO_PUBLISH_STATE = 0;
  public m_IsPublishing: boolean = false;
  private m_SearchValueTimeout: any;
  private m_SearchTimeout: number = 500;
  private m_MaxSearchCharLength: number = 80;
  private m_ContextOptions: ContextMenuOption[] = [];
  private m_OnLoginSubscription: Subscription;
  private m_EditorVideoChangedSubscription: Subscription;

  get IsAdmin() {
    if (this.m_UserService.IsAdmin == null) return false;
    return this.m_UserService.IsAdmin;
  }

  get SearchRenderData() {
    return this.m_CurrentSearchResults?.results ?? [];
  }

  get IsEditor() {
    if (this.m_UiService.EditorVideo == null) return false;
    else return this.m_UiService.EditorVideo;
  }

  get IsWatchPage() {
    return this.m_Router.url.includes("/watch");
  }

  get IsLoggedIn() {
    return this.m_AuthService.LoggedIn;
  }

  get EditorVideo(): VIDEO | null {
    return this.m_UiService.EditorVideo;
  }

  get Username() {
    const firstName = this.m_UserService.ActiveUserInfo?.first_name;
    const lastName = this.m_UserService.ActiveUserInfo?.last_name;

    if (firstName || lastName) {
      return `${firstName} ${lastName}`;
    }

    return this.m_UserService.ActiveUserInfo?.username;
  }

  constructor(
    public m_AuthService: SessionService,
    private m_UserService: UserService,
    private m_Localize: LocalizationService,
    private m_Router: Router,
    private m_UiService: UiService,
    private m_Events: Events,
    private m_Actions: ActionService,
    private m_NotificationService: HIANotificationService,
    private m_VideoService: VideoService
  ) {
    this.$t = m_Localize.translate.bind(m_Localize);

    this.m_OnLoginSubscription = this.m_AuthService.OnLogin.subscribe(() => {
      this.ngOnInit();
    });

    this.m_EditorVideoChangedSubscription =
      this.m_UiService.OnEditorVideoChanged.subscribe((video) => {
        this.m_SelectedStatusOption = video?.video_publish_state || 0;
        this.initContextMenu();
      });
  }

  async ngOnInit() {
    await new Promise((resolve) => {
      this.m_Localize.translationReady$.pipe(take(2)).subscribe((isReady) => {
        if (isReady) {
          resolve(isReady);
        }
      });
    });

    this.m_SelectedStatusOption = this.EditorVideo?.video_publish_state || 0;
    this.initContextMenu();
    this.initMenuOptions();

    this.m_Events.subscribe(
      EVENTS.VIDEO_STATUS_UPDATED,
      (status: VIDEO_PUBLISH_STATE) => {
        this.m_SelectedStatusOption = status;
        this.initContextMenu();
      }
    );

    setTimeout(() => {
      this.m_DisplayProfileImage = true;
    }, 3000);
  }
  //--------------------------------------------------------------------
  ngOnDestroy() {
    this.m_OnLoginSubscription.unsubscribe();
    this.m_EditorVideoChangedSubscription.unsubscribe();
  }
  //--------------------------------------------------------------------
  //#region Event Handlers
  onHomeClicked() {
    this.m_Router.navigateByUrl("/", { replaceUrl: true });
  }
  //--------------------------------------------------------------------
  goHome() {
    this.m_Router.navigateByUrl("/home", { replaceUrl: true });
  }
  //--------------------------------------------------------------------
  onPricingClicked() {
    this.m_UiService.redirectToPricing();
  }
  //--------------------------------------------------------------------
  onUpgradeClicked() {
    this.m_Router.navigateByUrl("/pricing", { replaceUrl: true });
  }
  //--------------------------------------------------------------------
  onCreateClicked() {
    this.m_Uploader?.openFileDialog();
  }
  //--------------------------------------------------------------------
  onAboutClicked() {
    window.open("https://hia.ai/labs/about");
  }
  //--------------------------------------------------------------------
  onContactClicked() {
    this.m_Router.navigate(["/contact"], {
      queryParams: {},
    });
  }
  //--------------------------------------------------------------------
  async logoutUser() {
    await this.m_AuthService.logoutUser();
  }
  //--------------------------------------------------------------------
  onOptionsClicked(event: Event) {
    event.stopPropagation();
    this.m_PrivateMenu?.open(event);
  }
  //--------------------------------------------------------------------
  onShareOptionsClicked(event: Event) {
    event.stopPropagation();
    this.m_ShareMenu?.open(event);
  }
  //--------------------------------------------------------------------
  editUser() {
    this.m_Router.navigate(["/edit-profile"], {
      queryParams: {},
    });
  }
  //--------------------------------------------------------------------
  expandSidebar() {
    this.m_UiService.setExpandSidebar(!this.m_UiService.ExpandSidebar);
  }
  //--------------------------------------------------------------------
  onLoginSignupClicked(signUp: boolean) {
    let pageToNav = signUp ? "/pricing" : "/login";

    this.m_Router.navigate([pageToNav], {
      queryParams: {},
    });
  }
  //--------------------------------------------------------------------
  onUserClicked(event: MouseEvent) {
    event.preventDefault();
    this.m_CtxMenu?.open(event);
  }
  //--------------------------------------------------------------------
  async handleSearchFilterChange(value: any) {
    if (this.m_SearchValueTimeout) {
      clearTimeout(this.m_SearchValueTimeout);
    }

    if (value == null || value == "") {
      this.m_CurrentSearchResults = new SEARCH_LIST();
      this.m_PreviousSearchValue = "";
      this.m_Searching = false;
      return;
    }

    this.m_SearchValueTimeout = setTimeout(() => {
      this.searchForValue(value);
    }, this.m_SearchTimeout);
  }
  //--------------------------------------------------------------------
  async onSearchResultClicked(item: any, event: MouseEvent) {
    event.stopPropagation();

    switch (item.type) {
      case "video":
        this.m_Router.navigate(["/edit"], {
          queryParams: { v: uuidToShortId(item.id) },
          replaceUrl: true,
        });
        break;
      case "record":
        this.m_Router.navigate(["/edit"], {
          queryParams: { v: uuidToShortId(item.video_id) },
          state: { searchQuery: item.question },
          replaceUrl: true,
        });
        break;
    }

    setTimeout(() => {
      this.m_Autocomplete?.toggle(false);
    }, 100);
  }
  //--------------------------------------------------------------------
  handleSearchFocus() {
    if (
      this.m_CurrentSearchResults.results.length > 0 &&
      this.m_Autocomplete?.isOpen === false
    ) {
      this.m_Autocomplete?.toggle(true);
    }
  }
  //--------------------------------------------------------------------
  handleSearchDropdownOpen(event: PreventableEvent) {
    if (this.m_CurrentSearchResults.totalItems == 0) {
      event.preventDefault();
    }
  }
  //--------------------------------------------------------------------
  onShareClicked() {
    this.m_Events.publish(EVENTS.OPEN_SHARE_MODAL);
  }
  //--------------------------------------------------------------------
  onLibraryClicked() {
    this.m_Router.navigate(["/library"]);
  }
  //--------------------------------------------------------------------
  onCopyLinkClicked() {
    navigator.clipboard.writeText(this.getShareableLink());
    this.m_NotificationService.showSuccess(
      this.$t("shared.messages.linkCopied"),
      2000
    );
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region Render Helpers
  public shouldDisplayLogin() {
    if (this.m_Router.url.includes("login")) return false;
    else return true;
  }
  //--------------------------------------------------------------------
  public shouldDisplayCreateAccount() {
    if (this.m_Router.url.includes("pricing")) return false;
    else return true;
  }
  //--------------------------------------------------------------------
  getContextMenuOpts() {
    return this.m_ContextOptions;
  }
  //--------------------------------------------------------------------
  getUsername() {
    let user = this.m_UserService.ActiveUserInfo;
    if (user == null) return "";
    return user.username;
  }
  //--------------------------------------------------------------------
  getProfileId() {
    let user = this.m_UserService.ActiveUserInfo;
    if (user == null) return "";
    return user.id;
  }
  //--------------------------------------------------------------------
  getUserFullName() {
    let activeUser = this.m_UserService.ActiveUserInfo;

    if (activeUser == null) {
      return "User";
    }
    if (!activeUser.first_name && activeUser.username)
      return activeUser.username;

    const firstName = activeUser.first_name || "";
    const lastName = activeUser.last_name || "";

    let fullName = firstName + " " + lastName;

    return fullName || "User";
  }
  //--------------------------------------------------------------------
  getUserEmail() {
    let user = this.m_UserService.ActiveUserInfo;
    if (user == null) return "";
    return user.email;
  }
  //--------------------------------------------------------------------
  getProfilePic() {
    let user = this.m_UserService.ActiveUserInfo;
    if (
      user == null ||
      user.profile_picture_url == null ||
      user.profile_picture_url == ""
    )
      return "assets/icon/profile/user-avatar.svg";
    else return user.profile_picture_url;
  }
  //--------------------------------------------------------------------
  getUserId() {
    let user = this.m_UserService.ActiveUserInfo;
    if (user == null) return "";
    return user?.id;
  }
  //--------------------------------------------------------------------
  getTruncatedSearchText(text: string) {
    if (text.length > this.m_MaxSearchCharLength) {
      return text.substring(0, this.m_MaxSearchCharLength) + "...";
    } else {
      return text;
    }
  }
  //--------------------------------------------------------------------
  getEditorMetaInfo() {
    return `${getMetaInfo(this.EditorVideo)}`;
  }
  //--------------------------------------------------------------------
  getSidebarIcon() {
    return !this.m_UiService.ExpandSidebar ? "menu-outline" : "close-outline";
  }
  //--------------------------------------------------------------------
  getVideoTitle() {
    return this.EditorVideo?.video_name;
  }
  //--------------------------------------------------------------------
  getIsMobile() {
    return this.m_UiService.isMobileSize();
  }
  //--------------------------------------------------------------------
  getVideoPublishTime() {
    return this.EditorVideo?.create_date;
  }
  //--------------------------------------------------------------------
  shouldShowSidebarButton() {
    if (!this.m_AuthService.LoggedIn) return false;
    if (this.IsEditor || this.IsWatchPage) return true;
    return this.getIsMobile();
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  private initContextMenu() {
    this.m_ContextOptions = [
      {
        text: this.$t("components.header.dashboard"),
        action: () => {
          this.m_Router.navigate(["/library"], {
            queryParams: {},
          });
        },
      },
      {
        text: this.$t("components.header.editProfile"),
        action: () => {
          this.editUser();
        },
      },
      {
        text: this.$t("components.header.logout"),
        action: () => {
          this.logoutUser();
        },
      },
    ];

    let userPublishStates = this.m_UserService.ActiveUserLimits?.publishStates;

    const userPublishStatesSet = new Set(
      userPublishStates?.map((state) => state.enum_value)
    );
    // Status options
    this.m_StatusOptions = [
      {
        text: this.$t("components.videoPublish.private"),
        disabled:
          this.m_SelectedStatusOption === VIDEO_PUBLISH_STATE.PRIVATE ||
          !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PRIVATE),
        tooltipText: this.$t("components.videoPublish.privateTooltip"),
        disabledTooltip: !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PRIVATE)
          ? this.$t("shared.tooltip.requirePermission")
          : this.$t("shared.tooltip.alreadyInVisibility").replace(
              "{0}",
              this.$t("components.videoPublish.private").toLowerCase()
            ),
        action: () => {
          this.m_SelectedStatusOption = VIDEO_PUBLISH_STATE.PRIVATE;
          this.setVideoState(VIDEO_PUBLISH_STATE.PRIVATE);
        },
      },
      {
        text: this.$t("components.videoPublish.public"),
        disabled:
          this.m_SelectedStatusOption === VIDEO_PUBLISH_STATE.PUBLIC ||
          !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PUBLIC),
        disabledTooltip: !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PUBLIC)
          ? this.$t("shared.tooltip.requirePermission")
          : this.$t("shared.tooltip.alreadyInVisibility").replace(
              "{0}",
              this.$t("components.videoPublish.public").toLowerCase()
            ),
        action: () => {
          this.m_SelectedStatusOption = VIDEO_PUBLISH_STATE.PUBLIC;
          this.setVideoState(VIDEO_PUBLISH_STATE.PUBLIC);
        },
      },
      {
        text: this.$t("components.videoPublish.unlisted"),
        disabled:
          this.m_SelectedStatusOption === VIDEO_PUBLISH_STATE.UNLISTED ||
          !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.UNLISTED),
        tooltipText: this.$t("components.videoPublish.unlistedTooltip"),
        disabledTooltip: !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.UNLISTED)
          ? this.$t("shared.tooltip.requirePermission")
          : this.$t("shared.tooltip.alreadyInVisibility").replace(
              "{0}",
              this.$t("components.videoPublish.unlisted").toLowerCase()
            ),
        action: () => {
          this.m_SelectedStatusOption = 2;
          this.setVideoState(VIDEO_PUBLISH_STATE.UNLISTED);
        },
      },
    ];

    this.m_ShareOptions = [
      {
        text: this.$t("shared.button.share"),
        tooltipText: this.$t("shared.button.share"),
        action: () => {
          this.onShareClicked();
        },
      },
      {
        text: this.$t("components.modals.share.copyLink"),
        tooltipText: this.$t("components.modals.share.copyLink"),
        action: () => {
          this.onCopyLinkClicked();
        },
      },
    ];
  }
  //--------------------------------------------------------------------
  async setVideoState(state: VIDEO_PUBLISH_STATE) {
    if (!this.EditorVideo?.video_id) return;
    this.m_IsPublishing = true;
    try {
      await this.m_VideoService.setVideoPublishState(
        this.EditorVideo?.video_id,
        state
      );
      this.initContextMenu();
      this.EditorVideo.video_publish_state = state;
    } finally {
      this.m_IsPublishing = false;
    }
  }
  //--------------------------------------------------------------------
  private initMenuOptions() {
    this.m_MenuOptions = [
      {
        text: this.$t("components.header.viewPublic"),
        action: () => {
          this.m_Router.navigate([
            `/u/${this.m_UserService.ActiveUserInfo?.username}`,
          ]);
        },
        borderBottom: false,
      },
      {
        text: this.$t("components.header.editProfile"),
        action: () => this.editUser(),
        borderBottom: false,
      },
      {
        text: this.$t("components.header.accountSettings"),
        action: () => {
          this.m_Router.navigate(["/edit-profile"], {
            queryParams: {
              tab: "account-settings",
            },
          });
        },
        borderBottom: true,
      },
      {
        text: this.$t("components.header.admin"),
        disabled: !this.IsAdmin,
        action: () => {
          this.m_Router.navigate(["/admin"]);
        },
        borderBottom: true,
      },
      {
        text: this.$t("components.header.help"),
        action: () => {},
        borderBottom: false,
        disabled: true, // Coming soon
      },
      {
        text: this.$t("components.header.sendFeedback"),
        action: () => {
          this.m_Events.publish(EVENTS.OPEN_FEEDBACK);
        },
        borderBottom: true,
      },
      {
        text: this.$t("components.header.logOut"),
        action: () => this.logoutUser(),
        borderBottom: false,
      },
    ];
  }
  //--------------------------------------------------------------------
  private async searchForValue(value: string) {
    if (value == this.m_PreviousSearchValue) return;

    this.m_Searching = true;

    try {
      let searchResults = await this.m_Actions.globalSearch(value, 0, 30);
      this.m_CurrentSearchResults = searchResults;
      this.m_Searching = false;
      this.m_PreviousSearchValue = value;

      if (this.m_CurrentSearchResults.totalItems == 0) {
        this.m_Autocomplete?.toggle(false);
        return;
      }

      if (!this.m_Autocomplete?.isOpen) this.m_Autocomplete?.toggle(true);
    } catch (err) {
      console.error(err);
    }
  }
  //--------------------------------------------------------------------
  private getShareableLink() {
    if (this.EditorVideo == null) return "";
    return (
      window.location.origin +
      "/watch?v=" +
      uuidToShortId(this.EditorVideo?.video_id)
    );
  }
  //#endregion
}
