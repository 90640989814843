import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { T } from "src/app/services/localization/localization.service";
import { CHANGE_LOGS } from "src/app/services/change-log/change-log.config";
import { ConfigService } from "src/app/services/config/config.service";

export interface ChangeLogConfig {
  JSON_URL: string;
}

@Component({
  selector: "app-change-log-modal",
  templateUrl: "./change-log-modal.component.html",
  styleUrls: ["./change-log-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChangeLogComponent implements OnInit {
  public $t = T.translate;
  public m_IsOpen: boolean = false;
  public m_Loading: boolean = false;
  public m_ChangeLog: any[] = CHANGE_LOGS;
  private m_Config: ChangeLogConfig | null = null;

  @ViewChild("termsRoot", { static: false }) m_TermsRoot: ElementRef | null =
    null;
  @Output() public OnDismiss: EventEmitter<void> = new EventEmitter<void>();

  constructor(private m_ConfigService: ConfigService) {}

  async ngOnInit() {
    this.m_Loading = true;
    let config = await this.m_ConfigService.get("CHANGELOG_CONFIG");
    if (config) {
      this.m_Config = JSON.parse(config) as ChangeLogConfig;

      // Load JSON file
      if (this.m_Config && this.m_Config.JSON_URL) {
        try {
          let response = await fetch(this.m_Config.JSON_URL);
          this.m_ChangeLog = await response.json();
        } catch (error) {
          console.error("Error loading change log JSON file", error);
        }
      }
    }
  }

  async show() {
    this.m_IsOpen = true;
  }

  onContinue() {
    this.m_IsOpen = false;
    this.OnDismiss.emit();
  }

  getChipColor(type: string): string {
    switch (type) {
      case "new":
        return "success";
      case "improved":
        return "warning";
      case "fix":
        return "danger";
      default:
        return "medium";
    }
  }
}
