import { IntroSequence } from "../base-sequence";

export class EditIntro extends IntroSequence {
  public static override Name: string = "EditIntroV1";

  constructor() {
    super();
  }

  public override getName(): string {
    return EditIntro.Name;
  }

  public async registerSteps(): Promise<void> {
    this.steps = [
      {
        title: this.$t("intros.edit.editStep1Title"),
        intro: this.$t("intros.edit.editStep1"),
        position: "right",
        scrollTo: "tooltip",
        step: 0,
      },
      {
        title: "",
        intro: this.$t("intros.edit.editStep2"),
        element: document.querySelector("#add-record-btn") as HTMLElement,
        position: "bottom",
        scrollTo: "tooltip",
        step: 1,
      },
      {
        title: "",
        intro: this.$t("intros.edit.editStep3"),
        element: document.querySelector("#import-record-btn") as HTMLElement,
        position: "bottom",
        scrollTo: "tooltip",
        step: 2,
      },
      {
        title: "",
        intro: this.$t("intros.edit.editStep4"),
        element: document.querySelector("#gallery-btn") as HTMLElement,
        position: "bottom",
        scrollTo: "tooltip",
        step: 3,
      },
      {
        title: "",
        intro: this.$t("intros.edit.editStep5"),
        element: document.querySelector("#video-title-edit") as HTMLElement,
        position: "bottom",
        scrollTo: "tooltip",
        step: 4,
      },
      {
        title: "",
        intro: this.$t("intros.edit.editStep6"),
        element: document.querySelector("#properties-tab") as HTMLElement,
        position: "bottom",
        scrollTo: "tooltip",
        step: 5,
      },
    ];

    this.m_Registred = true;
  }
}
