import { MEDIA_TYPE } from "@shared/models/media/media";
import { PAGIONATION_DTO } from "@shared/models/pagination";
import {
  INSIGHT_KEY_DTO,
  PAGINATED_RECORDS_DTO,
  RECORD_DTO,
  RECORD_DTO_QUERY_ENTRY,
  RECORD_DTO_QUERY_RES,
  RECORD_WITH_ATTACHMENT_DTO,
  RECORDS_DTO_PAGINATED_GET_RES,
} from "@shared/models/record/record";
import { VOICE_DTO } from "@shared/models/voice/voice";

export enum ATTACHMENT_TYPE {
  NONE = -1,
  IMAGE = 0,
  VIDEO = 1,
  WEBURL = 2,
  VIDEO_SEGMENT = 3,
}

export enum ATTACHMENT_MIME_TYPE {
  IMAGE = "image/",
  VIDEO = "hia-video/",
  WEBURL = "hia-webURL/",
}

export class PAGINATED_RECORDS implements PAGIONATION_DTO {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  records: RECORD[];
  seed: number | undefined;

  constructor(paginatedRecordsDTORes: RECORDS_DTO_PAGINATED_GET_RES) {
    let paginatedRecordsDTO: PAGINATED_RECORDS_DTO =
      paginatedRecordsDTORes.records;

    this.currentPage = paginatedRecordsDTO.currentPage;
    this.pageSize = paginatedRecordsDTO.pageSize;
    this.totalItems = paginatedRecordsDTO.totalItems;
    this.totalPages = paginatedRecordsDTO.totalPages;
    this.records = paginatedRecordsDTO.records.map(
      (recordDTO) => new RECORD(recordDTO)
    );
    this.seed = paginatedRecordsDTORes.seed;
  }
}

export class RECORD {
  record_id: string;
  video_id: string;
  question?: string;
  answer?: string;
  media_id?: string | null;
  assetPath?: string;
  assetType?: MEDIA_TYPE;
  video_start_time?: number | null;
  video_end_time?: number | null;
  web_url?: string | null;
  create_date?: Date;
  last_modified?: Date;
  last_modified_by?: string;
  created_by_id?: string;
  unsaved?: boolean;
  is_pinned?: boolean;
  is_suggestible?: boolean;
  thumbnailPath?: string;

  constructor(recordDTO: RECORD_WITH_ATTACHMENT_DTO | null = null) {
    if (!recordDTO) {
      this.record_id = "";
      this.video_id = "";
      this.is_suggestible = true;
      return;
    }
    this.record_id = recordDTO.id.toLowerCase();
    this.video_id = recordDTO.video_id;
    this.question = recordDTO.question;
    this.answer = recordDTO.answer;
    this.last_modified_by = recordDTO.last_modified_by;
    this.created_by_id = recordDTO.created_by_id;
    this.media_id = recordDTO.media_id;
    this.video_start_time = recordDTO.start_time;
    this.video_end_time = recordDTO.end_time;
    this.web_url = recordDTO.web_url;
    this.assetPath = recordDTO.assetPath;
    this.assetType = recordDTO.assetType;
    this.is_pinned = recordDTO.is_pinned;
    this.is_suggestible = recordDTO.is_suggestible;
    this.thumbnailPath = recordDTO.thumbnailPath;

    if (recordDTO.create_date)
      this.create_date = new Date(recordDTO.create_date);
    if (recordDTO.last_modified)
      this.last_modified = new Date(recordDTO.last_modified);
  }

  copy(recordToCopy: RECORD): void {
    this.record_id = recordToCopy.record_id;
    this.video_id = recordToCopy.video_id;
    this.question = recordToCopy.question;
    this.answer = recordToCopy.answer;
    this.create_date = recordToCopy.create_date;
    this.last_modified = recordToCopy.last_modified;
    this.last_modified_by = recordToCopy.last_modified_by;
    this.created_by_id = recordToCopy.created_by_id;
    this.video_start_time = recordToCopy.video_start_time;
    this.video_end_time = recordToCopy.video_end_time;
    this.web_url = recordToCopy.web_url;
    this.assetPath = recordToCopy.assetPath;
    this.assetType = recordToCopy.assetType;
    this.is_pinned = recordToCopy.is_pinned;
    this.is_suggestible = recordToCopy.is_suggestible;
    this.media_id = recordToCopy.media_id;
  }

  getAttachmentType(): ATTACHMENT_TYPE {
    if (this.video_start_time != null && this.video_end_time != null)
      return ATTACHMENT_TYPE.VIDEO_SEGMENT;
    if (this.assetType === MEDIA_TYPE.IMAGE) return ATTACHMENT_TYPE.IMAGE;
    if (this.assetType === MEDIA_TYPE.VIDEO) return ATTACHMENT_TYPE.VIDEO;
    if (this.web_url != null) return ATTACHMENT_TYPE.WEBURL;
    return ATTACHMENT_TYPE.NONE;
  }
}

export class QueryResponse {
  score: number;
  record?: RECORD;
  insight?: INSIGHT_KEY_DTO;
  type: "record" | "insight" | "fallback" | "none";
  voice?: VOICE_DTO;

  constructor(queryResponse: RECORD_DTO_QUERY_ENTRY) {
    this.score = queryResponse.score;
    this.type = queryResponse.type;
    if (queryResponse.record) {
      this.record = new RECORD(queryResponse.record);
    }
    if (queryResponse.insight) {
      this.insight = queryResponse.insight;
    }
    if (queryResponse.voice) {
      this.voice = queryResponse.voice;
    }
  }
}
