import { Component, OnInit, ViewChild } from "@angular/core";
import { T } from "src/app/services/localization/localization.service";
import { UiService } from "src/app/services/ui/ui.service";
import { VERSION } from "src/version";
import { TermsModalComponent } from "../../modals/terms-modal/terms-modal.component";
import { ChangeLogComponent } from "../../modals/change-log-modal/change-log-modal.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @ViewChild(TermsModalComponent) m_TermsModal: TermsModalComponent | null =
    null;
  @ViewChild(ChangeLogComponent) m_ChangeLogModal: ChangeLogComponent | null =
    null;

  public $t = T.translate;
  public m_Version = VERSION.DEFAULT;
  public m_ChangelogClicked = false;

  constructor(private m_UIService: UiService) {}

  ngOnInit() {
    this.checkChangelogStatus();
  }

  viewEULA() {
    if (this.m_TermsModal) this.m_TermsModal.show();
  }

  isMobile() {
    return this.m_UIService.isMobile();
  }

  checkChangelogStatus() {
    const status = localStorage.getItem("changelogClicked");
    if (status !== null) {
      this.m_ChangelogClicked = JSON.parse(status);
    }
  }

  viewChangelog() {
    if (this.m_ChangeLogModal) {
      this.m_ChangeLogModal.show();
      this.m_ChangelogClicked = true;
      localStorage.setItem("changelogClicked", JSON.stringify(true));
    }
  }
}
