import { Language } from "@azure/video-indexer-widgets";

export const LANGUAGES: Language[] = [
  "",
  "multi",
  "ar-EG",
  "ar-SY",
  "zh-Hans",
  "en-US",
  "en-GB",
  "en-AU",
  "fr-FR",
  "de-DE",
  "it-IT",
  "ja-JP",
  "pt-BR",
  "ru-RU",
  "es-ES",
  "es-MX",
  "fil-PH",
  "Af-ZA",
  "Bn-BD",
  "Bs-Latn",
  "Bg-BG",
  "Ca-ES",
  "Hr-HR",
  "Cs-CZ",
  "Da-DK",
  "Nl-NL",
  "En-FJ",
  "En-WS",
  "Et-EE",
  "Fi-FI",
  "El-GR",
  "Fr-HT",
  "He-IL",
  "Hi-IN",
  "hi-IN",
  "Hu-HU",
  "Id-ID",
  "Sw-KE",
  "ko-KR",
  "Lv-LV",
  "Lt-LT",
  "Mg-MG",
  "Ms-MY",
  "Mt-MT",
  "Nb-NO",
  "Fa-IR",
  "Pl-PL",
  "Ro-RO",
  "Sr-Cyrl-RS",
  "Sr-Latn-RS",
  "Sk-SK",
  "Sl-SI",
  "Sv-SE",
  "Ta-IN",
  "Th-TH",
  "To-TO",
  "Tr-TR",
  "Uk-UA",
  "Ur-PK",
  "Vi-VN",
  "auto",
  "Zh-Hant",
  "zh-CN",
];
