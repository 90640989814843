import { SEARCH_DTO_RES } from "@shared/models/actions/search";

export class SEARCH_LIST {
  results: Array<any>;
  totalItems: number;

  constructor(dto?: SEARCH_DTO_RES) {
    if (!dto) {
      this.results = [];
      this.totalItems = 0;
      return;
    }
    this.results = dto.results;
    this.totalItems = this.results.length;
  }
}
