import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { TermsModalComponent } from "./terms-modal.component";

@NgModule({
  imports: [IonicModule, CommonModule],
  declarations: [TermsModalComponent],
  exports: [TermsModalComponent],
})
export class TermsModalModule {}
