import {
  QNA_PAIR_DTO,
  RECORD_DTO_UPDATE_REQ,
} from "@shared/models/record/record";
import { PAGINATED_RECORDS, RECORD } from "src/app/models/record/record";
import { AdminService } from "src/app/services/admin/admin.service";
import { RecordService } from "src/app/services/record/record.service";
import { StorageService } from "src/app/services/storage/storage.service";
import { v4 as uuidv4 } from "uuid";

interface UpdateResults {
  recordsUpdated: RECORD[];
  restored: boolean;
  queueScroll: boolean;
}

export class RecordManagement {
  private m_Records: RECORD[] | null = [];
  private m_QuestionsQueue: string[] = [];
  private m_QueueScroll: boolean = false;
  private m_VideoId: string = "";
  private m_MediaId?: string;
  private m_PageNumber: number = 0;
  private m_TotalRecords: number = 0;

  get Records(): RECORD[] {
    return this.m_Records ?? [];
  }

  get TotalPages(): number {
    return this.m_PageNumber;
  }

  get TotalRecords(): number {
    return this.m_TotalRecords;
  }

  constructor(
    private m_RecordService: RecordService,
    private m_AdminService: AdminService,
    private m_Storage: StorageService
  ) {}

  //#region Public Interface
  initialize(videoId: string, mediaId?: string) {
    this.m_VideoId = videoId;
    this.m_MediaId = mediaId;
    this.m_Records = [];
  }

  setRecords(value: RECORD[] | null, getUnsaved: boolean = true) {
    this.m_QuestionsQueue = [];
    this.m_Records = value;
    this.m_TotalRecords = this.m_Records?.length ?? 0;
    if (getUnsaved) this.updateRecords(value);
  }

  addQuestions(questions: string[], queue: boolean) {
    if (this.m_Records == null) this.m_Records = [];
    if (queue) {
      this.m_QuestionsQueue = this.m_QuestionsQueue.concat(questions);
      return;
    }

    for (let question of questions) {
      let record = new RECORD();
      record.record_id = "unsaved-" + uuidv4();
      record.question = question;
      record.video_id = this.m_VideoId;
      record.unsaved = true;
      this.m_Records.unshift(record);
    }

    this.m_QueueScroll = true;
  }

  async addGeneratedQuestions(records: RECORD[]) {
    if (this.m_Records == null) this.m_Records = [];
    for (let record of records) {
      this.m_Records.unshift(record);
      await this.addUnsavedQuestion(record);
    }
    this.m_TotalRecords += records.length;
  }

  async addRecords(records: RECORD[], sumTotalRecords = true) {
    if (this.m_Records == null) this.m_Records = [];
    for (let record of records) {
      this.m_Records.unshift(record);
    }
    if (sumTotalRecords) this.m_TotalRecords += records.length;
  }

  async addRecordsFromQnaPairs(
    qnaPairs: QNA_PAIR_DTO[],
    enableAutoSegmentGeneration: boolean
  ) {
    for (let qna of qnaPairs) {
      let newRecord = new RECORD();
      newRecord.record_id = "unsaved-" + uuidv4();
      newRecord.video_id = this.m_VideoId;
      newRecord.question = qna.question;
      newRecord.answer = qna.answer;
      if (enableAutoSegmentGeneration ?? false) {
        newRecord.video_start_time = qna.startTime ?? undefined;
        newRecord.video_end_time = qna.endTime ?? undefined;
      }
      newRecord.unsaved = true;
      await this.addUnsavedQuestion(newRecord);
      if (this.m_Records != null) {
        this.m_Records.unshift(newRecord);
      }
    }

    //Add records to total
    this.m_TotalRecords += qnaPairs.length;
  }

  addEmptyRecord() {
    let record = new RECORD();
    record.question = "";
    record.answer = "";
    record.record_id = "unsaved-" + uuidv4();
    record.video_id = this.m_VideoId;
    this.m_Records?.unshift(record);
    this.m_TotalRecords += 1;

    return record;
  }

  deleteRecord(id: string) {
    if (this.m_Records == null) return;
    // Check if the record with the given id exists
    const recordExists = this.m_Records.some(
      (record) => record.record_id === id
    );

    // Proceed only if the record exists
    if (recordExists) {
      this.m_Records = this.m_Records.filter(
        (record) => record.record_id != id
      );
      this.m_TotalRecords -= 1;
    }
  }

  async deleteAllRecords() {
    await this.cleanUnsavedRecords();

    //Grab all records with valid ids
    let recordIdsToDelete = this.Records.filter(
      (r) =>
        r.record_id != null &&
        r.record_id.length > 0 &&
        !r.record_id.startsWith("unsaved")
    ).map((r) => r.record_id);

    if (recordIdsToDelete.length > 0) {
      //Bulk delete records
      await this.m_RecordService.deleteAllRecords(this.m_VideoId);
    }

    this.clearRecords();
  }

  /**
   * Clears all cahced unsaved records unless a recordId is provided, in which case only that record cache is removed
   * If a recordId is provided, the record cache is removed and the record is removed from the list of records, if it exists,
   * and if it is the last record in the list, the list cache is cleared
   * @param recordId
   * @returns
   */
  async cleanUnsavedRecords(recordId?: string) {
    //Get all unsaved records for the video id
    let unsavedRecords = JSON.parse(
      await this.m_Storage.get("record_unsaved_ids_" + this.m_VideoId)
    );

    //If a record id is provided, remove the record cache and the record from the list
    if (recordId && recordId.length > 0) {
      await this.m_Storage.remove(
        "record_unsaved_changes_" + this.m_VideoId + "_" + recordId
      );

      if (unsavedRecords && unsavedRecords.length == 1) {
        await this.m_Storage.remove("record_unsaved_ids_" + this.m_VideoId);
      } else if (unsavedRecords) {
        unsavedRecords = unsavedRecords.filter((id: string) => id != recordId);
        await this.m_Storage.set(
          "record_unsaved_ids_" + this.m_VideoId,
          JSON.stringify(unsavedRecords)
        );
      }
      return;
    }

    //If no record id is provided, remove all unsaved records for the video id
    if (unsavedRecords && unsavedRecords.length) {
      for (const id of unsavedRecords) {
        if (id) {
          await this.m_Storage.remove(
            "record_unsaved_changes_" + this.m_VideoId + "_" + id
          );
        }
      }
      await this.m_Storage.remove("record_unsaved_ids_" + this.m_VideoId);
    }
  }

  // Remove records with record_id starting with "unsaved" from this.m_Records
  removeUnsaved() {
    if (this.m_Records) {
      this.m_Records = this.m_Records.filter(
        (record) => !record.record_id.startsWith("unsaved")
      );
    }
  }

  async createRecord(record: RECORD) {
    return this.m_RecordService.createRecord(this.m_VideoId, record);
  }

  findRecord(id: string) {
    return this.m_Records?.find((record) => record.record_id == id);
  }

  async importCSV(file: File) {
    return this.m_RecordService.importCSV(this.m_VideoId, file);
  }

  async importJSON(file: File) {
    return this.m_RecordService.importJSON(this.m_VideoId, file);
  }

  clearRecords() {
    this.m_Records = [];
    this.m_TotalRecords = 0;
  }

  async searchRecord(searchTerm: string) {
    let results: RECORD[] = [];
    let records = await this.m_RecordService.getRecords(
      this.m_VideoId,
      undefined,
      undefined,
      searchTerm
    );

    if (records) {
      results = records.records;
    }

    let key = "record_unsaved_ids_" + this.m_VideoId;
    let unsavedIdsStr = await this.m_Storage.get(key);
    let unsavedIds = [];

    try {
      unsavedIds = JSON.parse(unsavedIdsStr);
    } catch (exc) {}

    for (let id of unsavedIds ?? []) {
      if (!records) return;

      if (results.find((item) => item.record_id == id) == null) {
        // Get from storage if exists
        let key = "record_unsaved_changes_" + this.m_VideoId + "_" + id;
        let recordStr = JSON.parse(await this.m_Storage.get(key));

        let record = new RECORD();
        record.record_id = id;
        record.video_id = this.m_VideoId;

        if (recordStr) {
          record.question = recordStr.question;
          record.answer = recordStr.answer;
          record.is_pinned = recordStr.is_pinned;
          record.is_suggestible = recordStr.is_suggestible;
        }

        const containSearchTerm =
          record.answer?.includes(searchTerm) ||
          record.question?.includes(searchTerm);

        if (containSearchTerm) results.unshift(record);
      }
    }

    if (results.length) {
      this.m_Records = results;
    } else {
      this.m_Records = null;
    }
  }

  async fetchRecords(
    isAdmin: boolean,
    pageNumber?: number,
    pageSize?: number,
    searchTerm?: string
  ): Promise<UpdateResults> {
    let records = null;
    try {
      if (this.m_MediaId == null) {
        let results: PAGINATED_RECORDS | null;

        if (isAdmin) {
          results = await this.m_AdminService.getRecords(
            this.m_VideoId,
            pageNumber,
            pageSize,
            searchTerm
          );
        } else {
          results = await this.m_RecordService.getRecords(
            this.m_VideoId,
            pageNumber,
            pageSize,
            searchTerm
          );
        }

        records = results?.records ?? null;
        this.m_PageNumber = results?.totalPages ?? 0;
        this.m_TotalRecords = results?.totalItems ?? 0;
      } else {
        records = await this.m_RecordService.getRecordsByMediaID(
          this.m_VideoId,
          this.m_MediaId
        );
      }
    } catch (error) {
      console.error(error);
    }

    return await this.updateRecords(records, !!searchTerm);
  }

  async saveAll(
    recordsToSave: RECORD_DTO_UPDATE_REQ[] = [],
    recordSimilarityThreshold: number
  ) {
    try {
      await this.m_RecordService.updateRecords(recordsToSave);
    } catch (error: any) {
      return false;
    }
    let recordsIds: string[] = [];
    if (recordsToSave) {
      for (const record of recordsToSave) {
        if (record?.record_id) recordsIds.push(record?.record_id);
      }
    }
    await this.compareRecords(recordsIds, recordSimilarityThreshold);
    return true;
  }

  async compareRecords(
    recordsToSave: string[],
    recordSimilarityThreshold: number
  ): Promise<Array<any>> {
    let recordsIds: string[] = recordsToSave;
    if (recordsIds.length === 0) return [];

    let compareIds = await this.m_RecordService.compareRecords(recordsIds);
    const highScoreRecords = compareIds.filter(
      (item: any) => item.score > recordSimilarityThreshold
    );

    return highScoreRecords;
  }

  async updateRecords(
    records: RECORD[] | null,
    isSearching = false
  ): Promise<UpdateResults> {
    let recordsUpdated: RECORD[] = [];
    let recordsRestored = false;

    if (records != null) {
      if (this.m_Records == null) this.m_Records = [];

      //Update m_Records, updating existing records and adding new ones
      for (let record of records) {
        let existingRecord = this.m_Records?.find(
          (r) => r.record_id == record.record_id
        );
        if (existingRecord && !existingRecord.unsaved) {
          existingRecord.copy(record);
          recordsUpdated.push(existingRecord);
        } else if (existingRecord == null) {
          this.m_Records?.push(record);
        }
      }

      //Remove records that no longer exist
      //for (let i = this.m_Records?.length - 1; i >= 0; i--) {
      //  let record = this.m_Records[i];
      //  if (record.record_id == "" || record.record_id.startsWith("unsaved"))
      //    continue;
      //
      //  let existingRecord = records.find(
      //    (r) => r.record_id == record.record_id
      //  );
      //  if (!existingRecord) {
      //    this.m_Records?.splice(i, 1);
      //  }
      //}

      this.m_Records = this.m_Records.filter(
        (record) => record.video_id == this.m_VideoId
      );

      if (this.m_QuestionsQueue.length > 0) {
        this.addQuestions(this.m_QuestionsQueue, false);
        this.m_QuestionsQueue = [];
        this.m_QueueScroll = true;
      }

      let key = "record_unsaved_ids_" + this.m_VideoId;
      let unsavedIdsStr = await this.m_Storage.get(key);
      let unsavedIds = [];

      try {
        unsavedIds = JSON.parse(unsavedIdsStr);
      } catch (exc) {}

      recordsRestored = false;
      for (let id of unsavedIds ?? []) {
        if (this.m_Records.find((item) => item.record_id == id) == null) {
          // Get from storage if exists
          let key = "record_unsaved_changes_" + this.m_VideoId + "_" + id;
          let recordStr = JSON.parse(await this.m_Storage.get(key));

          let record = new RECORD();
          record.record_id = id;
          record.video_id = this.m_VideoId;

          if (recordStr) {
            record.question = recordStr.question;
            record.answer = recordStr.answer;
            record.is_pinned = recordStr.is_pinned;
            record.is_suggestible = recordStr.is_suggestible;
          }

          //Add on the top of records
          this.m_Records.unshift(record);
          recordsRestored = true;
        }
      }

      //TODO add sorting on backend, for now sort here
      //Sort by create date
      // this.m_Records?.sort((a, b) => {
      //   if (a.create_date == null) return 1;
      //   if (b.create_date == null) return -1;
      //   return a.create_date.getTime() - b.create_date.getTime();
      // });

      if (unsavedIds && unsavedIds.length) {
        this.m_TotalRecords += unsavedIds.length;
      }
    } else {
      //Failed to get records for the video, null records to indicate failure
      this.m_Records = null;
    }

    let updateResults: UpdateResults = {
      recordsUpdated: recordsUpdated,
      restored: recordsRestored,
      queueScroll: this.m_QueueScroll,
    };

    //Reset queue scroll
    this.m_QueueScroll = false;
    return updateResults;
  }

  async exportCSV(): Promise<Blob> {
    return await this.m_RecordService.exportCSV(this.m_VideoId);
  }
  //#endregion
  //--------------------------------------------------------------------------------
  //#region Private Methods
  private async addUnsavedQuestion(record: RECORD) {
    // Add as unsaved changes
    await this.m_Storage.set(
      "record_unsaved_changes_" + record.video_id + "_" + record.record_id,
      JSON.stringify(record)
    );

    // Add unsaved id
    if (record.record_id == null) return;

    let key = "record_unsaved_ids_" + this.m_VideoId;
    let unsavedIdsStr = await this.m_Storage.get(key);
    let unsavedIds = null;
    try {
      unsavedIds = JSON.parse(unsavedIdsStr);
    } catch (exc) {}

    if (unsavedIds == null) unsavedIds = [];
    if (unsavedIds.indexOf(record.record_id) < 0) {
      unsavedIds.push(record.record_id);
      await this.m_Storage.set(key, JSON.stringify(unsavedIds));
    }
  }
  //#endregion
}
