import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { ContextMenuComponent } from "./context-menu.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [IonicModule, CommonModule, MatTooltipModule],
  declarations: [ContextMenuComponent],
  exports: [ContextMenuComponent],
})
export class ContextModule {}
