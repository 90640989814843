import {
  MEDIA_DTO,
  MEDIA_DTO_GET_MEDIA_RES,
  MEDIA_DTO_LIST_MEDIA_RES,
  MEDIA_DTO_UPLOAD_IMAGE_RES,
  MEDIA_TYPE,
} from "@shared/models/media/media";
import { BaseApiService } from "../base-api-service";
import { UserService } from "src/app/services/user/user.service";
import { BASE_RESPONSE } from "@shared/models/api/api";
import { Events } from "src/app/services/events/events.service";

export class MediaApi extends BaseApiService {
  //Create private readonly values for media endpoints
  private static readonly mediaGetEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/media",
  };

  private static readonly mediaGetAMediaEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/media/{{mediaID}}",
  };

  private static readonly mediaDeleteEndpoint = {
    method: "DELETE",
    path: "/api/video/{{videoID}}/media/{{mediaID}}",
  };

  private static readonly mediaUploadEndpoint = {
    method: "POST",
    path: "/api/video/{{videoID}}/media/image",
  };

  private static readonly mediaUploadVideoEndpoint = {
    method: "POST",
    path: "/api/video/{{videoID}}/media/video",
  };

  private static readonly mediaUploadVideoCompleteEndpoint = {
    method: "PATCH",
    path: "/api/video/{{videoID}}/media/{{mediaID}}/uploaded",
  };

  private static readonly mediaEncodingProgressEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/media/{{mediaID}}/encoding/progress",
  };

  private static readonly mediaIndexingProgressEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/media/{{mediaID}}/indexer/progress",
  };

  //--------------------------------------------------------------------
  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }
  //--------------------------------------------------------------------
  //#region Public Methods
  /**
   * Gets a list of media items for the given video and type, paginated
   * @param videoID
   * @param type
   * @param page_number
   * @param page_size
   * @param query (optional)
   * @returns
   */
  async getMedia(
    videoID: string,
    type: MEDIA_TYPE,
    page_number: number,
    page_size: number,
    query?: string,
    nlp?: boolean
  ) {
    let url = MediaApi.mediaGetEndpoint.path.replace("{{videoID}}", videoID);
    const params = new Map<string, string>();
    params.set("type", type.toString());
    params.set("page_number", page_number.toString());
    params.set("page_size", page_size.toString());

    if (query != null && query.trim() != "") {
      params.set("query", query.trim());
    }
    params.set("qt", nlp ? "nlp" : "name");

    url = this.addParams(url, params);

    let apiRequest = {
      method: MediaApi.mediaGetEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: MEDIA_DTO_LIST_MEDIA_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Media Failed",
      3,
      200
    );

    return response;
  }

  /**
   * Gets a single media item for the given video and media ID
   * @param videoID
   * @param mediaID
   * @returns
   */
  async getAMedia(videoID: string, mediaID: string) {
    let url = MediaApi.mediaGetAMediaEndpoint.path
      .replace("{{videoID}}", videoID)
      .replace("{{mediaID}}", mediaID);

    let apiRequest = {
      method: MediaApi.mediaGetAMediaEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: MEDIA_DTO_GET_MEDIA_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Media Failed",
      3,
      200
    );

    return response;
  }

  /**
   * Deletes a media item for the given video
   * @param videoID
   * @param mediaID
   * @returns
   */
  async deleteMedia(videoID: string, mediaID: string) {
    let url = MediaApi.mediaDeleteEndpoint.path
      .replace("{{videoID}}", videoID)
      .replace("{{mediaID}}", mediaID);

    let apiRequest = {
      method: MediaApi.mediaDeleteEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Delete Media Failed",
      3,
      200
    );

    return response;
  }

  /**
   * Uploads an image, associates it with a video, and returns the new media DTO
   * @param videoID
   * @param file
   * @returns
   */
  async uploadImage(
    videoID: string,
    file: File,
    onProgress: (event: ProgressEvent) => void
  ): Promise<MEDIA_DTO_UPLOAD_IMAGE_RES> {
    let url = MediaApi.mediaUploadEndpoint.path.replace("{{videoID}}", videoID);

    let formData = new FormData();
    formData.append("file", file);

    const response: MEDIA_DTO_UPLOAD_IMAGE_RES = await this.makeRequestWithFile(
      url,
      MediaApi.mediaUploadEndpoint.method,
      file,
      this.m_UserService.SessionId,
      onProgress
    );

    return response;
  }

  async createVideoMedia(
    videoID: string,
    file_name: string
  ): Promise<MEDIA_DTO_UPLOAD_IMAGE_RES> {
    let url = MediaApi.mediaUploadVideoEndpoint.path.replace(
      "{{videoID}}",
      videoID
    );

    let apiRequest = {
      method: MediaApi.mediaUploadVideoEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_name: file_name,
      }),
    };

    const response: MEDIA_DTO_UPLOAD_IMAGE_RES =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Upload Video Failed",
        3,
        200
      );

    return response;
  }

  async completeVideoMedia(
    videoID: string,
    mediaID: string
  ): Promise<BASE_RESPONSE> {
    let url = MediaApi.mediaUploadVideoCompleteEndpoint.path
      .replace("{{videoID}}", videoID)
      .replace("{{mediaID}}", mediaID);

    let apiRequest = {
      method: MediaApi.mediaUploadVideoCompleteEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Upload Video Failed",
      3,
      200
    );

    return response;
  }

  /**
   * Gets the encoding progress for a media item
   * @param videoID
   * @param mediaID
   * @returns
   */
  async getEncodingProgress(videoID: string, mediaID: string) {
    let url = MediaApi.mediaEncodingProgressEndpoint.path
      .replace("{{videoID}}", videoID)
      .replace("{{mediaID}}", mediaID);

    let apiRequest = {
      method: MediaApi.mediaEncodingProgressEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: { status: number; progress: number } =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Get Encoding Progress Failed",
        3,
        200
      );

    return response;
  }

  /**
   * Gets the indexing progress for a media item
   * @param videoID
   * @param mediaID
   * @returns
   */
  async getIndexingProgress(videoID: string, mediaID: string) {
    let url = MediaApi.mediaIndexingProgressEndpoint.path
      .replace("{{videoID}}", videoID)
      .replace("{{mediaID}}", mediaID);

    let apiRequest = {
      method: MediaApi.mediaIndexingProgressEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: { status: number; progress: number } =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Get Indexing Progress Failed",
        3,
        200
      );

    return response;
  }
  //#endregion
}
