// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-settings ion-checkbox {
  --border-color: #3d657399;
  --border-width: 1px;
  --checkmark-color: white;
  --size: 0.9rem;
  font-size: 14px;
  font-weight: 500;
}
.account-settings label {
  margin-left: 1em;
  font-size: 14px;
  font-weight: 500;
}
.account-settings ion-button {
  width: 190px;
}
.account-settings ion-spinner {
  width: 1.5em;
  height: 1.5em;
}
.account-settings .error-input {
  border-color: red;
}
.account-settings .error-input:focus-visible, .account-settings .error-input:focus {
  outline: none;
}
.account-settings .error-text {
  color: red;
  font-size: 12px;
  margin: -10px 0 0;
}

.account-settings-content {
  padding: 2.5em 3em;
}

.account-settings-input {
  margin-bottom: 1em;
}

.account-settings-mail:focus {
  outline: none;
}

.account-settings-description {
  opacity: 0.6;
}
.account-settings-description span {
  font-weight: bold;
}

.account-settings-checkbox-content {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile/account-settings/account-settings.component.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,mBAAA;EACA,wBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,YAAA;EACA,aAAA;AAHJ;AAOI;EACE,iBAAA;AALN;AAMM;EAEE,aAAA;AALR;AAQI;EACE,UAAA;EACA,eAAA;EACA,iBAAA;AANN;;AAWA;EACE,kBAAA;AARF;;AAWA;EACE,kBAAA;AARF;;AAYE;EACE,aAAA;AATJ;;AAaA;EACE,YAAA;AAVF;AAYE;EACE,iBAAA;AAVJ;;AAcA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AAXF","sourcesContent":[".account-settings {\n  ion-checkbox {\n    --border-color: #3d657399;\n    --border-width: 1px;\n    --checkmark-color: white;\n    --size: 0.9rem;\n    font-size: 14px;\n    font-weight: 500;\n  }\n\n  label {\n    margin-left: 1em;\n    font-size: 14px;\n    font-weight: 500;\n  }\n\n  ion-button {\n    width: 190px;\n  }\n\n  ion-spinner {\n    width: 1.5em;\n    height: 1.5em;\n  }\n\n  .error {\n    &-input {\n      border-color: red;\n      &:focus-visible,\n      &:focus {\n        outline: none;\n      }\n    }\n    &-text {\n      color: red;\n      font-size: 12px;\n      margin: -10px 0 0;\n    }\n  }\n}\n\n.account-settings-content {\n  padding: 2.5em 3em;\n}\n\n.account-settings-input {\n  margin-bottom: 1em;\n}\n\n.account-settings-mail {\n  &:focus {\n    outline: none;\n  }\n}\n\n.account-settings-description {\n  opacity: 0.6;\n\n  span {\n    font-weight: bold;\n  }\n}\n\n.account-settings-checkbox-content {\n  display: flex;\n  align-items: center;\n  margin: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
